import ExportNotifyApi from "@/Api/ExportNotifyApi";
import { getBatchInfo } from "@/Api/StockSlotController";
import { toYMD } from "@/Common/date";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { BatchInfo } from "@/Models/Other/BatchInfo";
import { ViewExportNotify } from "@/Models/Views/ViewExportNotify";
import { Checkbox, message, Modal } from "antd";
import dayjs from "dayjs";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

export function UpdBatchNoComponent({ record }: { record: ViewExportNotify; }) {
    const [show, setShow] = useState(false);
    const onShow = useCallback(() => setShow(true), []);
    const onHideShow = useCallback(() => setShow(false), []);
    const text = useMemo(() => {
        if (record.batchNo && record.batchNo.length > 0) {
            return record.batchNo;
        }
        if (record.isAuto) {
            return '设置批次';
        }
        else {
            return '自动出库';
        }
    }, [record.batchNo, record.isAuto]);
    return (<Fragment>
        <TableInnerButton type="link" onClick={onShow}>{text}</TableInnerButton>
        {show && <ChooseBatch record={record} onCancel={onHideShow} />}
    </Fragment>);
}

function ChooseBatch({ record, onCancel }: { record: ViewExportNotify; onCancel: () => void }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<BatchInfo[]>([]);
    const [batchNo, setBatchNo] = useState(record.batchNo?.split(',') || []);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getBatchInfo(record.goodsId);
                const d2 = d1.map(s => {
                    if (!s.batchNo) s.batchNo = "*";
                    return s;
                }).sort((a, b) => a.time - b.time);
                if (!d2.some(s => s.batchNo === "*")) {
                    d2.push({ batchNo: "*", quantity: 0, time: dayjs().valueOf() });
                }
                setData(d2);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [record.goodsId]);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            let r1;
            if (batchNo.length === 0) {
                if (record.isAuto === "LockAuto") {
                    const msg = "该单据已下发,必须选择一个批次!";
                    message.error(msg);
                    throw msg;
                }
                r1 = await ExportNotifyApi.updBatchNo(record.id, null);
            }
            else {
                if (batchNo.some(s => s === "*")) {
                    r1 = await ExportNotifyApi.updBatchNo(record.id, "*");
                }
                else {
                    r1 = await ExportNotifyApi.updBatchNo(record.id, batchNo.join(','));
                }
            }
            if (r1 > 0) onCancel();
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [batchNo, onCancel, record]);

    const options = useMemo(() => {
        const d1 = data.map(s => ({ label: `${s.batchNo}[${toYMD(s.time)},${s.quantity}]`, value: s.batchNo }));
        return d1;
    }, [data]);

    console.log(options, data, batchNo);

    return <Modal open confirmLoading={loading} title="选择出库批次" onCancel={onCancel} onOk={onOk} >
        <Checkbox.Group options={options} value={batchNo} onChange={s => setBatchNo(s as any)} />
    </Modal>;
}