import EquipmentTaskList from "./EquipmentTaskList";
import { ImportEmptyStockApply } from "./ImportEmptyStockApply";
import './index.scss';
import { MoveInLaneway } from "./MoveInLaneway";

export default Index;

function Index() {
    return (
        <div className="test-root">
            <div className="actions">
                <ImportEmptyStockApply />
                {/* <ImportApply />
                <ExportApply />
                <ExportEmptyStockApply /> */}
                <MoveInLaneway />
            </div>
            <EquipmentTaskList />
        </div>

    );
}
