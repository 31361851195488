import { toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { MessageItem } from "@/Hubs/IActions/IDeviceMessageHubAction";
import { List, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { Fragment, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { DeviceMessageProvider, DeviceMessageProviderContext } from "./DeviceMessageProvider";

export default Index;

function Index() {
    const provider = useCreateHubProvider(DeviceMessageProvider);
    const [data, setData] = useState(provider.data.Value);
    useEffect(() => provider.data.listen(setData), [provider]);

    const options = useMemo(() => Object.keys(data).map(s => ({ label: s, value: s })), [data]);

    useEffect(() => {
        setSelectKey(options[0]?.value);
    }, [options]);

    const [selectKey, setSelectKey] = useState(options[0]?.value);
    const radioOnChange = useCallback((s: RadioChangeEvent) => setSelectKey(s.target.value), []);
    const [dataSource, setDataSource] = useState<string[]>(resolveMessageItem(data[selectKey]?.Value));

    useEffect(() => {
        if (selectKey) {
            setDataSource(resolveMessageItem(data[selectKey].Value));
            return data[selectKey].listen(s => setDataSource(resolveMessageItem(s)));
        }
    }, [data, selectKey]);

    if (options.length > 0) {
        return <DeviceMessageProviderContext.Provider value={provider}>
            <Radio.Group value={selectKey} options={options} optionType="button" onChange={radioOnChange} />
            <List style={{ marginTop: 16, height: 700, overflow: "auto" }} dataSource={dataSource} size="small" pagination={false} bordered renderItem={item => <List.Item>{item}</List.Item>} />
        </DeviceMessageProviderContext.Provider>;
    }
    else {
        return <Fragment />;
    }
}

function resolveMessageItem(items: MessageItem[]) {
    return items?.map(item => `[${toYMDHMS(item.time)}]${item.message}`);
}


