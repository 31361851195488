import { StackerInfo } from "@/Models/Other/StackerInfo";
import { BaseHub, tpOnError } from "./BaseHub";
import { IStackerHubAction } from "./IActions/IStackerHubAction";

export class StackerHub extends BaseHub<IStackerHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/Stacker");
    }
    public getInfos = () => this.hub.invoke<StackerInfo[]>("GetInfos");
}


