import { resolveEquipmentTime } from "@/Common/date";
import { resolveConveyerFaultInfo } from "@/Common/fault";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { ConveyerInfo } from "@/Models/Other/ConveyerInfo";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { ConveyerProvider, ConveyerProviderContext } from "./ConveyerProvider";

export default Index;

function getColumns(): ColumnType<ConveyerInfo>[] {
    return [
        numProps("stationNo", "站台号", 100, true, (_, r) => `${r.plcNo}-${r.stationNo}`, true),
        numProps("status", "设备状态", 120, true),
        numProps("taskNo", "任务号", 120, true, s => s.toString(), true),
        numProps("stockCodeValue", "托盘条码值", 140, true),
        numProps("runtime", "运行时间", 140, true, resolveEquipmentTime, true),
        numProps("whereToGo", "去向号", 120, true),
        strProps("fault", "故障", undefined as any, true, true, s => resolveConveyerFaultInfo(s).join(',')),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const taskProvider = useCreateHubProvider(ConveyerProvider);
    const [data, setData] = useState(taskProvider.data.Value);
    useEffect(() => taskProvider.data.listen(setData), [taskProvider]);
    return <ConveyerProviderContext.Provider value={taskProvider}>
        <CommonTable rowKey={s => `${s.plcNo}-${s.stationNo}`} columns={columns} dataSource={data} />
    </ConveyerProviderContext.Provider>;
}

