import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getStatusString, StackerInfo } from '@/Models/Other/StackerInfo';
import { Obs } from '@/Common/Obs';
import SgvStacker from './images/Stacker';
import { StackerPositionProviderContext } from './StackerPositionProvider';

export function Stacker({ data }: { data: Obs<StackerInfo> }) {
    const [info, setInfo] = useState<StackerInfo>(data.Value);
    const imgRef = useRef<HTMLDivElement>(null);
    const provider = useContext(StackerPositionProviderContext);
    useEffect(() => {
        return data.listen(setInfo);
    }, [data]);
    useLayoutEffect(() => {
        if (imgRef.current != null) {
            const { left, top } = provider.getPosition(info);
            imgRef.current.style.top = `${top}px`;
            imgRef.current.style.left = `${left}px`;
            setStackerColor(imgRef.current, info);
        }
    }, [info, provider]);

    return (<div ref={imgRef} className='stacker'>
        <SgvStacker fill='red' isFault={info.faults !== "AAAAAAAAAAAAAAAAAAAAAAAAAAA="} canExp={info.taskInfoStatus === 1} canImp={info.taskInfoStatus === 1} controlMode={info.controlMode} />
        <div>{getStatusString(info.status)}</div>
    </div>);
}

function setStackerColor(img: HTMLDivElement, info: StackerInfo) {
    if (info.status === 1) {// 空闲状态
        img.style.color = `#49aa19`;
    }
    else if (info.status === 2) {// 执行任务中
        img.style.color = `#2e3c10`;
    }
    else if (info.status === 3) {// 执行任务中
        img.style.color = `#3e4f13`;
    }
    else if (info.status === 4) {// 执行任务中
        img.style.color = `#536d13`;
    }
    else if (info.status === 5) {// 执行任务中
        img.style.color = `#177ddc`;
    }
    else if (info.status === 6) {
        img.style.color = `#642ab5`;
    }
    else if (info.status === 7) {
        img.style.color = `#d8bd14`;
    }
}
