import ImportBillApi from "@/Api/ImportBillApi";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMDHMS } from "@/Common/date";
import { BillExecuteFlag, BillExecuteFlagNames, getBillExecuteFlagName } from "@/Models/Enums/BillExecuteFlag";
import { BillSplitPageProps } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { expandable } from "../Bill/expandable";
import { PrintBillDetail } from "./PrintBillDetail";
import { SearchComponent } from "./SearchComponent";

function getColumns(onPrint: (data: ViewImportBill) => void): ColumnType<ViewImportBill>[] {
  return [
    strProps("typeName", "单据类型", 100, true, true),
    strProps("batchNo", "批次号", 140, true, true),
    strProps("contractNumber", "合同号", 140, true, true),
    strProps("code", "入库单编码", 200, true, true),
    strProps("supplierName", "供应商名称", 200, true, true),
    strProps("customerName", "客户名称", 200, true, true),
    numProps("quantity", "计划数量", 100),
    numProps("factQuantity", "已下发数量", 120),
    numProps("completeQuantity", "已完成数量", 120),
    strProps("executeFlag", "执行标志", 100, true, true, getBillExecuteFlagName),
    strProps("userName", "创建人", 160, true, true),
    numProps("time", "建单时间", 160, true, toYMDHMS),
    { title: "打印", key: "print", width: 100, render: (_, r) => <TableInnerButton type="link" onClick={() => onPrint(r)}>打印</TableInnerButton> }
  ];
}

export default QueryBill; function QueryBill() {
  const [printData, setPrintData] = useState<ViewImportBill>();
  const columns = useMemo(() => getColumns(setPrintData), []);
  const searchProps = useRef<BillSplitPageProps>({ current: 1, pageSize: 13 });
  const [data, setData] = useState<PaginationData<ViewImportBill>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
  const [loading, setLoading] = useState(false);
  const query = useCallback((current: number, pageSize: number) => {
    setLoading(true);
    searchProps.current.current = current;
    searchProps.current.pageSize = pageSize;
    ImportBillApi.getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
  }, []);
  const onSearch = useCallback((data: {}) => {
    searchProps.current = { ...searchProps.current, ...data, current: 1 };
    query(searchProps.current.current, searchProps.current.pageSize);
  }, [query]);
  useEffect(() => {
    onSearch({});
  }, [onSearch]);

  return <Fragment>
    <SearchComponent onSearch={onSearch} flagOptions={Object.keys(BillExecuteFlagNames).map((s: any) => ({ label: (BillExecuteFlagNames as any)[s], value: BillExecuteFlag[s] }))} />
    <CommonTable rowKey={s => s.id} expandable={expandable} heightOffset={83} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, onChange: query, current: data.current, showSizeChanger: true }} />
    {printData && <PrintBillDetail record={printData} onCancel={() => setPrintData(undefined)} />}
    <PrintFooter data={data.items} title="入库单" columns={columns} />
  </Fragment>;
}


