import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { BaseHub, tpOnError } from "./BaseHub";
import './HubError.scss';

function Error({ err }: { err: string | null }) {
    if (err) return <div className="kedle-hub-error">{err}</div>;
    else return <></>;
}

export const HubErrorContext = createContext<tpOnError>({} as any);

export function HubError(props: PropsWithChildren<{}>) {
    const [error, setError] = useState<string | null>(null);
    const provider = useMemo(() => new ErrorProvider(setError), [])
    return <HubErrorContext.Provider value={provider.setError}>
        {props.children}
        <Error err={error} />
    </HubErrorContext.Provider>;
}

export function useCreateHubProvider<T extends { hub: BaseHub<{}> }>(c: new (onError: tpOnError) => T): T {
    const onError = useContext(HubErrorContext);
    const provider = useMemo<T>(() => new c(onError), [c, onError]);
    useEffect(() => provider.hub.stop(), [provider]);
    return provider;
}

class ErrorProvider {
    private _onError: (msg: string | null) => void;
    private _data: { [K: string]: string | null };
    constructor(onError: (msg: string | null) => void) {
        this._onError = onError;
        this._data = {};
    }

    public readonly setError = (msg: string | null, url: string) => {
        if (msg) {
            this._data[url] = msg;
        }
        else {
            delete this._data[url];
        }
        var keys = Object.keys(this._data);
        if (keys.length > 0) {
            this._onError(this._data[keys[0]]);
        }
        else {
            this._onError(null);
        }
    }
}