import SysUser from './SysUser';
import SysUserGroup from './SysUserGroup';
import AccessLog from './AccessLog';
import RestLog from './RestLog';
import {
    LaptopOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    TrophyOutlined,
    LineHeightOutlined,
    RestOutlined,
} from '@ant-design/icons';
import RightManager from './RightManager';
import { IMenuGroup } from '../../routeData';


function systemGroup(): IMenuGroup {
    return {
        name: "系统管理",
        icon: LaptopOutlined,
        path: "system",
        items: [
            {
                name: "用户管理",
                icon: TeamOutlined,
                element: <SysUser />,
                path: "SysUser",
                right: 1,
            },
            {
                name: "用户组管理",
                icon: UsergroupAddOutlined,
                element: <SysUserGroup />,
                path: "SysUserGroup",
                right: 1 << 1,
            },
            {
                name: "权限管理",
                icon: TrophyOutlined,
                element: <RightManager />,
                path: "RightManager",
                right: 1 << 2,
            },
            {
                name: "系统访问日志",
                icon: LineHeightOutlined,
                element: <AccessLog />,
                path: "AccessLog",
                right: 1 << 3,
            },
            {
                name: "接口调用日志",
                icon: RestOutlined,
                element: <RestLog />,
                path: "RestLog",
                right: 1 << 3,
            },
        ]
    };
}
export default systemGroup;