export enum UploadFlag {
    WaitingUpload = 0,
    Success = 1,
    Fault = 2
}

export type TypeUploadFlag = keyof typeof UploadFlag;

const UploadFlagNames: { [K in TypeUploadFlag]: string } = {
    WaitingUpload: "待上传",
    Success: "成功",
    Fault: "失败",
};

export function getUploadFlagNames(e: TypeUploadFlag) {
    return UploadFlagNames[e];
}
