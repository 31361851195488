import { get, post } from '@/Api';
import { BaseLedRect, LedDevice, LedDisplay } from '@/Models/Tables/Led';
import { ViewSlot } from '@/Models/Views/ViewSlot';

const name = 'LedDevice';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export const getAll = () => get<LedDevice[]>(getUrl("getAll"));
export const writeLed = (data: WriteLedParams) => post<ViewSlot>(getUrl("writeLed"), data);
export const setDisplays = (data: LedDisplay[]) => post<number>(getUrl("setDisplays"), data);
export const getDisplays = (deviceId: number) => get<LedDisplay[]>(getUrl("getDisplays"), { deviceId });
export const reloadDevice = () => get<any>(getUrl("ReloadDevice"));
export const getCurrentShowInfo = (name: string) => get<BaseLedRect[]>(getUrl("GetCurrentShowInfo"), { name });

export interface WriteLedParams {
    ledDeviceId: number;
    displays: BaseLedRect[];
}