import { getViews, updInfo } from "@/Api/ExitPortController";
import { boolProps, numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { getPortTypeNames } from "@/Models/Enums/PortType";
import { ExitPort } from "@/Models/Tables/ExitPort";
import { ViewExitPort } from "@/Models/Views/ViewExitPort";
import { Fragment, useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import Add from "./Add";

export default Index;

function getColumns(onEditData: (r: ViewExitPort) => void): ColumnType<ViewExitPort>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("warehouseName", "库房名称", 200, true, true),
        numProps("stationNo", "站台号", 120, true),
        strProps("name", "出库口名称", 160, true, true),
        strProps("type", "站台类型", 120, true, true, getPortTypeNames),
        strProps("platformName", "月台名称", 160, true, true),
        numProps("maxTaskCount", "并发任务数", 120, true),
        boolProps("disabled", "状态", 120, true, s => s ? "禁用" : "正常"),
        { title: "操作", key: "action", width: 80, render: (_, r) => <TableInnerButton type="link" onClick={() => onEditData(r)} >修改</TableInnerButton> }
    ];
}

function Index() {
    const [data, setData] = useState<ViewExitPort[]>([]);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState<ViewExitPort>();
    const onSubmit = useCallback(async (data: ExitPort) => {
        try {
            setLoading(true);
            const res = await updInfo(data);
            setData(s => {
                const index = s.findIndex(s => s.id === res.id);
                s.splice(index, 1, res);
                return [...s];
            });
            setEditData(undefined);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, []);
    const onCancel = useCallback(() => setEditData(undefined), []);
    const columns = useMemo(() => getColumns(setEditData), []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setData(await getViews());
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    return (
        <Fragment>
            <CommonTable rowKey={s => s.id} loading={loading} dataSource={data} columns={columns} />
            {editData && <Add data={editData} onSubmit={onSubmit} onCancel={onCancel} />}
        </Fragment>
    );
}