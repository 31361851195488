import { get, post } from '@/Api';
import { ContractInvoice } from "@/Models/Tables/ContractInvoice";
import { ViewContractInvoice } from '@/Models/Views/ViewContractInvoice';

const name = 'ContractInvoice';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewContractInvoice[]> {
    return get(getUrl("getViews"));
}
export function add(data: ContractInvoice): Promise<ViewContractInvoice> {
    return post(getUrl("add"), data);
}
export function upd(data: ContractInvoice): Promise<ViewContractInvoice> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
