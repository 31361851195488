import { cancel } from "@/Api/ImportOrderController";
import { CommonTable } from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getOrderExecuteFlagNames } from "@/Models/Enums/OrderExecuteFlag";
import { ViewImportOrder } from "@/Models/Views/ViewImportOrder";
import { Popconfirm } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Add from "./Add";
import { ImportOrderHubProvider, ImportOrderHubProviderContext } from "./ImportOrderHubProvider";

function getColumns(action: ColumnType<ViewImportOrder>): ColumnType<ViewImportOrder>[] {
    return [
        strProps("orderNo", "入库流水号", 140, true, true),
        strProps("billCode", "入库单号", 150, true, true),
        strProps("batchNo", "批次号", 120, true, true),
        strProps("goodsName", "物品名称", 300, true, true),
        strProps("standard", "规格", 220, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("slotCode", "储位编码", 120, true, true),
        strProps("executeFlag", "执行标志", 120, true, true, getOrderExecuteFlagNames),
        action,
        strProps("userName", "操作员", 100, true, true),
        numProps("time", "添加日期", 160, true, toYMDHMS),
        strProps("remark", "备注", 120, true, true),
    ];
}

function getTableAction(onAdd: () => void, onChange: (d: ViewImportOrder) => void): ColumnType<ViewImportOrder> {
    return {
        title: <TableInnerButton type="primary" onClick={onAdd}>添加</TableInnerButton>,
        // title: "操作",
        key: 'action',
        width: 120,
        render: (_, data) => <CancelOperation data={data} onChange={onChange} />
    };
}

function CancelOperation({ data: record, onChange }: { data: ViewImportOrder, onChange: (d: ViewImportOrder) => void }) {
    const [loading, setLoading] = useState(false);
    const onCancel = useCallback(() => {
        setLoading(true);
        cancel(record.id).then(onChange).catch(console.error).finally(() => setLoading(false));
    }, [onChange, record.id]);
    if (record.executeFlag === "WaitingExecute") {
        return (<Popconfirm title={`您确定要取消入库流水${record.orderNo}吗?`} disabled={loading} onConfirm={onCancel}>
            <TableInnerButton loading={loading} type="link">取消</TableInnerButton>
        </Popconfirm>);
    }
    else {
        return <></>;
    }
}

export default Index;
function Index() {
    const provider = useCreateHubProvider(ImportOrderHubProvider);
    const [data, setData] = useState<ViewImportOrder[]>(provider.data.Value);
    const [loading, setLoading] = useState(provider.loading.Value);
    const [showAdd, setShowAdd] = useState(false);
    const onAdd = useCallback(() => setShowAdd(true), []);
    const onCancel = useCallback(() => setShowAdd(false), []);
    const onChange = useCallback((d: ViewImportOrder) => {
        setData(s => {
            const index = s.findIndex(a => a.id === d.id);
            if (index > -1) {
                s[index] = d;
                return [...s];
            }
            return s;
        });
    }, []);
    const columns = useMemo(() => getColumns(getTableAction(onAdd, onChange),), [onAdd, onChange]);
    useEffect(() => {
        const f1 = provider.loading.listen(setLoading);
        const f2 = provider.data.listen(setData);
        return () => {
            f1();
            f2();
            provider.hub.stop();
        };
    }, [provider]);
    return (<ImportOrderHubProviderContext.Provider value={provider}>
        <Fragment>
            <CommonTable loading={loading} rowKey={s => s.id} dataSource={data} columns={columns} />
            {showAdd && <Add onCancel={onCancel} />}
        </Fragment>
    </ImportOrderHubProviderContext.Provider >);
}