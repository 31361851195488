import { add, del, getViews, upd } from "@/Api/SysUserGroupController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { SysUserGroup } from "@/Models/Tables/SysUserGroup";
import { ViewSysUserGroup } from "@/Models/Views/ViewSysUserGroup";
import React, { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewSysUserGroup>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("name", "用户组名称", 200, true, true),
        strProps("code", "用户组编码", 200, true, true),
        numProps("userCount", "用户数量", 80, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable<SysUserGroup, ViewSysUserGroup > getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewSysUserGroup, target: SysUserGroup) {
    target.id = source.id;
    return target;
}