import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { Customer } from "@/Models/Tables/Customer";
import { Form, Input } from "antd";
import { useCallback } from "react";

function Item(props: IFormItemProps<Customer>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<Customer>) {
    const [form] = Form.useForm();
    const onOk = useCallback(() => {
        form.validateFields().then(onSubmit);
    }, [form, onSubmit]);
    return <MyModal name="客户信息" data={data} width={360} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data} labelCol={{ span: 8 }}>
            <Item name="name" label="客户名称" required >
                <Input ></Input>
            </Item>
            <Item name="code" label="客户编码" required >
                <Input ></Input>
            </Item>
            <Item name="linkMan" label="联系人" required >
                <Input ></Input>
            </Item>
            <Item name="phoneNumber" label="电话" >
                <Input ></Input>
            </Item>
            <Item name="address" label="地址" >
                <Input ></Input>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;