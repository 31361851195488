import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ViewSysUser } from "@/Models/Views/ViewSysUser";
import ButtonGroup from "antd/lib/button/button-group";
import React, { useCallback } from "react";
import { FunctionComponent } from "react";

export interface TypeEditData {
    data?: ViewSysUser,
    type?: "editInfo" | "editPwd"
}

interface SysUserActionProps {
    record: ViewSysUser,
    onEdit: (data: TypeEditData) => void;
}

const SysUserAction: FunctionComponent<SysUserActionProps> = ({ record, onEdit }) => {
    const editInfo = useCallback(() => onEdit({ data: record, type: "editInfo" }), [onEdit, record]);
    const editPwd = useCallback(() => onEdit({ data: record, type: "editPwd" }), [onEdit, record]);
    return (
        <ButtonGroup>
            <TableInnerButton type="default" onClick={editInfo}>修改信息</TableInnerButton>
            <TableInnerButton type="default" onClick={editPwd}>修改密码</TableInnerButton>
        </ButtonGroup>
    );
};

export default SysUserAction;
