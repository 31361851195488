import { Listen } from "@/Common/Listen";
import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { GlobalHub } from "@/Hubs/GlobalHub";
import { NetworkSpeedData } from "@/Models/Other/NetworkSpeedData";
import { SysUserToken } from "@/Models/Other/SysUserToken";
import { createContext } from "react";

export class GlobalProvider {
    public readonly hub: GlobalHub;
    constructor(onError: tpOnError) {
        this.hub = new GlobalHub(onError);
        this.hub.on("UserChange", s => {
            if (s === this.user.Value?.id) {
                this.refresh.notify();
            }
        });
        this.hub.on("RightChange", s => {
            if (s === this.user.Value?.sysUserGroupId) {
                this.refresh.notify();
            }
        });
        this.hub.on("NetworkSpeedChange", s => {
            this.networkSpeed[(s.plcNo)].Value = s;
        });
        this.hub.start();
    }
    public readonly user = new Obs<SysUserToken | null>(null);
    public readonly refresh = new Listen<void>();
    public readonly networkSpeed: { [K: number]: Obs<NetworkSpeedData> } = {
        1: new Obs<NetworkSpeedData>({ plcNo: 1, speed: 0, plcSpeed: 0 }),
        2: new Obs<NetworkSpeedData>({ plcNo: 2, speed: 0, plcSpeed: 0 }),
        4: new Obs<NetworkSpeedData>({ plcNo: 4, speed: 0, plcSpeed: 0 }),
        3: new Obs<NetworkSpeedData>({ plcNo: 3, speed: 0, plcSpeed: 0 }),
        5: new Obs<NetworkSpeedData>({ plcNo: 5, speed: 0, plcSpeed: 0 }),
    };
}

export const GlobalProviderContext = createContext<GlobalProvider>(null as any);