import { getIps, getMethods, getRestUrls } from "@/Api/AccessLogController";
import { utcEndSecond, utcStartSecond } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { AccessLogSplitPageProps, ExcludeBasePagination as Ext } from "@/Models/Other/BaseSplitPageProps";
import { Button, DatePicker, Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";


export function SearchComponent({ onSearch }: { onSearch: (data: Ext<AccessLogSplitPageProps>) => void; }) {
    const [loading, setLoading] = useState(false);
    const [methodOptions, setMethodOptions] = useState<{ label: string, value: string }[]>([]);
    const [ipOptions, setIpOptions] = useState<{ label: string, value: string }[]>([]);
    const [urlOptions, setUrlOptions] = useState<{ label: string, value: string }[]>([]);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let reqTime: number | undefined = undefined;
            let resTime: number | undefined = undefined;
            if (s["dateRange"]) {
                reqTime = utcStartSecond(s["dateRange"][0]);
                resTime = utcEndSecond(s["dateRange"][1]);
            }
            onSearch(
                {
                    reqTime, resTime, method: s["method"], ip: s["ip"], url: s["url"]
                }
            );
        }}>
            <Form.Item name="dateRange" label="日期范围">
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss"></DatePicker.RangePicker>
            </Form.Item>

            <Form.Item label="请求方法" name="method">
                <Select onDropdownVisibleChange={methodOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getMethods().then(convertOptions).then(setMethodOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={methodOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="URL" name="url">
                <Select onDropdownVisibleChange={urlOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getRestUrls().then(convertOptions).then(setUrlOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ width: 200 }} loading={loading} options={urlOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="Ip" name="ip">
                <Select onDropdownVisibleChange={ipOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getIps().then(convertOptions).then(setIpOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={ipOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}

function convertOptions(data: string[]) {
    return data.map(s => ({ label: s, value: s }));
}
