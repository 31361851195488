import { getSelectItems as getAreaSelectItems } from "@/Api/AreaController";
import { getSelectItems as getGoodsTypeSelectItems } from "@/Api/GoodsTypeController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Goods } from "@/Models/Tables/Goods";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ColProps } from "antd/lib/col";
import { useEffect, useMemo, useState } from "react";
import { PropsWithChildren, useCallback } from "react";

function Item(props: IFormItemProps<Goods>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function FCol(props: PropsWithChildren<ColProps>) {
    return <Col span={12} {...props}>
    </Col>;
}

function Add({ onCancel, onSubmit, data }: AddProps<Goods>) {
    const [form] = Form.useForm();
    const onOk = useCallback(() => {
        form.validateFields().then(onSubmit);
    }, [form, onSubmit]);
    const [options, setOptions] = useState<{ area: SelectItem[], type: SelectItem[] }>({ area: [], type: [] });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const s = await Promise.all([getAreaSelectItems(), getGoodsTypeSelectItems()]);
                setOptions({ area: s[0].filter(s => s.value !== 1), type: s[1].filter(s => s.value !== 1) });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const editDisable = useMemo(() => data?.id === 1, [data?.id]);

    return <MyModal confirmLoading={loading} name="物品信息" data={data} width={720} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data ?? { stockHigh: 1000, stockLow: 60, validityDay: 90, alarmDay: 60 }} labelCol={{ span: 8 }}>
            <Row >
                <FCol span={12} >
                    <Item name="code" label="物品编码" required >
                        <Input disabled={editDisable}  ></Input>
                    </Item>
                </FCol>
                <FCol span={12}>
                    <Item name="name" label="物品名称" required  >
                        <Input disabled={editDisable}  ></Input>
                    </Item>
                </FCol>
            </Row>
            <Row>
                <FCol>
                    <Item name="standard" label="物品规格"  >
                        <Input ></Input>
                    </Item>
                </FCol>
                <FCol>
                    <Item name="unit" label="单位"  >
                        <Input ></Input>
                    </Item>
                </FCol>
            </Row>
            <Row>
                <FCol>
                    <Item name="areaId" label="所属区域"  >
                        <Select options={options.area} loading={loading} optionFilterProp="label" showSearch allowClear  ></Select>
                    </Item>
                </FCol>
                <FCol>
                    <Item name="typeId" label="物料类别" required >
                        <Select disabled={editDisable} options={options.type} loading={loading} optionFilterProp="label" showSearch allowClear  ></Select>
                    </Item>
                </FCol>
            </Row>
            <Row>
                <FCol>
                    <Item name="stockHigh" label="最高库存" required  >
                        <InputNumber min={1} precision={0} />
                    </Item>
                </FCol>
                <FCol>
                    <Item name="stockLow" label="最低库存" required >
                        <InputNumber min={1} precision={0} />
                    </Item>
                </FCol>
            </Row>
            <Row>
                <FCol>
                    <Item name="validityDay" label="保质期(天)" required >
                        <InputNumber min={1} precision={0} />
                    </Item>
                </FCol>
                <FCol>
                    <Item name="alarmDay" label="提前预警(天)" required >
                        <InputNumber min={1} precision={0} />
                    </Item>
                </FCol>
            </Row>
            <Row>
                <FCol>
                    <Item name="packageCount" label="包装数量" required >
                        <InputNumber min={1} precision={0} />
                    </Item>
                </FCol>
            </Row>

        </Form>
    </MyModal>;
}

export default Add;