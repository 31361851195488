import { get } from '@/Api';

const name = 'Offline';

function getUrl(url: string) {
    return `${name}/${url}`;
}

interface OfflineImportProps {
    stockCode: number;
    goodsId: number;
    quantity: number;
    slotId: number;
    notifyId: number;
}

type IOfflineResponse = { res: number, msg: string };

export function offlineImport(data: OfflineImportProps): Promise<IOfflineResponse> {
    return get(getUrl("Import"), data);
}
export function offlineExport(stockSlotId: number, quantity: number, notifyId: number): Promise<IOfflineResponse> {
    return get(getUrl("Export"), { stockSlotId, quantity, notifyId });
}