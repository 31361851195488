import { get, post } from '@/Api';
import { GoodsSelectItem } from '@/Models/Other/GoodsSelectItem';
import { SelectItemExtStockQuantity } from '@/Models/Other/SelectItemExtStockQuantity';
import { Goods } from '@/Models/Tables/Goods';
import { ViewGoods } from '@/Models/Views/ViewGoods';

namespace GoodsApi {
    const name = 'Goods';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }
    export function getViews(): Promise<ViewGoods[]> {
        return get(getUrl("getViews"));
    }
    export function add(data: Goods): Promise<ViewGoods> {
        return post(getUrl("add"), data);
    }
    export function upd(data: Goods): Promise<ViewGoods> {
        return post(getUrl("upd"), data);
    }
    export function del(id: number): Promise<number> {
        if (id === 1) return Promise.reject("不能删除空托盘!");
        return get(getUrl("del"), { id });
    }
    export function getSelectItems(): Promise<GoodsSelectItem[]> {
        return get(getUrl("getSelectItems"));
    }
    export function getSelectItemExtStockQuantities(): Promise<SelectItemExtStockQuantity[]> {
        return get(getUrl("GetSelectItemExtStockQuantities"));
    }

    export function UpFlag(ids:string): Promise<number> {
        return post(getUrl("UpFlag"),{ids});
    }

    
}

export default GoodsApi;