import { GlobalProviderContext } from "@/App/GlobalProvider";
import React, { useContext, useEffect, useState } from "react";
import { WifiOutlined, AlertOutlined } from '@ant-design/icons';
import './networkSpeedComponent.scss';


export function NetWorkSpeedComponent({ plcNo, name }: { plcNo: number, name: string }) {

    const provider = useContext(GlobalProviderContext);
    const [data, setData] = useState(provider.networkSpeed[plcNo].Value);
    useEffect(() => provider.networkSpeed[plcNo].listen(setData), [provider.networkSpeed, plcNo]);

    return <div className="network-speed">
        <div className="network-speed-body">
            <div className="icon">
                {data.plcSpeed > 0 ? <WifiOutlined className="icon-success" /> : <AlertOutlined className="icon-alert" />}
            </div>
            <div className="speed-body">
                <div className="plc-speed">
                    {data.plcSpeed}
                </div>
                <div className="speed">
                    {data.speed}
                </div>
            </div>
        </div>
        <div className="warehouse-name">
            <div>
                {name}
            </div>
        </div>
    </div>;
}
