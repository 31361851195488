import { get, post } from '@/Api';
import { WarehouseType } from '@/Models/Enums/WarehouseType';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Warehouse } from "@/Models/Tables/Warehouse";

const name = 'Warehouse';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<Warehouse[]> {
    return get(getUrl("getViews"));
}
export function getSelectItems(type: WarehouseType[]): Promise<SelectItem[]> {
    return post(getUrl("getSelectItems"), type);
}