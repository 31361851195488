import { getSelectItems } from "@/Api/AreaController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Slot } from "@/Models/Tables/Slot";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";

function Item(props: IFormItemProps<Slot>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<ViewSlot>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onOk = useCallback(() => {
        form.validateFields().then(onSubmit);
    }, [form, onSubmit]);
    const [areaOptions, setAreaOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setAreaOptions(await getSelectItems());
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    return <MyModal name="平库储位" data={data} width={360} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data} labelCol={{ span: 8 }}>
            <Item name="code" label="储位编码" required rules={[{ pattern: /^[A-Z]{1}[0-9]{1,2}$/gm, message: "您输入的储位编码不符合平库规范!" }]} >
                <Input ></Input>
            </Item>
            <Item name="areaId" label="区域"  >
                <Select options={areaOptions} loading={loading} optionFilterProp="label" showSearch allowClear  ></Select>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;