import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { HomeHub } from "@/Hubs/HomeHub";
import { DayAnalysis, MonthImportAndExportCount, SlotRatioUse } from "@/Models/Other/Home";
import { StackerInfo } from "@/Models/Other/StackerInfo";
import { createContext } from "react";

export class HomeProvider {
    public readonly hub: HomeHub;
    constructor(onError: tpOnError) {
        this.hub = new HomeHub(onError);
        this.hub.on("SlotRatioUseChange", data => this.slotRatioUse.Value = data);
        this.hub.on("StackerInfoChange", data => {
            const index = this.stackerInfo.Value.findIndex(s => s.stationNo === data.stationNo);
            if (index > -1) {
                this.stackerInfo.Value[index] = data;
                this.stackerInfo.resetValue();
            }
        });
        this.hub.on("DayAnalysisChange", data => this.dayAnalysis.Value = data);
        this.hub.on("MonthImportAndExportCountChange", data => this.monthImportAndExportCount.Value = data);
        this.hub.start(async () => {
            try {
                const [
                    stackerInfo,
                    dayAnalysis,
                    monthImportAndExportCount,
                    slotRatioUse,
                ] = await Promise.all([
                    this.hub.getStackerInfo(),
                    this.hub.getDayAnalysis(),
                    this.hub.getMonthImportAndExportCount(),
                    this.hub.getSlotRatioUse(),
                ]);
                this.stackerInfo.Value = stackerInfo;
                this.dayAnalysis.Value = dayAnalysis;
                this.monthImportAndExportCount.Value = monthImportAndExportCount;
                this.slotRatioUse.Value = slotRatioUse;
            } catch (error) {
                console.log(error);
            }
            finally {
                this.loading.Value = false;
            }
        });
    }
    public readonly loading = new Obs<boolean>(true);
    public readonly stackerInfo = new Obs<StackerInfo[]>([]);
    public readonly dayAnalysis = new Obs<DayAnalysis>({} as any);
    public readonly monthImportAndExportCount = new Obs<MonthImportAndExportCount[]>([]);
    public readonly slotRatioUse = new Obs<SlotRatioUse>({} as any);
    public readonly renderDelay = 600;
}

export const HomeProviderContext = createContext<HomeProvider>(null as any);