import { resolveEnumOptions } from "./help";

export enum PurchaseBillStatus {
    Pending = 0,// 待定
    Bidding = 1,// 招标中
    Finished = 2,// 已完成
    Cancel = -1,// 取消
}

export type TypePurchaseBillStatus = keyof typeof PurchaseBillStatus;

export const PurchaseBillStatusNames: { [K in TypePurchaseBillStatus]: string } = {
    Pending: "待定",
    Bidding: "招标中",
    Finished: "已完成",
    Cancel: "取消",
};

export function getPurchaseBillStatusName(e: TypePurchaseBillStatus) {
    return PurchaseBillStatusNames[e];
}

export function getPurchaseBillStatusOptions() {
    return resolveEnumOptions(PurchaseBillStatusNames);
}