import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { groupBy, sum } from "lodash";

export class SumHelper {
    private _data: ViewStockSlot[];
    constructor(data: ViewStockSlot[]) {
        this._data = data;
    }
    readonly getData = (tp: number) => {
        const d1 = groupBy(this._data, s => {
            let obj = s.goodsName;
            Object.keys(sumOptions).forEach((k) => {
                const v = (sumOptions as any)[k].val;
                if ((tp & v) === v) {
                    obj = `${obj}${(s as any)[k]}`;
                }
            });
            return obj;
        });
        const columns = getColumns();
        Object.keys(sumOptions).forEach((k) => {
            const v = (sumOptions as any)[k];
            if ((tp & v.val) === v.val) {
                columns.push(v.col);
            }
        });
        return { columns, dataSource: Object.keys(d1).map(s => ({ ...d1[s][0], quantity: sum(d1[s].map(b => b.quantity)) })) };
    }
}

export const sumOptions = {
    "standard": { val: 1 << 4, name: "规格", col: strProps<any, any>("standard", "规格", 140, true, true), },
    "batchNo": { val: 1, name: "批次", col: strProps<any, any>("batchNo", "批次号", 140, true, true), },
    "contractNumber": { val: 1 << 5, name: "合同号", col: strProps<any, any>("contractNumber", "合同号", 140, true, true), },
    "lanewayName": { val: 1 << 1, name: "巷道", col: strProps<any, any>("lanewayName", "巷道", 140, true, true), },
    "goodsStatusName": { val: 1 << 2, name: "物品状态", col: strProps<any, any>("goodsStatusName", "物品状态", 140, true, true), },
    "flag": { val: 1 << 3, name: "占用标记", col: strProps<any, any>("flag", "库存占用", 140, true, true, getStockFlagName) },
};

function getColumns(): ColumnType<ViewStockSlot>[] {
    return [
        strProps("warehouseName", "仓库", 100, true, true),
        strProps("goodsTypeName", "物品类型", 100, true, true),
        strProps("goodsCode", "物料编码", 140, true, true),
        strProps("goodsName", "物品名称", 140, true, true),
        numProps("quantity", "库存数量", 100, true),
        strProps("unit", "单位", 60, true, true),
    ];
}


