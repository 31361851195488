import { emptyStockImport } from "@/Api/EquipmentTaskController";
import { Button, Form, Input, message, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useState } from "react";

export function ImportEmptyStockApply() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);


    const onSubmit = useCallback(async () => {
        try {
            setLoading(true);
            const data = await form.validateFields();
            const res= await emptyStockImport(data["stockCode"], data["quantity"]);
            message.info(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);



    return (<div className="item">
        <Typography.Title level={3}>空托盘入库申请</Typography.Title>
        <Form title="空托盘入库" form={form} layout="vertical">
            <Form.Item name="stockCode" label="托盘条码" required rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="quantity" label="数量" required rules={[{ required: true }]}>
                <Input />
            </Form.Item>
        </Form>
        <Button.Group>
            <Button loading={loading} disabled={loading} onClick={onSubmit}>提交</Button>
        </Button.Group>
    </div>);
}

