import { getProductionItemCascaderItems } from "@/Api/ContractProductionItemController";
import CustomerApi from "@/Api/CustomerApi";
import { getDepartmentSelectItems } from "@/Api/DepartmentController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { getBluePrintTypeOptions as getBluePrintTypeSelectOptions } from "@/Models/Enums/BluePrintType";
import { CascaderItem } from "@/Models/Other/CascaderItem";
import { SelectItem } from "@/Models/Other/SelectItem";
import { BluePrint } from "@/Models/Tables/BluePrint";
import { Cascader, DatePicker, Form, Input, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

function Item(props: IFormItemProps<BluePrint>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<BluePrint>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ customer: SelectItem[], department: SelectItem[], productionItem: CascaderItem[] }>({ customer: [], department: [], productionItem: [] });

    useEffect(() => {
        form.setFieldValue("dateValue", dayjs(data?.date));
        if (data?.finishDate) {
            form.setFieldValue("finishDateValue", dayjs(data?.finishDate));
        }
        (async () => {
            setLoading(true);
            try {
                const [customer, department, productionItem] = await Promise.all([CustomerApi.getSelectItems(), getDepartmentSelectItems(), getProductionItemCascaderItems()]);
                setOptions({ customer, department, productionItem });
                if (data) {
                    const d1 = productionItem.find(s => s.children.some(a => a.value === data.productionItemId));
                    if (d1) {
                        form.setFieldValue("productionItemIdValue", [d1.value, data.productionItemId]);
                    }
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [data, form])

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            const date = t1.dateValue.valueOf();
            const finishDate = t1.finishDateValue?.valueOf();
            const productionItemId = t1.productionItemIdValue[1];
            onSubmit({ ...data, ...t1, date, finishDate, productionItemId });
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onSubmit]);

    return <MyModal confirmLoading={loading} name={`${data?.code || ''}图纸信息`} data={data} width={400} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" labelCol={{ span: 8 }} initialValues={data}>
            <Item name="departmentId" label="责任部门" labelAlign="right" required>
                <Select options={options.department} loading={loading} optionFilterProp="label" showSearch ></Select>
            </Item>
            <Form.Item name="productionItemIdValue" label="合同项目" labelAlign="right" required rules={[{ required: true }]} >
                <Cascader options={options.productionItem} loading={loading} showSearch allowClear ></Cascader>
            </Form.Item>
            <Item name="type" label="图纸类型" labelAlign="right" required  >
                <Select options={getBluePrintTypeSelectOptions()} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="name" label="图纸名称" labelAlign="right" required >
                <Input />
            </Item>
            <Item name="link" label="图纸链接" labelAlign="right" >
                <Input />
            </Item>
            <Item name="progress" label="进度" labelAlign="right" required >
                <InputNumber precision={0} min={1} max={100} style={{ width: '100%' }} />
            </Item>
            <Form.Item name="dateValue" label="开始日期" labelAlign="right" required rules={[{ required: true }]} >
                <DatePicker picker="date" />
            </Form.Item>
            <Form.Item name="finishDateValue" label="完成日期" labelAlign="right" >
                <DatePicker picker="date" allowClear />
            </Form.Item>
            <Item name="productionDepartmentId" label="生产部门" labelAlign="right">
                <Select options={options.department} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;