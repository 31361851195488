import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel, RetryContext } from '@microsoft/signalr';
import { delay } from 'lodash';

export type tpOnError = (err: string | null, t: string) => void;

export const nextRetryDelayInMilliseconds = (s: RetryContext, onError: tpOnError, url: string) => {
    onError(`与服务器断开连接! 第 ${s.previousRetryCount} 次尝试重新连接服务器.`, url);
    return 5000;
};

export const onreconnecting = (e: any, onError: tpOnError, url: string) => onError(`重新连接中....${JSON.stringify(e)}`, url);
export const startCatch = (e: any, onError: tpOnError, url: string) => onError(`无法连接服务器,请检查您的网络连接....${JSON.stringify(e)}`, url);

export type tpOnStart = () => void;
type filterStringType<T> = T extends string ? T : never;
export type tpOn<T> = <K extends filterStringType<keyof T>, M = T[K]>(methodName: K, method: M) => void;

export class BaseHub<T>{
    private _url: string;

    constructor(onError: tpOnError, url: string) {
        this._url = url;
        this.onError = onError;
        this.hub = new HubConnectionBuilder().withUrl(`/${url}`, { transport: HttpTransportType.WebSockets, skipNegotiation: true })
            .withAutomaticReconnect({ nextRetryDelayInMilliseconds: (s) => nextRetryDelayInMilliseconds(s, this.onError, this._url) })
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(LogLevel.Debug)
            .build();
        this.hub.onreconnected(this.onStart);
        this.hub.onreconnecting((e) => onreconnecting(e, this.onError, this._url));
    }

    private onError: tpOnError;
    public hub: HubConnection;
    private startList: tpOnStart[] = [];
    private readonly onStart = () => {
        this.onError(null, this._url);
        this.startList.forEach(f => f());
    };

    public readonly on: tpOn<T> = (methodName, method) => {
        this.hub.on(methodName, method as any);
    }

    public readonly off: tpOn<T> = (methodName, method) => {
        this.hub.off(methodName, method as any);
    }

    public readonly start = (onstart?: tpOnStart) => {
        delay(() => {
            if (onstart) this.startList.push(onstart);
            if (this.hub.state === HubConnectionState.Disconnected) {
                this.hub.start().then(this.onStart).catch(e => {
                    console.error(e);
                    this.start();
                });
            }
            else if (this.hub.state === HubConnectionState.Connected) {
                onstart && onstart();
            }
        }, 300)
    }

    public readonly stop = (onstart?: tpOnStart) => {
        if (onstart) {
            const i = this.startList.indexOf(onstart);
            if (i > -1) this.startList.splice(i, 1);
        }
        else {
            this.hub.stop();
        }
    }

}

