export enum SlotStatus {
    Empty = 0,
    HasSomething = 1,
    Importing = 2,
    Exporting = 3,
    WaitingConfirm = 4,// 待确认,入库时发现有物品,重新申请新的货位后标记此状态
}
export type TypeSlotStatus = keyof typeof SlotStatus;
const slotStatusNames: { [K in TypeSlotStatus]: string } = {
    "Empty": "空储位",
    "HasSomething": "有物品",
    "Importing": "入库中",
    "Exporting": "出库中",
    "WaitingConfirm": "待确认",
};

export function getSlotStatusName(e: any) {
    return slotStatusNames[e as TypeSlotStatus];
}



