import { getAlarmDays } from "@/Api/StockSlotController";
import { DAY, toYMD } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { ViewValidityAlarm } from "@/Models/Views/ViewValidityAlarm";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import { Fragment, useEffect } from "react";
import { useMemo, useState } from "react";

export default Index;

function endDateSort(a: ViewValidityAlarm, b: ViewValidityAlarm) {
    const a1 = a.productionDate + (a.alarmDay * DAY);
    const b1 = b.productionDate + (b.alarmDay * DAY);
    return a1 - b1;
}

function endDateRender(data: number, record: ViewValidityAlarm) {
    return toYMD(record.productionDate + (record.alarmDay * DAY));
}

function overDaySort(a: ViewValidityAlarm, b: ViewValidityAlarm) {
    const a1 = a.productionDate + (a.alarmDay * DAY);
    const b1 = b.productionDate + (b.alarmDay * DAY);
    return a1 - b1;
}

function overDayRender(data: number, record: ViewValidityAlarm) {
    const d1 = record.productionDate + (record.alarmDay * DAY);
    return dayjs().diff(dayjs(d1), "days");
}

function getColumns(): ColumnType<ViewValidityAlarm>[] {
    return [
        strProps("warehouseName", "仓库", 80, false, true),
        strProps("importBillCode", "入库单号", 160, true, true),
        strProps("goodsCode", "物料编码", 120, true, true),
        strProps("name", "物料名称", 200, true, true),
        strProps("standard", "规格", 120, true, true),
        strProps("unit", "单位", 80, true, false),
        strProps("slotCode", "储位编码", 120, true, true),
        strProps("notifyGoodsStatusName", "状态", 120, true, true),
        numProps("productionDate", "生产日期", 120, true, toYMD),
        numProps("alarmDay", "提前预警(天)", 140, true),
        { title: "截止日期", key: "endDate", width: 100, sorter: endDateSort, render: endDateRender },
        { title: "超出天数", key: "overDay", width: 100, sorter: overDaySort, render: overDayRender },
        numProps("validityDay", "保质期(天)", 140, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewValidityAlarm[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getAlarmDays().then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    return <Fragment>
        <CommonTable rowKey={s => uniqueId()} loading={loading} columns={columns} dataSource={data} />
    </Fragment>;
}


