import { getViews, reSetUploadFlag } from "@/Api/ImportOrderController";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMDHMS } from "@/Common/date";
import { getOrderExecuteFlagNames } from "@/Models/Enums/OrderExecuteFlag";
import { UploadOrderType } from "@/Models/Enums/UploadOrderType";
import { OrderSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewImportOrder } from "@/Models/Views/ViewImportOrder";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ResetUploadComponent } from "../../Export/QueryOrder/ResetUploadComponent";
import { UploadOrderLogComponent } from "./OrderUploadLogComponent";
import { SearchComponent } from "./SearchComponent";

function getColumns(setData: (record: ViewImportOrder) => void): ColumnType<ViewImportOrder>[] {
    return [
        strProps("billCode", "入库单号", 160, true, true),
        { title: "入库流水号", width: 140, dataIndex: "orderNo", ellipsis: true, render: (v, r) => <TableInnerButton onClick={() => setData(r)} type="link" >{v}</TableInnerButton> },
        strProps("supplierName", "供应商", 100, true, true),
        strProps("goodsName", "物品名称", 200, true, true),
        strProps("standard", "规格", 140, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("slotCode", "储位编码", 120, true, true),
        numProps("location", "码垛", 60, true),
        strProps("qrCode", "二维码", 160, true, true),
        strProps("executeFlag", "执行标志", 120, true, true, getOrderExecuteFlagNames),
        { title: "上传", dataIndex: "uploadFlag", width: 80, render: (_, r) => r.executeFlag === "Finished" ? <ResetUploadComponent record={r} reSetUploadFlag={reSetUploadFlag} /> : undefined },
        strProps("userName", "操作员", 100, true, true),
        numProps("time", "添加日期", 160, true, toYMDHMS),
        strProps("remark", "备注", 80, true, true),
    ];
}

export default QueryOrder; function QueryOrder() {
    const [uploadLogData, setUploadLogData] = useState<ViewImportOrder>();
    const columns = useMemo(() => getColumns(setUploadLogData), []);
    const searchProps = useRef<OrderSplitPageProps>({ current: 1, pageSize: 13 });
    const [data, setData] = useState<PaginationData<ViewImportOrder>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);
    useEffect(() => {
        onSearch({});
    }, [onSearch]);

    const onClearUploadLogData = useCallback(() => setUploadLogData(undefined), []);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} />
        <CommonTable rowKey={s => s.id} heightOffset={83} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange, showSizeChanger: true }} />
        <PrintFooter data={data.items} title="入库流水" columns={columns} />
        {uploadLogData && <UploadOrderLogComponent id={uploadLogData.id!} orderNo={uploadLogData.orderNo} type={UploadOrderType.Import} onCancel={onClearUploadLogData} />}
    </Fragment>;
}

