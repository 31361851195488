import { toCode } from "@/Common/Converters/toCode";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { ViewLaneway } from "@/Models/Views/ViewLaneway";
import { useEffect, useMemo, useState } from "react";
import { EditStatus } from "./EditStatus";
import { LanewayProvider, LanewayProviderContext } from "./LanewayProvider";

export default Index;

function getColumns(): ColumnType<ViewLaneway>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("warehouseName", "库房名称", 200, true, true),
        strProps("name", "巷道名称", 200, true, true),
        strProps("stackerName", "堆垛机", 200, true, true),
        numProps("no", "巷道号", 200, true, s => toCode(s)),
        {
            title: "状态", dataIndex: "status", ellipsis: true, width: 120, sorter: strSorter("status"),
            render: (_, r) => <EditStatus record={r} />
        },
        numProps("emptySlotCount", "空储位", 120, true),
        numProps("stockQuantity", "库存数量", 120, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewLaneway[]>([]);
    const provider = useCreateHubProvider(LanewayProvider);
    useEffect(() => provider.data.listen(setData), [provider.data]);
    useEffect(() => provider.hub.stop, [provider]);
    return <LanewayProviderContext.Provider value={provider}>
        <CommonTable rowKey={s => s.id} dataSource={data} columns={columns} />
    </LanewayProviderContext.Provider>;
}

