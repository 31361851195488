import { add, del, getViews, upd } from "@/Api/AreaController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { Area } from "@/Models/Tables/Area";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<Area>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "区域编码", 200, true, true),
        strProps("name", "区域名称", 200, true, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< Area> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
        editCheck={editCheck}
    />;
}

function updMerge(source: Area, target: Area) {
    target.id = source.id;
    return target;
}

function editCheck(d: Area) {
    return d.id === 1;
}