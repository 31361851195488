import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { ConveyerHub } from "@/Hubs/ConveyerHub";
import { ConveyerInfo } from "@/Models/Other/ConveyerInfo";
import { createContext } from "react";


export class ConveyerProvider {
    public readonly hub: ConveyerHub;
    constructor(onError: tpOnError) {
        this.hub = new ConveyerHub(onError);
        this.hub.on("InfoChange", data => {
            const index = this.data.Value.findIndex(s => s.stationNo === data.stationNo);
            if (index !== -1) {
                this.data.Value[index] = data;
                this.data.Value = [...this.data.Value];
            }
        });
        this.hub.start(async () => {
            try {
                this.data.Value = await this.hub.getInfos();
            } catch (error) {
                console.log(error);
            }
        });
    }
    public data = new Obs<ConveyerInfo[]>([]);
}

export const ConveyerProviderContext = createContext<ConveyerProvider>(null as any);