import { TableInnerButton } from "@/Common/Table/getTableAction";
import { getUploadFlagNames, TypeUploadFlag } from "@/Models/Enums/OrderUploadFlag";
import { useCallback, useState } from "react";

export function ResetUploadComponent({ record, reSetUploadFlag }: { record: { id: number; uploadFlag: TypeUploadFlag; }; reSetUploadFlag: (id: number) => Promise<number>; }) {
    const [flag, setFlag] = useState(record.uploadFlag);
    const [loading, setLoading] = useState(false);
    const onClick = useCallback(async () => {
        setLoading(false);
        try {
            await reSetUploadFlag(record.id);
            setFlag("WaitingUpload");
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [reSetUploadFlag, record.id]);
    if (flag === "Fault") {
        return <TableInnerButton loading={loading} type="link" onClick={onClick} >{getUploadFlagNames(flag)}</TableInnerButton>;
    }
    else {
        return <div >{getUploadFlagNames(flag)}</div>;
    }
}
