import { getViews } from "@/Api/TemporaryStockController";
import { CommonTable } from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { ViewTemporaryStock } from "@/Models/Views/ViewTemporaryStock";
import { Fragment, useEffect, useMemo, useState } from "react";
import { StockCodeColumnItem } from "./StockCodeColumnItem";

export default Index;

function getColumns(): ColumnType<ViewTemporaryStock>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("exportTaskNo", "任务号", 100, true, true),
        strProps("exportOrderNo", "出库流水号", 150, true, true),
        strProps("importOrderNo", "入库流水号", 150, true, true),
        strProps("goodsName", "物品名称", 120, true, true),
        strProps("goodsStatusName", "品质状态", 120, true, true),
        {
            title: "托盘条码", dataIndex: "stockCode", width: 120, render: (text, record, index) => { return <StockCodeColumnItem record={record} /> },
        },
        strProps("slotCode", "储位编码", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("flag", "状态", 100, true, true, getStockFlagName),
        strProps("remark", "备注", 200, true, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewTemporaryStock[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getViews().then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    return <Fragment>
        <CommonTable rowKey={s => s.id} loading={loading} columns={columns} dataSource={data} />
    </Fragment>;
}


