import { LanewayStatus } from "@/Models/Enums/LanewayStatus";
import { ViewLaneway } from "@/Models/Views/ViewLaneway";
import { BaseHub, tpOnError } from "./BaseHub";
import { ILanewayHubAction } from "./IActions/ILanewayHubAction";


export class LanewayHub extends BaseHub<ILanewayHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/Laneway");
    }
    public getViews = () => this.hub.invoke<ViewLaneway[]>("GetViews");
    public updLanewayStatus = (id: number, flag: LanewayStatus) => this.hub.invoke<number>("UpdLanewayStatus", id, flag);
}
