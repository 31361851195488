export enum TaskExecuteFlag {
    WaitingExecute = 0,
    DownSend = 1,
    Executing = 2,
    Finished = 3,
    Cancel = 4,
    WaitingConfirm = 5,
    ConveyerExecuting = 6,
    ExitPortCacheExecuting = 7,
    AgvExecuting = 8,
}

export type TypeTaskExecuteFlag = keyof Omit<typeof TaskExecuteFlag, "Agv" | "Conveyer" | "ExitPortCache">;

export const taskExecuteFlagNames: { [K in TypeTaskExecuteFlag]: string } = {
    WaitingExecute: "待执行",
    DownSend: "已下发",
    Executing: "执行中",
    Finished: "已完成",
    WaitingConfirm: "待确认",
    AgvExecuting: "Agv搬运中",
    ConveyerExecuting: "输送机执行中",
    ExitPortCacheExecuting: "抵达出库缓存区",
    Cancel: "已取消",
};

export function getTaskExecuteFlagName(e: TypeTaskExecuteFlag) {
    return taskExecuteFlagNames[e];
}