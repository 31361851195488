import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { ExitPort } from '@/Models/Tables/ExitPort';
import { ViewExitPort } from '@/Models/Views/ViewExitPort';

const name = 'ExitPort';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewExitPort[]> {
    return get(getUrl("getViews"));
}
export function getSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("GetSelectItems"));
}
export function updInfo(data: ExitPort): Promise<ViewExitPort> {
    return post(getUrl("UpdInfo"), data);
}