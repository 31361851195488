
export enum StockReportType {
    Day = 0,
    Month = 1,
    Year = 2
}


export type TypeStockReportType = keyof typeof StockReportType;

const StockReportTypeNames: { [K in TypeStockReportType]: string } = {
    Day: "日",
    Month: "月",
    Year: "年",
};

export function getStockReportTypeName(e: TypeStockReportType) {
    return StockReportTypeNames[e];
}

