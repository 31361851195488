import { cancel } from "@/Api/ExportOrderController";
import { CommonTable } from "@/Common/Table/EditTable";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getOrderExecuteFlagNames } from "@/Models/Enums/OrderExecuteFlag";
import { ViewExportOrder } from "@/Models/Views/ViewExportOrder";
import { Popconfirm } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ExportOrderHubProvider, ExportOrderHubProviderContext } from "./ExportOrderHubProvider";

function getColumns(): ColumnType<ViewExportOrder>[] {
    return [
        strProps("orderNo", "出库流水号", 120, true, true),
        strProps("billCode", "出库单号", 120, true, true),
        strProps("goodsName", "物品名称", 200, true, true),
        strProps("standard", "规格", 140, true, true),
        numProps("quantity", "出库数量", 100, true),
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("slotCode", "储位编码", 120, true, true),
        strProps("receiver", "领料人", 120, true, true),
        { title: "执行标志", dataIndex: "executeFlag", width: 120, sorter: strSorter("executeFlag"), render: (_, r) => <ActionComponent record={r} /> },
        strProps("userName", "操作员", 100, true, true),
        numProps("time", "添加日期", 160, true, toYMDHMS),
        strProps("importOrderNo", "入库流水号", 120, true, true),
        strProps("importBillCode", "入库单号", 120, true, true),
    ];
}

function ActionComponent({ record }: { record: ViewExportOrder }) {
    const provider = useContext(ExportOrderHubProviderContext);
    const [loading, setLoading] = useState(provider.loading.Value);
    useEffect(() => provider.loading.listen(setLoading), [provider]);
    const onConfirm = useCallback(async () => {
        setLoading(true);
        try {
            const res = await cancel(record.id);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [record]);
    if (record.executeFlag === "WaitingExecute") {
        return (<Popconfirm disabled={loading} title={`您确定要取消${record.orderNo}吗?`} onConfirm={onConfirm}>
            <TableInnerButton type="link" >{getOrderExecuteFlagNames(record.executeFlag)}</TableInnerButton>
        </Popconfirm>);
    }
    else {
        return <span>{getOrderExecuteFlagNames(record.executeFlag)}</span>;
    }
}

export default Index;
function Index() {
    const provider = useCreateHubProvider(ExportOrderHubProvider);
    const [loading, setLoading] = useState(provider.loading.Value);
    const [data, setData] = useState<ViewExportOrder[]>(provider.data.Value);
    const columns = useMemo(getColumns, []);
    useEffect(() => provider.loading.listen(setLoading), [provider]);
    useEffect(() => provider.data.listen(setData), [provider]);
    return <ExportOrderHubProviderContext.Provider value={provider}>
        <CommonTable loading={loading} rowKey={s => s.id} dataSource={data} columns={columns} pagination={{ pageSize: 14 }} />
    </ExportOrderHubProviderContext.Provider>;
}