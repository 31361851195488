import { getAllViews } from "@/Api/StockSlotController";
import CommonTable2 from "@/Common/Table/CommonTable2";
import { TableActions } from "@/Common/Table/TableActions";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { Checkbox } from "antd";
import { Fragment, useEffect, useMemo, useState } from "react";
import { SumHelper, sumOptions } from "./SumHelper";

export default Index;
function Index() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ViewStockSlot[]>([]);
    const [checkList, setCheckList] = useState<number[]>([]);
    const options = useMemo(() => Object.keys(sumOptions).map(s => {
        const v = (sumOptions as any)[s];
        return { label: v.name, value: v.val };
    }), []);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await getAllViews();
                setData(res);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const { columns, dataSource } = useMemo(() => new SumHelper(data).getData(checkList.reduce((a, b) => a | b, 0)), [checkList, data]);

    return (<Fragment>
        <TableActions>
            <Checkbox.Group options={options} onChange={s => setCheckList(s as any)} value={checkList} />
        </TableActions>
        <CommonTable2 rowKey={s => s.id} heightOffset={80} loading={loading} columns={columns} dataSource={dataSource} />
    </Fragment>);
}
