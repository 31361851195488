import { getConveyerDeviceStatusKeys, getConveyerTaskStatusKeys, getDownSendConveyerTaskKeys, getScanConveyerDeviceStatusKeys, setConveyerDeviceStatus, setConveyerTaskStatus, setDownSendConveyerTask, setScanConveyerDeviceStatus, setWcsControlConveyerDirection } from '@/Api/TestPlcController';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';


export function ConveyerComponent() {
    const [stationOptions, setStationOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getConveyerDeviceStatusKeys();
                setStationOptions(d1.map(s => ({ label: s.toString(), value: s })));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    if (stationOptions.length === 0) return <Spin spinning={loading} />;
    return (<div >
        <div>输送机</div>
        <div className='plcTest-Conveyer'>
            <ConveyerDeviceStatusComponent options={stationOptions} />
            <ScanConveyerDeviceStatusComponent />
            <ConveyerTaskStatusComponent />
            <DownSendConveyerTaskComponent />
            <ControlConveyerDirectionComponent />
        </div>
    </div>);
}

export function ConveyerDeviceStatusComponent({ options }: { options: SelectItem[] }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setConveyerDeviceStatus(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>设备状态反馈</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0]?.value}>
                <Select options={options} />
            </Form.Item>
            <Form.Item name="status" label="设备状态" initialValue={1}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="taskNo" label="任务号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="controlMode" label="控制方式" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="stockCodeValue" label="托盘条码" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="apply" label="入库申请" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="exportApply" label="出库申请" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="slotApply" label="储位申请" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}
export function ScanConveyerDeviceStatusComponent() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [options, setOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getScanConveyerDeviceStatusKeys();
                setOptions(d1.map(s => ({ label: s.toString(), value: s })));
                form.setFields([{ name: "stationNo", value: d1[0] }])
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [form]);

    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setScanConveyerDeviceStatus(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    return <div>
        <div>尺检设备状态</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0]?.value} >
                <Select options={options} />
            </Form.Item>
            <Form.Item name="scanMeasure" label="尺检报警" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="scanApply" label="尺检申请" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="scanHeight" label="尺检高度" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="stockCodeValue" label="托盘条码" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}


export function ConveyerTaskStatusComponent() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [options, setOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getConveyerTaskStatusKeys();
                setOptions(d1.map(s => ({ label: s.toString(), value: s })));
                form.setFields([{ name: "stationNo", value: d1[0] }])
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [form]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setConveyerTaskStatus(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>预约任务反馈</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0]?.value} >
                <Select options={options} />
            </Form.Item>
            <Form.Item name="status" label="设备状态" initialValue={1}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="taskNo" label="任务号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}


export function DownSendConveyerTaskComponent() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [options, setOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getDownSendConveyerTaskKeys();
                setOptions(d1.map(s => ({ label: s.toString(), value: s })));
                form.setFields([{ name: "stationNo", value: d1[0] }])
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [form]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setDownSendConveyerTask(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>下发输送机任务</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0]?.value} >
                <Select options={options} />
            </Form.Item>
            <Form.Item name="taskNo" label="任务号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}

export function ControlConveyerDirectionComponent() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setWcsControlConveyerDirection(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    const modeOptions = useMemo(() => [{ label: "入库", value: 1 }, { label: "出库", value: 2 }], []);

    return <div>
        <div>切换输送线方向</div>
        <Form layout='inline' form={form}>
            <Form.Item name="currentDirection" label="当前方向" initialValue={1}>
                <Select options={modeOptions} ></Select>
            </Form.Item>
            <Form.Item name="settingDirection" label="设定方向" initialValue={1}>
                <Select options={modeOptions} ></Select>
            </Form.Item>
            <Form.Item name="canSwitchImportMode" label="可切换入库" initialValue={0}>
                <Input ></Input>
            </Form.Item>
            <Form.Item name="canSwitchExportMode" label="可切换出库" initialValue={0}>
                <Input ></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}

