
export enum FaultType {
    Conveyer = 1,
    Stacker = 2,
    Split = 3,
    Split1 = 4,
    Separation = 5,
    System = 6
}

export type TypeFaultType = keyof typeof FaultType;

const FaultTypeNames: { [K in TypeFaultType]: string } = {
    "Conveyer": "输送机",
    "Separation": "分离机",
    "Split": "拆盘机106",
    "Split1": "拆盘机",
    "Stacker": "堆垛机",
    "System": "系统"
};

export function getFaultTypeName(e: any) {
    return FaultTypeNames[e as TypeFaultType];
}


