import { add, del, getViews, upd } from "@/Api/ContractProductionItemController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { getContractProductionItemStatusNames } from "@/Models/Enums/ContractProductionItemStatus";
import { ContractProductionItem } from "@/Models/Tables/ContractProductionItem";
import { ViewContractProductionItem } from "@/Models/Views/ViewContractProductionItem";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewContractProductionItem>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("contractCode", "合同编码", 160, true, true),
        strProps("contractName", "合同名称", 200, true, true),
        strProps("goodsName", "物料名称", 160, true, true),
        strProps("goodsStandard", "物料规格", 120, true, true),
        numProps("price", "销售单价", 60, true),
        strProps("status", "状态", 60, true, true, getContractProductionItemStatusNames),
        strProps("remark", "品牌", 80, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< ContractProductionItem, ViewContractProductionItem> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewContractProductionItem, target: ContractProductionItem) {
    target.id = source.id;
    return target;
}