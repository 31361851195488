import { utcEndHour, utcStartHour } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { getTaskModeSelectOptions } from "@/Models/Enums/TaskMode";
import { ExcludeBasePagination, TaskSplitPageProps } from "@/Models/Other/BaseSplitPageProps";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useMemo } from "react";

export function SearchComponent({ onSearch }: { onSearch: (data: ExcludeBasePagination<TaskSplitPageProps>) => void; }) {
    const taskModeOptions = useMemo(getTaskModeSelectOptions, []);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let start: number | undefined = undefined;
            let end: number | undefined = undefined;
            if (s["dateRange"]) {
                start = utcStartHour(s["dateRange"][0]);
                end = utcEndHour(s["dateRange"][1]);
            }
            onSearch(
                {
                    start, end,
                    taskNo: s["taskNo"],
                    taskBegin: s["taskBegin"],
                    taskEnd: s["taskEnd"],
                    stockCode: s["stockCode"],
                    slotCode: s["slotCode"],
                    taskMode: s["taskMode"],
                }
            );
        }}>
            <Form.Item name="dateRange" label="时间范围">
                <DatePicker.RangePicker showTime={{ format: 'HH' }} format="YYYY-MM-DD HH"></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item label="任务模式" name="taskMode">
                <Select options={taskModeOptions} style={{ minWidth: 100 }} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="任务号" name="taskNo">
                <Input style={{ width: 100 }} />
            </Form.Item>
            <Form.Item label="开始" name="taskBegin">
                <Input style={{ width: 100 }} />
            </Form.Item>
            <Form.Item label="结束" name="taskEnd">
                <Input style={{ width: 100 }} />
            </Form.Item>
            <Form.Item label="托盘条码" name="stockCode">
                <Input style={{ width: 120 }} />
            </Form.Item>
            <Form.Item label="储位编码" name="slotCode">
                <Input style={{ width: 120 }} />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}
