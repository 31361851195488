import { Fragment, useEffect, useState } from "react";
import { LeafText } from "./LeafText";
import { LeafDataProvider } from "./LeafDataProvider";
import { uniqueId } from "lodash";


export function LeafTextContainer({ leafDataProvider, syncDisplay }: { leafDataProvider: LeafDataProvider; syncDisplay: () => void }) {
    const [data, setData] = useState(leafDataProvider.data.Value);
    useEffect(() => leafDataProvider.data.listen(setData), [leafDataProvider.data]);
    return (<Fragment>
        {data.map((s, index) => <LeafText data={s} key={uniqueId()} syncDisplay={syncDisplay} />)}
    </Fragment>);
}
