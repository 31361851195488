import { createContext } from "react";

type TypeSettings = TypeConfig & { stationNo: number };
export interface TypeConfig {
    type: "pickAndPut" | "turn" | "l" | "s";
    top: number;
    left: number;
    length: number;
}

export const ConveyerLocationBuilderContext = createContext<ConveyerLocationBuilder>(null as any);

export class ConveyerLocationBuilder {
    private _w1: TypeSettings[];
    constructor() {
        this._w1 = [];
        this.w1Build();
    }

    w1Build() {
        this._w1.push({ left: 1018, top: 310, length: 140, type: "s", stationNo: 101 });
    }



    public getData(warehouseId: number) {
        return this._w1;
    }
}

export function getConveyerProps(config: TypeConfig) {
    if (config.type === 's') {
        return { width: 64, height: config.length, transform: 'rotate(90deg)', sgvHeight: 64, top: config.top, left: config.left };
    }
    else {
        return { width: config.length, height: 62, sgvHeight: 62, top: config.top, left: config.left };
    }
}