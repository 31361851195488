import { Modal, Form } from "antd";
import { FormItemProps } from "antd/lib/form";
import { ModalProps } from "antd/lib/modal";
import React, { PropsWithChildren } from "react";


type tpFormItemProps<T> = Omit<FormItemProps, "name"> & { name: keyof T };

export type IFormItemProps<T> = React.PropsWithChildren<tpFormItemProps<T>>;

type tp = ModalProps & { name: string, data?: {} | null };

export interface AddProps<T> {
    onCancel: () => void;
    data?: T;
    onSubmit: (data: any) => void;
}

export function MyModal(props: PropsWithChildren<tp>) {
    const tmp = props.data ? "修改" : "添加";
    return <Modal maskClosable={false} closable={true} open width={320} title={`${tmp}${props.name}`} cancelText="取消" okText={tmp} {...props}    >
        {props.children}
    </Modal>;
}

export const convertProps = (props: FormItemProps) => {
    if (props.required) {
        return { ...props, rules: [{ required: true, message: `${props.label}不能为空！` }, ...(props.rules || [])] };
    }
    return props;
};

export function NoShowItem<Values>(props: FormItemProps<Values>) {
    return <Form.Item noStyle {...props}>
        <input type="hidden" />
    </Form.Item>;
}