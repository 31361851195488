import Laneway from './Laneway';
import Area from './Area';
import Task from './Task';
import SlotManager from './SlotManager';
import QueryEquipmentTaskFinished from './QueryEquipmentTaskFinished';
import Fault from './Fault';
import Conveyer from './Conveyer';
import DeviceMessage from './DeviceMessage';
import SlotBatchUpd from './SlotBatchUpd';
import {
    DeploymentUnitOutlined,
    SplitCellsOutlined,
    TableOutlined,
    ApartmentOutlined,
    OrderedListOutlined,
    TransactionOutlined,
    EuroCircleOutlined,
    CloudServerOutlined,
    MessageOutlined,
    SlidersOutlined,
    StepBackwardOutlined,
    FilePptOutlined,
    FlagOutlined,
} from '@ant-design/icons';
import Stacker from './Stacker';
import AnalyzeTaskFinished from './AnalyzeTaskFinished';
import { IMenuGroup } from '../../routeData';
import FlatSlot from './FlatSlot';

function equipmentGroup(): IMenuGroup {
    return {
        name: "设备管理",
        icon: DeploymentUnitOutlined,
        path: "equipment",
        items: [
            {
                name: "巷道管理",
                icon: SplitCellsOutlined,
                element: <Laneway />,
                path: "Laneway",
                right: 1,
            },
            {
                name: "储位管理",
                icon: TableOutlined,
                element: <SlotManager />,
                path: "SlotManager",
                right: 1 << 2,
            },
            {
                name: "储位批量修改",
                icon: SlidersOutlined,
                element: <SlotBatchUpd />,
                path: "SlotBatchUpd",
                right: 1 << 4,
            },
            {
                name: "区域管理",
                icon: ApartmentOutlined,
                element: <Area />,
                path: "Area",
                right: 1 << 5,
            },
            {
                name: "任务管理",
                icon: TransactionOutlined,
                element: <Task />,
                path: "Task",
                right: 1 << 6,
            },
            {
                name: "历史任务",
                icon: OrderedListOutlined,
                element: <QueryEquipmentTaskFinished />,
                path: "QueryEquipmentTaskFinished",
                right: 1 << 7,
            },
            {
                name: "任务分析",
                icon: FilePptOutlined,
                element: <AnalyzeTaskFinished />,
                path: "AnalyzeTaskFinished",
                right: 1 << 11,
            },
            {
                name: "异常记录",
                icon: EuroCircleOutlined,
                element: <Fault />,
                path: "Fault",
                right: 1 << 8,
            },
            {
                name: "堆垛机",
                icon: StepBackwardOutlined,
                element: <Stacker />,
                path: "Stacker",
                right: 1 << 9,
            },
            {
                name: "输送机",
                icon: CloudServerOutlined,
                element: <Conveyer />,
                path: "Conveyer",
                right: 1 << 10,
            },
            {
                name: "设备消息",
                icon: MessageOutlined,
                element: <DeviceMessage />,
                path: "DeviceMessage",
                right: 1 << 11,
            },
            {
                name: "平库管理",
                icon: FlagOutlined,
                element: <FlatSlot />,
                path: "FlatSlot",
                right: 1 << 12,
            },

        ]
    };
}

export default equipmentGroup;