import { Button, Checkbox, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from "react";
import { login } from "@/Api/SysUserController";
import { Md5 } from "ts-md5";
import { cleanLoginInfo, getLoginInfo, setLoginInfo, setSysUserToken } from "@/Common/LocalStorageProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalProviderContext } from "@/App/GlobalProvider";
import './index.scss';

function Login() {
    const provider = useContext(GlobalProviderContext);
    const location = useLocation();
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "登录";
    }, []);
    async function onFinish(v: any) {
        setLoading(true);
        try {
            const code = v["code"];
            const password = v["password"];
            const pwd = new Md5().appendStr(password).end()!.toString();
            const res = await login(code, pwd);
            if (v.remember) {
                setLoginInfo(v);
            }
            else {
                cleanLoginInfo();
            }
            setSysUserToken(res);
            provider.user.Value = res;
            if (location.state) {
                nav(location.state);
            }
            else {
                document.title = "WMS数据管理";
                nav("/wms");
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    return (<div className="login-root" >
        <Form
            name="normal_login"
            className="login-form"
            initialValues={getLoginInfo() ?? { remember: false }}
            onFinish={onFinish}
        >
            <Form.Item
                name="code"
                rules={[{ required: true, message: '请输入您的用户编码!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入您的用户Id" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入您的用户密码!' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入密码"
                />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" >
                <Checkbox>记住我</Checkbox>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>)
}

export default Login;