import * as PIXI from 'pixi.js';
import Vec2 from "vec2";

export class DrawProvider {
    private _dragTarget: any;
    private readonly _app: PIXI.Application<PIXI.ICanvas>;
    private _offset: Vec2 = new Vec2(0, 0);
    private readonly _dom: PIXI.Text;
    private readonly _xyChange: (dom: PIXI.Text) => void;
    private readonly _fontChange: (deltaY: number) => void;
    constructor(app: PIXI.Application, dom: PIXI.Text, xyChange: (dom: PIXI.Text) => void, fontChange: (deltaY: number) => void) {
        this._dragTarget = null;
        app.stage.interactive = true;
        app.stage.hitArea = app.screen;
        app.stage.on('pointerup', this.onDragEnd);
        app.stage.on('pointerupoutside', this.onDragEnd);
        this._app = app;
        this._dom = dom;
        this._dom.on("pointerdown", this.onDragStart);
        this._dom.on("wheel", this.wheel);
        this._xyChange = xyChange;
        this._fontChange = fontChange;
    }

    wheel = (s: PIXI.FederatedWheelEvent) => {
        s.preventDefault();
        s.stopPropagation();
        this._fontChange(s.deltaY);
        return false;
    }

    stop = () => {
        this._dom.off("pointerdown", this.onDragMove);
        this._app.stage.off('pointerup', this.onDragEnd);
        this._app.stage.off('pointerupoutside', this.onDragEnd);
        this._dragTarget = null;
        console.log('stop');
    };

    onDragMove = (event: any) => {
        if (this._dragTarget) {
            const v2 = new Vec2(event.global).subtract(this._offset);
            this._dragTarget.parent.toLocal(v2, null, this._dragTarget.position);
        }
    };

    onDragStart = (obj: PIXI.FederatedPointerEvent) => {
        this._dragTarget = obj.target;
        const d2 = obj.target as any;
        this._offset = new Vec2(obj.globalX - d2.x, obj.globalY - d2.y);
        this._dragTarget.alpha = 0.5;
        this._app.stage.on('pointermove', this.onDragMove);
    };

    onDragEnd = () => {
        if (this._dragTarget) {
            this._app.stage.off('pointermove', this.onDragMove);
            this._dragTarget.alpha = 1;
            this._dragTarget = null;
            this._xyChange(this._dom);
        }
    };
}

