import { getContractSelectItems } from "@/Api/ContractController";
import GoodsApi from "@/Api/GoodsApi";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { getContractProductionItemStatusOptions } from "@/Models/Enums/ContractProductionItemStatus";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ContractProductionItem } from "@/Models/Tables/ContractProductionItem";
import { Form, Input, InputNumber, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

function Item(props: IFormItemProps<ContractProductionItem>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<ContractProductionItem>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ contract: SelectItem[], goods: SelectItem[] }>({ contract: [], goods: [] });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const contract = await getContractSelectItems();
                const goods = await GoodsApi.getSelectItems();
                setOptions({ contract, goods });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            onSubmit(t1);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onSubmit]);


    return <MyModal confirmLoading={loading} name={`销售商品明细`} data={data} width={400} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" labelCol={{ span: 8 }} initialValues={data}>
            <Item name="contractId" label="合同名称" labelAlign="right" required>
                <Select options={options.contract} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="goodsId" label="物料名称" labelAlign="right" required>
                <Select options={options.goods} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="price" label="产品单价" labelAlign="right" required >
                <InputNumber precision={4} min={1} style={{ width: '100%' }} />
            </Item>
            <Item name="quantity" label="数量" labelAlign="right" required >
                <InputNumber min={1} style={{ width: '100%' }} />
            </Item>
            <Item name="brand" label="品牌" >
                <Input />
            </Item>
            <Item name="status" label="状态" >
                <Select options={getContractProductionItemStatusOptions()} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;