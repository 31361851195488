import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import Modal from "antd/lib/modal/Modal";
import './index.scss';
import { Context2DOperator } from "@/Common/CropImg/useContent2D";
import { Md5 } from "ts-md5";


interface IProps {
    imgSrc: string;
    onCancel: () => void;
    onOk: (f: File, cb?: () => void) => void;
    aspctRatio?: number;
}

function CropImg({ imgSrc, onCancel, onOk, aspctRatio }: IProps) {

    const imgRef = useRef<HTMLImageElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const [c2d, setC2d] = useState<Context2DOperator>();

    useEffect(() => {
        if (c2d) return c2d.clear;
    }, [c2d]);

    const imgLoad = useCallback(() => {
        const img = imgRef.current;
        const canvas = canvasRef.current;
        const div = divRef.current;
        if (img && canvas && div) {
            const c2d = canvas.getContext("2d");
            if (c2d) setC2d(new Context2DOperator(c2d, img, div));
        }
    }, []);

    const [loading, setLoading] = useState(false);

    const okHandle = useCallback(() => {
        setLoading(true);
        if (c2d?.canvas) {
            c2d.canvas.toBlob((b) => {
                if (b) {
                    const fr = new FileReader();
                    fr.onload = s => {
                        const m = new Md5();
                        m.appendByteArray(new Uint8Array(s.target?.result as ArrayBuffer));
                        const name = m.end()!.toString();
                        const f = new File([b], name + '.png');
                        onOk(f, () => setLoading(false));
                    };
                    fr.readAsArrayBuffer(b);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [c2d, onOk]);

    const paddingBottom = useMemo(() => {
        if (aspctRatio) return `${aspctRatio * 100}%`;
        return '56.25%';
    }, [aspctRatio]);

    return <Modal open onCancel={onCancel} confirmLoading={loading} className="cropping" onOk={okHandle} title="裁剪图片" okText="确定" cancelText="取消" >
        <div className="img" style={{ paddingBottom }} >
            <div ref={divRef}>
                <div hidden><img alt="..." ref={imgRef} hidden src={imgSrc} onLoad={imgLoad} ></img></div>
                <canvas ref={canvasRef} ></canvas>
            </div>
        </div>
    </Modal>;
};


export default CropImg;
