import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { StackerHub } from "@/Hubs/StackerHub";
import { StackerInfo } from "@/Models/Other/StackerInfo";
import { createContext } from "react";

export class StackerProvider {
    public readonly hub: StackerHub;
    constructor(onError: tpOnError) {
        this.hub = new StackerHub(onError);
        this.hub.on("InfoChange", s => {
            const key = `${s.plcNo}-${s.stationNo}`;
            if (this.stacker.Value[key]) {
                this.stacker.Value[key].Value = s;
                this.stacker.Value[key].resetValue();
            }
            else {
                this.stacker.Value[key] = new Obs(s);
                this.stacker.resetValue();
            }
            this.stacker.resetValue();
        });
        this.hub.start(async () => {
            try {
                const data = await this.hub.getInfos();
                this.stacker.Value = {};
                data.forEach(s => {
                    const key = `${s.plcNo}-${s.stationNo}`;
                    this.stacker.Value[key] = new Obs(s);
                });
                this.stacker.resetValue();
            } catch (error) {
                console.log(error);
            }
        });
    }
    public readonly stacker = new Obs<{ [K: string]: Obs<StackerInfo> }>({});
}

export const StackerProviderContext = createContext<StackerProvider>(null as any);
