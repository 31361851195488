import UpkeepConfig from './UpkeepConfig';
import UpkeepAlert from './UpkeepAlert';
import {
    DeploymentUnitOutlined, KeyOutlined, AlertOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
function upkeepGroup(): IMenuGroup {
    return {
        name: "维护保养",
        icon: DeploymentUnitOutlined,
        path: "upkeep",
        items: [
            {
                name: "保养方案",
                icon: KeyOutlined,
                element: <UpkeepConfig />,
                path: "UpkeepConfig",
                right: 1 << 1,
            },
            {
                name: "保养预警",
                icon: AlertOutlined,
                element: <UpkeepAlert />,
                path: "UpkeepAlert",
                right: 1 << 2,
            },
        ]
    };
}
export default upkeepGroup;