import GoodsStatusApi from "@/Api/GoodsStatusApi";
import EditTable from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { GoodsStatus } from "@/Models/Tables/GoodsStatus";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<GoodsStatus>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "编码", 120, true, true),
        strProps("name", "名称", 200, true, true),
        strProps("remark", "备注", 160, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< GoodsStatus> getData={GoodsStatusApi.getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={GoodsStatusApi.upd}
        delDataHandle={s => GoodsStatusApi.del(s.id)}
        updMerge={updMerge}
        addDataHandle={GoodsStatusApi.add}
        rowKey={"id"}
        editCheck={s => !(s.id > 3)}
    />;
}

function updMerge(source: GoodsStatus, target: GoodsStatus) {
    target.id = source.id;
    return target;
}