import { ColumnType } from "@/Common/Table/type";
import { Descriptions, Modal, Space, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import { logo } from "@/App/config";
import './index.scss';

export function PrintComponent<T extends { id: number; }>({ onCancel, getData, columns, title, header, footer }: { onCancel: () => void; getData: () => Promise<T[]>; columns: ColumnType<T>[]; header: React.ReactNode; footer: React.ReactNode; title: string; }) {
    const [data, setData] = useState<T[]>([]);
    const tableRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const handleOnBeforeGetContent = React.useCallback(() => {
        setLoading(true);
        return new Promise((resolve) => {
            resolve(undefined);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        getData().then(setData).catch(console.error).finally(() => setLoading(false));
    }, [getData]);

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: title,
        onBeforeGetContent: handleOnBeforeGetContent,
        removeAfterPrint: true,
        pageStyle: `style="padding: 50px;"`
    });

    return (
        <Modal open onCancel={onCancel} width={1024} bodyStyle={{ height: 600, overflow: "auto" }} onOk={handlePrint} confirmLoading={loading}>
            <div className="print-component" ref={tableRef} style={{ marginLeft: 24, marginRight: 24 }}>
                <img src={logo} alt="" className="watermark" />
                <Table size="small"
                    components={{
                        table: (props: any) => (<table {...props}>
                            {props.children}
                            <tfoot>
                                <Descriptions />
                                <tr>
                                    <td colSpan={columns.length}>
                                        {footer}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>),
                        header: {
                            wrapper: (props: any) => (<thead {...props} className={[props.className, "page-header-space"].join(' ')}>
                                <tr>
                                    <th colSpan={columns.length} style={{ fontSize: 24, fontWeight: "bold", textAlign: "center", padding: 8 }}>
                                        {header}
                                    </th>
                                </tr>
                                {props.children}
                            </thead>)
                        }
                    }}
                    loading={loading}
                    rowKey={(s) => s.id}
                    bordered
                    dataSource={data}
                    columns={columns}
                    pagination={false} />
            </div>
        </Modal>
    );
}


export function PrintNameFooter({ userName }: { userName: string }) {
    return <div style={{ float: 'right' }}>
        <Space>
            <span>打印人:</span>
            <span>{userName}</span>
            <span>打印时间:</span>
            <span>{dayjs().format("YYYY-MM-DD HH:mm:ss")}</span>
        </Space>
    </div>;
}