import { Button, Popconfirm } from "antd";
import { ButtonProps } from "antd/es/button";
import { useCallback, useMemo, useState } from "react";
import { ColumnType } from "./type";

export const TableInnerButton = (props: ButtonProps) => <Button size="small" {...props} style={{ margin: -2 }}></Button>;

interface IGetTableActionProps<T> {
    setEdit: (obj: { data?: T; } | null) => void;
    delData: (data: T, cb: () => void) => void;
    width?: number;
    key?: string;
    editCheck?: (data: T) => boolean;
}

export function useTableEditState<T>() {
    return useState<{ data?: T; } | null>(null);
}

function getTableAction<T extends {}>({ setEdit, width, key, delData, editCheck }: IGetTableActionProps<T>): ColumnType<T> {
    return {
        title: <TableInnerButton type="primary" onClick={() => setEdit({})}>添加</TableInnerButton>,
        key: key || 'action',
        width: width || 120,
        render: (_, data) => <EditDelDom<T> data={data} setEdit={setEdit} delData={delData} editCheck={editCheck} />
    };
}

interface IEditDelDomProps<T> {
    setEdit: (obj: { data?: T; } | null) => void;
    delData: (data: T, cb: () => void) => void;
    data: T,
    editCheck?: (data: T) => boolean;
}

/**
 * 修改和删除功能
 * @param param0 
 * @returns 
 */
function EditDelDom<T extends {}>({ setEdit, delData, data, editCheck }: IEditDelDomProps<T>) {
    const [loading, setLoading] = useState(false);

    const edit = useCallback(() => {
        setEdit({ data });
    }, [setEdit, data]);

    const del = useCallback(() => {
        setLoading(true);
        delData(data, () => setLoading(false));
    }, [delData, data]);

    const dis = useMemo(() => editCheck && editCheck(data), [data, editCheck]);

    return <Button.Group size="small">
        <TableInnerButton type="default" onClick={edit} disabled={dis}>修改</TableInnerButton>
        <Popconfirm title="您确定要删除吗?" onConfirm={del}>
            <TableInnerButton loading={loading} danger disabled={dis}>删除</TableInnerButton>
        </Popconfirm>
    </Button.Group>;
}

/**
 * 为table 追加基本的 添加，修改，删除 功能
 */
export default getTableAction;