import { get, post } from '@/Api';
import { AccessLogSplitPageProps, BaseAccessLogSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { AccessLog, RestLog } from '@/Models/Tables/AccessLog';
import { ViewAccessLog } from '@/Models/Views/ViewAccessLog';

const name = 'AccessLog';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(data: AccessLogSplitPageProps): Promise<PaginationData<ViewAccessLog>> {
    return post(getUrl("getViews"), data);
}
export function getRestData(data: BaseAccessLogSplitPageProps): Promise<PaginationData<RestLog>> {
    return post(getUrl("GetRestData"), data);
}
export function add(data: AccessLog): Promise<ViewAccessLog> {
    return post(getUrl("add"), data);
}
export function upd(data: AccessLog): Promise<ViewAccessLog> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getMethods(): Promise<string[]> {
    return get(getUrl("GetMethods"));
}
export function getUrls(): Promise<string[]> {
    return get(getUrl("GetUrls"));
}
export function getRestUrls(): Promise<string[]> {
    return get(getUrl("GetRestUrls"));
}
export function getIps(): Promise<string[]> {
    return get(getUrl("GetIps"));
}