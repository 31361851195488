import { getUpkeepAlarmList } from "@/Api/EquipmentController";
import { boolProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { EquipmentType, getEquipmentTypeOptions } from "@/Models/Enums/EquipmentType";
import { ViewUpkeepAlarm } from "@/Models/Views/ViewUpkeepAlarm";
import { Radio, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { EquipmentTypeContext } from "../UpkeepConfig/common";
import Add from "./Add";
import RecordModal from "./RecordModal";

export default Index;

function getColumns(setAddRecord: (data: TypeShowData) => void): ColumnType<ViewUpkeepAlarm>[] {
    return [
        strProps("equipmentId", "设备编码", 100, true, true),
        boolProps("flag", "需要保养", 100, true, s => s ? '是' : '否'),
        strProps("configName", "方案名称", 160, true, true),
        strProps("description", "方案描述信息", 200, true, true),
        strProps("message", "预警信息", undefined as any, true, true),
        {
            title: "操作", key: "action", width: 160, render: (_, r) => (<Space>
                <TableInnerButton type="link" onClick={() => setAddRecord({ type: "record", data: r })}>查看</TableInnerButton>
                <TableInnerButton type="link" onClick={() => setAddRecord({ type: "add", data: r })}>保养</TableInnerButton>
            </Space>)
        },
    ];
}

type TypeShowData = { type: "record" | "add", data: ViewUpkeepAlarm };

function Index() {
    const radioOptions = useMemo(getEquipmentTypeOptions, []);
    const [type, setType] = useState(radioOptions[0].value);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ViewUpkeepAlarm[]>([]);
    const [showInfo, setShowInfo] = useState<TypeShowData>();
    const columns = useMemo(() => getColumns(setShowInfo), []);
    const onHide = useCallback(() => setShowInfo(undefined), []);
    const onDataChange = useCallback((d: ViewUpkeepAlarm) => setData(s => {
        const index = s.findIndex(a => a.configId === d.configId && a.equipmentId === d.equipmentId);
        if (index > -1) {
            setAlarmInfo(d, type);
            s[index] = d;
            return [...s];
        }
        return s;
    }), [type]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getUpkeepAlarmList(type);
                d1.forEach(s => setAlarmInfo(s, type));
                setData(d1);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [type]);

    return (<EquipmentTypeContext.Provider value={type}>
        <CommonTable rowKey={s => `${s.configId}-${s.equipmentId}`} dataSource={data} loading={loading}
            columns={columns}
            title={s => <Radio.Group value={type} optionType="button" options={radioOptions} onChange={s => setType(s.target.value)} />}
        />
        {showInfo && (showInfo.type === "add" ? <Add data={showInfo.data} onCancel={onHide} onSubmit={onDataChange} /> : <RecordModal data={showInfo.data} onCancel={onHide} />)}
    </EquipmentTypeContext.Provider>);
}

function setAlarmInfo(d: ViewUpkeepAlarm, type: EquipmentType) {
    if (type === EquipmentType.Stacker) {
        d.surplusRuntime1 = d.runtimeInterval1 - (d.runtime1 - d.lastRuntime1);
        d.surplusRuntime2 = d.runtimeInterval2 - (d.runtime2 - d.lastRuntime2);
        d.surplusRuntime3 = d.runtimeInterval3 - (d.runtime3 - d.lastRuntime3);
        d.surplusRuntime4 = d.runtimeInterval4 - (d.runtime4 - d.lastRuntime4);
        d.surplusMileage1 = d.mileageInterval1 - (d.mileage1 - d.lastMileage1);
        d.surplusMileage2 = d.mileageInterval2 - (d.mileage2 - d.lastMileage2);
        d.surplusMileage3 = d.mileageInterval3 - (d.mileage3 - d.lastMileage3);
        d.surplusMileage4 = d.mileageInterval4 - (d.mileage4 - d.lastMileage4);
        d.message = '';
        if (d.surplusRuntime1 < 0) {
            d.message = `行走运行时间已达到限定时间,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},行走运行(${d.surplusRuntime1}s)`;
        }
        if (d.surplusRuntime2 < 0) {
            d.message = `升降运行时间已达到限定时间,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},升降运行(${d.surplusRuntime2}s)`;
        }
        if (d.surplusRuntime3 < 0) {
            d.message = `单叉运行时间已达到限定时间,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},单叉运行(${d.surplusRuntime3}s)`;
        }
        if (d.surplusRuntime4 < 0) {
            d.message = `双叉运行时间已达到限定时间,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},双叉运行(${d.surplusRuntime4}s)`;
        }
        if (d.surplusMileage1 < 0) {
            d.message = `行走里程已达到限定距离,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},行走里程(${d.surplusMileage1}s)`;
        }
        if (d.surplusMileage2 < 0) {
            d.message = `升降里程已达到限定距离,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},升降里程(${d.surplusMileage2}s)`;
        }
        if (d.surplusMileage3 < 0) {
            d.message = `单叉里程已达到限定距离,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},单叉里程(${d.surplusMileage3}s)`;
        }
        if (d.surplusMileage4 < 0) {
            d.message = `双叉里程已达到限定距离,请及时安排保养!`;
            d.flag = true;
            return;
        }
        else {
            d.message = `${d.message},双叉里程(${d.surplusMileage4}s)`;
        }
        d.message = `[${d.message.substring(1)}]后需要保养!`;
        d.flag = false;

    }
    else {
        d.surplusRuntime1 = d.runtimeInterval1 - (d.runtime1 - d.lastRuntime1);
        if (d.surplusRuntime1 > 0) {
            d.message = `运行${d.surplusRuntime1}s后需保养!`;
        }
        else {
            d.message = `运行时间已达到设定时间,请及时保养!`;
            d.flag = true;
        }
    }
}