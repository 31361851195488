import { IMenuGroup } from "@/Pages/Wms/routeData";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FunctionComponent } from "react";

interface RightSelectProps {
    onChange?: (s: number) => void;
    value?: number;
    data: IMenuGroup;
}

const RightSelect: FunctionComponent<RightSelectProps> = ({ onChange, value, data }) => {
    const val = useMemo(() => value ?? 0, [value]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const options = useMemo(() => data.items.map((s) => s.name), [data.items]);
    const checkedList = useMemo(() => data.items.filter((s) => (val & s.right) === s.right).map(s => s.name), [data.items, val]);
    const groupOnChange = useCallback((s: string[]) => {
        if (onChange) {
            let v = 0;
            data.items.forEach((a) => {
                if (s.some(b => b === a.name)) {
                    v |= a.right;
                }
            });
            onChange(v);
        }
    }, [data.items, onChange]);

    useEffect(() => {
        setIndeterminate(!!checkedList.length && checkedList.length < data.items.length);
        setCheckAll(checkedList.length === data.items.length);
    }, [checkedList, data.items.length]);

    const onCheckAllChange = useCallback((e: CheckboxChangeEvent) => {
        setCheckAll(e.target.checked);
        setIndeterminate(false);
        if (onChange) {
            let v = 0;
            if (e.target.checked) {
                data.items.forEach((a: any) => {
                    v |= a.right;
                });
            }
            onChange(v);
        }
    }, [data.items, onChange]);
    return (
        <div className="right-group">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>{data.name}</Checkbox>
            <div className="right-body">
                <Checkbox.Group options={options} value={checkedList} onChange={groupOnChange as any}></Checkbox.Group>
            </div>
        </div>
    );
};

export default RightSelect;



