import { resolveEnumOptions } from "./help";


export enum ContractStatus {
    Pending = 0,
    Manufacturing = 1,
    Installation = 2,
    Commissioning = 3,
    TestRunning = 4,
    Finished = 5,
    Cancel = -1,
}


export type TypeContractStatus = keyof typeof ContractStatus;
const ContractStatusNames: {
    [K in TypeContractStatus]: string;
} = {
    Pending: "待定",
    Manufacturing: "生产中",
    Installation: "安装中",
    Commissioning: "调试中",
    TestRunning: "试运行",
    Finished: "已完成",
    Cancel: "已取消",
};

export function getContractStatusNames(e: TypeContractStatus) {
    return ContractStatusNames[e];
}

export function getContractStatusOptions() {
    return resolveEnumOptions(ContractStatusNames);
}