export enum LanewayStatus {
    Free = 0,
    Lock = 1
}
export type TypeLanewayStatus = keyof typeof LanewayStatus;
const lanewayStatusNames: { [K in TypeLanewayStatus]: string } = {
    "Free": "正常",
    "Lock": "锁定",
};

export function getLanewayStatusNames(e: TypeLanewayStatus) {
    return lanewayStatusNames[e];
}