import CustomerApi from "@/Api/CustomerApi";
import SupplierApi from "@/Api/SupplierApi";
import { utcEndSecond, utcStartSecond } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { BillSplitPageProps, ExcludeBasePagination as Ext } from "@/Models/Other/BaseSplitPageProps";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, DatePicker, Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";



export function SearchComponent({ onSearch, flagOptions }: { onSearch: (data: Ext<BillSplitPageProps>) => void; flagOptions: { label: string, value: string }[] }) {
    const [loading, setLoading] = useState(false);
    const [suppliers, setSuppliers] = useState<SelectItem[]>([]);
    const [customer, setCustomer] = useState<SelectItem[]>([]);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let start: number | undefined = undefined;
            let end: number | undefined = undefined;
            if (s["dateRange"]) {
                start = utcStartSecond(s["dateRange"][0]);
                end = utcEndSecond(s["dateRange"][1]);
            }
            onSearch(
                {
                    start, end,
                    customerId: s["customerId"],
                    flag: s["flag"],
                    supplierId: s["supplierId"],
                }
            );
        }}>
            <Form.Item name="dateRange" label="日期范围">
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss"></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item label="状态" name="flag">
                <Select style={{ minWidth: 100 }} options={flagOptions} allowClear />
            </Form.Item>
            <Form.Item label="供应商" name="supplierId">
                <Select onDropdownVisibleChange={suppliers.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        SupplierApi.getSelectItems().then(setSuppliers).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={suppliers} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="客户" name="customerId">
                <Select onDropdownVisibleChange={customer.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        CustomerApi.getSelectItems().then(setCustomer).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={customer} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}


