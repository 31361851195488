import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import * as echarts from 'echarts';
import { delay } from "lodash";
import { HomeProviderContext } from "./HomeProvider";
import { SlotRatioUse } from "@/Models/Other/Home";
function getOptions(v: SlotRatioUse) {
    return {
        title: {
            text: '储位利用率分析',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
        },
        series: [
            {
                type: 'pie',
                radius: '50%',
                itemStyle: { normal: { label: { show: true, formatter: '{b} {c} ({d}%)' } } },
                data: [
                    { value: v.hasSomething, name: '有物品' },
                    { value: v.exporting, name: '正在出库' },
                    { value: v.empty, name: '空储位' },
                    { value: v.importing, name: '正在入库' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
}


function Index() {
    const divRef = useRef<HTMLDivElement>(null);
    const charRef = useRef<{ chart?: echarts.ECharts }>({});
    const provider = useContext(HomeProviderContext);
    const [slotRatioUse, setSlotRatioUse] = useState(provider.slotRatioUse.Value);
    useEffect(() => provider.slotRatioUse.listen(setSlotRatioUse), [provider]);
    useEffect(() => {
        if (charRef.current.chart) {
            charRef.current.chart.setOption(getOptions(slotRatioUse));
        }
    }, [slotRatioUse]);

    useLayoutEffect(() => {
        if (divRef.current) {
            const node = divRef.current;
            delay(() => {
                const chart = echarts.init(node);
                chart.setOption(getOptions(provider.slotRatioUse.Value));
                const obj = { width: node.clientWidth, height: node.clientHeight };
                chart.resize(obj)
                charRef.current.chart = chart;
            }, provider.renderDelay);
        }
    }, [provider]);

    return <div ref={divRef}></div>;
}

export default Index;