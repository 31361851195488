import { TableInnerButton } from "@/Common/Table/getTableAction";
import { getLanewayStatusNames, LanewayStatus } from "@/Models/Enums/LanewayStatus";
import { ViewLaneway } from "@/Models/Views/ViewLaneway";
import { Popconfirm } from "antd";
import { useContext } from "react";
import { useCallback } from "react";
import { useMemo, useState } from "react";
import { LanewayProviderContext } from "./LanewayProvider";

export function EditStatus({ record }: { record: ViewLaneway; }) {
    const status = useMemo(() => getLanewayStatusNames(record.status), [record.status]);
    const [loading, setLoading] = useState(false);
    const provider = useContext(LanewayProviderContext);
    const onConfirm = useCallback(async () => {
        const status = LanewayStatus[record.status] === LanewayStatus.Free ? LanewayStatus.Lock : LanewayStatus.Free;
        setLoading(true);
        try {
            await provider.hub.updLanewayStatus(record.id, status);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }, [provider.hub, record.id, record.status]);
    return <Popconfirm disabled={loading} title="您确定要修改巷道的状态吗？" onConfirm={onConfirm}>
        <TableInnerButton loading={loading} type="link">{status}</TableInnerButton>
    </Popconfirm>;
}
