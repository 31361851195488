import { getViews } from "@/Api/SysUserGroupController";
import { ViewSysUserGroup } from "@/Models/Views/ViewSysUserGroup";
import { Button, Divider, Form, Menu, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import RightSelect from "./RightSelect";
import { getListById, upd } from "@/Api/SysRightController";
import { convertObjToSysRights, getMenuData } from "@/Pages/Wms/routeData";
import './index.scss';

interface RightManagerProps {

}

const RightManager: FunctionComponent<RightManagerProps> = () => {

    const [data, setData] = useState<ViewSysUserGroup[]>([]);
    const [selection, setSelection] = useState<ViewSysUserGroup>();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const menuData = useMemo(getMenuData, []);

    useEffect(() => {
        setLoading(true);
        getViews().then(s => {
            setData(s);
            setSelection(s[0]);
        }).catch(console.error).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (selection) {
            setLoading(true);
            getListById(selection.id).then(s => {
                form.resetFields();
                form.setFields(s.map(a => ({ ...a, value: a.right })));
            }).catch(console.error).finally(() => setLoading(false));
        }
    }, [form, selection]);

    const onFinished = useCallback((s: any) => {
        if (selection) {
            setLoading(true);
            upd(convertObjToSysRights(selection.id, s)).then(s => {
                message.success({
                    content: `权限修改成功!`,
                });
            }
            ).catch(console.error).finally(() => setLoading(false));
        }
    }, [selection]);

    return (<div className="right-manager-root">
        <Menu className="left-side" selectedKeys={selection && [selection.code]}>
            {data.map(s => <Menu.Item key={s.code} onClick={() => setSelection(s)}>{s.name}</Menu.Item>)}
        </Menu>
        <div className="right-side">
            <Form layout="vertical" onFinish={onFinished} form={form}>
                {menuData.map(s => (<Form.Item name={s.name} noStyle key={s.name}>
                    <RightSelect data={s.data} />
                </Form.Item>))}
                <Divider />
                <Form.Item>
                    <Button loading={loading} disabled={loading} htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>
    </div>);
};

export default RightManager;









