import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import * as echarts from 'echarts';
import { delay } from "lodash";
import { HomeProviderContext } from "./HomeProvider";
import { MonthImportAndExportCount } from "@/Models/Other/Home";
import dayjs from "dayjs";
function getOptions(v: MonthImportAndExportCount[]) {
    const title = `[${dayjs().subtract(14, "day").format("MM-DD")}~${dayjs().format("MM-DD")}]入库出库分析`;
    const colors = ['#5470C6', '#EE6666'];
    const names = v.map(s => s.name);
    return {
        title: {
            text: title,
            left: 'center'
        },
        color: colors,
        tooltip: {
            trigger: 'none',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: 70,
            bottom: 30
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[1]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: (params: any) => {
                            return '出库  ' + params.value
                                + (params.seriesData.length ? ':' + params.seriesData[0].data : '');
                        }
                    }
                },
                data: names
            },
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[0]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: function (params: any) {
                            return '入库  ' + params.value
                                + (params.seriesData.length ? ':' + params.seriesData[0].data : '');
                        }
                    }
                },
                data: names
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '入库',
                type: 'line',
                xAxisIndex: 1,
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: v.map(s => s.import),
            },
            {
                name: '出库',
                type: 'line',
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: v.map(s => s.export),
            }
        ]
    };
}
function Index() {
    const divRef = useRef<HTMLDivElement>(null);
    const charRef = useRef<{ chart?: echarts.ECharts }>({});
    const provider = useContext(HomeProviderContext);
    const [monthImportAndExportCount, setMonthImportAndExportCount] = useState(provider.monthImportAndExportCount.Value);
    useEffect(() => provider.monthImportAndExportCount.listen(setMonthImportAndExportCount), [provider]);
    useEffect(() => {
        if (charRef.current.chart) {
            charRef.current.chart.setOption(getOptions(monthImportAndExportCount));
        }
    }, [monthImportAndExportCount]);

    useLayoutEffect(() => {
        if (divRef.current) {
            const node = divRef.current;
            delay(() => {
                const chart = echarts.init(node);
                chart.setOption(getOptions(provider.monthImportAndExportCount.Value));
                const obj = { width: node.clientWidth, height: node.clientHeight };
                chart.resize(obj);
                charRef.current.chart = chart;
            }, provider.renderDelay);
        }
    }, [provider]);
    return <div ref={divRef}></div>;
}

export default Index;