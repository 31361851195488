import { add, getViews, upd, updPwd } from "@/Api/SysUserController";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { urlRender } from "@/Common/UrlHelp";
import SysUserAction, { TypeEditData } from "@/Common/UserInfo/SysUserAction";
import { getShieldFlagNames } from "@/Models/Enums/ShieldFlag";
import { ViewSysUser } from "@/Models/Views/ViewSysUser";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Add from "./Add";

function getColumns(setEdit: (data: TypeEditData) => void): ColumnType<ViewSysUser>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("name", "用户名称", 200, true, true),
        strProps("code", "用户编码", 200, true, true),
        { title: "用户头像", width: 300, dataIndex: "avatar", render: urlRender },
        strProps("groupCode", "用户组编码", 200, true, true),
        strProps("groupName", "用户组名称", 200, true, true),
        { title: "在职/离职", width: 140, dataIndex: "shieldFlag", render: getShieldFlagNames },
        {
            title: <TableInnerButton type="primary" onClick={() => setEdit({})}>添加用户</TableInnerButton>,
            key: "action",
            width: 160,
            render: (_, r) => <SysUserAction record={r} onEdit={setEdit} />
        }
    ];
}

export default Index;

function Index() {
    const [data, setData] = useState<ViewSysUser[]>([]);
    const [edit, setEdit] = useState<TypeEditData>();
    const [loading, setLoading] = useState(false);
    const columns = useMemo(() => getColumns(setEdit), []);
    useEffect(() => {
        setLoading(true);
        getViews().then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);

    const onCancel = useCallback(() => setEdit(undefined), []);

    const onSubmit = useCallback(async (data: any) => {
        setLoading(true);
        try {
            let s: ViewSysUser;
            if (edit?.data) {
                if (edit.type === "editPwd") {
                    console.log(await updPwd(edit.data.id, data));
                }
                else {
                    s = await upd(data);
                    setData(a => {
                        const index = a.findIndex(b => b.id === s.id);
                        if (index !== -1) {
                            a.splice(index, 1, s);
                            return [...a];
                        }
                        return a;
                    });
                }
            } else {
                s = await add(data);
                setData(a => [...a, s]);
            }
            setEdit(undefined);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }

    }, [edit]);

    return <Fragment>
        <CommonTable rowKey={s => s.id} columns={columns} dataSource={data} loading={loading} />
        {edit && <Add data={edit.data as any} type={edit.type} onCancel={onCancel} onSubmit={onSubmit} />}
    </Fragment>;
}
