import { get } from '@/Api';
import { UploadOrderType } from '@/Models/Enums/UploadOrderType';
import { OrderUploadLog } from '@/Models/Tables/OrderUploadLog';

const name = 'OrderUploadLog';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getViews(orderId: number, type: UploadOrderType): Promise<OrderUploadLog[]> {
    return get(getUrl("getViews"), { orderId, type });
}
