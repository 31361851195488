import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { BaseHub, tpOnError } from "./BaseHub";
import { IImportBillHubAction } from "./IActions/IImportBillHubAction";

export class ImportBillHub extends BaseHub<IImportBillHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/ImportBill");
    }
    public getUnFinishedList = () => this.hub.invoke<ViewImportBill[]>("GetUnFinishiedList");
}
