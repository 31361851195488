import { Obs } from "@/Common/Obs";
import { Button, List, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { LeafComponentProps } from "./LeafComponentProps";

export function LeafItem({ data, onEdit, onDelete }: { data: Obs<LeafComponentProps>; onEdit: (d: Obs<LeafComponentProps>) => void; onDelete: (d: Obs<LeafComponentProps>) => void; }) {
    const [item, setItem] = useState(data.Value);
    useEffect(() => data.listen(setItem), [data]);
    return (<List.Item actions={[
        <Button size="small" type="link" onClick={() => onEdit(data)}>修改</Button>,
        <Popconfirm title="您确定要删除这一项吗?" onConfirm={() => onDelete(data)}>
            <Button size="small" type="link" danger>删除</Button>
        </Popconfirm>
    ]}>{item.text}</List.Item>);
}
