import React, { useRef, useCallback, ChangeEvent, useState, useEffect, Fragment } from "react";
import { Spin } from "antd";
import FileUpload from "@/Api/UploadController";
import CropDom from "@/Common/CropImg";
import './index.scss';

interface IProps {
    value?: string;
    onChange?: (val: string) => void;
    onLoading?: (b: boolean) => void;
    name?: string;
    aspctRatio?: number
}

const SelectImg = ({ value, onChange, onLoading, name, aspctRatio }: IProps) => {

    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const fileRef = useRef<HTMLInputElement>(null);
    const fileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImgSrc(URL.createObjectURL(e.target.files[0]));
            e.target.value = '';
        }
    }, []);

    useEffect(() => { if (imgSrc.length > 0) return () => URL.revokeObjectURL(imgSrc); }, [imgSrc]);

    const cropCancel = useCallback(() => setImgSrc(''), []);

    const cropOk = useCallback((f: File, cb?: () => void) => {
        const onFinished = (s: string) => {
            onChange && onChange(s);
            cb && cb();
            setImgSrc('');
        };
        const upload = new FileUpload(f, onFinished);
        upload.setLoading = b => { setLoading(b); onLoading && onLoading(b); };
        upload.upload();
    }, [onChange, onLoading]);

    const onSelect = useCallback(() => fileRef.current?.click(), []);


    return <div className="common-select-image-body">
        <div className="common-select-image-content" onClick={onSelect}>
            {loading ? <Spin spinning={loading} size="large" />
                : <Fragment> <div hidden><input type="file" hidden ref={fileRef} accept="image/*" onChange={fileChange} /></div>
                    {value && value.length > 0 ? <img className="common-select-image-img" src={value} alt=""></img> : name || '选择图片'}
                </Fragment>
            }
        </div>
        {imgSrc.length > 0 && <CropDom aspctRatio={aspctRatio ?? 1} imgSrc={imgSrc} onCancel={cropCancel} onOk={cropOk} />}
    </div>;
};

export default SelectImg;