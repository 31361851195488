import { get } from '@/Api';
import { StockReportType } from '@/Models/Enums/StockReportType';
import { ViewStockReport } from '@/Models/Views/ViewStockReport';

const name = 'StockReport';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getViews(start: number, end: number, type: StockReportType): Promise<ViewStockReport[]> {
    return get(getUrl("getViews"), { start, end, type });
}