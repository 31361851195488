import Bill from './Bill';
import Order from './Order';
import Task from './Task';
import ImportBill from './QueryBill';
import ImportOrder from './QueryOrder';

import {
    ImportOutlined,
    ProfileOutlined,
    ReadOutlined,
    RocketOutlined,
    FileSearchOutlined,
    AimOutlined,
    LaptopOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
import ImportLaneway from './ImportLaneway';

function importGroup(): IMenuGroup {
    return {
        name: "入库管理",
        path: "import",
        icon: ImportOutlined,
        items: [
            {
                name: "巷道配置",
                icon: LaptopOutlined,
                element: <ImportLaneway />,
                path: "ImportLaneway",
                right: 1 << 1,
            },
            {
                name: "入库单",
                icon: ProfileOutlined,
                element: <Bill />,
                path: "Bill",
                right: 1 << 2,
            },
            {
                name: "入库流水",
                icon: ReadOutlined,
                element: <Order />,
                path: "Order",
                right: 1 << 3,
            },
            {
                name: "入库任务",
                icon: RocketOutlined,
                element: <Task />,
                path: "Task",
                right: 1 << 4,
            },
            {
                name: "入库单查询",
                icon: FileSearchOutlined,
                element: <ImportBill />,
                path: "ImportBill",
                right: 1 << 5,
            },
            {
                name: "入库流水查询",
                icon: AimOutlined,
                element: <ImportOrder />,
                path: "ImportOrder",
                right: 1 << 6,
            },
        ]
    };
}
export default importGroup; 