import { Button, Input, InputRef, Space } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { createRef } from "react";

type IGetFilterPropsSearchColumn = string | string[] | undefined;

/**
 * 表的列过滤器
 * @param dataIndex 过滤的属性
 * @param getField 过滤方法 
 * @returns 
 */
export function getFilterProps<T>(dataIndex: any, getField: (record: T) => any, valConvert?: (s: any, record: any) => any) {
    const searchInput = createRef<InputRef>();
    let search: { text: string, column?: IGetFilterPropsSearchColumn } = { text: '', column: '' };
    const handleSearch = (selectedKeys: FilterDropdownProps["selectedKeys"], confirm: FilterDropdownProps["confirm"], dataIndex: IGetFilterPropsSearchColumn) => {
        confirm();
        const t = selectedKeys[0] ? selectedKeys[0].toString() : '';
        search = { text: t, column: dataIndex };
    };
    const handleReset = (clearFilters: FilterDropdownProps["clearFilters"]) => {
        clearFilters && clearFilters();
        search = { text: '' };
    };
    return {
        filterDropdown: (props: FilterDropdownProps) => <div style={{ padding: 8 }}>
            <Input
                ref={searchInput}
                placeholder={`请输入过滤的内容`}
                value={props.selectedKeys[0]}
                onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(props.selectedKeys, props.confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(props.selectedKeys, props.confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    查询
                </Button>
                <Button onClick={() => handleReset(props.clearFilters)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </Space>
        </div>,
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string | number | boolean, record: T) => {
            let tmp: any = getField(record);
            if (valConvert) tmp = valConvert(tmp, record);
            return tmp.toString().toLowerCase().includes(value.toString().toLowerCase());
        },
        // onFilterDropdownVisibleChange: (visible: boolean) => { if (visible) { setTimeout(() => searchInput.current?.select()); } },
        onFilterDropdownOpenChange: (visible: boolean) => { if (visible) { setTimeout(() => searchInput.current?.select()); } },
        render: (text: any, record: any) => {
            let tmp = text;
            if (valConvert) {
                tmp = valConvert(text, record);
            }
            return search.column === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[search.text]}
                    autoEscape
                    textToHighlight={tmp}
                />
            ) : (tmp);
        }
    };
}