import { get, post } from '@/Api';
import { BillTypeCategory } from '@/Models/Enums/BillTypeCategory';
import { SelectItem } from '@/Models/Other/SelectItem';
import { BillType } from '@/Models/Tables/BillType';

namespace BillTypeApi {
    const name = 'BillType';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }

    export function getViews(): Promise<BillType[]> {
        return get(getUrl("getViews"));
    }

    export function add(data: BillType): Promise<BillType> {
        return post(getUrl("add"), data);
    }

    export function upd(data: BillType): Promise<BillType> {
        return post(getUrl("upd"), data);
    }

    export function del(id: number): Promise<number> {
        return get(getUrl("del"), { id });
    }

    export function getSelectItems(category: BillTypeCategory): Promise<SelectItem[]> {
        return get(getUrl("getSelectItems"), { category });
    }
}

export default BillTypeApi;
