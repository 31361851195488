import { TaskInfo } from "./TaskInfo";
export interface StackerInfo {
    name: string;
    stationNo: number;
    warehouseId: number;
    taskNo: number;
    info: TaskInfo;
    status: number;
    faults: string;
    currentLanewayNo: number;
    currentRow: number;
    currentColumn: number;
    currentLayer: number;
    targetLanewayNo: number;
    targetRow: number;
    targetColumn: number;
    targetLayer: number;
    runningStatus: number;
    online: number;
    controlMode: number;
    taskInfoStatus: number;
    runtime1: number;
    runtime2: number;
    runtime3: number;
    runtime4: number;
    mileage1: number;
    mileage2: number;
    mileage3: number;
    mileage4: number;
    plcNo: number;
}

export function getStatusString(status: number) {
    switch (status) {
        case 1: return '空闲';
        case 2: return '准备';
        case 3: return '空载移动';
        case 4: return '取货';
        case 5: return '正在作业';
        case 6: return '满载移动';
        case 7: return '卸货';
        case 8: return '出库';
        case 9: return '入库';
        default: return '';
    }
}

