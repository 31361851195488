import { useCallback } from "react";
import { BillExecuteFlag } from "../Enums/BillExecuteFlag";
import { ExportBillExecuteFlag } from "../Enums/ExportBillExecuteFlag";
import { TaskMode } from "../Enums/TaskMode";
import { WarehouseType } from "../Enums/WarehouseType";

export interface BasePagination {
    current: number;
    pageSize: number;
}

export function useQueryPageChange(data: React.MutableRefObject<BasePagination>, fn: (current: number, pageSize: number) => void) {
    return useCallback((current: number, pageSize: any) => {
        if (data.current.current === current && pageSize === data.current.pageSize) {
            return;
        }
        else {
            fn(current, pageSize);
        }
    }, [data, fn]);
}

export type ExcludeBasePagination<T> = Omit<T, "current" | "pageSize">;

export interface TimeFilterProps extends BasePagination {
    start?: number;
    end?: number;
}

export interface OrderSplitPageProps extends TimeFilterProps {
    goodsId?: number;
    billCode?: string;
    stockCode?: string;
    slotId?: number;
}

export interface BaseAccessLogSplitPageProps extends BasePagination {
    ip?: string;
    method?: string;
    url?: string;
    reqTime?: number;
    resTime?: number;
}

export interface AccessLogSplitPageProps extends BaseAccessLogSplitPageProps {
    userId?: number;
}

export interface TaskSplitPageProps extends TimeFilterProps {
    stockCode?: string;
    slotCode?: string;
    taskNo?: string;
    taskBegin?: string;
    taskEnd?: string;
    taskMode?: TaskMode;
}

export interface StockSlotSplitPageProps extends OrderSplitPageProps {
    slotCode?: string;
    goodsStatusId?: number;
    warehouseType?: WarehouseType;
}

export interface StockSlotBatchExportSplitPageProps extends OrderSplitPageProps {
    lanewayId?: number;
    row?: number;
    column?: number;
    layer?: number;
}

export interface BillSplitPageProps extends TimeFilterProps {
    supplierId?: number;
    customerId?: number;
    flag?: BillExecuteFlag;
}

export interface ExportBillSplitPageProps extends TimeFilterProps {
    supplierId?: number;
    customerId?: number;
    flag?: ExportBillExecuteFlag;
}

export interface EquipmentFaultSplitPageProps extends TimeFilterProps {
    stationNo?: number | null;
    categoryId?: number | null;
    isFaulting?: boolean;
}

export interface MesExportBillSyncLogSplitPageProps extends TimeFilterProps {

}
export interface AgvToWmsBodySplitPageProps extends TimeFilterProps {

}