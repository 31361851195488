import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ViewSlot } from '@/Models/Views/ViewSlot';
import { LocationPositionProvider } from './LocationPositionProvider';
import { GlobalProviderContext } from '@/App/GlobalProvider';

export function Location({ data, onShow, position }: { data: ViewSlot; onShow: (data: ViewSlot) => void; position: LocationPositionProvider }) {

    const globalProvider = useContext(GlobalProviderContext);
    const [user, setUser] = useState(globalProvider.user.Value);
    useEffect(() => {
        return globalProvider.user.listen(setUser);
    }, [globalProvider.user]);

    const { left, top } = useMemo(() => position.getPosition(data), [data, position]);

    const status = useMemo(() => {
        if (data.lockFlag === "Free") return data.status;
        else return "Lock";
    }, [data]);

    const onClick = useCallback(() => {
        if (data.status === 'HasSomething' || data.status === 'WaitingConfirm') {
            onShow(data);
        }
        else if (user?.id === 1) {
            onShow(data);
        }
    }, [data, onShow, user]);

    const borderRadius = useMemo(() => data.isEmptyStock ? 8 : 0, [data.isEmptyStock]);

    return <div className={`location ${status}`} style={{ left, top, borderRadius }} onClick={onClick} >
        <span>
            {data.column}
        </span>
    </div>;
}


