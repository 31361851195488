import React from "react";

export function getUrlName(s: string) {
    if (s && s.length > 0) {
        const index = s.lastIndexOf('/');
        if (index > -1) return s.substring(index + 1);
    }
    return s;
}

export const urlRender = (s: string) => <a href={s} target="blank" >{getUrlName(s)}</a>;