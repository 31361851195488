export class Listen<T> {
    private _fns: ((data: T) => void)[] = [];

    notify(data: T) {
        this._fns.forEach(f => f(data));
    }

    listen(fn: (data: T) => void) {
        this._fns.push(fn);
        return () => {
            const index = this._fns.indexOf(fn);
            if (index === -1) {
                console.error("卸载钩子失败!");
            }
            else {
                this._fns.splice(index, 1);
            }
        };
    }
}

export class ReverseListen<T> {
    public readonly fns: (() => T)[] = [];
    listen(fn: () => T) {
        this.fns.push(fn);
        return () => {
            const index = this.fns.indexOf(fn);
            if (index === -1) {
                console.error("卸载钩子失败!");
            }
            else {
                this.fns.splice(index, 1);
            }
        };
    }
}
