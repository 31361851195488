import { groupBy } from "lodash";

export interface SelectItem {
    value: number;
    label: string;
}

export interface SelectItemGroup {
    label: string;
    options: SelectItem[];
}

export interface SelectItemExt1 extends SelectItem {
    labelLeaf: string;
}

export function getGroupBySelectItemExt1(d: SelectItemExt1[]): SelectItemGroup[] {
    const t1 = groupBy(d, s => s.labelLeaf) as { [K: string]: SelectItem[] };
    return Object.keys(t1).map(s => {
        return { label: s, options: t1[s] };
    });
}