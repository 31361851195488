import { getTaskFinishedKeys, setTaskFinished } from '@/Api/TestPlcController';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Button, Form, Input, Select, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

export function TaskFinishedComponent() {
    const [options, setOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getTaskFinishedKeys();
                setOptions(d1.map(s => ({ label: s.toString(), value: s })));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setTaskFinished(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    if (options.length === 0)
        return <Spin spinning={loading} />;
    return (<div>
        <div>过账</div>
        <div className='plcTest-taskFinished'>
            <Form layout='inline' form={form}>
                <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0].value}>
                    <Select options={options} />
                </Form.Item>
                <Form.Item name="status" label="设备状态" initialValue={1}>
                    <Input></Input>
                </Form.Item>
                <Form.Item name="taskNo" label="任务号" initialValue={0}>
                    <Input></Input>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
                </Form.Item>
            </Form>
        </div>
    </div>);
}
