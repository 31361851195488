
export enum WarehouseType {
    Stereoscopic = 0,
    Flat = 1
}

export type TypeWarehouseType = keyof typeof WarehouseType;

export const WarehouseTypeNames: { [K in TypeWarehouseType]: string } = {
    Stereoscopic: "智能库",
    Flat: "平库",
};

export function getWarehouseTypeName(e: TypeWarehouseType) {
    return WarehouseTypeNames[e];
}


