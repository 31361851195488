import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { PurchaseBill } from '@/Models/Tables/PurchaseBill';
import { ViewPurchaseBill } from '@/Models/Views/ViewPurchaseBill';
const name = 'PurchaseBill';
function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewPurchaseBill[]> {
    return get(getUrl("getViews"));
}
export function add(data: PurchaseBill): Promise<ViewPurchaseBill> {
    return post(getUrl("add"), data);
}
export function upd(data: PurchaseBill): Promise<ViewPurchaseBill> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getPurchaseBillSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("GetSelectItems"));
}
