import { ViewImportOrder } from "@/Models/Views/ViewImportOrder";
import { BaseHub, tpOnError } from "./BaseHub";
import { IImportOrderHubAction } from "./IActions/IImportOrderHubAction";

export class ImportOrderHub extends BaseHub<IImportOrderHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/ImportOrder");
    }
    public getUnFinishedList = () => this.hub.invoke<ViewImportOrder[]>("GetUnFinishiedList");
}

