import { TableInnerButton } from "@/Common/Table/getTableAction";
import { getTaskExecuteFlagName, TaskExecuteFlag } from "@/Models/Enums/TaskExecuteFlag";
import { ViewEquipmentTask } from "@/Models/Views/ViewEquipmentTask";
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Fragment, useCallback } from "react";
import useModal from "antd/lib/modal/useModal";
import { deleteTask, updFlag } from "@/Api/EquipmentTaskController";

export function TaskExecuteFlagActionDom({ record }: { record: ViewEquipmentTask; }) {
    const [modal, contextHolder] = useModal();
    const onCancel = useCallback(() => {
        modal.confirm({
            title: `您确定要取消任务号为:${record.taskNo}的任务吗？`, content: ` 手动操作任务时需要确认这个任务的实际执行状态!`, onOk: async () => {
                try {
                    const res1 = await updFlag(record.id, TaskExecuteFlag[record.executeFlag], TaskExecuteFlag.Cancel);
                    const res2 = await deleteTask(record.id);
                    console.log(res1 + res2);
                } catch (error) {
                    console.error(error);
                }
            },
            okText: "确认",
            cancelText: "取消"
        });
    }, [modal, record]);


    const onFinished = useCallback(() => {
        modal.confirm({
            title: `您确定要手动完成任务号为:${record.taskNo}的任务吗？`, content: ` 手动操作任务时需要确认这个任务的实际执行状态!`, onOk: async () => {
                try {
                    const res1 = await updFlag(record.id, TaskExecuteFlag[record.executeFlag], TaskExecuteFlag.Finished);
                    const res2 = await deleteTask(record.id);
                    console.log(res1 + res2);
                } catch (error) {
                    console.error(error);
                }
            },
            okText: "确认",
            cancelText: "取消"
        });
    }, [modal, record]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: `您确定要手动删除任务号为:${record.taskNo}的任务吗？`, content: ` 手动操作任务时需要确认这个任务的实际执行状态!`, onOk: async () => {
                try {
                    const res2 = await deleteTask(record.id);
                    console.log(res2);
                } catch (error) {
                    console.error(error);
                }
            },
            okText: "确认",
            cancelText: "取消"
        });
    }, [modal, record]);

    if (record.executeFlag === "WaitingExecute") {
        return <Fragment>
            <Dropdown menu={{ items: [{ label: "取消", key: 'cancel', onClick: onCancel }] }} trigger={["click"]}>
                <TableInnerButton type="link">
                    {getTaskExecuteFlagName(record.executeFlag)}<DownOutlined />
                </TableInnerButton>
            </Dropdown>
            {contextHolder}
        </Fragment>;
    }
    else if (record.executeFlag === "Cancel") {
        return <Fragment>
            <Dropdown menu={{ items: [{ label: "删除", key: "delete", onClick: onDelete }] }} trigger={["click"]}>
                <TableInnerButton type="link">
                    {getTaskExecuteFlagName(record.executeFlag)}<DownOutlined />
                </TableInnerButton>
            </Dropdown>
            {contextHolder}
        </Fragment>;
    }
    else if (record.executeFlag === "Finished") {
        return <Fragment>
            <Dropdown menu={{ items: [{ label: "删除", key: "delete", onClick: onDelete }] }} trigger={["click"]}>
                <TableInnerButton type="link">
                    {getTaskExecuteFlagName(record.executeFlag)}<DownOutlined />
                </TableInnerButton>
            </Dropdown>
            {contextHolder}
        </Fragment>;
    }
    else if (record.executeFlag === "WaitingConfirm") {
        return <Fragment>
            <Dropdown menu={{
                items: [
                    { label: "取消", key: "cancel", onClick: onCancel },
                    { label: "完成", key: "finished", onClick: onFinished },
                ]
            }} trigger={["click"]}>
                <TableInnerButton type="link">
                    {getTaskExecuteFlagName(record.executeFlag)}<DownOutlined />
                </TableInnerButton>
            </Dropdown>
            {contextHolder}
        </Fragment>;
    }
    return <div>{getTaskExecuteFlagName(record.executeFlag)}</div>;
}
