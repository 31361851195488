import { addOrders } from "@/Api/ImportOrderController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { ImportBill } from "@/Models/Tables/Bill";
import { ImportOrder } from "@/Models/Tables/Order";
import { Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { ImportOrderHubProviderContext } from "./ImportOrderHubProvider";
import { Notifies } from "./OrderList";

function Item(props: IFormItemProps<{ orders: ImportOrder[] }>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, data }: Omit<AddProps<ImportBill>, "onSubmit">) {
    const [form] = Form.useForm();
    const provider = useContext(ImportOrderHubProviderContext);
    const [loading, setLoading] = useState(provider.loading.Value);
    const onOk = useCallback(async () => {
        provider.loading.Value = true;
        try {
            const s = await form.validateFields();
            const res = await addOrders(s.orders as any);
            console.log(res);
            onCancel();
        } catch (error) {
            console.log(error);
        }
        finally {
            provider.loading.Value = false;
        }
    }, [form, onCancel, provider.loading]);
    useEffect(() => provider.loading.listen(setLoading), [provider.loading]);

    return <MyModal confirmLoading={loading} name="入库流水" data={data} width={840} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="left" initialValues={data} labelCol={{ span: 8 }}>
            <Item name="orders" label="明细" noStyle>
                <Notifies />
            </Item>
        </Form>
    </MyModal>;
}

export default Add;




