import ImportBillApi from "@/Api/ImportBillApi";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { ContentSizeContext } from "@/Common/context";
import { toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getBillExecuteFlagName } from "@/Models/Enums/BillExecuteFlag";
import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { Popconfirm, Table } from "antd";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AddForm } from "./AddForm";
import { ImportBillHubProvider, ImportBillHubProviderContext } from "./ImportBillHubProvider";
import { expandable } from "./expandable";

function getColumns(onShow: () => void): ColumnType<ViewImportBill>[] {
    return [
        { dataIndex: "id", title: "Id", width: 80 },
        strProps("typeName", "单据类型", 120, true, true),
        strProps("batchNo", "批次号", 140, true, true),
        strProps("contractNumber", "合同号", 140, true, true),
        strProps("code", "入库单编码", 180, true, true),
        strProps("supplierName", "供应商名称", 160, true, true),
        strProps("customerName", "客户名称", 160, true, true),
        numProps("quantity", "计划数", 100),
        numProps("factQuantity", "任务数", 100),
        numProps("completeQuantity", "完成数", 100),
        strProps("executeFlag", "执行标志", 100, false, true, getBillExecuteFlagName),
        strProps("userName", "创建人", 80, false, true),
        numProps("time", "建单时间", 150, true, toYMDHMS),
        { title: <TableInnerButton type="primary" onClick={onShow}>添加</TableInnerButton>, width: 100, key: "action", render: (_, r) => (<ActionComponent record={r} />), fixed: "right" },
        // { title: "操作", width: 100, key: "action", render: (_, r) => (<ActionComponent record={r} />) },
    ];
}

function ActionComponent({ record }: { record: ViewImportBill }) {
    const [loading, setLoading] = useState(false);
    const onCancel = useCallback(async () => {
        setLoading(true);
        try {
            const res = await ImportBillApi.cancel(record.id);
            console.log(res);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [record.id]);
    const onFinish = useCallback(async () => {
        setLoading(true);
        try {
            const res = await ImportBillApi.setFinished(record.id);
            console.log(res);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [record.id]);
    if (record.executeFlag === "WaitingExecute") {
        return (<Popconfirm title={`您确定要取消入库单${record.code}吗?`} onConfirm={onCancel} disabled={loading} >
            <TableInnerButton loading={loading} type="link" >取消</TableInnerButton>
        </Popconfirm>);
    }
    else if (record.executeFlag === "Executing") {
        return (<Popconfirm title={`您确定要完成出库单${record.code}吗?`} onConfirm={onFinish} disabled={loading} >
            <TableInnerButton loading={loading} type="link" >完成</TableInnerButton>
        </Popconfirm>);
    }
    return <Fragment></Fragment>;
}

export default Index;
function Index() {
    const provider = useCreateHubProvider(ImportBillHubProvider);
    const [loading, setLoading] = useState(provider.loading.Value);
    const [data, setData] = useState<ViewImportBill[]>([]);
    const [showAdd, setShowAdd] = useState(false);
    const onShow = useCallback(() => setShowAdd(true), []);
    const onCancel = useCallback(() => setShowAdd(false), []);
    const columns = useMemo(() => getColumns(onShow), [onShow]);
    useEffect(() => {
        const f1 = provider.data.listen(setData);
        const f2 = provider.loading.listen(setLoading);
        return () => { f1(); f2(); provider.hub.stop(); };
    }, [provider]);

    const size = useContext(ContentSizeContext);

    return (<ImportBillHubProviderContext.Provider value={provider}>
        <Fragment>
            <Table loading={loading}
                rowKey={s => s.id}
                dataSource={data}
                columns={columns}
                expandable={expandable}
                bordered
                scroll={{ y: size[0] - 120 }}
                size="middle"
                pagination={{ defaultPageSize: 14 }}
            />
            {showAdd && <AddForm onCancel={onCancel} />}
        </Fragment>
    </ImportBillHubProviderContext.Provider>);
}
