import { getViewsBySlotId } from "@/Api/StockSlotController";
import { toYMD, toYMDHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { Button, message, Modal, Popconfirm, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { sum } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { StockInfoListProps } from "./StockInfoListProps";
import './index.scss';
import { emptyExport, slotExport } from "@/Api/EquipmentTaskController";

function getColumns(): ColumnType<ViewStockSlot>[] {
  return [
    strProps("orderNo", "入库流水号", 160, false, true),
    strProps("goodsTypeName", "物品类别", 100, false, true),
    strProps("goodsName", "物品名称", 100, false, true),
    strProps("batchNo", "批次", 80, false, true),
    strProps("stockCode", "托盘条码", 120, false, true),
    strProps("slotCode", "储位编码", 120, false, true),
    numProps("productionDate", "生产日期", 100, true, toYMD),
    numProps("time", "入库时间", 160, true, toYMDHMS),
    numProps("quantity", "数量", 60, false),
    strProps("goodsStatusName", "状态", 80, false, false),
    strProps("flag", "库存状态", 80, false, true, getStockFlagName),
    strProps("remark", "备注", 100, false, false),
  ];
}

export function StockInfoList({ data, onCancel }: StockInfoListProps) {
  const columns = useMemo(getColumns, []);
  const [slotList, setSlotList] = useState<ViewStockSlot[]>([]);
  const [loading, setLoading] = useState(false);
  const width = useMemo(() => sum(columns.map(s => s.width ?? 120)) + 80, [columns]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setSlotList(await getViewsBySlotId(data.id));
      } catch (error) {
        console.error(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [data]);

  return (<Modal open footer={null} title={<StockInfoLIstTitle data={data} onCancel={onCancel} />} width={width} onCancel={onCancel}>
    <Table rowKey={s => s.id} scroll={{ y: 400 }} bordered loading={loading} size="small" dataSource={slotList} columns={columns} pagination={false} />
  </Modal>);
}

function StockInfoLIstTitle({ data, onCancel }: { data: ViewSlot, onCancel: () => void }) {
  const [loading, setLoading] = useState(false);

  const actionProps = useMemo(() => {
    const text = data.isEmptyStock ? "空托盘出库" : "抽检出库";
    const title = `您确定要执行${text}吗？`;
    return {
      text, title, onConfirm: async () => {
        setLoading(true);
        try {
          let msg;
          if (data.isEmptyStock) {
            msg = await emptyExport(data.id);
          }
          else {
            msg = await slotExport(data.id);
          }
          message.info(msg);
        } catch (error) {
          console.error(error);
        }
        finally {
          setLoading(false);
        }
      }
    }
  }, [data.id, data.isEmptyStock])

  return <div>
    <span>{`储位 ${data.code} 库存列表`}</span>
    {data.status === "HasSomething" ? (<Space style={{ marginLeft: 24 }}>
      {<Popconfirm title={actionProps.title} disabled={loading} onConfirm={actionProps.onConfirm}>
        <Button type="link" loading={loading} disabled={loading}>{actionProps.text}</Button>
      </Popconfirm>}
    </Space>) : (<Space style={{ marginLeft: 24 }}>

    </Space>)}
  </div>;
}


