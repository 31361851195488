import React from "react";
import { RouteProps } from "react-router-dom";
import { SysRight } from "@/Models/Tables/SysRight";
import baseGroup from "./Components/Base";
import equipmentGroup from "./Components/Equipment";
import stockGroup from "./Components/Stock";
import exportGroup from "./Components/Export";
import systemGroup from "./Components/System";
import upkeepGroup from "./Components/Upkeep";
import importGroup from "./Components/Import";
import purchaseGroup from "./Components/Purchase";
import saleGroup from "./Components/Sale";
import { SysUserToken } from "@/Models/Other/SysUserToken";

export type IMenuItem = RouteProps & {
    name: string;
    icon: React.ForwardRefExoticComponent<{}>;
    right: number;
    disabled?: boolean;
    path: string;
};

export interface IMenuGroup {
    name: string;
    path: string;
    icon: React.ForwardRefExoticComponent<{}>;
    items: IMenuItem[];
}

export function getRouteData(user: SysUserToken) {
    const data: IMenuGroup[] = [];
    const fn = (d1: IMenuGroup) => {
        d1.path = d1.path.toLowerCase();
        let right = user.rights.find(s => s.name === d1.path)?.right || 0;
        if (user.id === 1) {
            if (d1.path === "system") {
                right = 0xffffffff;
            }
        }
        if (right) {
            const d2: any[] = [];
            d1.items.forEach(s => {
                if ((right & s.right) === s.right) {
                    s.path = `${d1.path}-${s.path.toLowerCase()}`;
                    d2.push(s);
                }
            })
            d1.items = d2;
            data.push(d1);
        }
    };
    fn(baseGroup());
    fn(saleGroup());
    fn(purchaseGroup());
    fn(importGroup());
    fn(exportGroup());
    fn(stockGroup());
    fn(equipmentGroup());
    fn(systemGroup());
    fn(upkeepGroup());
    return data;
}

export function resolveIMenuGroupToItem(data: IMenuGroup[]) {
    const d1: IMenuItem[] = [];
    data.forEach(s => {
        d1.push(...s.items);
    });
    return d1;
}

export function getMenuData() {
    const fn = (data: IMenuGroup) => {
        return { name: data.path, data };
    }
    return [
        fn(baseGroup()),
        fn(saleGroup()),
        fn(purchaseGroup()),
        fn(importGroup()),
        fn(exportGroup()),
        fn(stockGroup()),
        fn(equipmentGroup()),
        fn(systemGroup()),
        fn(upkeepGroup()),
    ];
}

export function convertObjToSysRights(id: number, data: { [K: string]: number }) {
    const rightList: SysRight[] = [
        { name: baseGroup().path, sysUserGroupId: id, right: 0 },
        { name: saleGroup().path, sysUserGroupId: id, right: 0 },
        { name: purchaseGroup().path, sysUserGroupId: id, right: 0 },
        { name: importGroup().path, sysUserGroupId: id, right: 0 },
        { name: exportGroup().path, sysUserGroupId: id, right: 0 },
        { name: stockGroup().path, sysUserGroupId: id, right: 0 },
        { name: equipmentGroup().path, sysUserGroupId: id, right: 0 },
        { name: systemGroup().path, sysUserGroupId: id, right: 0 },
        { name: upkeepGroup().path, sysUserGroupId: id, right: 0 },
    ];
    rightList.forEach(s => {
        if (data[s.name]) {
            s.right = data[s.name];
        }
    });
    return rightList;
}