import { ColumnExtMemberType, FilterType } from "./type";
import { getFilterProps } from "./getFilterProps";

/**
 * 数字转字符串
 * @param n 
 * @returns 
 */
export function numToStr(n?: number) {
    return n && n !== 0 ? n.toString() : '';
}

/**
 * 字符串排序
 * @param key 
 * @returns 
 */
export function strSorter<T, K extends FilterType<T, string>>(key: K) {
    return (a: T, b: T) => {
        if (!a[key]) return 1;
        if (!b[key]) return -1;
        const a1 = (a[key] as any).toUpperCase();
        const b1 = (b[key] as any).toUpperCase();
        if (a1 < b1) return -1;
        else if (a1 > b1) return 1;
        else return 0;
    };
}

/**
 * @param key 数值排序
 */
export function numSorter<T, K extends FilterType<T, number>>(key: K) {
    return (a: T, b: T) => {
        const a1 = (a[key] ? a[key] : -1) as any;
        const b1 = (b[key] ? b[key] : -1) as any;
        return a1 - b1;
    };
}

/**
 * 布尔值排序
 * @param key 
 * @returns 
 */
export function boolSorter<T, K extends FilterType<T, boolean>>(key: K) {
    return (a: T, b: T) => {
        const a1 = (a[key] ? a[key] : -1) as any;
        const b1 = (b[key] ? b[key] : -1) as any;
        return a1 - b1;
    };
}

/**
 * 数字成员
 * @param key1 
 * @param title 
 * @param width 
 * @param sorter 
 * @param render 
 * @returns 
 */
export function numProps<T, K1 extends FilterType<T, number>>
    (key1: K1, title: string, width: number, sorter: boolean = true, render: (s: number, r: T,) => any = numToStr, filter: boolean = false)
    : ColumnExtMemberType<T, number, K1> {
    return {
        ...(filter ? filterProps(key1, render as any) : { render }),
        width, sorter: (sorter ? numSorter(key1) : undefined),
        dataIndex: key1,
        title: title,
        ellipsis: true
    };
}

/**
 * 布尔值成员
 * @param key1 
 * @param title 
 * @param width 
 * @param sorter 
 * @param render 
 * @returns 
 */
export function boolProps<T, K1 extends FilterType<T, boolean>>
    (key1: K1, title: string, width: number, sorter: boolean = true, render: (s: number, r: T) => any = numToStr, filter: boolean = false)
    : ColumnExtMemberType<T, boolean, K1> {
    return {
        ...(filter ? filterProps(key1, render as any) : { render }),
        width, sorter: (sorter ? boolSorter(key1) : undefined),
        dataIndex: key1,
        title: title,
        ellipsis: true
    };
}

export const strProps = <T, K extends FilterType<T, string>>(
    key: K, title: string, width: number, filter: boolean = false, sorter: boolean = true, valConvert?: (s: any, record: any) => any
): ColumnExtMemberType<T, string, K> & { ellipsis: boolean } => {
    return {
        ...(filter ? filterProps(key, valConvert) : { render: valConvert }),
        width, sorter: (sorter ? strSorter(key) : undefined),
        dataIndex: key,
        title: title, ellipsis: true
    };
};

function filterProps<T>(key: any, valConvert?: (s: any, record: any) => any) {
    return getFilterProps<T>(key, (s: any) => {
        if (!s[key]) return '';
        else return s[key];
    }, valConvert);
}


