import CustomerApi from "@/Api/CustomerApi";
import EditTable from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { Customer } from "@/Models/Tables/Customer";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<Customer>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "客户编码", 120, true, true),
        strProps("name", "客户名称", 160, true, true),
        strProps("linkMan", "联系人", 120, true, true),
        strProps("phoneNumber", "联系电话", 160, true, true),
        strProps("address", "地址", 200, true, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< Customer> getData={CustomerApi.getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={CustomerApi.upd}
        delDataHandle={s => CustomerApi.del(s.id)}
        updMerge={updMerge}
        addDataHandle={CustomerApi.add}
        rowKey={"id"}
    />;
}

function updMerge(source: Customer, target: Customer) {
    target.id = source.id;
    return target;
}