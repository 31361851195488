import { getViews } from "@/Api/OrderUploadLogController";
import { toYMDHMS, getOnlyHMS } from "@/Common/date";
import { CommonTable } from "@/Common/Table/EditTable";
import { renderCopyable } from "@/Common/Table/renderCopyable";
import { UploadOrderType } from "@/Models/Enums/UploadOrderType";
import { OrderUploadLog } from "@/Models/Tables/OrderUploadLog";
import { Modal } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";

export function UploadOrderLogComponent({ id, orderNo, type, onCancel }: { id: number, orderNo: string, type: UploadOrderType, onCancel: () => void }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<OrderUploadLog[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setData(await getViews(id, type));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [id, type]);

    return (<Modal title={`${orderNo}执行日志`} width={1200} open footer={null} confirmLoading={loading} onCancel={onCancel}>
        <CommonTable rowKey={s => s.id} dataSource={data} size="middle"
            loading={loading}
            pagination={{ pageSize: 10 }}
            columns={
                [
                    { title: "请求报文", dataIndex: "req", width: 300, ellipsis: true, render: renderCopyable },
                    { title: "反馈报文", dataIndex: "res", width: 300, ellipsis: true, render: renderCopyable },
                    { title: "请求", dataIndex: "time", width: 160, render: toYMDHMS },
                    { title: "答复", dataIndex: "resTime", width: 160, render: toYMDHMS },
                    { title: "耗时", key: "totalTime", width: 120, render: (_, r) => getOnlyHMS(r.time, r.resTime) },
                ]
            } />
    </Modal>);
}
