import { Stage } from "@pixi/react";
import { Button, Form, InputNumber, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LeafTextContainer } from "./LeafTextContainer";
import { LeafItemContainer } from "./LeafItemContainer";
import { LeafDataProvider } from "./LeafDataProvider";
import { getAll, getCurrentShowInfo, getDisplays, reloadDevice, setDisplays, writeLed } from "@/Api/LedDeviceController";
import { LedDevice } from "@/Models/Tables/Led";
import { SyncLedDisplay } from "./SyncLedDisplay";
import { Obs } from "@/Common/Obs";
import { LeafComponentProps } from "./LeafComponentProps";

const App = () => {
    const [scale, setScale] = useState(8);
    const [width, setWidth] = useState(256);
    const [height, setHeight] = useState(128);
    const leafDataProvider = useMemo(() => new LeafDataProvider(), []);
    const [ledDeviceList, setLedDeviceList] = useState<LedDevice[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getAll();
                setLedDeviceList(d1);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    const [loading, setLoading] = useState(false);
    const ledDeviceOptions = useMemo(() => ledDeviceList.map(s => ({ label: s.name, value: s.id })), [ledDeviceList])
    const reloadDeviceInfo = useCallback(async () => {
        setLoading(true);
        try {
            await reloadDevice();
            const d1 = await getAll();
            setLedDeviceList(d1);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [])

    const [form] = Form.useForm();

    const syncDisplay = useCallback(async () => {
        const syncStatus = form.getFieldValue("syncStatus");
        const ledDeviceId = form.getFieldValue("ledDeviceId");
        if (syncStatus && ledDeviceId > 0) {
            const t1 = leafDataProvider.data.Value.map(s => s.Value);
            const t2 = await writeLed({ ledDeviceId, displays: t1 as any });
            console.log(t2);
        }
    }, [form, leafDataProvider.data.Value]);

    const setDefaultShowInfo = useCallback(async () => {
        const ledDeviceId = form.getFieldValue("ledDeviceId");
        if (ledDeviceId > 0) {
            setLoading(true);
            const t1 = leafDataProvider.data.Value.map(s => ({ ...s.Value, ledDeviceId }));
            try {
                await setDisplays(t1 as any);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [form, leafDataProvider]);

    const getDefaultShowInfo = useCallback(async () => {
        const ledDeviceId = form.getFieldValue("ledDeviceId");
        if (ledDeviceId > 0) {
            setLoading(true);
            try {
                const t1 = await getDisplays(ledDeviceId);
                const t2 = t1.map(s => new Obs(new LeafComponentProps(s.text, s.fontSize, s.left, s.top, s.method)))
                leafDataProvider.data.Value = t2;
                leafDataProvider.data.resetValue();
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [form, leafDataProvider])

    const getCurrent = useCallback(async () => {
        const ledDeviceId = form.getFieldValue("ledDeviceId");
        if (ledDeviceId > 0) {
            const name = ledDeviceList.find(s => s.id === ledDeviceId)?.name;
            if (!name) return;
            setLoading(true);
            try {
                const t1 = await getCurrentShowInfo(name);
                const t2 = t1.map(s => new Obs(new LeafComponentProps(s.text, s.fontSize, s.left, s.top, s.method)))
                leafDataProvider.data.Value = t2;
                leafDataProvider.data.resetValue();
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [form, leafDataProvider, ledDeviceList])

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ padding: 8, borderBottom: "1px #ccc solid" }} >
                <Form layout="inline" form={form}>
                    <Form.Item label="缩放">
                        <InputNumber value={scale} step={1} min={1} max={8} precision={0} onChange={setScale as any}></InputNumber>
                    </Form.Item>
                    <Form.Item label="屏幕宽度">
                        <InputNumber value={width} step={1} precision={0} onChange={setWidth as any}></InputNumber>
                    </Form.Item>
                    <Form.Item label="屏幕高度">
                        <InputNumber value={height} step={1} precision={0} onChange={setHeight as any}></InputNumber>
                    </Form.Item>
                    <Form.Item label="设备" name="ledDeviceId">
                        <Select loading={loading} disabled={loading} style={{ minWidth: 200 }} options={ledDeviceOptions}></Select>
                    </Form.Item>
                    <Form.Item label="实时同步" name="syncStatus" valuePropName="checked">
                        <Switch loading={loading} disabled={loading}></Switch>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} disabled={loading} onClick={setDefaultShowInfo} >设为默认显示信息</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} disabled={loading} onClick={getDefaultShowInfo} >读取默认显示信息</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} disabled={loading} onClick={getCurrent} >读取当前显示信息</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} disabled={loading} onClick={reloadDeviceInfo}>重新加载设备信息</Button>
                    </Form.Item>
                </Form>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <div style={{ display: "flex", width: 240, borderRight: "1px #ccc solid", padding: 16 }}>
                    <SyncLedDisplay.Provider value={syncDisplay}>
                        <LeafItemContainer data={leafDataProvider} />
                    </SyncLedDisplay.Provider>
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Stage width={width / 2} height={height / 2} options={{ backgroundColor: 0 }} style={{ transform: `scale(${scale})`, transformOrigin: "center center" }} >
                        <LeafTextContainer leafDataProvider={leafDataProvider} syncDisplay={syncDisplay} />
                    </Stage>
                </div>
            </div>
        </div>

    );
}

export default App;

