import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";


export type ViewStockSlotExtItems = ViewStockSlot & { items: ViewStockSlot[]; pickQuantity: number; };

export function groupByStockSlot(data: ViewStockSlot[]): ViewStockSlotExtItems[] {
    const obj: { [key: string]: ViewStockSlot[]; } = {};
    data.forEach((a, index) => {
        const key = `group-${index}`;
        if (obj[key]) {
            obj[key].push(a);
        }
        else {
            obj[key] = [a];
        }
    });
    return Object.values(obj).map(a => ({ ...a[0], items: a, quantity: a.reduce((p, c) => p + c.quantity, 0), pickQuantity: 0 }));
}
