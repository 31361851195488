import { delay } from "lodash";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { PropsWithChildren } from "react";
import { ContentSizeContext } from "./context";


export function ContentPageSizeContextBody({ children }: PropsWithChildren<{}>) {

    const divRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(800);
    const [width, setWidth] = useState(1688);
    // const [contentSize, setContentSize] = useState({ width: 1688, height: 800 });


    // useEffect(() => {
    //     const element = divRef.current;
    //     if (element) {
    //         const sizeChange: ResizeObserverCallback = (s) => {
    //             if (s.length > 0) {
    //                 const height = s[0].target.clientHeight;
    //                 const width = s[0].target.clientWidth;
    //                 setContentSize({ width, height });
    //             }
    //         }
    //         const resize = new ResizeObserver(sizeChange);
    //         resize.observe(element!);
    //         return () => {
    //             resize.unobserve(element);
    //         }
    //     }
    // }, []);

    useLayoutEffect(() => {
        if (divRef.current) {
            const div = divRef.current;
            const fn = (s: any) => {
                setHeight(div.clientHeight);
                setWidth(div.clientWidth);
            };
            window.addEventListener("resize", fn);
            delay(fn, 300);
            return () => {
                window.removeEventListener('resize', fn);
            };
        }
    }, [divRef]);

    return <div className="content-page" ref={divRef}>
        <ContentSizeContext.Provider value={[height, width]}>
            {children}
        </ContentSizeContext.Provider>
    </div>;
}


