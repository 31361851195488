import { CommonTable } from "@/Common/Table/EditTable";
import { TaskMode } from "@/Models/Enums/TaskMode";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { getColumns } from "../../Equipment/Task";
import { EquipmentTaskProviderContext, useEquipmentTaskProvider } from "../../Equipment/Task/EquipmentTaskProvider";

export default Index;

function Index() {
    const columns = useMemo(getColumns, []);
    const taskProvider = useEquipmentTaskProvider(TaskMode.Import);
    const [data, setData] = useState(taskProvider.data.Value);
    useEffect(() => {
        const f1 = taskProvider.data.listen(setData);
        return () => { f1(); taskProvider.hub.stop(); };
    }, [taskProvider]);
    return <EquipmentTaskProviderContext.Provider value={taskProvider}>
        <CommonTable rowKey={s => s.id} columns={columns} dataSource={data} />
    </EquipmentTaskProviderContext.Provider>;
}

