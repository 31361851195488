import { add, del, getViews, upd } from "@/Api/ContractInvoiceController";
import { toYMD, toYMDHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { urlRender } from "@/Common/UrlHelp";
import { ContractInvoice } from "@/Models/Tables/ContractInvoice";
import { ViewContractInvoice } from "@/Models/Views/ViewContractInvoice";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewContractInvoice>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("contractCode", "合同编码", 160, true, true),
        strProps("contractName", "合同名称", 200, true, true),
        strProps("departmentName", "签约部门", 120, true, true),
        numProps("amount", "发票金额", 120, true),
        numProps("date", "开票日期", 120, true, toYMD),
        strProps("voucher", "佐证资料", 200, true, true, urlRender),
        strProps("userName", "录入人", 80, true, true),
        numProps("time", "录入时间", 160, true, toYMDHMS),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< ContractInvoice, ViewContractInvoice> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewContractInvoice, target: ContractInvoice) {
    target.id = source.id;
    return target;
}