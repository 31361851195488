import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { ExportOrderHub } from "@/Hubs/ExportOrderHub";
import { ViewExportOrder } from "@/Models/Views/ViewExportOrder";
import { createContext } from "react";

export class ExportOrderHubProvider {
    hub: ExportOrderHub;
    constructor(onError: tpOnError) {
        this.hub = new ExportOrderHub(onError);
        this.hub.on("ExecuteFlagChange", this._dataChange);
        this.hub.on("Add", s => this.data.Value = [...this.data.Value, ...s]);
        this.hub.start(async () => {
            this.loading.Value = true;
            try {
                this.data.Value = await this.hub.getUnFinishedList();
            } catch (error) {
                console.log(error);
            }
            finally {
                this.loading.Value = false;
            }
        });
    }

    private _dataChange = (data: ViewExportOrder) => {
        const index = this.data.Value.findIndex(s => s.id === data.id);
        if (index > -1) {
            const items = [...this.data.Value];
            if (data.executeFlag === "Finished" || data.executeFlag === "Cancel") {
                items.splice(index, 1);
            }
            else {
                items[index] = data;
            }
            this.data.Value = items;
        }
    }
    public loading = new Obs(false);
    public data = new Obs<ViewExportOrder[]>([]);
}

export const ExportOrderHubProviderContext = createContext<ExportOrderHubProvider>(null as any);