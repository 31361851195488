import { resolveEnumOptions } from "./help";

export enum BluePrintType {
    Sketch = 0,//草图
    DetailedMap = 1,//细化图
    GeneralPlan = 2,//总图
    PartsDiagram = 3,//部件图
    SubassemblyDiagram = 4,//子部件图
    ComponentDiagram = 5,//组件图
    SubComponentDiagram = 6,//子组件图
    ElectricalDiagram = 8,//电器图
    NetworkDiagram = 9,//网络图
}

export type TypeBluePrintType = keyof typeof BluePrintType;
const BluePrintTypeNames: {
    [K in TypeBluePrintType]: string;
} = {
    Sketch: "草图",
    DetailedMap: "细化图",
    GeneralPlan: "总图",
    PartsDiagram: "部件图",
    SubassemblyDiagram: "子部件图",
    ComponentDiagram: "组件图",
    SubComponentDiagram: "子组件图",
    ElectricalDiagram: "电器图",
    NetworkDiagram: "网络图",
};

export function getBluePrintTypeNames(e: TypeBluePrintType) {
    return BluePrintTypeNames[e];
}

export function getBluePrintTypeOptions() {
    return resolveEnumOptions(BluePrintTypeNames);
}