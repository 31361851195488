import { getViewsByStartAndEnd } from "@/Api/EquipmentTaskFinishedController";
import { toYMDHMS, utcEndHour, utcStartHour } from "@/Common/date";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { TableActions } from "@/Common/Table/TableActions";
import { ColumnType } from "@/Common/Table/type";
import { getTaskExecuteFlagName } from "@/Models/Enums/TaskExecuteFlag";
import { getTaskModeNames } from "@/Models/Enums/TaskMode";
import { ViewEquipmentTaskFinished } from "@/Models/Views/ViewEquipmentTask";
import { Button, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { Fragment, useCallback } from "react";
import { useMemo, useState } from "react";
import AnalyzeComponent from "./AnalyzeComponent";

export default Index;

function getColumns(): ColumnType<ViewEquipmentTaskFinished>[] {
    return [
        numProps("id", "任务Id", 80, true),
        strProps("warehouseName", "所属仓库", 120, true, true),
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("taskNo", "任务号", 120, true, true),
        strProps("taskMode", "任务模式", 120, true, true, getTaskModeNames),
        { title: "执行标志", dataIndex: "executeFlag", width: 120, sorter: strSorter("executeFlag"), render: getTaskExecuteFlagName },
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("goodsInfo", "物品信息", 120, true, true),
        strProps("slotCode", "储位编码", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("begin", "开始", 80, true),
        strProps("end", "结束", 80, true),
        // strProps("from", "任务来源", 120, true, true, getTaskFromTypeName),
        numProps("taskTime", "下发时间", 150, true, toYMDHMS),
        numProps("time", "完成时间", 150, true, toYMDHMS),
        strProps("remark", "备注", 80, true, true),
    ];
}

function Index() {
    const [form] = Form.useForm();
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewEquipmentTaskFinished[]>([]);
    const [loading, setLoading] = useState(false);
    const onSearch = useCallback(async (data: {}) => {
        setLoading(true);
        try {
            const s = await form.validateFields();
            if (s["dateRange"]) {
                const start = utcStartHour(s["dateRange"][0]);
                const end = utcEndHour(s["dateRange"][1]);
                const d1 = await getViewsByStartAndEnd({ start, end });
                setData(d1);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    const [analyzeData, setAnalyzeData] = useState(false);

    return <Fragment>
        <TableActions>
            <Form form={form} layout="inline" >
                <Form.Item name="dateRange" label="时间范围" required rules={[{ required: true }]} initialValue={[dayjs().subtract(1, "month"), dayjs()]}>
                    <DatePicker.RangePicker showTime={{ format: 'HH' }} format="YYYY-MM-DD HH"></DatePicker.RangePicker>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} disabled={loading} onClick={onSearch}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} disabled={loading && data.length > 0} onClick={() => setAnalyzeData(true)}>图表分析</Button>
                </Form.Item>
            </Form>
        </TableActions>
        {data.length > 0 && analyzeData && <AnalyzeComponent onCancel={() => setAnalyzeData(false)} data={data} />}
        <CommonTable rowKey={s => s.id} heightOffset={83} loading={loading} columns={columns} dataSource={data} />
        <PrintFooter data={data} title="历史任务" columns={columns} />
    </Fragment>;
}



