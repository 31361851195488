

function _base64ToUint8Array(base64: string) {
    const binary_string = window.atob(base64);//解码使用base64编码的字符串
    const len = binary_string.length;			//获取长度
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

export function resolveConveyerFaultInfo(d: string) {
    if (d === "AAAAAA==") return [];
    const data = _base64ToUint8Array(d);
    return resolveMessage(getConveyer(), data);
}

export function resolveStackerFaultInfo(d: string) {
    if (d === "AAAAAAAAAAAAAAAAAAAAAAAAAAA=") return [];
    const data = _base64ToUint8Array(d);
    return resolveMessage(getStacker(), data);
}

function resolveMessage(f: string[], data: Uint8Array) {
    const res = [];
    for (let i = 0; i < data.length; i++) {
        for (let k = 0; k < 8; k++) {
            const n = (1 << k);
            if ((data[i] & n) === n) {
                res.push(f[((i + 1) * (k + 1)) - 1]);
            }
        }
    }
    return res;
}

const getConveyer = () => [
    "输送动作超时",
    "变频器故障A",
    "变频器故障B",
    "升降断路器跳闸",
    "升降动作超时",
    "移载时,托盘上有异物",
    "左侧叉跳闸",
    "右侧叉跳闸",
    "内门动作超时",
    "侧移向外门碰撞保护",
    "侧移向内门碰撞保护",
    "侧移至外门位置时,外门没有打开",
    "侧移至内门位置时,内门没有打开",
    "侧移车在外门位置时,外门有关闭动作",
    "侧移车在内门位置时,内门有关闭动作",
    "入库叉互锁被遮挡,超时报警",
    "出库叉互锁被挡住,超时报警",
    "输送机当前任务和设定运行方向相反",
    "移栽时,托盘超出极限",
    "未检测到托盘条码",
    "接收指令超时",
    "输送机去向号与当前站台号不同",
    "输送机当前作业和实际冲突",
    "侧移位置丢失",
    "未检测到重量",
    "AGV卸货完成后未检测货物",
    "AGV卸货完成后,仍检测到AGV",
    "AGV取货完成后,仍检测到货物",
    "AGV取货完成后,仍检测到AGV",
    "预留15",
    "预留16",
    "预留17",
];

const getStacker = () => [
    "Err_系统故障:扫描时间失败",
    "Err_系统故障:IO连接失败",
    "Err_系统故障:机架故障",
    "Err_系统故障:通讯错误",
    "Err_系统故障:程序错误",
    "Err_系统故障:模块存取故障",
    "Err_预留1",
    "Err_预留2",
    "Err_堆垛机删除或提交任务条件不满足",
    "Err_输送机删除或提交任务条件不满足",
    "Err_堆垛机收到指令的任务号与上次运行指令的任务号相同",
    "Err_堆垛机入库指令的任务号与入库输送机的任务号不同",
    "Err_堆垛机出库指令的任务号与出库输送机的任务号相同",
    "Err_堆垛机接收的指令错误",
    "Err_入库输送机未准备好",
    "Err_出库输送机未准备好",
    "Err_行走变频器故障",
    "Err_升降变频器故障",
    "Err_货叉变频器故障",
    "Err_水平制动电阻过热",
    "Err_升降制动电阻过热",
    "Err_松绳过载",
    "Err_升降限速防坠保护",
    "Err_光通讯故障",
    "Err_行走时,穿梭板左侧超出",
    "Err_行走时,穿梭板右侧超出",
    "Err_行走计数错误",
    "Err_行走超时报警",
    "Err_行走向前限位停止",
    "Err_行走向后限位停止",
    "Err_行走激光前极限保护",
    "Err_行走激光后极限保护",
    "Err_行走向前强制减速开关故障",
    "Err_行走向后强制减速开关故障",
    "Err_行走条码读零错误",
    "Err_行走方向错误",
    "Err_行走堵转",
    "Err_行走激光测量超速",
    "Err_行走强制减速区速度错误",
    "Err_升降向上限位停止",
    "Err_升降向下限位停止",
    "Err_升降激光上极限保护",
    "Err_升降激光下极限保护",
    "Err_升降向上强制减速开关故障",
    "Err_升降向下强制减速开关故障",
    "Err_升降超时报警",
    "Err_升降时,穿梭板左侧超出",
    "Err_升降时,穿梭板右侧超出",
    "Err_升降强制减速区速度错误",
    "Err_升降激光测量超速",
    "Err_升降堵转",
    "Err_升降定位速度错误",
    "Err_升降方向错误",
    "Err_升降计数错误",
    "Err_升降条码读零错误",
    "Err_双伸位微升降超时报警",
    "Err_微升降超时报警",
    "Err_预留12",
    "Err_货叉单伸左极限",
    "Err_货叉单伸右极限",
    "Err_货叉双伸左极限",
    "Err_货叉双伸右极限",
    "Err_货叉编码器脉冲丢失",
    "Err_双货叉编码器脉冲丢失",
    "Err_货叉编码器读零错误",
    "Err_双货叉编码器读零错误",
    "Err_单双货叉同时有效",
    "Err_单双货叉同时被选择",
    "Err_单双货叉未被选择时,货叉不在中位",
    "Err_单货叉有效时,不能运行双货叉",
    "Err_双货叉有效时,不能运行单货叉",
    "Err_单伸位货叉和双伸位货叉同时不居中",
    "Err_货叉计数错误",
    "Err_货叉动作位置错误",
    "Err_货叉运行超过中位",
    "Err_货叉居中光电或左/右超出检测异常",
    "Err_货叉回中检测失败",
    "Err_货叉不在中心时提升超过提升区间",
    "Err_货叉运动时堆垛机不在行走对位点",
    "Err_货叉超时报警",
    "Err_双货叉超时报警",
    "Err_左侧货物坍塌",
    "Err_右侧货物坍塌",
    "Err_前侧货物超宽",
    "Err_后侧货物超宽",
    "Err_左侧货物超高",
    "Err_右侧货物超高",
    "Err_自动时,目的层货物超高",
    "Err_触摸屏输入的坐标错误",
    "Err_移动时,目的层列排错误",
    "Err_行走时货叉不居中",
    "Err_行走时,货叉左侧超出",
    "Err_行走时,货叉右侧超出",
    "Err_移动时,货叉不居中",
    "Err_移动时,货物左侧坍塌",
    "Err_移动时,货物右侧坍塌",
    "Err_未在提升区间内运行货叉",
    "Err_取货时未在指定位置出叉",
    "Err_取货前升降台上有货",
    "Err_取货前升降台未在下位置",
    "Err_取货时空出库",
    "Err_取里货位时外部有货",
    "Err_取货完成后升降台上没有检测到货物",
    "Err_取货时,穿梭道异常",
    "Err_卸货时,未在指定位置出叉",
    "Err_卸货前升降台上无货",
    "Err_卸货前升降台未在上位置",
    "Err_卸货时,重复入库",
    "Err_卸货完成后升降台上仍然检测到货物",
    "Err_卸货时,穿梭道异常",
    "Err_预留28",
    "Err_变频器速度参数设定错误",
    "Err_单机自动时,变频器不能处于速度模式",
    "Err_行走相对定位参数错误",
    "Err_升降相对定位参数错误",
    "Err_当前巷道错误",
    "Err_堆垛机转轨时,地面输送机设备故障",
    "Err_换轨运行时,堆垛机不在运行区域",
    "Err_行走变频器控制方式错误",
    "Err_转轨行走时轨道位置错误",
    "Err_转轨进入巷道后,激光读值仍为零",
    "Err_在当前巷道运行时,行走变频器不处于定位模式",
    "Err_转轨时,启动位置错误",
    "Err_转轨时,货叉上有货",
    "Err_转轨时,货叉不居中",
    "Err_堆垛机进入弯道与设定不符",
    "Err_堆垛机进入弯道时堆垛机载货台位置错误",
    "Err_转轨时,地面轨道设备未联机或有故障",
    "Err_转轨时,轨道不在正确位置",
    "Err_堆垛机有正执行指令,收到新指令替换任务号为0",
    "Err_堆垛机有正执行指令,收到新指令替换任务号错误",
    "Err_堆垛机空闲时,收到替换任务号指令",
    "Err_未检测到托盘条码",
    "Err_预留_6",
    "Err_预留_7",
    "Err_预留_8",
    "Err_预留_9",
    "Err_预留_10",
    "Err_预留_11",
    "Err_预留_12",
    "Err_预留_13",
    "Err_预留_14",
    "Err_预留_15",
    "Err_预留_16",
    "Err_预留_17",
    "Err_预留_18",
    "Err_预留_19",
    "Err_预留_20",
    "Err_预留_21",
    "Err_预留_22",
    "Err_预留_23",
    "Err_预留_24",
    "Err_预留_25",
    "Err_预留_26",
    "Err_预留_27",
    "Err_预留_28",
    "Err_预留_29",
    "Err_预留_30",
    "Err_预留_31",
    "Err_预留_32",
]