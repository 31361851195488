import { getViews } from "@/Api/EquipmentTaskFinishedController";
import { toYMDHMS } from "@/Common/date";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { ColumnType } from "@/Common/Table/type";
import { getTaskExecuteFlagName } from "@/Models/Enums/TaskExecuteFlag";
import { getTaskModeNames } from "@/Models/Enums/TaskMode";
import { TaskSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewEquipmentTaskFinished } from "@/Models/Views/ViewEquipmentTask";
import { Fragment, useCallback, useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import { TaskLogComponent } from "../Task/TaskLogComponent";
import { SearchComponent } from "./SearchComponent";

export default Index;

function getColumns(): ColumnType<ViewEquipmentTaskFinished>[] {
    return [
        numProps("id", "任务Id", 80, true),
        strProps("warehouseName", "所属仓库", 120, true, true),
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("taskNo", "任务号", 120, true, true),
        strProps("taskMode", "任务模式", 120, true, true, getTaskModeNames),
        { title: "执行标志", dataIndex: "executeFlag", width: 120, sorter: strSorter("executeFlag"), render: getTaskExecuteFlagName },
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("goodsInfo", "物品信息", 120, true, true),
        strProps("slotCode", "储位编码", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("begin", "开始", 80, true),
        strProps("end", "结束", 80, true),
        // strProps("from", "任务来源", 120, true, true, getTaskFromTypeName),
        numProps("taskTime", "下发时间", 150, true, toYMDHMS),
        numProps("time", "完成时间", 150, true, toYMDHMS),
        strProps("remark", "备注", 80, true, true),
    ];
}

function getTaskLogColumns(setData: (record: ViewEquipmentTaskFinished) => void) {
    const data = getColumns();
    const index = data.findIndex(s => s.dataIndex === "taskNo");
    if (index > -1) {
        data[index].render = (v, r) => <TableInnerButton onClick={() => setData(r)} type="link" >{v}</TableInnerButton>;
    }
    return data;
}

function Index() {
    const [record, setRecord] = useState<ViewEquipmentTaskFinished | null>(null);
    const columns = useMemo(() => getTaskLogColumns(setRecord), []);
    const onCancel = useCallback(() => setRecord(null), []);
    const searchProps = useRef<TaskSplitPageProps>({ current: 1, pageSize: 13 });
    const [data, setData] = useState<PaginationData<ViewEquipmentTaskFinished>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);
    useEffect(() => {
        onSearch({});
    }, [onSearch]);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} />
        <CommonTable rowKey={s => s.id} heightOffset={83} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange }} />
        <PrintFooter data={data.items} title="历史任务" columns={columns} />
        {record && <TaskLogComponent record={record} isFinished={true} onCancel={onCancel} />}
    </Fragment>;
}


