import { getSelectItems } from "@/Api/PlatformController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ExitPort } from "@/Models/Tables/ExitPort";
import { Form, InputNumber, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";

function Item(props: IFormItemProps<ExitPort>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<ExitPort> & { data: ExitPort, onSubmit: (d: ExitPort) => Promise<void> }) {
    const [form] = Form.useForm();
    const onOk = useCallback(() => {
        setLoading(true);
        form.validateFields().then(s => onSubmit({ ...data, ...s })).finally(() => {
            setLoading(false);
        });
    }, [data, form, onSubmit]);
    const [options, setOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setOptions(await getSelectItems());
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    return <MyModal confirmLoading={loading} name={`${(data as any).name}`} data={data} width={360} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data} labelCol={{ span: 8 }}>
            <Item name="platformId" label="所属月台" required >
                <Select allowClear={false} options={options} showSearch />
            </Item>
            <Item name="maxTaskCount" label="并发任务数" required >
                <InputNumber min={1} precision={0} ></InputNumber>
            </Item>
            <Item name="disabled" label="状态" required valuePropName="checked" >
                <Switch checkedChildren="禁用" unCheckedChildren="正常" />
            </Item>
        </Form>
    </MyModal>;
}

export default Add;