
export enum UploadOrderType {
    Import = 1,
    Export = 2,
    Move = 3,
    ExportBill = 4,
}
export type TypeUploadOrderType = keyof typeof UploadOrderType;

const UploadOrderTypeNames: { [K in TypeUploadOrderType]: string } = {
    "Import": "入库流水",
    "Export": "出库流水",
    "Move": "移库",
    "ExportBill": "出库单",
};

export function getUploadOrderTypeNames(e: TypeUploadOrderType) {
    return UploadOrderTypeNames[e];
}