import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Supplier } from '@/Models/Tables/Supplier';
namespace SupplierApi{

    const name = 'Supplier';
    
    function getUrl(url: string) {
        return `${name}/${url}`
}
export function getViews(): Promise<Supplier[]> {
    return get(getUrl("getViews"));
}
export function add(data: Supplier): Promise<Supplier> {
    return post(getUrl("add"), data);
}
export function upd(data: Supplier): Promise<Supplier> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("getSelectItems"));
}
}

export default SupplierApi;