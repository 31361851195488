import { updStatus } from "@/Api/StockSlotController";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { Dropdown } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import { StatusOptionsContext } from "./index";

export function StatusComponent({ record }: { record: ViewStockSlot; }) {
    const [loading, setLoading] = useState(false);
    const options = useContext(StatusOptionsContext);

    const statusName = useMemo(() => record.goodsStatusName, [record.goodsStatusName]);

    const submitUpdStatus = useCallback(async (value: number | null) => {
        setLoading(true);
        try {
            const res = updStatus([{ id: record.id, goodsStatusId: value }]);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [record.id]);

    const menuOptions = useMemo(() => {
        const items = options.filter(s => record.goodsStatusId !== s.value).map((s) => {
            return {
                label: s.label, key: s.value, onClick: () => submitUpdStatus(s.value)
            }
        });
        return { items }
    }, [options, record.goodsStatusId, submitUpdStatus])

    return <Dropdown disabled={loading} menu={menuOptions} trigger={["click"]}>
        <TableInnerButton type="link">
            {statusName}<DownOutlined />
        </TableInnerButton>
    </Dropdown>;
}
