import { resolveEquipmentTime } from "@/Common/date";
import { resolveStackerFaultInfo } from "@/Common/fault";
import { renderBool } from "@/Common/numExtensions";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getStatusString, StackerInfo } from "@/Models/Other/StackerInfo";
import { StackerProvider, StackerProviderContext } from "@/Pages/Wcs/Components/StackerProvider";
import { toArray } from "lodash";
import { useEffect } from "react";
import { useMemo, useState } from "react";

export default Index;

function getColumns(): ColumnType<StackerInfo>[] {
    return [
        { title: "No", width: 60, dataIndex: "stationNo", render: (_, r) => `${r.warehouseId}-${r.stationNo}` },
        { title: "任务号", width: 80, dataIndex: "taskNo" },
        { title: "控制模式", width: 80, dataIndex: "controlMode", ellipsis: true, render: (r) => r === 5 ? "联机" : "离线", align: "center" },
        {
            title: "任务状态", children: [
                { title: "入库", width: 45, align: "center", key: "importOnLine", render: (_: any, r: StackerInfo) => renderTaskInfoStatus(r) },
                { title: "出库", width: 45, align: "center", key: "exportOnLine", render: (_: any, r: StackerInfo) => renderTaskInfoStatus(r, "exp") },
            ]
        } as any,
        {
            title: "当前", children: [
                { title: "巷道", width: 45, align: "center", dataIndex: "currentLanewayNo" },
                { title: "排", width: 45, align: "center", dataIndex: "currentRow" },
                { title: "列", width: 45, align: "center", dataIndex: "currentColumn" },
                { title: "层", width: 45, align: "center", dataIndex: "currentLayer" },
            ]
        } as any,
        {
            title: "目标", children: [
                { title: "巷道", width: 45, align: "center", dataIndex: "targetLanewayNo" },
                { title: "排", width: 45, align: "center", dataIndex: "targetRow" },
                { title: "列", width: 45, align: "center", dataIndex: "targetColumn" },
                { title: "层", width: 45, align: "center", dataIndex: "targetLayer" },
            ]
        } as any,
        { title: "设备状态", width: 80, align: "center", dataIndex: "status", render: getStatusString, ellipsis: true },
        {
            title: "行走", children: [
                { title: "时间", width: 80, align: "center", dataIndex: "runtime1", render: resolveEquipmentTime, ellipsis: true },
                { title: "里程(cm)", width: 80, align: "center", dataIndex: "mileage1", ellipsis: true },
            ]
        } as any,
        {
            title: "升降", children: [
                { title: "时间", width: 80, align: "center", dataIndex: "runtime2", render: resolveEquipmentTime, ellipsis: true },
                { title: "里程(cm)", width: 80, align: "center", dataIndex: "mileage2", ellipsis: true },
            ]
        } as any,
        {
            title: "单叉运行", children: [
                { title: "时间", width: 80, align: "center", dataIndex: "runtime3", render: resolveEquipmentTime, ellipsis: true },
                { title: "里程(cm)", width: 80, align: "center", dataIndex: "mileage3", ellipsis: true },
            ]
        } as any,
        {
            title: "双叉运行", children: [
                { title: "时间", width: 80, align: "center", dataIndex: "runtime4", render: resolveEquipmentTime, ellipsis: true },
                { title: "里程(cm)", width: 80, align: "center", dataIndex: "mileage4", ellipsis: true },
            ]
        } as any,
        { title: "故障", width: 300, align: "center", dataIndex: "faults", render: s => resolveStackerFaultInfo(s).join(','), ellipsis: true },
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const taskProvider = useCreateHubProvider(StackerProvider);
    const [data, setData] = useState(taskProvider.stacker.Value);
    useEffect(() => taskProvider.stacker.listen(setData), [taskProvider]);
    const dataSource = useMemo(() => toArray(data).map(s => s.Value), [data]);
    return <StackerProviderContext.Provider value={taskProvider} >
        <CommonTable rowKey={s => `${s.plcNo}-${s.stationNo}`} scroll={{}} columns={columns} dataSource={dataSource} pagination={false} />
    </StackerProviderContext.Provider>;
}

function renderTaskInfoStatus(record: StackerInfo, type?: string) {
    let d1: number;
    if (type === "exp") {
        d1 = record.taskInfoStatus >> 16;
    }
    else {
        d1 = record.taskInfoStatus & (0x0000ffff);
    }
    return renderBool(d1 === 1);
}

