import { get, post } from '@/Api';
import { PurchaseNotify } from '@/Models/Tables/PurchaseNotify';
import { ViewPurchaseNotify } from '@/Models/Views/ViewPurchaseNotify';

const name = 'PurchaseNotify';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewPurchaseNotify[]> {
    return get(getUrl("getViews"));
}
export function add(data: PurchaseNotify): Promise<ViewPurchaseNotify> {
    return post(getUrl("add"), data);
}
export function upd(data: PurchaseNotify): Promise<ViewPurchaseNotify> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
