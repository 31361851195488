import { get, post } from '@/Api';
import { BillSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { SelectItem } from '@/Models/Other/SelectItem';
import { ImportBill } from '@/Models/Tables/Bill';
import { ViewImportBill } from '@/Models/Views/ViewImportBill';

namespace ImportBillApi {


    const name = 'ImportBill';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }
    export function getViews(data: BillSplitPageProps): Promise<PaginationData<ViewImportBill>> {
        return get(getUrl("getViews"), data);
    }
    export function getUnFinishedList(): Promise<ViewImportBill[]> {
        return get(getUrl("getUnFinishedList"));
    }
    export function add(data: ImportBill): Promise<number> {
        return post(getUrl("add"), data);
    }
    export function mesAdd(data: any): Promise<any> {
        return post(getUrl("add"), data);
    }
    export function cancel(id: number): Promise<number> {
        return get(getUrl("Cancel"), { id });
    }

    export function getSelectItems(): Promise<SelectItem[]> {
        return get(getUrl("getSelectItems"));
    }
    export function setFinished(billId: number): Promise<SelectItem[]> {
        return get(getUrl("SetFinished"), { billId });
    }

}

export default ImportBillApi;