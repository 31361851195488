import { resolveEnumOptions } from "./help";


export enum PayMode {
    WireTransfer = 0,
    Accept = 1
}

export type TypePayMode = keyof typeof PayMode;
const PayModeNames: {
    [K in TypePayMode]: string;
} = {
    WireTransfer: "电汇",
    Accept: "承兑"
};

export function getPayModeNames(e: TypePayMode) {
    return PayModeNames[e];
}

export function getPayModeOptions() {
    return resolveEnumOptions(PayModeNames);
}