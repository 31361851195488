import { add, del, getViews, upd } from "@/Api/FlatSlotController";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { Slot } from "@/Models/Tables/Slot";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import React, { useMemo } from "react";
import { EditArea } from "../SlotManager";
import Add from "./Add";

function getColumns(): ColumnType<ViewSlot>[] {
    return [
        numProps("id", "ID", 80, true),
        strProps("warehouseName", "库房名称", 160, true, true),
        { title: "所属区域", dataIndex: "areaName", width: 160, sorter: strSorter("areaName"), render: (_, r) => <EditArea record={r} /> },
        strProps("code", "编码", 120, true, true),
        strProps("lanewayName", "巷道", 160, true, true),
        numProps("row", "排", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("column", "列", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("layer", "层", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("stockCount", "库存数量", 100, true),
        strProps("remark", "备注", 200, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable<ViewSlot> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd as any}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge as any}
        addDataHandle={add as any}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewSlot, target: Slot) {
    target.id = source.id;
    return target;
}