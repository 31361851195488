import { LedScrollMethod } from "@/Models/Tables/Led";


export class LeafComponentProps {
    text: string;
    fontSize: number;
    left: number;
    top: number;
    bottom: number;
    right: number;
    method: LedScrollMethod;
    constructor(text: string, fontSize: number, x: number, y: number, method?: LedScrollMethod) {
        this.text = text;
        this.fontSize = fontSize;
        this.left = x;
        this.top = y;
        this.bottom = 70; // 默认值
        this.right = 200; // 默认值
        this.method = method || LedScrollMethod.None;
    }
}
