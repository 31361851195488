import Search from './Search';
import SearchSum from './SearchSum';
import High from './High';
import Low from './Low';
import Validity from './Validity';
import AlarmDay from './AlarmDay';
import Status from './Status';
import TemporaryStock from './TemporaryStock';
import StockReport from './StockReport';
import OfflineImport from './OfflineImport';
import OfflineExport from './OfflineExport';
import {
    MedicineBoxOutlined, InsertRowLeftOutlined, SearchOutlined, SmileOutlined, HighlightOutlined, BorderVerticleOutlined, VerticalAlignTopOutlined, VerticalAlignMiddleOutlined,
    AccountBookOutlined, TaobaoCircleOutlined, CarryOutFilled, ExportOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';


function stockGroup(): IMenuGroup {
    return {
        name: "库存管理",
        icon: InsertRowLeftOutlined,
        path: "stock",
        items: [
            {
                name: "库存查询",
                icon: SearchOutlined,
                element: <Search />,
                path: "Search",
                right: 1
            },
            {
                name: "库存汇总",
                icon: SmileOutlined,
                element: <SearchSum />,
                path: "SearchSum",
                right: 1 << 1
            },
            {
                name: "高库存预警",
                icon: HighlightOutlined,
                element: <High />,
                path: "High",
                right: 1 << 3
            },
            {
                name: "低库存预警",
                icon: BorderVerticleOutlined,
                element: <Low />,
                path: "Low",
                right: 1 << 4
            },
            {
                name: "效期预警",
                icon: VerticalAlignMiddleOutlined,
                element: <AlarmDay />,
                path: "AlarmDay",
                right: 1 << 5
            },
            {
                name: "超期预警",
                icon: VerticalAlignTopOutlined,
                element: <Validity />,
                path: "Validity",
                right: 1 << 6
            },
            {
                name: "品质管理",
                icon: MedicineBoxOutlined,
                element: <Status />,
                path: "Status",
                right: 1 << 7
            },
            {
                name: "暂存区",
                icon: AccountBookOutlined,
                element: <TemporaryStock />,
                path: "TemporaryStock",
                right: 1 << 8
            },
            {
                name: "出入库统计",
                icon: TaobaoCircleOutlined,
                element: <StockReport />,
                path: "StockReport",
                right: 1 << 9,
            },
            {
                name: "脱机入库",
                icon: CarryOutFilled,
                element: <OfflineImport />,
                path: "OfflineImport",
                right: 1 << 10,
            },
            {
                name: "脱机出库",
                icon: ExportOutlined,
                element: <OfflineExport />,
                path: "OfflineExport",
                right: 1 << 11,
            },
        ]
    };
}

export default stockGroup;