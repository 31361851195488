

export enum BillExecuteFlag {
    Cancel = -1,
    WaitingExecute = 0,
    Executing = 1,
    Finished = 2
}

export type TypeBillExecuteFlag = keyof typeof BillExecuteFlag;

export const BillExecuteFlagNames: { [K in TypeBillExecuteFlag]: string } = {
    "Cancel": "已取消",
    "WaitingExecute": "待执行",
    "Executing": "执行中",
    "Finished": "已完成",
};

export function getBillExecuteFlagName(e: TypeBillExecuteFlag) {
    return BillExecuteFlagNames[e];
}
