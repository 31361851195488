import { get, post, setToken } from '@/Api';
import { SysUserToken } from '@/Models/Other/SysUserToken';
import { SysUser } from '@/Models/Tables/SysUser';
import { ViewSysUser } from '@/Models/Views/ViewSysUser';
import { delay } from 'lodash';

const name = 'SysUser';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewSysUser[]> {
    return get(getUrl("getViews"));
}
export function add(data: SysUser): Promise<ViewSysUser> {
    return post(getUrl("add"), data);
}
export function upd(data: SysUser): Promise<ViewSysUser> {
    return post(getUrl("upd"), data);
}
export function updPwd(id: number, pwd: string): Promise<ViewSysUser> {
    return get(getUrl("updPwd"), { id, pwd });
}
export async function login(code: string, pwd: string): Promise<SysUserToken> {
    const s = await get<SysUserToken>(getUrl("login"), { code, pwd });
    setToken(s.token);
    return s;
}
export function refresh(): Promise<SysUserToken | null> {
    const p = new Promise<SysUserToken | null>(async (resolve) => {
        let flag = false;
        delay(() => {
            console.log("flag", flag);
            if (!flag) resolve(null);
        }, 5000);
        try {
            const d1 = await get<SysUserToken>(getUrl("refresh"));
            setToken(d1.token);
            return resolve(d1);
        } catch (error) {
            return resolve(null);
        }
        finally {
            flag = true;
        }
    });
    return p;
}
