import { getSimpleStockSlots } from "@/Api/StockSlotController";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { ViewSimpleStockSlot } from "@/Models/Views/ViewSimpleStockSlot";
import { Col, Row, Table } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AddOrders } from "./AddOrders";
import { tpOrder } from "./common";

function getColumns(): ColumnType<tpOrder>[] {
    return [
        strProps("goodsName", "物品名称", 160, false, true),
        numProps("quantity", "数量", 60, false),
        strProps("stockCode", "托盘条码", 100, false, true),
        strProps("standard", "规格", 100, false, true),
    ];
}

function getStockColumns(): ColumnType<ViewSimpleStockSlot>[] {
    return [
        strProps("goodsName", "物品名称", 160, false, true),
        numProps("quantity", "数量", 60, false)
    ];
}

function getTableAction<T>(onRemove: (d: T) => void): ColumnType<T> {
    return {
        title: "操作",
        key: 'action',
        width: 80,
        render: (_, data) => <TableInnerButton type="link" onClick={() => onRemove(data)}>移除</TableInnerButton>
    };
}

function findOrder(b: tpOrder, s: tpOrder) {
    return b.notifyId === s.notifyId && b.stockCode === s.stockCode;
}

export function Notifies({ onChange }: { onChange?: (d?: tpOrder[]) => void }) {
    const tempData = useRef({ stockCode: '' });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<tpOrder[]>([]);
    const [simpleData, setSimpleData] = useState<ViewSimpleStockSlot[]>([]);
    const onRemove = useCallback((s: tpOrder) => {
        setData(a => {
            const index = a.findIndex(b => findOrder(b, s));
            if (index > -1) {
                a.splice(index, 1);
            }
            return [...a];
        });
    }, []);
    const columns = useMemo(() => [...getColumns(), getTableAction(onRemove)], [onRemove]);
    const pushData = useCallback((s: tpOrder) => {
        setData(a => {
            const index = a.findIndex(b => findOrder(b, s));
            if (index === -1) {
                a.push(s);
            }
            else {
                a[index].quantity += s.quantity;
            }
            return [...a];
        });
    }, []);
    useEffect(() => {
        if (onChange) {
            if (data.length === 0) {
                onChange();
            }
            else {
                onChange(data);
            }
        }
    }, [data, onChange]);

    const stockCodeChange = useCallback(async (stockCode: string) => {
        tempData.current.stockCode = stockCode;
        setLoading(true);
        try {
            setSimpleData(await getSimpleStockSlots(stockCode));
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, []);

    return <div>
        <AddOrders onChange={pushData} data={data} stockCodeChange={stockCodeChange} />
        <Row gutter={48}>
            <Col span={8}>
                <Table caption={`${tempData.current.stockCode} 库存列表`} loading={loading} size="small" pagination={false} rowKey={(s, index) => index as any} bordered dataSource={simpleData} columns={getStockColumns()} />
            </Col>
            <Col span={16}>
                <Table caption={"待添加流水列表"} size="small" pagination={false} rowKey={s => s.notifyId} bordered dataSource={data} columns={columns} />
            </Col>
        </Row>
    </div>;
}


