import { slotExport } from "@/Api/EquipmentTaskController";
import { getViews } from "@/Api/StockSlotController";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { renderCopyable } from "@/Common/Table/renderCopyable";
import { ColumnType } from "@/Common/Table/type";
import { toYMD, toYMDHMS } from "@/Common/date";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { StockSlotSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { Popconfirm, message } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SearchComponent } from "./SearchComponent";

export default Index;

function getColumns(): ColumnType<ViewStockSlot>[] {
    return [
        strProps("warehouseName", "仓库", 100, true, true),
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("goodsTypeName", "类型", 100, true, true),
        strProps("goodsName", "物品名称", 300, true, true, renderCopyable),
        strProps("standard", "规格", 220, true, true),
        strProps("batchNo", "批次", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("unit", "单位", 60, false, false),
        strProps("stockCode", "托盘条码", 120, true, true),
        { title: "储位编码", dataIndex: "slotCode", width: 114, render: (_, r) => <SlotCodeActionComponent data={r} /> },
        strProps("supplierName", "供应商", 120, true, true),   strProps("customerName", "客户", 120, true, true),     
        strProps("goodsStatusName", "品质状态", 120, true, true),
        numProps("productionDate", "生产日期", 120, true, toYMD, true),
        strProps("contractNumber", "合同号", 160, true, true),
        strProps("orderNo", "入库流水号", 160, true, true),
        numProps("time", "入库时间", 160, true, toYMDHMS),
        { title: "状态", dataIndex: "flag", width: 80, sorter: strSorter("flag"), render: getStockFlagName },
        strProps("remark", "备注", 200, true, true),
    ];
}

function SlotCodeActionComponent({ data }: { data: ViewStockSlot }) {
    const [loading, setLoading] = useState(false);
    const onConfirm = useCallback(async () => {
        try {
            setLoading(true);
            await slotExport(data.slotId);
        } catch (error) {
            message.info(JSON.stringify(error));
        }
        finally {
            setLoading(false);
        }
    }, [data.slotId])
    if (data.slotCode.length === 8) {
        return (<Popconfirm disabled={loading} title={`您确定要对储位[${data.slotCode}]进行抽检出库作业吗?`} onConfirm={onConfirm}>
            <TableInnerButton loading={loading} disabled={loading} type="link">{data.slotCode}</TableInnerButton>
        </Popconfirm>);
    }
    else {
        return <div>{data.slotCode}</div>;
    }
}

function Index() {
    const columns = useMemo(getColumns, []);
    const searchProps = useRef<StockSlotSplitPageProps>({ current: 1, pageSize: 13 });
    const [data, setData] = useState<PaginationData<ViewStockSlot>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);

    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);

    useEffect(() => {
        onSearch({});
    }, [onSearch]);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} />
        <CommonTable rowKey={s => s.id} heightOffset={80} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange, showSizeChanger: true }} />
        <PrintFooter data={data.items} title="库存" columns={columns} />
    </Fragment>;
}
