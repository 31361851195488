export interface BaseLedRect {
    id: number;
    top: number;
    bottom: number;
    left: number;
    right: number;
    fontSize: number;
    method: LedScrollMethod;
    text: string;
}

export enum LedScrollMethod {
    None = 0,
    Bottom = 1,
    Left = 2,
    Right = 3,
    Top = 4
}

export type TypeLedScrollMethod = keyof typeof LedScrollMethod;

export interface LedDevice {
    id: number;
    name: string;
    ip: string;
    address: number;
    ledDisplays: LedDisplay[];
}

export interface LedDisplay extends BaseLedRect {
    ledDeviceNav: LedDevice;
    ledDeviceId: number;
}
