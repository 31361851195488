import { ViewExportNotify } from "@/Models/Views/ViewExportNotify";
import { FormInstance } from "antd/lib/form";
import { Obs } from "@/Common/Obs";
import { ViewStockSlotExtItems } from "./ViewStockSlotExtItems";
import { createContext } from "react";
export const EditableContext = createContext<EditableProvider>(null as any);
export class EditableProvider {
    readonly notify: ViewExportNotify;
    form: FormInstance<any>;
    data: ViewStockSlotExtItems[];
    needQuantity: number;
    private _quantityChange: (data: number) => void;
    constructor(notify: ViewExportNotify, form: FormInstance, data: ViewStockSlotExtItems[], quantityChange: (data: number) => void) {
        this.notify = notify;
        this.needQuantity = notify.quantity - notify.factQuantity;
        this.form = form;
        this.data = data;
        this._quantityChange = quantityChange;
    }
    record = new Obs<ViewStockSlotExtItems | null>(null);
    readonly onEdit = async (record: ViewStockSlotExtItems | null) => {
        if (this.record.Value) {
            let { pickQuantity } = await this.form.validateFields();
            this.record.Value.pickQuantity = pickQuantity;
            this._quantityChange(this.data.reduce((p, c) => p + c.pickQuantity, 0));
        }
        if (record === null)
            return;
        if (record.pickQuantity === 0) {
            const quantity = this.data.reduce((p, c) => p + c.pickQuantity, 0);
            if (quantity === this.needQuantity) {
                this.record.Value = null;
                return;
            }
            if (quantity >= this.needQuantity) {
                throw new Error("不允许超量分配!");
            }
            const max = this.needQuantity - quantity;
            if (record.quantity > max) {
                this.form.setFieldValue("pickQuantity", max);
            }
            else {
                this.form.setFieldValue("pickQuantity", record.quantity);
            }
        }
        else {
            this.form.setFieldValue("pickQuantity", record.pickQuantity);
            record.pickQuantity = 0;
        }
        this.record.Value = record;
    };
}
