import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import * as echarts from 'echarts';
import { delay, forEach, groupBy } from "lodash";
import { ViewEquipmentTaskFinished } from "@/Models/Views/ViewEquipmentTask";
import { getTaskModeNames } from "@/Models/Enums/TaskMode";
import { Modal, Radio, Space } from "antd";
import { strSorter } from "@/Common/Table/column";

interface IAnalyzeComponentProps {
    onCancel: () => void;
    data: ViewEquipmentTaskFinished[];
}

function AnalyzeComponentProps({ onCancel, data }: IAnalyzeComponentProps) {
    const divRef = useRef<HTMLDivElement>(null);
    const charRef = useRef<{ chart?: echarts.ECharts }>({});
    const h = useMemo(() => new AnalyzeHelper(data), [data]);
    const [type, setType] = useState(1);
    useEffect(() => {
        if (divRef.current) {
            const node = divRef.current;
            delay(() => {
                const chart = echarts.init(node);
                chart.clear();
                chart.setOption(h.getOptions(type));
                const obj = { width: node.clientWidth, height: node.clientHeight };
                chart.resize(obj);
                charRef.current.chart = chart;
            }, 300);
        }
    }, [h, type]);
    return <Modal title={<Space>
        <div>任务分析</div>
        <Radio.Group size="middle" value={type} options={[{ label: "任务模式", value: 1 }, { label: "巷道", value: 2 }]} buttonStyle="solid" optionType="button" onChange={s => setType(s.target.value)} />
    </Space>} open onCancel={onCancel} width={1180} footer={false} >
        <div ref={divRef} style={{ height: 400 }}></div>
    </Modal>;
}

export default AnalyzeComponentProps;

class AnalyzeHelper {
    private _data: ViewEquipmentTaskFinished[];
    constructor(data: ViewEquipmentTaskFinished[]) {
        this._data = data;
    }
    private _cache: { [K: number]: any } = {};
    readonly getOptions = (type: number) => {
        if (!this._cache[type]) {
            if (type === 1) this._cache[type] = this.getPieOptions();
            else if (type === 2) this._cache[type] = this.getBarOptions();
        }
        return this._cache[type];
    }

    private readonly getPieOptions = () => {
        const d1 = groupBy(this._data, s => s.taskMode);
        const d2: any[] = [];
        forEach(d1, (s, k) => {
            d2.push({ name: getTaskModeNames(k as any), value: s.length });
        });
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
            },
            series: [
                {
                    type: 'pie',
                    radius: '80%',
                    itemStyle: { normal: { label: { show: true, formatter: '{b} {c} ({d}%)' } } },
                    data: d2,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    private readonly getBarOptions = () => {
        const d1 = groupBy(this._data, s => s.lanewayName);
        const d2: { name: string, value: number }[] = [];
        forEach(d1, (s, k) => {
            d2.push({ name: k, value: s.length });
        });
        d2.sort(strSorter("name"));
        const colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
        return {
            xAxis: {
                type: 'category',
                data: d2.map(s => s.name),
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: d2.map(s => s.value),
                type: 'bar',
                itemStyle: {
                    normal: { label: { show: true, formatter: '{c}', position: 'top' }, color: (f: any) => colors[f.dataIndex], }
                }
            }]
        };
    }

}