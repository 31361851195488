import React, { useRef, useCallback, ChangeEvent, useState, useEffect } from "react";
import { Spin } from "antd";
import FileUpload from "@/Api/UploadController";
import CropDom from "@/Common/CropImg";
import './index.scss';

interface IUserAvatarProps {
    value?: string;
    onChange?: (val: string) => void;
}

export default UserAvatar;
function UserAvatar({ value, onChange }: IUserAvatarProps) {

    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    const fileRef = useRef<HTMLInputElement>(null);

    const fileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImgSrc(URL.createObjectURL(e.target.files[0]));
        }
    }, []);

    useEffect(() => { if (imgSrc.length > 0) return () => URL.revokeObjectURL(imgSrc); }, [imgSrc]);

    const cropCancel = useCallback(() => setImgSrc(''), []);

    const cropOk = useCallback((f: File, cb?: () => void) => {
        const onFinished = (s: string) => {
            onChange && onChange(s);
            cb && cb();
            setImgSrc('');
        };
        const upload = new FileUpload(f, onFinished);
        upload.setLoading = setLoading;
        upload.upload();

    }, [onChange]);

    return <Spin spinning={loading}>
        <div className="image-myAvatar" onClick={() => fileRef.current?.click()}>
            <div hidden>
                <input type="file" ref={fileRef} accept="image/*" onChange={fileChange} />
            </div>
            {value && value.length > 0 ? <img src={value} alt=""></img> : <div>点击上传</div>}
        </div>
        {imgSrc.length > 0 && <CropDom aspctRatio={1} imgSrc={imgSrc} onCancel={cropCancel} onOk={cropOk} />}
    </Spin>;
};
