import ImportBillApi from "@/Api/ImportBillApi";
import ImportNotifyApi from "@/Api/ImportNotifyApi";
import { convertProps, IFormItemProps } from "@/Common/Form/MyModal";
import { ImportNotifySelectItem } from "@/Models/Other/ImportNotifySelectItem";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ImportOrder } from "@/Models/Tables/Order";
import { Button, Col, Form, Input, InputNumber, message, Row, Select, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { sum } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { tpOrder } from "./common";

export function Item(props: IFormItemProps<ImportOrder>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

export function notifyFields(r: any) {
    return [
        { name: "notifyId", value: r.value },
        // { "name": "quantity", value: r.quantity - r.factQuantity }
    ];
}

export type tp1 = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void) => Promise<void | any> | void;

export function AddOrders({ onChange, data, stockCodeChange }: { onChange: (s: tpOrder) => void; data: tpOrder[]; stockCodeChange: (stockCode: string) => void }) {
    const [notify, setNotify] = useState<ImportNotifySelectItem>();
    const [billOptions, setBillOptions] = useState<SelectItem[]>([]);
    const [billId, setBillId] = useState<number>();
    const [notifyOptions, setNotifyOptions] = useState<ImportNotifySelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const bill = await ImportBillApi.getSelectItems();
                if (bill.length > 0) {
                    const notify = await ImportNotifyApi.getSelectItems(bill[0].value);
                    setBillOptions(bill);
                    const ns = notify.filter(s => s.factQuantity < s.quantity);
                    setNotifyOptions(ns);
                    setBillId(bill[0].value);
                    if (ns.length > 0) {
                        form.setFields(notifyFields(ns[0]));
                        setNotify(ns[0]);
                    }
                }
                else {
                    message.error("没有入库单明细!");
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [form]);

    useEffect(() => {
        if (billId) {
            setLoading(true);
            (async () => {
                try {
                    const notify = await ImportNotifyApi.getSelectItems(billId);
                    const ns = notify.filter(s => s.factQuantity < s.quantity);
                    setNotifyOptions(ns);
                    if (ns.length > 0) {
                        form.setFields(notifyFields(ns[0]));
                        setNotify(ns[0]);
                        form.setFieldValue("standard", ns[0].standard);
                    }
                } catch (error) {
                    console.error(error);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [billId, form]);

    const onSubmit = useCallback(() => {
        form.validateFields().then((s: any) => {
            const data = { ...s, goodsName: notify?.label };
            onChange(data);
        }).catch(console.error).finally(() => { });
    }, [form, notify?.label, onChange]);

    const quantityValidator = useCallback<tp1>((rule, val) => {
        if (val <= 0) {
            return Promise.reject(`数量不能小于等于0`);
        }
        if (notify) {
            const listCount = sum(data.map(a => a.notifyId === notify.value ? a.quantity : 0));
            const count = (val + listCount + notify.factQuantity);
            if (count > notify.quantity) {
                const canCount = notify.quantity - listCount - notify.factQuantity;
                return Promise.reject(`下发不能大于计划数量!输入值应当<=${canCount}`);
            }
        }
        return Promise.resolve();
    }, [notify, data]);

    return (
        <Form form={form} labelCol={{ span: 6 }} layout="horizontal" style={{ paddingBottom: 16 }}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="入库单号">
                        <Select loading={loading} options={billOptions} optionFilterProp="label" showSearch allowClear onChange={setBillId} value={billId} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Item label="物品信息" name="notifyId" required>
                        <Select loading={loading} options={notifyOptions} optionFilterProp="label" showSearch onChange={(_, r: any) => {
                            setNotify(r);
                            form.setFieldValue("standard", r.standard);
                            console.log(r.standard);
                            // form.setFields([{ "name": "quantity", value: r.quantity - r.factQuantity }]);
                        }} />
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Item label="托盘条码" name="stockCode" required rules={[{
                        validator: (_, value) => {
                            if (/^([0-9]{6})|(^[A-Z][0-9]{1,2})$/.test(value)) {
                                stockCodeChange(value);
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("请输入正确的托盘条码或平库储位编码!"));
                        }
                    }]}>
                        <Input></Input>
                    </Item>
                </Col>

                <Col span={12}>
                    <Item label="入库数量" name="quantity" required rules={[{ validator: quantityValidator }]}>
                        <InputNumber min={0} precision={2} />
                    </Item>
                </Col>

            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Item label="规格" name="standard" required >
                        <Input />
                    </Item>
                </Col>
                <Col span={12}>
                    <Item label="备注" name="remark" >
                        <Input />
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{ textAlign: "right" }}>
                        <div style={{ paddingRight: 20, display: "inline-block" }}>
                            <Tag color="gold">计划:{notify?.quantity}</Tag>
                            <Tag color="green">已下发:{notify?.factQuantity}</Tag>
                        </div>
                        <Button style={{ marginRight: 12 }} onClick={onSubmit}>添加</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
