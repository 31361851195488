import { getViews } from "@/Api/EquipmentFaultController";
import { toYMDHMS, getOnlyHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { ColumnType } from "@/Common/Table/type";
import { getFaultTypeName } from "@/Models/Enums/FaultType";
import { TaskSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewEquipmentFault } from "@/Models/Views/ViewEquipmentFault";
import { Fragment, useCallback, useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import { SearchComponent } from "./SearchComponent";

export default Index;

function getColumns(): ColumnType<ViewEquipmentFault>[] {
    return [
        numProps("id", "ID", 80, true),
        numProps("plcNo", "PLC", 80, true),
        numProps("stationNo", "设备号", 100, true),
        strProps("type", "异常类别", 140, true, true, getFaultTypeName),
        numProps("time", "开始时间", 160, true, toYMDHMS),
        numProps("resetTime", "复位时间", 160, true, toYMDHMS),
        { title: "停止时间", key: "stopTime", width: 160, render: (_, r) => r.resetTime == null ? '' : getOnlyHMS(r.time, r.resetTime) },
        strProps("description", "异常描述信息", 0, true, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);

    const searchProps = useRef<TaskSplitPageProps>({ current: 1, pageSize: 13 });
    const [data, setData] = useState<PaginationData<ViewEquipmentFault>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);
    useEffect(() => {
        onSearch({});
    }, [onSearch]);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} />
        <CommonTable rowKey={s => s.id} heightOffset={83} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange, showSizeChanger: true }} />
        <PrintFooter data={data.items} title="故障记录" columns={columns} />
    </Fragment>;
}


