import { get, post } from '@/Api';
import { SlotLockFlag } from '@/Models/Enums/SlotLockFlag';
import { SelectItemExtWarehouseName } from '@/Models/Other/SelectItemExtWarehouseName';
import { SlotBatchUpdFilter } from '@/Models/Other/SlotBatchUpdFilter';
import { ViewSlot } from '@/Models/Views/ViewSlot';

const name = 'Slot';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export const getViews = () => get<ViewSlot[]>(getUrl("getViews"));
export const getViewsByFilter = (data: SlotBatchUpdFilter) => post<ViewSlot[]>(getUrl("GetViewsByFilter"), data);
export const getRows = () => get<number[]>(getUrl("GetRows"));
export const getColumns = () => get<number[]>(getUrl("GetColumns"));
export const getLayers = () => get<number[]>(getUrl("GetLayers"));
export const getViewsByLanewayId = (lanewayId: number) => get<ViewSlot[]>(getUrl("getViewsByLanewayId"), { lanewayId });
export const updLockFlag = (slotId: number, flag: SlotLockFlag) => get<ViewSlot>(getUrl("updLockFlag"), { slotId, flag });
export const updLockFlagByList = (ids: number[], flag: SlotLockFlag) => post<ViewSlot[]>(getUrl("UpdLockFlagByList"), { ids, flag });
export const updAreaByList = (ids: number[], areaId: number | null) => post<ViewSlot[]>(getUrl("UpdAreaByList"), { ids, areaId });
export const importEmptyStock = (slotId: number) => get<number>(getUrl("ImportEmptyStock"), { slotId });
export const updAreaId = (slotId: number, areaId?: number) => get<number>(getUrl("updAreaId"), { slotId, areaId });
export function getSelectItems(): Promise<SelectItemExtWarehouseName[]> {
    return get(getUrl('getSelectItems'));
}