import { getViews } from "@/Api/StockReportController";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableActions } from "@/Common/Table/TableActions";
import { ColumnType } from "@/Common/Table/type";
import { StockReportType } from "@/Models/Enums/StockReportType";
import { ViewStockReport } from "@/Models/Views/ViewStockReport";
import { Button, DatePicker, Radio } from "antd";
import { Fragment, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker";
import { forEach, toLower } from "lodash";
import moment from "dayjs";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { local } from "@/Common/date";

function getColumns(): ColumnType<ViewStockReport>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("typeName", "物料类别", 100, true, true),
        strProps("code", "物料编码", 120, true, true),
        strProps("batchNo", "批次", 100, true, true),
        strProps("name", "物料名称", 200, true, true),
        strProps("standard", "物料规格", 200, true, true),
        numProps("importQuantity", "入库数", 100, true),
        numProps("exportQuantity", "出库数", 100, true),
        numProps("oldQuantity", "上期库存", 100, true),
        numProps("nowQuantity", "本期库存", 100, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(() => getColumns(), []);
    const [data, setData] = useState<ViewStockReport[]>([]);
    const [searchData, setSearchData] = useState({ start: moment(), end: moment(), type: StockReportType.Day });
    const picker = useMemo<DatePickerProps["picker"]>(() => {
        if (searchData.type === StockReportType.Month) return "month";
        else if (searchData.type === StockReportType.Year) return "year";
    }, [searchData.type]);
    const [loading, setLoading] = useState(false);

    const onSearch = useCallback(() => {
        if (searchData) {
            setLoading(true);
            const dt1 = local(searchData.start.startOf(toLower(StockReportType[searchData.type]) as any)).valueOf();
            const dt2 = local(searchData.end.endOf(toLower(StockReportType[searchData.type]) as any)).valueOf();
            getViews(dt1, dt2, searchData.type).then(setData).catch(console.error).finally(() => setLoading(false));
        }
    }, [searchData]);

    const onTypeChange = useCallback((s: RadioChangeEvent) => {
        if (s.target.value < 2) {
            setSearchData(a => ({ start: moment(), end: moment(), type: s.target.value }));
        }
        else {
            setSearchData(a => ({ start: a.start, end: a.end, type: s.target.value }));
        }
    }, []);

    const onDateChange = useCallback<NonNullable<RangePickerProps["onChange"]>>((s) => {
        if (s) {
            setSearchData(a => ({ start: s[0]!, end: s[1]!, type: a.type }));
        }
    }, []);

    useEffect(() => {
        onSearch();
    }, [onSearch]);

    const [showType, setShowType] = useState<typeShowType>("all");
    const showTypeOptions = useMemo(getShowTypeOptions, []);
    const dataGroup = useMemo(() => resolveData(data, showType), [data, showType]);

    return <Fragment>
        <TableActions>
            {/** 这里没必要使用form 状态管理更优 */}
            <DatePicker.RangePicker picker={picker} value={[searchData.start, searchData.end]} onChange={onDateChange} clearIcon ></DatePicker.RangePicker>
            <Radio.Group value={searchData.type} onChange={onTypeChange} optionType="button" buttonStyle="solid" options={[
                { label: "日报", value: StockReportType.Day },
                { label: "月报", value: StockReportType.Month },
                { label: "年报", value: StockReportType.Year },]}></Radio.Group>
            <Button icon={<SearchOutlined />} onClick={onSearch}>查询</Button>
            <Radio.Group style={{ float: "right" }} optionType="button" options={showTypeOptions} value={showType} onChange={s => setShowType(s.target.value)}></Radio.Group>
        </TableActions>
        <CommonTable loading={loading}
            rowKey={s => s.id}
            dataSource={dataGroup}
            columns={columns}
            widthOffset={48}
            pagination={{ pageSize: 13, showSizeChanger: false }}
        />
        <PrintFooter data={data} title="出入库报表" columns={columns} />
    </Fragment>;
}


type typeShowType = "all" | "import" | "export";
function getShowTypeOptions(): { label: string, value: typeShowType }[] {
    return [{ label: "所有信息", value: "all" }, { label: "入库汇总", value: "import" }, { label: "出库汇总", value: "export" }];
}

function resolveData(data: ViewStockReport[], type: typeShowType): ViewStockReport[] {
    const a = new C1();
    data.forEach(s => a.push(s));
    const res = a.getData(type);
    res.forEach((s, i) => s.id = i + 1);
    return res;
}

class C1 {
    readonly data: { [K: string]: C2 };
    constructor() {
        this.data = {};
    }
    push(d: ViewStockReport) {
        if (this.data[d.code]) {
            this.data[d.code].push(d);
        }
        else {
            this.data[d.code] = new C2(d);
        }
    }
    getData(type: typeShowType) {
        const data: ViewStockReport[] = [];
        forEach(this.data, (s, k) => {
            s.pushData(type, data, k);
        });
        return data;
    }
}

class C3 {
    readonly start: { date: number, val: number }
    readonly end: { date: number, val: number }
    imp: number;
    exp: number;
    constructor(d: ViewStockReport) {
        this.start = { date: d.date, val: d.oldQuantity };
        this.end = { date: d.date, val: d.nowQuantity };
        this.imp = d.importQuantity;
        this.exp = d.exportQuantity;
    }

    push(d: ViewStockReport) {
        this.imp += d.importQuantity;
        this.exp += d.exportQuantity;
        if (d.date > this.end.date) {
            this.end.date = d.date;
            this.end.val = d.nowQuantity;
        }
        if (d.date < this.start.date) {
            this.end.date = d.date;
            this.end.val = d.oldQuantity;
        }
    }
}

class C2 {
    data: { [x: string]: C3; };
    readonly name: string;
    readonly typeName: string;
    readonly standard: string;
    constructor(d: ViewStockReport) {
        this.data = { [d.batchNo]: new C3(d) };
        this.name = d.name;
        this.typeName = d.typeName;
        this.standard = d.standard;
    }
    push(d: ViewStockReport) {
        if (this.data[d.batchNo]) {
            this.data[d.batchNo].push(d);
        }
        else {
            this.data[d.batchNo] = new C3(d);
        }
    }
    pushData(type: typeShowType, data: ViewStockReport[], code: string) {
        if (type === "all") {
            forEach(this.data, (s, k) => {
                data.push({ batchNo: k, code, date: 0, name: this.name, standard: this.standard, typeName: this.typeName, id: 0, oldQuantity: s.start.val, nowQuantity: s.end.val, importQuantity: s.imp, exportQuantity: s.exp });
            });
        }
        else {
            const temp1 = { batchNo: '', code, date: 0, name: this.name, standard: this.standard, typeName: this.typeName, id: 0, oldQuantity: 0, nowQuantity: 0, importQuantity: 0, exportQuantity: 0 };
            forEach(this.data, (s, k) => {
                temp1.importQuantity += s.imp;
                temp1.exportQuantity += s.exp;
                temp1.oldQuantity += s.start.val;
                temp1.nowQuantity += s.end.val;
            });
            if (temp1.importQuantity > 0 && type === "import") {
                data.push(temp1);
            }
            else if (temp1.exportQuantity > 0 && type === "export") {
                data.push(temp1);
            }
        }
    }
}