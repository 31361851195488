import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AddProps } from "../Form/MyModal";
import { ContentSizeContext } from "../context";
import getTableAction, { useTableEditState } from "./getTableAction";
import './index.scss';
import { ColumnType } from "./type";

const defaultPagination = { pageSize: 14, showSizeChanger: false };

interface IProps<T, T1 extends T = T> {
    baseColumns: ColumnType<T1>[];
    getData: () => Promise<T1[]>;
    addDataHandle: (data: T) => Promise<T1>;
    updDataHandle: (data: T) => Promise<T1>;
    delDataHandle: (data: T) => Promise<number>;
    updMerge: (source: T1, target: T) => T;
    isRank: boolean;
    editCheck?: (d: T1) => boolean;
    AddDom: (props: AddProps<T>) => JSX.Element
    heightOffset?: number;
    rowKey: keyof T;
    title?: TableProps<T1>["title"]
}

export function sumWidth(arr: { width?: number }[], def: number = 120): number {
    let num = 0;
    arr.forEach(s => num += (s.width || def));
    return num;
}

export function CommonTable<RecordType extends object = any>(props: Omit<TableProps<RecordType>, "columns"> & {
    columns: ColumnType<RecordType>[],
    widthOffset?: number,
    heightOffset?: number,
    rowKey: NonNullable<TableProps<RecordType>["rowKey"]>,
}) {
    const [, contentWidth] = useContext(ContentSizeContext);
    const tableWidth = useMemo(() => {
        if (props.columns) {
            const width = sumWidth(props.columns) + 20 + (props.widthOffset ?? 0);//有纵轴滚动条所以要补偿20px
            if (width > contentWidth) return contentWidth;
            else return width;
        }
    }, [contentWidth, props.columns, props.widthOffset]);

    const pagination = useMemo(() => {
        const pageSizeOptions = ["10", "20", "50", "100", "200", "400", "800"];
        if (props.pagination) {
            if (!props.pagination.pageSizeOptions) {
                props.pagination.pageSizeOptions = pageSizeOptions;
            }
            props.pagination.responsive = true;
            return props.pagination;
        }
        else {
            return { showSizeChanger: false, pageSize: 13, pageSizeOptions, responsive: true };
        }
    }, [props.pagination]);

    return <Table
        scroll={{ x: tableWidth, y: (705 - (props.heightOffset ?? 0)) }}
        {...props}
        size="middle"
        bordered
        pagination={pagination}
    />;
}

function EditTable<T extends {}, T1 extends T = T>({ getData,
    updMerge,
    updDataHandle: updData,
    addDataHandle: addData,
    delDataHandle,
    baseColumns,
    isRank,
    editCheck,
    AddDom,
    rowKey,
    title
}: IProps<T, T1>) {

    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<T1[]>([]);
    const [edit, setEdit] = useTableEditState<T1>();

    useEffect(() => {
        setLoading(true);
        getData().then(s => setData(s))
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [getData]);

    const onSubmit = useCallback((s: any) => {
        if (edit) {
            if (edit.data) {
                const dt = updMerge(edit.data, s);
                updData(dt)
                    .then(s => {
                        s && setData(ds => {
                            const index = ds.findIndex(a => a === edit.data);
                            if (index > -1) ds[index] = s;
                            setEdit(null);
                            return [...ds];
                        });
                    });
            }
            else {
                addData(s).then(s => {
                    if (s) {
                        setEdit(null);
                        setData(ds => [...ds, s]);
                    }
                });
            }
        }
    }, [edit, updMerge, updData, setEdit, addData]);

    const onCancel = useCallback(() => setEdit(null), [setEdit]);

    const delData = useCallback((s: T1, callBack: () => void) => {
        delDataHandle(s).then(a => {
            if (a > 0) {
                setData(ds => {
                    const index = ds.findIndex(a => a === s);
                    if (index >= 0) ds.splice(index, 1);
                    return [...ds];
                });
            }
        }).finally(callBack);
    }, [delDataHandle]);

    const { columns, width } = useMemo(() => {
        const width = sumWidth(baseColumns) + 20;//有纵轴滚动条所以要补偿20px
        if (isRank) {
            const action = getTableAction({ setEdit, delData, editCheck });
            return { columns: [...baseColumns, action], width: width + 120 };
        }
        else return { columns: baseColumns, width };
    }, [baseColumns, isRank, setEdit, delData, editCheck]);

    const [, contentWidth] = useContext(ContentSizeContext);

    const tableWidth = useMemo(() => {
        if (width > contentWidth) return contentWidth;
        else return width;
    }, [contentWidth, width]);

    if (isRank) {
        return <>
            <Table size="middle"
                bordered loading={loading}
                title={title}
                dataSource={data}
                columns={columns}
                scroll={{ x: tableWidth }}
                pagination={defaultPagination}
                rowKey={s => s[rowKey] as any} ></Table>
            {edit && <AddDom data={edit.data} onCancel={onCancel} onSubmit={onSubmit} />}
        </>;
        
    }
    else {
        return <Table size="middle"
            bordered
            loading={loading}
            dataSource={data}
            columns={columns}
            scroll={{ x: tableWidth }}
            pagination={defaultPagination} ></Table>;
    }
}

export default EditTable;