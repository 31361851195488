import { offlineExport } from "@/Api/OfflineController";
import { getCanExportViews } from "@/Api/StockSlotController";
import { toYMD, toYMDHMS } from "@/Common/date";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { Form, InputNumber, Modal } from "antd";
import React, { Fragment, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { SearchComponent } from "./SearchComponent";

export default Index;

function getColumns(onShow: (data: ViewStockSlot) => void): ColumnType<ViewStockSlot>[] {
    return [
        strProps("warehouseName", "库房", 80, true, true),
        strProps("lanewayName", "巷道", 80, true, true),
        strProps("goodsTypeName", "机型", 90, true, true),
        strProps("goodsName", "物品名称", 114, true, true),
        strProps("supplierName", "供应商", 90, true, true),
        strProps("customerName", "客户", 90, true, true),
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("slotCode", "储位编码", 114, true, true),
        strProps("goodsStatusName", "状态", 80, true, true),
        numProps("productionDate", "生产日期", 114, true, toYMD, true),
        numProps("time", "入库时间", 160, true, toYMDHMS),
        numProps("quantity", "数量", 80, true),
        { title: "状态", dataIndex: "flag", width: 80, sorter: strSorter("flag"), render: getStockFlagName },
        strProps("remark", "备注", 100, true, true),
        { title: "操作", width: 100, render: (_, r) => <TableInnerButton type="link" onClick={() => onShow(r)}>脱机出库</TableInnerButton> }
    ];
}

function Index() {
    const [notifyId, setNotifyId] = useState<number>();
    const [offlineExportShow, setOfflineExportShow] = useState<ViewStockSlot>();
    const onOfflineExportShow = useCallback((data: ViewStockSlot) => setOfflineExportShow(data), []);
    const onHideOfflineExportShow = useCallback(() => setOfflineExportShow(undefined), []);
    const columns = useMemo(() => getColumns(onOfflineExportShow), [onOfflineExportShow]);
    const [data, setData] = useState<ViewStockSlot[]>([]);
    const [loading, setLoading] = useState(false);
    const onSearch = useCallback(async () => {
        console.log("notifyId ", notifyId);
        if (notifyId) {
            setLoading(true);
            try {
                const d1 = await getCanExportViews(notifyId);
                setData(d1);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [notifyId]);
    useEffect(() => { onSearch(); }, [notifyId, onSearch]);
    const onExport = useCallback(async (quantity: number, stockSlotId: number, notifyId: number) => {
        const res = await offlineExport(stockSlotId, quantity, notifyId);
        if (res.res > 0) {
            onSearch();
        }
        else {
            throw res.msg;
        }
    }, [onSearch]);
    return <Fragment>
        <SearchComponent onSearch={setNotifyId} />
        <CommonTable rowKey={s => s.id} heightOffset={80} loading={loading} columns={columns} dataSource={data} pagination={false} />
        {
            notifyId && offlineExportShow && <OfflineExport notifyId={notifyId} data={offlineExportShow} onCancel={onHideOfflineExportShow} onSubmit={onExport} />
        }
    </Fragment>;
}

function OfflineExport({ data, notifyId, onCancel, onSubmit }: {
    data: ViewStockSlot,
    notifyId: number,
    onCancel: () => void,
    onSubmit: (quantity: number, stockSlotId: number, notifyId: number) => Promise<void>
}) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            await onSubmit(d1.quantity, data.id, notifyId);
            onCancel();
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data.id, form, notifyId, onCancel, onSubmit]);
    return <Modal closable={true} confirmLoading={loading} open width={360} title={`脱机出库[${data.goodsName}]`} onCancel={onCancel} onOk={onOk}>
        <Form layout="horizontal" form={form} labelCol={{ span: 8 }}>
            <Form.Item label="出库数量" name="quantity" rules={[{
                validator: (s, v) => {
                    if (v < 0) return Promise.reject(new Error("出库数量不能小于0"));
                    if (v > data.quantity) return Promise.reject(new Error("出库数量不能大于库存数量"));
                    return Promise.resolve();
                }
            }]} required initialValue={data.quantity}>
                <InputNumber />
            </Form.Item>
        </Form>
    </Modal>;

}