import ImportNotifyApi from "@/Api/ImportNotifyApi";
import { PrintComponent } from "@/Common/Print/PrintComponent";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { toYMD } from "@/Common/date";
import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { ViewImportNotify } from "@/Models/Views/ViewImportNotify";
import { Space } from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";

export interface PrintComponentProps {
    record: ViewImportBill;
    onCancel: () => void;
}

export function PrintBillDetail({ record, onCancel }: PrintComponentProps) {
    const columns = useMemo(getColumns, []);
    const getData = useCallback(() => {
        return ImportNotifyApi.getViews(record.id);
    }, [record.id]);
    return <PrintComponent onCancel={onCancel} getData={getData} columns={columns} title={`入库单:${record.code}`} header={`入库单号:${record.code}`}
        footer={
            <div style={{ float: 'right' }}>
                <Space>
                    <span>打印人:</span>
                    <span>李龙</span>
                    <span>打印时间:</span>
                    <span>{dayjs().format("YYYY-MM-DD HH:mm:ss")}</span>
                </Space>
            </div>
        } />;
}

function getColumns(): ColumnType<ViewImportNotify>[] {
    return [
        { title: "No", key: "no", render: (_, r, index) => (index + 1).toString(), width: 80 },
        strProps("goodsName", "物品名称", 160, false, false),
        strProps("goodsStatusName", "状态", 80, false, false),
        numProps("quantity", "计划数量", 120, false),
        numProps("factQuantity", "已下发数量", 120, false),
        numProps("completeQuantity", "已完成数量", 120, false),
        numProps("productionDate", "生产日期", 160, false, toYMD),
    ];
}