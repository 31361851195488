import { get } from '@/Api';
const name = 'TestPlc';
function getUrl(url: string) {
    return `${name}/${url}`;
}

interface IData {
    stationNo: number;
}

export function getStackerDeviceStatusKeys(): Promise<number[]> {
    return get(getUrl("GetStackerDeviceStatusKeys"));
}
export function setStackerDeviceStatus(data: IData): Promise<number> {
    return get(getUrl("SetStackerDeviceStatus"), data);
}
export function setStackerTaskStatus(data: IData): Promise<number> {
    return get(getUrl("SetStackerTaskStatus"), data);
}
export function getConveyerDeviceStatusKeys(): Promise<number[]> {
    return get(getUrl("GetConveyerDeviceStatusKeys"));
}
export function setDownSendConveyerTask(data: IData): Promise<number> {
    return get(getUrl("SetDownSendConveyerTask"), data);
}
export function setDownSendStackerTask(data: IData): Promise<number> {
    return get(getUrl("SetDownSendStackerTask"), data);
}
export function setConveyerDeviceStatus(data: IData): Promise<number> {
    return get(getUrl("SetConveyerDeviceStatus"), data);
}
export function getScanConveyerDeviceStatusKeys(): Promise<number[]> {
    return get(getUrl("GetScanConveyerDeviceStatusKeys"));
}
export function getDownSendStackerTaskKeys(): Promise<number[]> {
    return get(getUrl("GetDownSendStackerTaskKeys"));
}
export function getDownSendConveyerTaskKeys(): Promise<number[]> {
    return get(getUrl("GetDownSendConveyerTaskKeys"));
}
export function getConveyerTaskStatusKeys(): Promise<number[]> {
    return get(getUrl("GetConveyerTaskStatusKeys"));
}
export function setScanConveyerDeviceStatus(data: IData): Promise<number> {
    return get(getUrl("SetScanConveyerDeviceStatus"), data);
}
export function setConveyerTaskStatus(data: IData): Promise<number> {
    return get(getUrl("SetConveyerTaskStatus"), data);
}
export function getTaskFinishedKeys(): Promise<number[]> {
    return get(getUrl("GetTaskFinishedKeys"));
}
export function setTaskFinished(data: IData): Promise<number> {
    return get(getUrl("SetTaskFinished"), data);
}
export function setWcsControlConveyerDirection(data: IData): Promise<number> {
    return get(getUrl("SetWcsControlConveyerDirection"), data);
}
export function delay(fn: () => Promise<any>, interval: number) {
    return new Promise(resolve => {
        const time = setTimeout(async () => {
            await fn();
            clearTimeout(time);
            return resolve(null);
        }, interval);
    });
}
