import React from 'react';
import { ConveyerComponent } from './ConveyerComponent';
import { StackerComponent } from './StackerComponent';
import { TaskFinishedComponent } from './TaskFinishedComponent';

export function App() {
    return (<div className='plcTest-root'>
        <StackerComponent />
        <ConveyerComponent />
        <TaskFinishedComponent />
    </div>);
}

