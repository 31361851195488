import Contract from './Contract';
import ProductItem from './ProductionItem';
import PayRecord from './PayRecord';

import {
    ImportOutlined,
    ProfileOutlined,
    ReadOutlined,
    ManOutlined,
    KeyOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
import Invoice from './Invoice';
import BluePrint from './BluePrint';

function saleGroup(): IMenuGroup {
    return {
        name: "销售管理",
        path: "sale",
        icon: ImportOutlined,
        items: [
            {
                name: "合同管理",
                icon: ProfileOutlined,
                element: <Contract />,
                path: "Contract",
                right: 1 << 0,
            },
            {
                name: "商品明细",
                icon: ReadOutlined,
                element: <ProductItem />,
                path: "ProductItem",
                right: 1 << 1,
            },
            {
                name: "付款记录",
                icon: KeyOutlined,
                element: <PayRecord />,
                path: "PayRecord",
                right: 1 << 2,
            },
            {
                name: "开票记录",
                icon: KeyOutlined,
                element: <Invoice />,
                path: "Invoice",
                right: 1 << 3,
            },
            {
                name: "图纸管理",
                icon: ManOutlined,
                element: <BluePrint />,
                path: "BluePrint",
                right: 1 << 4,
            },

        ]
    };
}

export default saleGroup; 