import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { EquipmentType } from "@/Models/Enums/EquipmentType";
import { UpkeepConfig } from "@/Models/Tables/UpkeepConfig";
import { Col, Form, Input, InputNumber, Row } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { useCallback } from "react";
import { EquipmentTypeContext } from "./common";

function Item(props: IFormItemProps<UpkeepConfig>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}



function Add({ onCancel, onSubmit, data }: AddProps<UpkeepConfig>) {
    const type = useContext(EquipmentTypeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            onSubmit({ ...d1, type });
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onSubmit, type]);

    return <MyModal confirmLoading={loading} name="维护方案" data={data} width={type === EquipmentType.Conveyer ? 360 : 480} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" layout="vertical" initialValues={data}>
            {type === EquipmentType.Conveyer ?
                (<Fragment>
                    <Item name="name" label="方案名称" required >
                        <Input ></Input>
                    </Item>
                    <Item name="runtimeInterval1" label="维护周期(小时)" required >
                        <InputNumber></InputNumber>
                    </Item>
                    <Item name="description" label="方案描述" >
                        <Input.TextArea rows={3} ></Input.TextArea>
                    </Item>
                </Fragment>)
                :
                (<Fragment>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Item name="name" label="方案名称" required >
                                <Input ></Input>
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Item name="runtimeInterval1" label="行走时间(s)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="mileageInterval1" label="行走里程(cm)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Item name="runtimeInterval2" label="升降时间(s)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="mileageInterval2" label="升降里程(cm)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Item name="runtimeInterval3" label="单叉时间(s)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="mileageInterval3" label="单叉里程(cm)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Item name="runtimeInterval4" label="双叉时间(s)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="mileageInterval4" label="双叉里程(cm)" required >
                                <InputNumber></InputNumber>
                            </Item>
                        </Col>
                    </Row>
                    <Item name="description" label="方案描述" >
                        <Input.TextArea rows={3} ></Input.TextArea>
                    </Item>
                </Fragment>)
            }
        </Form>
    </MyModal>;
}

export default Add;