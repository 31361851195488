export enum OrderExecuteFlag {
    Cancel = -1,
    WaitingExecute = 0,
    Executing = 1,
    Finished = 2
}

export type TypeOrderExecuteFlag = keyof typeof OrderExecuteFlag;

const orderExecuteFlagNames: { [K in TypeOrderExecuteFlag]: string } = {
    Cancel: "已取消",
    WaitingExecute: "待执行",
    Executing: "执行中",
    Finished: "已完成",
};

export function getOrderExecuteFlagNames(e: TypeOrderExecuteFlag) {
    return orderExecuteFlagNames[e];
}
