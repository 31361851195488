import { getSelectItems } from "@/Api/SlotController";
import { moveInLaneway, offlineMove } from "@/Api/WcsDataProviderController";
import { Button, Cascader, CascaderProps, Form, message, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { forEach, groupBy } from "lodash";
import { useCallback, useState } from "react";

export function MoveInLaneway() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [slotOptions, setSlotOptions] = useState<CascaderProps<any>["options"]>([]);
    const onSubmit = useCallback(async () => {
        try {
            setLoading(true);
            const data = await form.validateFields();
            const res = await moveInLaneway(data["originSlotId"][1], data["targetSlotId"][1]);
            if (res.startsWith("移库任务创建成功")) {
                form.setFields([{ name: "originSlotId", value: data["targetSlotId"] }, { name: "targetSlotId", value: data["originSlotId"] }]);
            }
            message.info(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    const onOfflineMove = useCallback(async () => {
        try {
            setLoading(true);
            const data = await form.validateFields();
            const res = await offlineMove(data["originSlotId"][1], data["targetSlotId"][1]);
            message.info(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    return (<div className="item">
        <Typography.Title level={3}>移库</Typography.Title>
        <Form title="巷道内移库" form={form} layout="vertical">
            <Form.Item label="源储位" name="originSlotId">
                <Cascader style={{ minWidth: 100 }} onDropdownVisibleChange={slotOptions ? async s => {
                    if (s) {
                        setLoading(true);
                        try {
                            const d1 = await getSelectItems();
                            const slot: CascaderProps<any>["options"] = [];
                            forEach(groupBy(d1, s => s.warehouseName), (s, key) => {
                                slot.push({ label: key, value: key, children: s });
                            });
                            setSlotOptions(slot);
                        } catch (error) {
                            console.error(error);
                        }
                        finally {
                            setLoading(false);
                        }
                    }
                } : undefined} showSearch allowClear options={slotOptions} />
            </Form.Item>
            <Form.Item label="目标储位" name="targetSlotId">
                <Cascader style={{ minWidth: 100 }} onDropdownVisibleChange={slotOptions ? async s => {
                    if (s) {
                        setLoading(true);
                        try {
                            const d1 = await getSelectItems();
                            const slot: CascaderProps<any>["options"] = [];
                            forEach(groupBy(d1, s => s.warehouseName), (s, key) => {
                                slot.push({ label: key, value: key, children: s });
                            });
                            setSlotOptions(slot);
                        } catch (error) {
                            console.error(error);
                        }
                        finally {
                            setLoading(false);
                        }
                    }
                } : undefined} showSearch allowClear options={slotOptions} />
            </Form.Item>
        </Form>
        <Button loading={loading} disabled={loading} onClick={onSubmit}>巷道内移库</Button>
        <Button loading={loading} disabled={loading} onClick={onOfflineMove}>脱机交换物品</Button>
    </div>);
}
