import { getSelectItems as getLanewaySelectItems } from "@/Api/LanewayController";
import { getColumns, getLayers, getRows } from "@/Api/SlotController";
import { getSelectItems } from "@/Api/WarehouseController";
import { TableActions } from "@/Common/Table/TableActions";
import { WarehouseType } from "@/Models/Enums/WarehouseType";
import { SelectItem } from "@/Models/Other/SelectItem";
import { SlotBatchUpdFilter } from "@/Models/Other/SlotBatchUpdFilter";
import { Button, Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";


export function SearchComponent({ onSearch }: { onSearch: (data: SlotBatchUpdFilter) => void; }) {
    const [loading, setLoading] = useState(false);
    const [warehouseOptions, setWarehouseOptions] = useState<SelectItem[]>([]);
    const [lanewayOptions, setLanewayOptions] = useState<SelectItem[]>([]);
    const [rowOptions, setRowOptions] = useState<SelectItem[]>([]);
    const [columnOptions, setColumnOptions] = useState<SelectItem[]>([]);
    const [layerOptions, setLayerOptions] = useState<SelectItem[]>([]);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            onSearch(
                {
                    warehouseId: s["warehouseId"], lanewayId: s["lanewayId"], row: s["row"], column: s["column"], layer: s["layer"]
                }
            );
        }}>
            <Form.Item label="库房" name="warehouseId">
                <Select onDropdownVisibleChange={warehouseOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getSelectItems([WarehouseType.Stereoscopic]).then(setWarehouseOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 100 }} loading={loading} options={warehouseOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="巷道" name="lanewayId">
                <Select onDropdownVisibleChange={lanewayOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getLanewaySelectItems().then(setLanewayOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 140 }} loading={loading} options={lanewayOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="排" name="row">
                <Select onDropdownVisibleChange={rowOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getRows().then(toOptions).then(setRowOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 100 }} loading={loading} options={rowOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="列" name="column">
                <Select onDropdownVisibleChange={columnOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getColumns().then(toOptions).then(setColumnOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 100 }} loading={loading} options={columnOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="层" name="layer">
                <Select onDropdownVisibleChange={layerOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        getLayers().then(toOptions).then(setLayerOptions).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 100 }} loading={loading} options={layerOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}
function toOptions(s: number[]) {
    return s.sort((a, b) => a - b).map(a => ({ label: a.toString(), value: a }));
}


