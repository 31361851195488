import { add, del, getViews, upd } from "@/Api/PurchaseRequisitionController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { getPurchaseRequisitionStatusNames } from "@/Models/Enums/PurchaseRequisitionStatus";
import { PurchaseRequisition } from "@/Models/Tables/PurchaseRequisition";
import { ViewPurchaseRequisition } from "@/Models/Views/ViewPurchaseRequisition";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewPurchaseRequisition>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("contractName", "合同名称", 120, true, true),
        strProps("bluePrintName", "图纸名称", 120, true, true),
        strProps("goodsCode", "物料编码", 120, true, true),
        strProps("goodsName", "物料名称", 200, true, true),
        strProps("brand", "品牌", 160, true, true),
        strProps("goodsStandard", "物料规格", 120, true, true),
        numProps("price", "参考单价", 100, true),
        numProps("quantity", "数量", 80, true),
        strProps("status", "状态", 80, true, true, getPurchaseRequisitionStatusNames),
        strProps("remark", "品牌", 80, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< PurchaseRequisition, ViewPurchaseRequisition> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewPurchaseRequisition, target: PurchaseRequisition) {
    target.id = source.id;
    return target;
}