import ExportBillApi from "@/Api/ExportBillApi";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { Popconfirm, Space } from "antd";
import { Fragment, useCallback, useState } from "react";
import { DownSendComponent } from "./DownSendTask";

export function BillExecuteFlagOperation({ record }: { record: ViewExportBill; }) {
    const [loading, setLoading] = useState(false);
    const onConfirm = useCallback(async () => {
        setLoading(true);
        try {
            const res = await ExportBillApi.cancel(record.id);
            console.log(res);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [record.id]);
    if (record.executeFlag === "WaitingPick" || record.executeFlag === "WaitingExecute" || record.executeFlag === "Executing") {
        return (<Space>
            <Popconfirm title={`您确定要取消出库单:${record.code}吗?`} onConfirm={onConfirm} disabled={loading}>
                <TableInnerButton loading={loading} type="link">取消</TableInnerButton>
            </Popconfirm>
            {record.executeFlag === "Executing" && <DownSendComponent record={record} />}
        </Space>);
    }
    else if (record.executeFlag === "WaitingDownSend") {
        return (<DownSendComponent record={record} />);
    }
    return <Fragment></Fragment>;
}
