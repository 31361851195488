import { getLogs as getTaskLogs } from "@/Api/EquipmentTaskController";
import { getLogs as getFinishedLogs } from "@/Api/EquipmentTaskFinishedController";
import { toYMDHMS } from "@/Common/date";
import { CommonTable } from "@/Common/Table/EditTable";
import { EquipmentTaskLog } from "@/Models/Tables/EquipmentTaskLog";
import { Modal } from "antd";
import { useEffect } from "react";
import { useState } from "react";

export function TaskLogComponent({ record, isFinished, onCancel }: { record: { id: number, taskNo: string }; isFinished: boolean, onCancel: () => void }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<EquipmentTaskLog[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                if (isFinished) {
                    setData(await getFinishedLogs(record.id));
                }
                else {
                    setData(await getTaskLogs(record.id));
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [isFinished, record.id]);

    return (<Modal title={`${record.taskNo}执行日志`} width={800} open footer={null} confirmLoading={loading} onCancel={onCancel}>
        <CommonTable rowKey={s => s.id} dataSource={data} size="middle"
            loading={loading}
            pagination={{ pageSize: 10 }}
            columns={
                [
                    { title: "Id", dataIndex: "id", width: 100 },
                    { title: "描述信息", dataIndex: "note", width: 300, ellipsis: true },
                    { title: "发生时间", dataIndex: "time", width: 160, render: toYMDHMS },
                ]
            } />
    </Modal>);
}
