import { ConveyerInfo } from "@/Models/Other/ConveyerInfo";
import { BaseHub, tpOnError } from "./BaseHub";
import { IConveyerHubAction } from "./IActions/IConveyerHubAction";


export class ConveyerHub extends BaseHub<IConveyerHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/Conveyer");
    }
    public readonly getInfos = () => this.hub.invoke<ConveyerInfo[]>("GetInfos");
}
