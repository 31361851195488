import { get, post } from '@/Api';
import { ExportBillSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { ExportPortChoose } from '@/Models/Other/ExportPortChoose';
import { MES_TO_WMS_EXPORT_Req_Body, MES_TO_WMS_EXPORT_Res_Body } from '@/Models/Other/MES_TO_WMS_EXPORT';
import { PaginationData } from "@/Models/Other/PaginationData";
import { SelectItem } from '@/Models/Other/SelectItem';
import { ImportBill } from '@/Models/Tables/Bill';
import { ViewExportBill } from '@/Models/Views/ViewExportBill';

namespace ExportBillApi {



    const name = 'ExportBill';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }

    export function getViews(data: ExportBillSplitPageProps): Promise<PaginationData<ViewExportBill>> {
        return get(getUrl("getViews"), data);
    }

    export function getUnFinishedList(): Promise<ViewExportBill[]> {
        return get(getUrl("getUnFinishedList"));
    }

    export function getSelectItems(): Promise<SelectItem[]> {
        return get(getUrl("getSelectItems"));
    }

    export function cancel(id: number): Promise<number> {
        return get(getUrl("Cancel"), { id });
    }

    export function add(data: ImportBill): Promise<number> {
        return post(getUrl("Add"), data);
    }

    export function mesAdd(data: MES_TO_WMS_EXPORT_Req_Body): Promise<MES_TO_WMS_EXPORT_Res_Body> {
        return post(getUrl("MesAdd"), data);
    }

    export function getExportPortChooses(id: number): Promise<ExportPortChoose[]> {
        return get(getUrl("GetExportPortChooses"), { id });
    }

    export function downSendTask(billId: number): Promise<number> {
        return get(getUrl(`DownSendTask?billId=${billId}`));
    }

    export function syncMesBill(): Promise<number> {
        return get(getUrl('syncMesBill'));
    }

    export function reSetUploadFlag(id: number): Promise<number> {
        return get(getUrl("ReSetUploadFlag"), { id });
    }

    export function updPlatformId(billId: number, platformId: number): Promise<number> {
        return get(getUrl("UpdPlatformId"), { billId, platformId });
    }

}

export default ExportBillApi;