import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { LanewayHub } from "@/Hubs/LanewayHub";
import { ViewLaneway } from "@/Models/Views/ViewLaneway";
import { createContext } from "react";

export class LanewayProvider {
    public readonly hub: LanewayHub;
    constructor(onError: tpOnError) {
        this.hub = new LanewayHub(onError);
        this.hub.on("ViewChange", data => {
            const index = this.data.Value.findIndex(s => s.id === data.id);
            if (index !== -1) {
                this.data.Value[index] = data;
                this.data.Value = [...this.data.Value];
            }
        });
        this.hub.start(async () => {
            try {
                const laneways = await this.hub.getViews();
                this.data.Value = laneways;
            } catch (error) {
                console.log(error);
            }
        });
    }
    public readonly data = new Obs<ViewLaneway[]>([]);
    public readonly importLanewayId = new Obs(1);
}

export const LanewayProviderContext = createContext<LanewayProvider>(null as any);