import { get } from '@/Api';
import { ImportNotifySelectItem } from "@/Models/Other/ImportNotifySelectItem";
import { ViewImportNotify } from '@/Models/Views/ViewImportNotify';



namespace ImportNotifyApi {

    const name = 'ImportNotify';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }

    export function getViews(billId: number): Promise<ViewImportNotify[]> {
        return get(getUrl("getViews"), { billId });
    }

    export function getSelectItems(billId: number): Promise<ImportNotifySelectItem[]> {
        return get(getUrl("getSelectItems"), { billId });
    }
}

export default ImportNotifyApi;