import { createContext, Fragment, useEffect, useMemo, useState } from 'react';
import { ConveyerInfo } from '@/Models/Other/ConveyerInfo';
import { Obs } from '@/Common/Obs';
import LongConveyer from '../images/LongConveyer';
import TurnConveyer from '../images/TurnConveyer';
import PickConveyer from '../images/PickConveyer';
import { getConveyerProps, TypeConfig } from './ConveyerLocationBuilder';
import { Tooltip } from 'antd';
import { TaskInfo } from '@/Models/Other/TaskInfo';
import { resolveConveyerFaultInfo } from '@/Common/fault';
import { resolveEquipmentTime } from '@/Common/date';

export function Conveyer({ data, config }: { data: Obs<ConveyerInfo>, config: TypeConfig }) {
    const [info, setInfo] = useState<ConveyerInfo>(data.Value);
    useEffect(() => {
        return data.listen(setInfo);
    }, [data]);

    const blink = useMemo(() => {
        console.log(info.fault);
        if (info.fault !== 'AAAAAA==') {
            return '#d9f7be;red;';
        }
        if (info.status === 5) {
            return '#d9f7be;#73d13d;#237804;#092b00;';
        }
    }, [info]);

    const SvgComp = useMemo(() => {
        if (config.type === 'turn') return TurnConveyer;
        if (config.type === 'pickAndPut') return PickConveyer;
        return LongConveyer;
    }, [config.type]);

    const { width, height, top, left, transform, sgvHeight } = useMemo(() => getConveyerProps(config), [config]);
    return (<div className="conveyer" style={{ height, width, top, left }} >
        <SvgComp style={{ height: sgvHeight, position: 'absolute', fill: '#bfbfbf', transform }} blink={blink} ></SvgComp>
        <div className='stationNo'>{info.stationNo}</div>
        {
            <Tooltip className='more-info' title={<div>
                <div><span>站台号:</span>{info.stationNo}</div>
                <div><span>任务号:</span>{info.taskNo}</div>
                <div><span>去向号:</span>{info.whereToGo}</div>
                <div><span>托盘条码:</span>{info.stockCodeValue}</div>
                <div><span>运行时间:</span>{resolveEquipmentTime(info.runtime)}</div>
                {getTaskInfoItems(info.info)}
                {getFaultInfo(info.fault)}
            </div>}></Tooltip>
        }
    </div>);

}

export const CurrentLayerContext = createContext<number>(null as any);

function getTaskInfoItems(info?: TaskInfo) {
    if (info) return <Fragment>
        <div><span>物品名称</span> {info?.goodsName}</div>
        <div><span>物料编码</span> {info?.goodsCode}</div>
        <div><span>数量</span> {info?.quantity}</div>
    </Fragment>;
}

function getFaultInfo(d: string) {
    if (d === "AAAAAA===") return undefined;
    const faults = resolveConveyerFaultInfo(d);
    return <div>{faults.join(',')}</div>;
}