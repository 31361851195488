import { Obs } from "@/Common/Obs";
import { LedScrollMethod } from "@/Models/Tables/Led";
import { Form, Input, InputNumber, Modal, Select } from "antd";
import { useCallback, useState } from "react";
import { LeafComponentProps } from "./LeafComponentProps";



export function LeafItemEdit({ data, onCancel }: { data: Obs<LeafComponentProps>; onCancel: () => void; }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            data.Value = { ...data.Value, ...d1 };
            onCancel();
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onCancel]);
    return <Modal title="元素信息" open width={320} onCancel={onCancel} onOk={onOk} confirmLoading={loading}>
        <Form form={form} initialValues={data.Value} style={{ marginTop: 20 }} labelCol={{ span: 8 }}>
            <Form.Item label="显示内容" name="text" required rules={[{ required: true }]}>
                <Input></Input>
            </Form.Item>
            <Form.Item label="字体大小" name="fontSize" required rules={[{ required: true }]}>
                <InputNumber precision={0} step={1} min={6}></InputNumber>
            </Form.Item>
            <Form.Item label="top" name="top" required rules={[{ required: true }]}>
                <InputNumber step={1} min={6}></InputNumber>
            </Form.Item>
            <Form.Item label="left" name="left" required rules={[{ required: true }]}>
                <InputNumber step={1} min={6}></InputNumber>
            </Form.Item>
            <Form.Item label="滚动方式" name="method">
                <Select options={[
                    { label: "固定", value: LedScrollMethod.None },
                    { label: "左滚", value: LedScrollMethod.Left },
                    { label: "右滚", value: LedScrollMethod.Right },
                    { label: "上滚", value: LedScrollMethod.Top },
                ]} allowClear />
            </Form.Item>
        </Form>
    </Modal>;
}
