import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Platform } from '@/Models/Tables/Platform';

const name = 'Platform';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<Platform[]> {
    return get(getUrl("getViews"));
}
export function add(data: Platform): Promise<Platform> {
    return post(getUrl("add"), data);
}
export function upd(data: Platform): Promise<Platform> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("getSelectItems"));
}
export function getSelectItemsByBillId(billId: number): Promise<SelectItem[]> {
    return get(getUrl("GetSelectItemsByBillId"), { billId });
}
export function setMaxTaskCount(id: number, count: number): Promise<SelectItem[]> {
    return get(getUrl("SetMaxTaskCount"), { id, count });
}
export function setPlatform(id: number, platformId: number): Promise<SelectItem[]> {
    return get(getUrl("SetPlatform"), { id, platformId });
}
export function setStatus(platformId: number, status: boolean): Promise<SelectItem[]> {
    return get(getUrl("SetStatus"), { platformId, status });
}