import { add, del, getViews, upd } from "@/Api/PurchaseNotifyController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { PurchaseNotify } from "@/Models/Tables/PurchaseNotify";
import { ViewPurchaseNotify } from "@/Models/Views/ViewPurchaseNotify";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewPurchaseNotify>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("code", "采购单编码", 160, true, true),
        strProps("goodsCode", "物料编码", 120, true, true),
        strProps("goodsName", "物料名称", 120, true, true),
        strProps("standard", "规格", 120, true, true),
        numProps("quantity", "数量", 100, true),
        numProps("price", "单价", 100, true),
        numProps("minPrice", "最低价", 100, true),
        numProps("maxPrice", "最高价", 100, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< PurchaseNotify, ViewPurchaseNotify> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewPurchaseNotify, target: PurchaseNotify) {
    target.id = source.id;
    return target;
}