import { add, del, getViews, upd } from "@/Api/PlatformController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { Platform } from "@/Models/Tables/Platform";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<Platform>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("name", "月台名称", 200, true, true),
        strProps("code", "月台编码", 120, true, true),
        strProps("remark", "备注", 160, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< Platform> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: Platform, target: Platform) {
    target.id = source.id;
    return target;
}