import { add, del, getViews, upd } from "@/Api/UpkeepConfigController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { EquipmentType, getEquipmentTypeOptions } from "@/Models/Enums/EquipmentType";
import { UpkeepConfig } from "@/Models/Tables/UpkeepConfig";
import { ViewUpkeepConfig } from "@/Models/Views/ViewUpkeepConfig";
import { Radio } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import Add from "./Add";
import { EquipmentTypeContext } from "./common";

function getColumns(type: EquipmentType): ColumnType<ViewUpkeepConfig>[] {
    if (type === EquipmentType.Stacker) {
        return [
            numProps("id", "Id", 60, true),
            strProps("name", "方案名称", 120, true, true),
            numProps("runtimeInterval1", "行走时间(s)", 140, true),
            numProps("runtimeInterval2", "升降时间(s)", 140, true),
            numProps("runtimeInterval3", "单叉时间(s)", 140, true),
            numProps("runtimeInterval4", "双叉时间(s)", 140, true),
            numProps("mileageInterval1", "行走里程(cm)", 140, true),
            numProps("mileageInterval2", "升降里程(cm)", 140, true),
            numProps("mileageInterval3", "单叉里程(cm)", 140, true),
            numProps("mileageInterval4", "双叉里程(cm)", 140, true),
            { title: "描述信息", dataIndex: "description" }
        ];
    }
    return [
        numProps("id", "Id", 60, true),
        strProps("name", "方案名称", 200, true, true),
        numProps("runtimeInterval1", "运行时间(s)", 200, true),
        { title: "描述信息", dataIndex: "description" }
    ];
}

export default Index;

function Index() {
    const radioOptions = useMemo(getEquipmentTypeOptions, []);
    const [type, setType] = useState(radioOptions[0].value);
    const columns = useMemo(() => getColumns(type), [type]);
    const getData = useCallback(() => getViews(type), [type]);

    return (<EquipmentTypeContext.Provider value={type}>
        <EditTable<UpkeepConfig, ViewUpkeepConfig> getData={getData}
            baseColumns={columns}
            isRank
            AddDom={Add}
            updDataHandle={upd}
            delDataHandle={s => del(s.id)}
            updMerge={updMerge}
            addDataHandle={add}
            rowKey={"id"}
            title={s => <Radio.Group value={type} optionType="button" options={radioOptions} onChange={s => setType(s.target.value)} />}
        />
    </EquipmentTypeContext.Provider>);
}

function updMerge(source: UpkeepConfig, target: UpkeepConfig) {
    target.id = source.id;
    return target;
}