import ExportBillApi from "@/Api/ExportBillApi";
import { getSelectItemsByBillId } from "@/Api/PlatformController";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { Form, Modal, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

function EditPlatformForm({ onCancel, data }: { data: ViewExportBill, onCancel: () => void }) {
    const [form] = Form.useForm();
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await ExportBillApi.updPlatformId(data.id, d1["platformId"]);
            console.log(res);
            onCancel();
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onCancel]);
    const [options, setOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getSelectItemsByBillId(data.id);
                setOptions(d1);
                const index = d1.findIndex(s => s.label === data.platformName);
                if (index > -1) {
                    form.setFields([{ name: "platformId", value: d1[index].value }]);
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [data.id, data.platformName, form]);
    return <Modal visible confirmLoading={loading} title={`修改出库单${data.code}的月台`} width={360} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data} labelCol={{ span: 8 }}>
            <Form.Item name="platformId" label="所属月台" required >
                <Select allowClear={false} options={options} showSearch />
            </Form.Item>
        </Form>
    </Modal>;
}

export default EditPlatformForm;