import { TableInnerButton } from "@/Common/Table/getTableAction";
import { getSlotLockFlagName, SlotLockFlag, slotLockFlagNames } from "@/Models/Enums/SlotLockFlag";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { useState } from "react";
import { SlotProviderContext } from "./SlotProvider";



export function EditStatus({ record }: {
    record: ViewSlot;
}) {
    const value = useMemo(() => getSlotLockFlagName(record.lockFlag), [record.lockFlag]);
    const [loading, setLoading] = useState(false);
    const slotProvider = useContext(SlotProviderContext);
    const selectChange = useCallback(async (s: SlotLockFlag) => {
        setLoading(true);
        try {
            await slotProvider.hub.updLockFlag(record.id, s);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }, [record.id, slotProvider.hub]);
    const menuOptions = useMemo(() => {
        const items = Object.keys(slotLockFlagNames)
            .filter(s => s !== record.lockFlag)
            .map((s: any) => ({ label: getSlotLockFlagName(s), key: s, onClick: () => selectChange(s) }));
        return { items };
    }, [record.lockFlag, selectChange]);

    return <Dropdown disabled={loading} menu={menuOptions} trigger={["click"]}>
        <TableInnerButton type="link" >
            {value}<DownOutlined />
        </TableInnerButton>
    </Dropdown>;
}

// function getMenu(flag: TypeSlotLockFlag, onClick: (flag: SlotLockFlag) => void) {
//     const data = Object.keys(slotLockFlagNames);
//     return data.map((s: any) => {
//         if (s !== flag) {
//             return <Menu.Item key={s} onClick={() => onClick(s)}>
//                 {getSlotLockFlagName(s)}
//             </Menu.Item>;
//         }
//         return undefined;
//     });
// }
