import { getViewsByFilter, updAreaByList, updLockFlagByList } from "@/Api/SlotController";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { getSlotLockFlagName, slotLockFlagNames } from "@/Models/Enums/SlotLockFlag";
import { getSlotStatusName } from "@/Models/Enums/SlotStatus";
import { SlotBatchUpdFilter } from "@/Models/Other/SlotBatchUpdFilter";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { Button, Checkbox, Dropdown, Modal, Space } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { SearchComponent } from "./SearchComponent";
import { DownOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getSelectItems } from "@/Api/AreaController";

function getColumns(): ColumnType<ViewSlot>[] {
    return [
        numProps("id", "ID", 80, true),
        strProps("warehouseName", "库房名称", 160, true, true),
        strProps("code", "编码", 120, true, true),
        strProps("areaName", "所属区域", 120, true, true),
        strProps("lanewayName", "巷道", 160, true, true),
        numProps("row", "排", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("column", "列", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("layer", "层", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("height", "高度", 80, true),
        strProps("status", "状态", 80, true, true, getSlotStatusName),
        strProps("lockFlag", "锁定标志", 120, true, true, getSlotLockFlagName),
    ];
}

export default SlotBatchUpd;
function SlotBatchUpd() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewSlot[]>([]);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState<SlotBatchUpdFilter>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [modal, modalHolder] = Modal.useModal();
    const statusOptions = useMemo(() => Object.keys(slotLockFlagNames).map(s => ({ label: (slotLockFlagNames as any)[s], value: s })), []);
    const [areaOptions, setAreaOptions] = useState<{ label: string, value: number | null }[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getSelectItems() as any;
                d1.push({ label: "无区域", value: null });
                setAreaOptions(d1);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!filterData) return;
        (async () => {
            setLoading(true);
            try {
                setData(await getViewsByFilter(filterData));
                setSelectedRowKeys([]);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [filterData]);
    const updSlotLockFlag = useCallback((v: any, ids: number[]) => {
        modal.confirm({
            title: "警告!", content: `您确定要将这${ids.length}条数据的品质状态转换为${v.label}吗?`, onOk: () => {
                setLoading(true);
                updLockFlagByList(ids, v.value).then((s) => {
                    setData((b) => {
                        return [...b.map(c => {
                            const d = s.find(e => e.id === c.id);
                            return d || c;
                        })];
                    });
                }).finally(() => setLoading(false));
            }
        });
    }, [modal]);
    const updSlotArea = useCallback((v: any, ids: number[]) => {
        modal.confirm({
            title: "警告!", content: `您确定要将这${ids.length}条数据的区域转换为${v.label}吗?`, onOk: () => {
                setLoading(true);
                updAreaByList(ids, v.value).then((s) => {
                    setData((b) => {
                        return [...b.map(c => {
                            const d = s.find(e => e.id === c.id);
                            return d || c;
                        })];
                    });
                }).finally(() => setLoading(false));
            }
        });
    }, [modal]);

    const [pageSize, setPageSize] = useState(12);


    return (<Fragment>
        <SearchComponent onSearch={setFilterData} />
        <CommonTable heightOffset={83} rowKey={s => s.id} loading={loading} rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys as any }} columns={columns} dataSource={data} pagination={{ pageSize, showSizeChanger: true, onChange: (_, pageSize) => { setPageSize(pageSize) } }} />
        {modalHolder}
        <div style={{ position: 'relative' }}>
            <Space style={{ position: 'absolute', bottom: 8 }}>
                {data.length > 0 && <CheckAllComponent selected={selectedRowKeys} data={data} setKeys={setSelectedRowKeys} />}
                {selectedRowKeys.length > 0 &&
                    (<Space>
                        <Dropdown disabled={loading} menu={{ items: statusOptions.map(s => ({ label: s.label, key: s.value, onClick: () => updSlotLockFlag(s, selectedRowKeys) })) }} trigger={["click"]}>
                            <Button type="primary" >
                                锁定状态<DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown disabled={loading} menu={{ items: areaOptions.map(s => ({ label: s.label, key: s.value || "null", onClick: () => updSlotArea(s, selectedRowKeys) })) }} trigger={["click"]}>
                            <Button type="primary" >
                                所属区域<DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>)
                }
            </Space>
        </div>
    </Fragment>);
}

function CheckAllComponent({ selected, data, setKeys }: { selected: number[], data: ViewSlot[], setKeys: (d: number[]) => void }) {
    const value = useMemo(() => selected.length === data.length, [selected, data]);
    const indeterminate = useMemo(() => selected.length > 0 && selected.length < data.length, [data, selected]);
    const text = useMemo(() => {
        if (value) return "反选";
        return "全选";
    }, [value]);
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setKeys(data.map(s => s.id));
        }
        else {
            setKeys([]);
        }
    };
    return <Checkbox checked={value} indeterminate={indeterminate} onChange={onCheckAllChange}>{text}</Checkbox>;
}

