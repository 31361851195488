import { getContractSelectItems } from "@/Api/ContractController";
import GoodsApi from "@/Api/GoodsApi";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import SelectImg from "@/Common/select/img";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ContractInvoice } from "@/Models/Tables/ContractInvoice";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

function Item(props: IFormItemProps<ContractInvoice>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<ContractInvoice>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ contract: SelectItem[], goods: SelectItem[] }>({ contract: [], goods: [] });

    useEffect(() => {
        if (data) {
            form.setFieldValue("dateValue", dayjs(data.date));
        }
    }, [data, form]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const contract = await getContractSelectItems();
                const goods = await GoodsApi.getSelectItems();
                setOptions({ contract, goods });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            const date = t1.dateValue.valueOf();
            onSubmit({ ...data, ...t1, date });
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onSubmit]);

    return <MyModal confirmLoading={loading} name={`合同发票`} data={data} width={400} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" labelCol={{ span: 8 }} initialValues={data}>
            <Item name="contractId" label="合同名称" labelAlign="right" required>
                <Select options={options.contract} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Form.Item name="dateValue" label="付款日期" labelAlign="right" required rules={[{ required: true }]} >
                <DatePicker picker="date" />
            </Form.Item>
            <Item name="amount" label="开票金额" labelAlign="right" required >
                <InputNumber precision={4} min={1} style={{ width: '100%' }} />
            </Item>
            <Item label="佐证资料" name="voucher" >
                <SelectImg />
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;