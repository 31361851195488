import { SysUserToken } from "@/Models/Other/SysUserToken";
import { AES, enc } from "crypto-ts";
const aecKey = "ed53b3e624d6448db24e7f3a4d4ae1f6";

function setItem(key: string, obj: {}) {
    localStorage.setItem(key, AES.encrypt(JSON.stringify(obj), aecKey).toString());
}

function getItem(key: string) {
    const str = localStorage.getItem(key);
    if (str && str.length > 0) {
        return JSON.parse(AES.decrypt(str, aecKey).toString(enc.Utf8));
    }
}

const loginInfo = "{a4819fd1-94e3-40ea-8787-07f35bd02884}";

export function getLoginInfo() {
    return getItem(loginInfo);
}

export function setLoginInfo(obj: {}) {
    setItem(loginInfo, obj);
}

export function cleanLoginInfo() {
    localStorage.removeItem(loginInfo);
    cleanSysUserToken();
}

const sysUserTokenName = "{0f24dc75-cac2-4f78-a435-beef3f987c92}";

export function getSysUserToken(): SysUserToken | null {
    return getItem(sysUserTokenName);
}

export function setSysUserToken(obj: SysUserToken) {
    setItem(sysUserTokenName, obj);
}

export function cleanSysUserToken() {
    localStorage.removeItem(sysUserTokenName);
}


