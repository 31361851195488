import { get, post } from '@/Api';
import { CascaderItem } from '@/Models/Other/CascaderItem';
import { BluePrint } from '@/Models/Tables/BluePrint';
import { ViewBluePrint } from '@/Models/Views/ViewBluePrint';

const name = 'BluePrint';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewBluePrint[]> {
    return get(getUrl("getViews"));
}
export function add(data: BluePrint): Promise<ViewBluePrint> {
    return post(getUrl("add"), data);
}
export function upd(data: BluePrint): Promise<ViewBluePrint> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getBluePrintCascaderItems(contractId: number): Promise<CascaderItem[]> {
    return get(getUrl("GetCascaderItems"), { contractId });
}
