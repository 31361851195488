import { resolveEnumOptions } from "./help";


export enum PurchaseRequisitionStatus {
    Pending = 0,// 待定
    Purchasing = 1,// 购买中
    Finished = 2,// 已完成
    Cancel = -1,//已取消
}


export type TypePurchaseRequisitionStatus = keyof typeof PurchaseRequisitionStatus;
const PurchaseRequisitionStatusNames: {
    [K in TypePurchaseRequisitionStatus]: string;
} = {
    Pending: "待定",
    Purchasing: "购买中",
    Finished: "已完成",
    Cancel: "已取消",
};

export function getPurchaseRequisitionStatusNames(e: TypePurchaseRequisitionStatus) {
    return PurchaseRequisitionStatusNames[e];
}

export function getPurchaseRequisitionStatusOptions() {
    return resolveEnumOptions(PurchaseRequisitionStatusNames);
}