import { getSelectItems } from "@/Api/AreaController";
import { updAreaId } from "@/Api/SlotController";
import { MyModal } from "@/Common/Form/MyModal";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getSlotStatusName, SlotStatus } from "@/Models/Enums/SlotStatus";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { Fragment, useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import { SlotOperation } from "../../Stock/StaticSlot/SlotOperation";
import { EditStatus } from "./EditStatus";
import { SlotProvider, SlotProviderContext } from "./SlotProvider";

export default Index;

function getColumns(onShow: (s: ViewSlot) => void): ColumnType<ViewSlot>[] {
    return [
        numProps("id", "ID", 80, true),
        strProps("warehouseName", "库房名称", 160, true, true),
        { title: "所属区域", dataIndex: "areaName", width: 160, sorter: strSorter("areaName"), render: (_, r) => <EditArea record={r} /> },
        strProps("code", "编码", 120, true, true),
        strProps("lanewayName", "巷道", 160, true, true),
        numProps("row", "排", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("column", "列", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("layer", "层", 80, true, s => s.toString().padStart(2, '0'), true),
        numProps("height", "高度", 80, true),
        { title: "状态", dataIndex: "status", width: 100, sorter: strSorter("status"), render: (_, r) => <SlotExport record={r} onShow={onShow} /> },
        numProps("stockCount", "库存数量", 100, true),
        { title: "锁定状态", dataIndex: "lockFlag", width: 120, render: (_, r) => <EditStatus record={r} /> }
    ];
}

function Index() {
    const slotProvider = useCreateHubProvider(SlotProvider);
    const [showData, setShowData] = useState(slotProvider.showStockInfo.Value);
    const [data, setData] = useState<ViewSlot[]>(slotProvider.data.Value);
    const onShow = useCallback((s: ViewSlot) => {
        if (s.status !== "Empty") {
            slotProvider.showStockInfo.Value = s;
        }
    }, [slotProvider]);
    const columns = useMemo(() => getColumns(onShow), [onShow]);
    const [loading, setLoading] = useState(slotProvider.loading.Value);
    const onCancel = useCallback(() => slotProvider.showStockInfo.Value = null, [slotProvider]);
    useEffect(() => {
        const f1 = slotProvider.data.listen(setData);
        const f2 = slotProvider.loading.listen(setLoading);
        const f3 = slotProvider.showStockInfo.listen(setShowData);
        return () => {
            f1();
            f2();
            f3();
        };
    }, [slotProvider]);

    return <SlotProviderContext.Provider value={slotProvider}>
        <CommonTable dataSource={data} loading={loading} rowKey={s => s.id} columns={columns} />
        {showData && <SlotOperation data={showData} onCancel={onCancel} />}
    </SlotProviderContext.Provider>;
}

export function SlotExport({ record, onShow }: { record: ViewSlot, onShow: (s: ViewSlot) => void }) {
    if (record.status === SlotStatus[SlotStatus.HasSomething]) {
        return <TableInnerButton type="link" onClick={() => onShow(record)} >{getSlotStatusName(record.status)}</TableInnerButton>;
    }
    return <div>{getSlotStatusName(record.status)}</div>;
}

export function EditArea({ record }: { record: ViewSlot }) {
    const [edit, setEdit] = useState<ViewSlot>();
    const onEdit = useCallback(() => setEdit(record), [record]);
    const onCancel = useCallback(() => setEdit(undefined), []);
    return (<Fragment>
        <TableInnerButton type="link" onClick={onEdit} >{record.areaId ? record.areaName : "指派区域"}</TableInnerButton>
        {edit && <ChooseArea record={edit} onCancel={onCancel} />}
    </Fragment>);
}


function ChooseArea({ record, onCancel }: { record: ViewSlot, onCancel: () => void }) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        setLoading(true);
        getSelectItems().then(setOptions).catch(console.error).finally(() => setLoading(false));
    }, []);
    const [form] = useForm();
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const data = await form.validateFields();
            const res = await updAreaId(record.id, data.areaId);
            if (res > 0) {
                onCancel();
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onCancel, record]);

    return <MyModal name="区域" confirmLoading={loading} width={320} onCancel={onCancel} onOk={onOk}>
        <Form initialValues={record} form={form} >
            <Form.Item name="areaId" label="所属区域">
                <Select options={options} loading={loading} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
        </Form>
    </MyModal>;
}