import { get } from '@/Api';
import { ViewExitPortGoodsType } from "@/Models/Views/ViewExitPortGoodsType";

const name = 'ExitPortGoodsType';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getExitPortGoodsTypeViewsByGoodsTypeId(goodsTypeId: number): Promise<ViewExitPortGoodsType[]> {
    return get(getUrl("GetExitPortGoodsTypeViewsByGoodsTypeId"), { GoodsTypeId: goodsTypeId });
}
export function setDisabled(exitPortId: number, goodsTypeId: number, disabled: boolean): Promise<number> {
    return get(getUrl("SetDisabled"), { exitPortId, goodsTypeId, disabled });
}
