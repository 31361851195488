import { updStockCode } from "@/Api/TemporaryStockController";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ViewTemporaryStock } from "@/Models/Views/ViewTemporaryStock";
import { Form, Input, Modal, notification } from "antd";
import { Fragment, useState } from "react";


export function StockCodeColumnItem({ record }: { record: ViewTemporaryStock; }) {
    const [show, setShow] = useState(false);
    const [stockCode, setStockCode] = useState(record.stockCode);

    return (<Fragment>
        <TableInnerButton loading={show} disabled={show} type="link" onClick={() => setShow(true)}>{stockCode}</TableInnerButton>
        {show && <EditQuantityModal record={record} onCancel={(flag?: boolean) => {
            if (flag) {
                setStockCode(record.stockCode);
            }
            setShow(false);
        }} />}
    </Fragment>);
}


export function EditQuantityModal({ record, onCancel }: { record: ViewTemporaryStock, onCancel: (flag?: boolean) => void }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    return <Modal confirmLoading={loading} open title={record.stockCode} width={320} onOk={async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            const updCount = await updStockCode(record.stockCode, values.stockCode);
            notification.success({ message: `修改成功,影响数据${updCount}行!` });
            record.stockCode = values.stockCode;
            onCancel(true);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }} onCancel={() => onCancel()} >
        <Form form={form}>
            <Form.Item label="托盘条码" name="stockCode" rules={[{
                validator(rule, value, callback) {
                    if (/^([0-9]{6})|(^[A-Z][0-9]{1,2})$/.test(value)) {
                        if (value === record.stockCode) {
                            callback("托盘条码未修改!");
                        }
                        callback();
                    }
                    callback("请输入正确的托盘条码或平库储位编码!");
                }
            }]} >
                <Input />
            </Form.Item>
        </Form>
    </Modal>
}