import { add } from "@/Api/ExportOrderController";
import { getCanExportViews } from "@/Api/StockSlotController";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { toYMD } from "@/Common/date";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { ViewExportNotify } from "@/Models/Views/ViewExportNotify";
import { Form, Modal, Space, Table, Tag, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditableCell } from "./EditableCell";
import { EditableContext, EditableProvider } from "./EditableProvider";
import { ViewStockSlotExtItems, groupByStockSlot } from "./ViewStockSlotExtItems";

function getColumns(): ColumnType<ViewStockSlotExtItems>[] {
    return [
        strProps("warehouseName", "库房", 100, true, true),
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("goodsTypeName", "物品类型", 120, true, true),
        strProps("goodsCode", "物料编码", 120, true, true),
        strProps("goodsName", "物料名称", 360, true, true),
        strProps("standard", "规格", 220, true, true), strProps("supplierName", "供应商", 120, true, true),   strProps("customerName", "客户", 120, true, true),     
        numProps("quantity", "数量", 80, true),
        {
            ...numProps("pickQuantity", "挑选数量", 100, true),
            onCell: (record, index) => ({ record, index, dataIndex: "pickQuantity" }) as any,
            ellipsis: false,
        },
        strProps("stockCode", "托盘条码", 110, true, true),
        strProps("slotCode", "储位编码", 110, true, true),
        strProps("batchNo", "批次", 120, true, true),
        numProps("productionDate", "生产日期", 120, true, toYMD, true),
        strProps("goodsStatusName", "品质状态", 120, true, true),
        strProps("flag", "库存状态", 120, true, true, getStockFlagName),
        strProps("remark", "备注", 200, true, true),
    ];
}

export interface GroupViewExportNotify extends ViewExportNotify {
    key: string;
    items: ViewExportNotify[];
}

interface PickStockSlotFormProps {
    record: ViewExportNotify;
    onCancel: () => void;
}

export function PickStockSlotForm({ record, onCancel }: PickStockSlotFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewStockSlotExtItems[]>([]);
    const [quantity, setQuantity] = useState(0);
    const provider = useMemo(() => new EditableProvider(record, form, data, setQuantity), [record, form, data]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const stockSlotList = await getCanExportViews(record.id);
                const group = groupByStockSlot(stockSlotList);
                setData(group);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [record]);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            await provider.onEdit(null);
            // 分配库存
            const items = [];
            // 将挑选的库存分配到record的items中
            for (const item of provider.data.filter(a => a.pickQuantity > 0)) {
                // 将挑选数量分配给各个库存
                let pickQuantity = item.pickQuantity;
                for (const stock of item.items) {
                    const quantity = Math.min(pickQuantity, stock.quantity);
                    if (quantity > 0) {
                        items.push({ stockId: stock.id, quantity });
                        pickQuantity -= quantity;
                    }
                }
            }

            // 获取出库单列表,集单传入时会嵌套,需要处理
            let recordItems = [record];
            // 将库存分配给各个出库单明细
            const orderList = [];
            for (const item of recordItems) {
                let notifyQuantity = item.quantity;
                for (const stock of items) {
                    // 循环生成出库流水 
                    while (stock.quantity > 0 && notifyQuantity > 0) {
                        const allotQuantity = Math.min(notifyQuantity, stock.quantity);
                        orderList.push({ notifyId: item.id, stockSlotId: stock.stockId, quantity: allotQuantity });
                        notifyQuantity -= allotQuantity;
                        stock.quantity -= allotQuantity;
                    }
                }
            }
            const res = await add(orderList as any)
            if (res > 0) {
                message.info("分配成功");
            }
            else {
                message.error("分配失败");
            }
            onCancel();
        } catch (error: any) {
            if (error?.message) {
                message.error(JSON.stringify(error.message));
            }
        }
        finally {
            setLoading(false);
        }
    }, [onCancel, provider, record]);

    return <Modal open confirmLoading={loading} onCancel={onCancel} width={1600} onOk={onOk} title={<Space>
        {`分配[${record.goodsName}]库存`}
        <Tag >
            计划数量{record.quantity}
        </Tag>
        <Tag>
            已分配数量{record.factQuantity}
        </Tag>
        <Tag>
            待分配数量{provider.needQuantity}
        </Tag>
        <Tag>
            本次选中数量{quantity}
        </Tag>
    </Space>} >
        <EditableContext.Provider value={provider}>
            <Form form={form} component={false}>
                <Table
                    loading={loading}
                    bordered
                    rowKey={s => s.stockCode}
                    size="small"
                    scroll={{ y: 240 }}
                    dataSource={data}
                    columns={columns}
                    components={{ body: { cell: EditableCell } }}
                />
            </Form>
        </EditableContext.Provider>
    </Modal>;
}


