import { Table,Button,message} from "antd";
import { TableProps } from "antd/lib/table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AddProps } from "../Form/MyModal";
import { ContentSizeContext } from "../context";
import getTableAction, { useTableEditState } from "./getTableAction";
import './index.scss';
import { ColumnType } from "./type";
import GoodsApi from "@/Api/GoodsApi";
const defaultPagination = { pageSize: 14, showSizeChanger: false };

interface IProps<T, T1 extends T = T> {
    baseColumns: ColumnType<T1>[];
    getData: () => Promise<T1[]>;
    addDataHandle: (data: T) => Promise<T1>;
    updDataHandle: (data: T) => Promise<T1>;
    delDataHandle: (data: T) => Promise<number>;
    updMerge: (source: T1, target: T) => T;
    isRank: boolean;
    editCheck?: (d: T1) => boolean;
    AddDom: (props: AddProps<T>) => JSX.Element
    heightOffset?: number;
    rowKey: keyof T
}

export function sumWidth(arr: { width?: number }[], def: number = 120): number {
    let num = 0;
    arr.forEach(s => num += (s.width || def));
    return num;
}

export function CommonTable<RecordType extends object = any>(props: Omit<TableProps<RecordType>, "columns"> & {
    columns: ColumnType<RecordType>[],
    widthOffset?: number,
    heightOffset?: number,
    rowKey: NonNullable<TableProps<RecordType>["rowKey"]>,
}) {
    const [, contentWidth] = useContext(ContentSizeContext);
    const tableWidth = useMemo(() => {
        if (props.columns) {
            const width = sumWidth(props.columns) + 20 + (props.widthOffset ?? 0);//有纵轴滚动条所以要补偿20px
            if (width > contentWidth) return contentWidth;
            else return width;
        }
    }, [contentWidth, props.columns, props.widthOffset]);

    const pagination = useMemo(() => {
        const pageSizeOptions = ["10", "20", "50", "100", "200", "400", "800"];
        if (props.pagination) {
            if (!props.pagination.pageSizeOptions) {
                props.pagination.pageSizeOptions = pageSizeOptions;
            }
            props.pagination.responsive = true;
            return props.pagination;
        }
        else {
            return { showSizeChanger: false, pageSize: 13, pageSizeOptions, responsive: true };
        }
    }, [props.pagination]);

    return <Table
        scroll={{ x: tableWidth, y: (705 - (props.heightOffset ?? 0)) }}
        {...props}
        size="middle"
        bordered
        pagination={pagination}
    />;
}

function EditTableCheck<T extends {}, T1 extends T = T>({ getData,
    updMerge,
    updDataHandle: updData,
    addDataHandle: addData,
    delDataHandle,
    baseColumns,
    isRank,
    editCheck,
    AddDom,
    rowKey
}: IProps<T, T1>) {

    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<T1[]>([]);
    const [edit, setEdit] = useTableEditState<T1>();
    const [ids, setIds] = useState<string>('');
    useEffect(() => {
        setLoading(true);
        getData().then(s => setData(s))
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [getData]);

    const onSubmit = useCallback((s: any) => {
        if (edit) {
            if (edit.data) {
                const dt = updMerge(edit.data, s);
                updData(dt)
                    .then(s => {
                        s && setData(ds => {
                            const index = ds.findIndex(a => a === edit.data);
                            if (index > -1) ds[index] = s;
                            setEdit(null);
                            return [...ds];
                        });
                    });
            }
            else {
                addData(s).then(s => {
                    if (s) {
                        setEdit(null);
                        setData(ds => [...ds, s]);
                    }
                });
            }
        }
    }, [edit, updMerge, updData, setEdit, addData]);

    const onCancel = useCallback(() => setEdit(null), [setEdit]);

    const delData = useCallback((s: T1, callBack: () => void) => {
        delDataHandle(s).then(a => {
            if (a > 0) {
                setData(ds => {
                    const index = ds.findIndex(a => a === s);
                    if (index >= 0) ds.splice(index, 1);
                    return [...ds];
                });
            }
        }).finally(callBack);
    }, [delDataHandle]);

    const { columns, width } = useMemo(() => {
        const width = sumWidth(baseColumns) + 20;//有纵轴滚动条所以要补偿20px
        if (isRank) {
            const action = getTableAction({ setEdit, delData, editCheck });
            return { columns: [...baseColumns, action], width: width + 120 };
        }
        else return { columns: baseColumns, width };
    }, [baseColumns, isRank, setEdit, delData, editCheck]);

    const [, contentWidth] = useContext(ContentSizeContext);

    const tableWidth = useMemo(() => {
        if (width > contentWidth) return contentWidth;
        else return width;
    }, [contentWidth, width]);
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: T1[]) => {
            setIds(selectedRowKeys.toString());
            //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: T1) => {
            // console.log("getCheckboxProps", record)
            var tmp = record as any;
            return {
                disabled: tmp.isCheck === false
            }
        }
    };
    const onCkeck = useCallback(() => {
        if (!ids) {
            message.error("请选择数据!");
            return;
        }
        GoodsApi.UpFlag(ids).then(s => {
            if (s) {
                setLoading(true);
                getData().then(s => setData(s))
                    .catch(e => console.error(e))
                    .finally(() => setLoading(false));
                message.success('成功修改:'+s+'条数据！');
            }else{
                message.error('操作失败！');
            }
        });
        
      
    }, [ids]);
    const [selectionType] = useState<'checkbox' | 'radio'>('checkbox');
    if (isRank) {
        return <>
            <Table size="middle"
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                bordered loading={loading}
                dataSource={data}
                columns={columns}
                scroll={{ x: tableWidth }}
                pagination={defaultPagination}
                rowKey={s => s[rowKey] as any} ></Table>
            {edit && <AddDom data={edit.data} onCancel={onCancel} onSubmit={onSubmit} />}
            <div style={{ marginTop: -45 }}>
                <Button type="primary" onClick={onCkeck}>
                    同步
                </Button>
            </div>
        </>;
    }
    else {
        return <Table size="middle"
            bordered
            loading={loading}
            dataSource={data}
            columns={columns}
            scroll={{ x: tableWidth }}
            pagination={defaultPagination} ></Table>;
    }
}

export default EditTableCheck;