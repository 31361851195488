import { resolveEnumOptions } from "./help";


export enum ContractProductionItemStatus {
    Pending = 0,//待定
    Purchasing = 1,// 采购中
    Manufacturing = 2, // 加工中
    Delivery = 3,// 待发货
    Approved = 4,// 已送达
    Refund = 5,// 已退货
}


export type TypeContractProductionItemStatus = keyof typeof ContractProductionItemStatus;
const ContractProductionItemStatusNames: {
    [K in TypeContractProductionItemStatus]: string;
} = {
    Pending: "待定",
    Purchasing: "采购中",
    Manufacturing: "生产中",
    Delivery: "待发货",
    Approved: "已送达",
    Refund: "已退货",
};

export function getContractProductionItemStatusNames(e: TypeContractProductionItemStatus) {
    return ContractProductionItemStatusNames[e];
}
export function getContractProductionItemStatusOptions() {
    return resolveEnumOptions(ContractProductionItemStatusNames);
}
