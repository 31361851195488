export enum SlotLockFlag {
    Free = 0,
    ImportLock = 1,
    ExportLock = 2,
    Disabled = 3,
}

export type TypeSlotLockFlag = keyof typeof SlotLockFlag;

export const slotLockFlagNames: { [K in TypeSlotLockFlag]: string } = {
    "Free": "正常",
    "ImportLock": "入库锁定",
    "ExportLock": "出库锁定",
    "Disabled": "储位禁用",
};

export function getSlotLockFlagName(e: TypeSlotLockFlag) {
    return slotLockFlagNames[e];
}
