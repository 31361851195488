import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { ExportNotify } from '@/Models/Tables/Notify';
import { ViewExportNotify } from '@/Models/Views/ViewExportNotify';
import { ViewExportNotifyExtPlatform } from "@/Models/Views/ViewExportNotifyExtPlatform";

namespace ExportNotifyApi {
    const name = 'ExportNotify';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }

    export function getViews(billId: number): Promise<ViewExportNotify[]> {
        return get(getUrl("getViews"), { billId });
    }

    export function getViewExportNotifyExtPlatforms(platformId: number): Promise<ViewExportNotifyExtPlatform[]> {
        return get(getUrl("GetViewExportNotifyExtPlatforms"), { platformId });
    }

    export function updNotifyIndex(data: ExportNotify[]): Promise<number> {
        return post(getUrl("UpdNotifyIndex"), data);
    }

    export function updBatchNo(id: number, batchNo: string | null): Promise<number> {
        return get(getUrl("UpdBatchNo"), { id, batchNo });
    }

    export function getSelectItems(billId: number): Promise<SelectItem[]> {
        return get(getUrl("GetSelectItems"), { billId });
    }
}

export default ExportNotifyApi;