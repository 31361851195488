import { DayAnalysis, MonthImportAndExportCount, SlotRatioUse } from "@/Models/Other/Home";
import { StackerInfo } from "@/Models/Other/StackerInfo";
import { BaseHub, tpOnError } from "./BaseHub";
import { IHomeHubAction } from "./IActions/IHomeHubAction";


export class HomeHub extends BaseHub<IHomeHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/Home");
    }
    public getStackerInfo = () => this.hub.invoke<StackerInfo[]>("GetStackerInfo");
    public getDayAnalysis = () => this.hub.invoke<DayAnalysis>("GetDayAnalysis");
    public getMonthImportAndExportCount = () => this.hub.invoke<MonthImportAndExportCount[]>("GetMonthImportAndExportCount");
    public getSlotRatioUse = () => this.hub.invoke<SlotRatioUse>("GetSlotRatioUse");
}
