import { get, post } from '@/Api';
import { CascaderItem } from '@/Models/Other/CascaderItem';
import { ContractProductionItem } from '@/Models/Tables/ContractProductionItem';
import { ViewContractProductionItem } from '@/Models/Views/ViewContractProductionItem';

const name = 'ContractProductionItem';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewContractProductionItem[]> {
    return get(getUrl("getViews"));
}
export function add(data: ContractProductionItem): Promise<ViewContractProductionItem> {
    return post(getUrl("add"), data);
}
export function upd(data: ContractProductionItem): Promise<ViewContractProductionItem> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getProductionItemCascaderItems(): Promise<CascaderItem[]> {
    return get(getUrl("GetCascaderItems"));
}
