import ExportBillApi from "@/Api/ExportBillApi";
import ExportNotifyApi from "@/Api/ExportNotifyApi";
import { TableActions } from "@/Common/Table/TableActions";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect, useState } from "react";

export function SearchComponent({ onSearch }: { onSearch: (notifyId: number) => void; }) {
    const [loading, setLoading] = useState(false);
    const [exportBillId, setExportBillId] = useState<number>();
    const [notifyList, setNotifyList] = useState<SelectItem[]>([]);
    const [billOptions, setBillOptions] = useState<SelectItem[]>([]);
    const [form] = useForm();

    useEffect(() => {
        (async () => {
            if (exportBillId) {
                setLoading(true);
                try {
                    const d1 = await ExportNotifyApi.getViews(exportBillId);
                    setNotifyList(d1.map(s => ({
                        label: (<div>
                            <div>{s.goodsName}</div>
                            <div>{s.standard}</div>
                        </div>), value: s.id, data: s
                    })) as any);
                    form.resetFields();
                } catch (error) {
                    console.error(error);
                }
                finally {
                    setLoading(false);
                }
            }
        })();
    }, [exportBillId, form]);

    const onQuery = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            onSearch(d1["notifyId"]);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onSearch]);

    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            onSearch(s["notifyId"]);
        }}>
            <Form.Item label="出库单">
                <Select onDropdownVisibleChange={billOptions.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        ExportBillApi.getUnFinishedList().then(s => setBillOptions(s.map(a => ({ label: a.code, value: a.id })))).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={billOptions} optionFilterProp="label" showSearch onChange={setExportBillId as any} />
            </Form.Item>
            <Form.Item label="物品信息" name="notifyId" required rules={[{ required: true }]}>
                <Select style={{ minWidth: 200 }} loading={loading} options={notifyList} filterOption={(input, opt: any) => {
                    return opt.data.goodsName?.toLowerCase().includes(input.toLowerCase())
                        || opt.data.standard?.toLowerCase().includes(input.toLowerCase());
                }} showSearch />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" onClick={onQuery}>查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}
