import { add, del, getViews, upd } from "@/Api/PurchaseBillController";
import { toYMD, toYMDHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { getPurchaseBillStatusName } from "@/Models/Enums/PurchaseBillStatus";
import { PurchaseBill } from "@/Models/Tables/PurchaseBill";
import { ViewPurchaseBill } from "@/Models/Views/ViewPurchaseBill";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewPurchaseBill>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("code", "编码", 120, true, true),
        strProps("supplierName", "供应商", 120, true, true),
        numProps("quantity", "数量", 100, true),
        numProps("deliveryDate", "纳期", 120, true, toYMD),
        strProps("status", "状态", 120, true, true, getPurchaseBillStatusName),
        numProps("time", "创建时间", 160, true, toYMDHMS),
        strProps("userName", "操作员", 80, true, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< PurchaseBill, ViewPurchaseBill> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewPurchaseBill, target: PurchaseBill) {
    target.id = source.id;
    return target;
}