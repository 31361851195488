import { getLowAlarms } from "@/Api/StockSlotController";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { ViewHighLowAlarm } from "@/Models/Views/ViewHighLowAlarm";
import React, { Fragment, useEffect } from "react";
import { useMemo, useState } from "react";

export default Index;

function overQuantitySort(a: ViewHighLowAlarm, b: ViewHighLowAlarm) {
    const a1 = a.stockLow - a.stockQuantity;
    const b1 = b.stockLow - b.stockQuantity;
    return a1 - b1;
}

function OverQuantityComponent({ record }: { record: ViewHighLowAlarm }) {
    const quantity = useMemo(() => record.stockLow - record.stockQuantity, [record]);
    if (quantity > 0) {
        return <div>{quantity}</div>;
    }
    else {
        return <></>;
    }

}

function getColumns(): ColumnType<ViewHighLowAlarm>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("name", "物料名称", 160, true, true),
        strProps("code", "物料编码", 120, true, true),
        strProps("standard", "规格", 120, true, true),
        strProps("unit", "单位", 80, true, false),
        numProps("stockQuantity", "库存数量", 120, true),
        numProps("stockHigh", "最高库存", 120, true),
        numProps("stockLow", "最低库存", 120, true),
        { title: "低出数量", key: "overQuantity", width: 100, sorter: overQuantitySort, render: (_, r) => <OverQuantityComponent record={r} /> },
        strProps("remark", "备注", 200, true, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewHighLowAlarm[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getLowAlarms().then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    return <Fragment>
        <CommonTable rowKey={s => s.id} loading={loading} columns={columns} dataSource={data} />
    </Fragment>;
}


