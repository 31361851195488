import { toYMD } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { useMemo } from "react";
import Add from "./Add";
import { BluePrint } from "@/Models/Tables/BluePrint";
import { add, del, getViews, upd } from "@/Api/BluePrintController";
import { ViewBluePrint } from "@/Models/Views/ViewBluePrint";
import { getBluePrintTypeNames } from "@/Models/Enums/BluePrintType";
import { renderCopyable } from "@/Common/Table/renderCopyable";

function getColumns(): ColumnType<ViewBluePrint>[] {
    return [
        numProps("id", "Id", 60, true),
        // strProps("contractCode", "合同编号", 160, true, true),
        strProps("contractName", "合同名称", 160, true, true),
        strProps("productionName", "产品名称", 120, true, true),
        strProps("code", "图纸编号", 160, true, true),
        strProps("name", "图纸名称", 120, true, true),
        strProps("departmentName", "责任部门", 120, true, true),
        strProps("userName", "责任人", 100, true, true),
        numProps("date", "开始日期", 120, true, toYMD),
        numProps("finishDate", "完成日期", 120, true, toYMD),
        numProps("progress", "进度", 80, true, s => `${s}%`),
        strProps("type", "类别", 80, true, true, getBluePrintTypeNames),
        numProps("time", "添加时间", 160, true),
        strProps("remark", "备注", 120, true, true, renderCopyable),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< BluePrint, ViewBluePrint > getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewBluePrint, target: BluePrint) {
    target.id = source.id;
    return target;
}