import { get, post } from '@/Api';
import { SysRight } from '@/Models/Tables/SysRight';

const name = 'SysRight';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getListById(id: number): Promise<SysRight[]> {
    return get(getUrl("getListById"), { id });
}
export function upd(data: SysRight[]): Promise<number> {
    return post(getUrl("upd"), data);
}
