import { get, post } from '@/Api';
import { ContractPayRecord } from "@/Models/Tables/ContractPayRecord";
import { ViewContractPayRecord } from '@/Models/Views/ViewContractPayRecord';

const name = 'ContractPayRecord';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewContractPayRecord[]> {
    return get(getUrl("getViews"));
}
export function add(data: ContractPayRecord): Promise<ViewContractPayRecord> {
    return post(getUrl("add"), data);
}
export function upd(data: ContractPayRecord): Promise<ViewContractPayRecord> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
