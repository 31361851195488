import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { GoodsType } from '@/Models/Tables/GoodsType';

const name = 'GoodsType';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<GoodsType[]> {
    return get(getUrl("getViews"));
}
export function add(data: GoodsType): Promise<GoodsType> {
    return post(getUrl("add"), data);
}
export function upd(data: GoodsType): Promise<GoodsType> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("getSelectItems"));
}