import Goods from './Goods';
import Customer from './Customer';
import Supplier from './Supplier';
import GoodsStatus from './GoodsStatus';
import BillType from './BillType';
import GoodsType from './GoodsType';
import {
    DatabaseOutlined,
    GiftOutlined,
    CustomerServiceOutlined,
    TeamOutlined,
    TrophyOutlined,
    MediumWorkmarkOutlined,
    PayCircleOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
import Platform from './Platform';
import Department from './Department';

function baseGroup(): IMenuGroup {
    return {
        name: "基础信息",
        path: "base",
        icon: DatabaseOutlined,
        items: [
            {
                name: "物品信息",
                icon: GiftOutlined,
                element: <Goods />,
                path: "Goods",
                right: 1,
            },
            {
                name: "客户信息",
                icon: CustomerServiceOutlined,
                element: <Customer />,
                path: "Customer",
                right: 1 << 1,
            },
            {
                name: "供应商信息",
                icon: TeamOutlined,
                element: <Supplier />,
                path: "Supplier",
                right: 1 << 2,
            },
            {
                name: "物品状态",
                icon: TeamOutlined,
                element: <GoodsStatus />,
                path: "GoodsStatus",
                right: 1 << 3,
            },
            {
                name: "单据类别",
                icon: TrophyOutlined,
                element: <BillType />,
                path: "BillType",
                right: 1 << 4,
            },
            {
                name: "物料类别",
                icon: MediumWorkmarkOutlined,
                element: <GoodsType />,
                path: "GoodsType",
                right: 1 << 5,
            },
            {
                name: "月台信息",
                icon: PayCircleOutlined,
                element: <Platform />,
                path: "Platform",
                right: 1 << 6,
            },
            {
                name: "部门管理",
                icon: PayCircleOutlined,
                element: <Department />,
                path: "Department",
                right: 1 << 7,
            },
        ]
    }
}
export default baseGroup;