import { Fragment, useContext, useEffect, useState } from 'react';
import { StackerProviderContext } from './StackerProvider';
import { Stacker } from './Stacker';

export function Stackers({ plcNo, warehouseId }: { plcNo: number, warehouseId: number }) {
    const provider = useContext(StackerProviderContext);
    const [stackers, setStackers] = useState(Object.values(provider.stacker.Value).filter(s => s.Value.plcNo === plcNo && s.Value.warehouseId === warehouseId));
    useEffect(() => {
        return provider.stacker.listen(s => setStackers(Object.values(s).filter(s => s.Value.plcNo === plcNo && s.Value.warehouseId === warehouseId)));
    }, [provider.stacker, plcNo, warehouseId]);
    return <StackerProviderContext.Provider value={provider}>
        <Fragment>
            {stackers.map((s, k) => <Stacker key={`stacker-${k}`} data={s} />)}
        </Fragment>
    </StackerProviderContext.Provider>;
}


