import { PrintComponent, PrintNameFooter } from "@/Common/Print/PrintComponent";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { GlobalProviderContext } from "@/App/GlobalProvider";
import { useCallback, useContext, useEffect } from "react";
import { useMemo, useState } from "react";
import { exportToExcel } from "../Print/exportToExcel";

export function PrintFooter<T extends { id: number; }>({ data, title, columns }: { data: T[]; title: string; columns: ColumnType<T>[]; }) {
    const [show, setShow] = useState(false);
    const onCancel = useCallback(() => setShow(false), []);
    const provider = useContext(GlobalProviderContext);
    const [user, setUser] = useState(provider.user.Value);
    useEffect(() => provider.user.listen(setUser), [provider]);

    const printColumns = useMemo(() => columns.map(obj => {
        const s = { ...obj };
        s.sorter = false;
        s.filterIcon = undefined;
        s.filterDropdown = undefined;
        s.onFilter = undefined;
        return s;
    }).filter(s => s.title !== "打印"), [columns]);
    if (data.length === 0)
        return <></>;
    else {
        if (show) {
            return <PrintComponent getData={() => Promise.resolve(data)}
                onCancel={onCancel}
                title={title}
                columns={printColumns}
                footer={<PrintNameFooter userName={user?.name ?? ''} />}
                header={title} />;
        }
        else {
            return <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 16 }}>
                    <TableInnerButton type="link" onClick={() => setShow(true)}>打印本页</TableInnerButton>
                    <TableInnerButton type="link" onClick={() => exportToExcel(data, columns as any, title)}>导出Excel</TableInnerButton>
                </div>
            </div>;
        }
    }
}