import { getUpkeepRecords, getUpkeepRecordsProps } from "@/Api/EquipmentController";
import { toYMDHMS } from "@/Common/date";
import { CommonTable } from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { EquipmentType } from "@/Models/Enums/EquipmentType";
import { ViewUpkeepRecord } from "@/Models/Views/ViewUpkeepRecord";
import { Modal } from "antd";
import { sum } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { EquipmentTypeContext } from "../UpkeepConfig/common";

function getColumns(type: EquipmentType): ColumnType<ViewUpkeepRecord>[] {
    if (type === EquipmentType.Conveyer) {
        return [
            { title: "保养项目", dataIndex: "configName", width: 160, ellipsis: true },
            { title: "运行时间", dataIndex: "runtime1", width: 160, ellipsis: true },
            { title: "保养时间", dataIndex: "time", width: 160, ellipsis: true, render: toYMDHMS },
            { title: "备注", dataIndex: "remark", ellipsis: true },
            { title: "保养人", dataIndex: "userName", width: 100, ellipsis: true },
        ];
    }
    else {
        return [
            { title: "保养项目", dataIndex: "configName", width: 160, ellipsis: true },
            { title: "运行时间", dataIndex: "runtime1", width: 120, ellipsis: true },
            { title: "行走时间", dataIndex: "runtime2", width: 120, ellipsis: true },
            { title: "单叉时间", dataIndex: "runtime3", width: 120, ellipsis: true },
            { title: "双叉时间", dataIndex: "runtime4", width: 120, ellipsis: true },
            { title: "运行里程", dataIndex: "mileage1", width: 120, ellipsis: true },
            { title: "行走里程", dataIndex: "mileage2", width: 120, ellipsis: true },
            { title: "单叉里程", dataIndex: "mileage3", width: 120, ellipsis: true },
            { title: "双叉里程", dataIndex: "mileage4", width: 120, ellipsis: true },
            { title: "保养时间", dataIndex: "time", width: 160, ellipsis: true, render: toYMDHMS },
            { title: "备注", dataIndex: "remark", ellipsis: true, width: 160 },
            { title: "保养人", dataIndex: "userName", width: 100, ellipsis: true },
        ];
    }
}

function RecordModal({ onCancel, data }: { onCancel: () => void, data: getUpkeepRecordsProps }) {
    const type = useContext(EquipmentTypeContext);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<ViewUpkeepRecord[]>([]);
    const columns = useMemo(() => getColumns(type), [type]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getUpkeepRecords(data);
                setRecords(d1);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [data]);
    const width = useMemo(() => sum(columns.map(s => s.width ?? 120)) + 100, [columns]);
    return <Modal confirmLoading={loading} title="设备保养" open width={width} onCancel={onCancel} footer={false}>
        <CommonTable rowKey={s => s.id} dataSource={records} size="middle"
            loading={loading}
            pagination={{ pageSize: 10 }}
            columns={columns} />
    </Modal>;
}

export default RecordModal;
