import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { ConveyerHub } from "@/Hubs/ConveyerHub";
import { ConveyerInfo } from "@/Models/Other/ConveyerInfo";
import { createContext } from "react";

export class ConveyerProvider {
    public readonly hub: ConveyerHub;
    constructor(onError: tpOnError) {
        this.hub = new ConveyerHub(onError);
        this.hub.on("InfoChange", s => {
            const key = `${s.plcNo}-${s.stationNo}`;
            if (this.conveyer.Value[key]) {
                this.conveyer.Value[key].Value = s;
                this.conveyer.Value[key].resetValue();
            }
            else {
                this.conveyer.Value[key] = new Obs(s);
                this.conveyer.resetValue();
            }
            this.conveyer.resetValue();
        });
        this.hub.start(async () => {
            try {
                const data = await this.hub.getInfos();
                this.conveyer.Value = {};
                data.forEach(s => {
                    const key = `${s.plcNo}-${s.stationNo}`;
                    this.conveyer.Value[key] = new Obs(s);
                });
                this.conveyer.resetValue();
            } catch (error) {
                console.log(error);
            }
        });
    }
    public readonly conveyer = new Obs<{ [K: string]: Obs<ConveyerInfo> }>({});
}

export const ConveyerProviderContext = createContext<ConveyerProvider>(null as any);
