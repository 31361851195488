import { ViewExportOrder } from "@/Models/Views/ViewExportOrder";
import { BaseHub, tpOnError } from "./BaseHub";
import { IExportOrderHubAction } from "./IActions/IExportOrderHubAction";

export class ExportOrderHub extends BaseHub<IExportOrderHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/ExportOrder");
    }
    public getUnFinishedList = () => this.hub.invoke<ViewExportOrder[]>("GetUnFinishedList");
}
