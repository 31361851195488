import BillTypeApi from "@/Api/BillTypeApi";
import CustomerApi from "@/Api/CustomerApi";
import ExportBillApi from "@/Api/ExportBillApi";
import GoodsApi from "@/Api/GoodsApi";
import { IFormItemProps, convertProps } from "@/Common/Form/MyModal";
import { utcStartDay } from "@/Common/date";
import { initOptionsItem } from "@/Common/initOptions";
import { BillTypeCategory } from "@/Models/Enums/BillTypeCategory";
import { ExportBill } from "@/Models/Tables/Bill";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Table, message } from "antd";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";

function Item(props: IFormItemProps<ExportBill>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

interface AddFormProps {
    onCancel: () => void;
}

export function AddForm({ onCancel }: AddFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [options, setOptions] = useState({
        billType: initOptionsItem(),
        goods: initOptionsItem(),
        customer: initOptionsItem(),
    });
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const [billType, goods, customer] = await Promise.all([
                    BillTypeApi.getSelectItems(BillTypeCategory.Export),
                    GoodsApi.getSelectItems(),
                    CustomerApi.getSelectItems(),
                ]);
                setOptions({
                    billType, goods: goods.map(s => ({
                        label: (<div>
                            <div>{s.label}</div>
                            <div>{s.standard}</div>
                        </div>), value: s.value, data: s
                    })) as any, customer
                });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [form]);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            d1.platformId = 1;
            if ((!d1.notifies) || d1.notifies.length === 0) {
                message.error("明细行不能为空!");
                return;
            }
            d1.planDate = utcStartDay(d1.planDate);
            const uniqueItems = new Set();
            for (const item of d1.notifies) {
                const identifier = `${item.goodsId}-${item.goodsStatusId}}`;
                if (uniqueItems.has(identifier)) {
                    message.error("不能添加重复的明细行!");
                    return;
                }
                uniqueItems.add(identifier);
            }
            const res = await ExportBillApi.add(d1);
            console.log(res);
            onCancel();
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onCancel])

    return <Modal title="添加出库单" width={1260} open onCancel={onCancel} confirmLoading={loading} onOk={onOk} >
        <Form form={form} layout="vertical">
            <Row gutter={24}>
                <Col span={8}>
                    <Item name="planDate" label="计划日期" labelAlign="right" required initialValue={dayjs()} >
                        <DatePicker picker="date" style={{ width: '100%' }} clearIcon={false} />
                    </Item>
                </Col>
                <Col span={8}>
                    <Item label="单据类型" name="typeId" required >
                        <Select optionFilterProp="label" options={options.billType} showSearch />
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="客户" name="customerId"  >
                        <Select optionFilterProp="label" options={options.customer} showSearch />
                    </Item>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Form.List name="notifies">
                {(fields, { add, remove }) => (
                    <Table
                        pagination={false}
                        bordered
                        rowKey="key"
                        size="small"
                        scroll={{ y: 240 }}
                        dataSource={fields.map(field => form.getFieldValue(["notifies", field.name]))}
                        columns={[
                            {
                                title: "物品名称", dataIndex: "goodsId", width: 260, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'goodsId']}
                                    rules={[{ required: true, message: '请选择商品' }]}
                                >
                                    <Select filterOption={(input, opt: any) => {
                                        return opt.data.label.toLowerCase().includes(input.toLowerCase())
                                            || opt.data.standard?.toLowerCase().includes(input.toLowerCase());
                                    }} showSearch placeholder="请选择商品" options={options.goods} onChange={(s, a) => {
                                        form.setFieldValue(["notifies", index, "unit"], a?.data.unit);
                                        form.setFieldValue(["notifies", index, "stockQuantity"], a?.data.stockQuantity);
                                        form.setFieldValue(["notifies", index, "standard"], a?.data.standard);
                                    }} />
                                </Form.Item>
                            },
                            {
                                title: "规格", dataIndex: "standard", width: 120, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'standard']}
                                >
                                    <Input disabled className="kedle-input-disabled-color" />
                                </Form.Item>
                            },
                            {
                                title: "参考库存", dataIndex: "stockQuantity", width: 100, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'stockQuantity']}
                                >
                                    <Input disabled className="kedle-input-disabled-color" />
                                </Form.Item>
                            },
                            {
                                title: "数量", dataIndex: "quantity", width: 100, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'quantity']} rules={[{ required: true, message: '请输入数量' }]} required
                                >
                                    <InputNumber step={1} precision={2} min={0} placeholder="数量" />
                                </Form.Item>
                            },
                            {
                                title: "单位", dataIndex: "unit", width: 100, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'unit']}
                                >
                                    <Input disabled className="kedle-input-disabled-color" />
                                </Form.Item>
                            },
                            {
                                title: "领用人", dataIndex: "unit", width: 100, render: (v, r, index) => <Form.Item
                                    style={{ margin: 0 }}
                                    name={[index, 'receiver']}
                                >
                                    <Input />
                                </Form.Item>
                            },
                            {
                                title: <Button size="small" icon={<PlusOutlined />} onClick={() => {
                                    const maxLineNo = Math.max(...fields.map(field => form.getFieldValue(["notifies", field.name, "fluxNav", "lineNo"])), 0) + 1;
                                    console.log(maxLineNo);
                                    add({ key: uniqueId(), fluxNav: { lineNo: maxLineNo.toString() } }, 0)
                                }} />,
                                key: "action", width: 40, align: "center",
                                render: (v, r, index) => <Button size="small" danger icon={<MinusCircleOutlined />} onClick={() => remove(index)} type="link" />
                            },
                        ]}
                    />
                )}
            </Form.List>
        </Form>
    </Modal>
}
