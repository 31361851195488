import ExportNotifyApi from "@/Api/ExportNotifyApi";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { ViewExportNotify } from "@/Models/Views/ViewExportNotify";
import { Button, Table } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { sum } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ExportBillHubProvider } from "../ExportBillHubProvider";
import { PickStockSlotForm } from "../PickStockSlotForm";
import { UpdBatchNoComponent } from "./UpdBatchNoComponent";

export const expandable: ExpandableConfig<ViewExportBill> = {
    expandedRowRender,
};

function expandedRowRender(record: ViewExportBill) {
    return <Notifies record={record} />;
}

interface NotifiesProps {
    record: ViewExportBill
}

function getColumns(): ColumnType<ViewExportNotify>[] {
    const data: ColumnType<ViewExportNotify>[] = [
        numProps("id", "Id", 80, false),
        strProps("goodsTypeName", "机型", 120, false, false),
        strProps("goodsName", "物料名称", 160, false, false),
        strProps("goodsCode", "物料编码", 160, false, false),
        strProps("unit", "单位", 80, false, false),
        strProps("receiver", "领料人", 120, false, false),
        numProps("packageCount", "包装数量", 120, false),
        numProps("quantity", "计划数量", 120, false),
        numProps("factQuantity", "已下发数量", 120, false),
        numProps("completeQuantity", "已完成数量", 120, false),
        {
            title: "操作", key: "action", width: 200, render: (_, r) => {
                if (r.isAuto !== "Pick") {
                    return <UpdBatchNoComponent record={r} />;
                }
                // else if (r.factQuantity === 0) {
                //     return <Space>
                //         <PickActionComponent record={r} />
                //         <UpdBatchNoComponent record={r} />
                //     </Space>;
                // }
                else {
                    return <PickActionComponent record={r} />;
                }
            }
        },
    ];
    return data;
}

function PickActionComponent({ record }: { record: ViewExportNotify }) {
    const [showPick, setShowPick] = useState(false);
    const onCancel = useCallback(() => setShowPick(false), []);
    const onShowPick = useCallback(() => setShowPick(true), []);
    if (record.factQuantity >= record.quantity) {
        return <Fragment />;
    }
    else {
        return (
            <Button.Group>
                <TableInnerButton type="link" onClick={onShowPick}>挑选出库</TableInnerButton>
                {showPick && <PickStockSlotForm record={record} onCancel={onCancel} />}
            </Button.Group>);
    }
}

function Notifies({ record }: NotifiesProps) {
    const [data, setData] = useState<ViewExportNotify[]>([]);
    const [loading, setLoading] = useState(false);
    const columns = useMemo(getColumns, []);
    // 加40像素补偿，避免右侧显示异常
    const width = useMemo(() => sum(columns.map(a => a.width || 120)) + 40, [columns]);
    const provider = useCreateHubProvider(ExportBillHubProvider);
    useEffect(() => {
        return provider.notifyChange.listen(s => {
            setData(a => {
                const index = a.findIndex(b => b.id === s.id);
                if (index === -1) {
                    return a;
                } else {
                    a[index] = s;
                }
                return [...a];
            });
        });
    }, [provider]);
    useEffect(() => {
        setLoading(true);
        ExportNotifyApi.getViews(record.id).then(setData)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [record.id]);
    return <div>
        <Table pagination={false}
            size="small"
            style={{ width }}
            loading={loading}
            rowKey={s => s.id}
            bordered
            dataSource={data}
            columns={columns}
        />
    </div>;
}
