import { get, post } from '@/Api';
import { EquipmentType } from '@/Models/Enums/EquipmentType';
import { SelectItem } from '@/Models/Other/SelectItem';
import { UpkeepConfig } from "@/Models/Tables/UpkeepConfig";
import { ViewUpkeepConfig } from '@/Models/Views/ViewUpkeepConfig';

const name = 'UpkeepConfig';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(type: EquipmentType): Promise<ViewUpkeepConfig[]> {
    return get(getUrl("getViews"), { type });
}
export function add(data: UpkeepConfig): Promise<ViewUpkeepConfig> {
    return post(getUrl("add"), data);
}
export function upd(data: UpkeepConfig): Promise<ViewUpkeepConfig> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getSelectItems(): Promise<SelectItem[]> {
    return get(getUrl("getSelectItems"));
}