import { toYMD } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { useMemo } from "react";
import Add from "./Add";
import { Contract } from "@/Models/Tables/Contract";
import { add, del, getViews, upd } from "@/Api/ContractController";
import { ViewContract } from "@/Models/Views/ViewContract";
import { getContractStatusNames } from "@/Models/Enums/ContractStatus";

function getColumns(): ColumnType<ViewContract>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("code", "合同号", 160, true, true),
        strProps("name", "合同名称", 160, true, true),
        strProps("departmentName", "部门", 120, true, true),
        strProps("customerName", "客户", 120, true, true),
        strProps("userName", "合同发起人", 120, true, true),
        numProps("date", "签订日期", 120, true, toYMD),
        numProps("price", "合同金额", 120, true),
        numProps("receivedPayment", "已付款金额", 120, true),
        numProps("invoiceAmount", "已开票金额", 120, true),
        { title: "待付款金额", key: "pendingPayment", width: 120, render: (_, r) => r.price - r.receivedPayment },
        strProps("status", "合同状态", 120, true, true, getContractStatusNames),
        strProps("remark", "备注", 80, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< Contract, ViewContract > getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewContract, target: Contract) {
    target.id = source.id;
    return target;
}