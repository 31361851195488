import { get } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { ViewLaneway } from '@/Models/Views/ViewLaneway';

const name = 'Laneway';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export const getViews: () => Promise<ViewLaneway[]> = () => get(getUrl("getViews"));
export const getSelectItems: () => Promise<SelectItem[]> = () => get(getUrl("getSelectItems"));
export const setImportLanewayId: (lanewayId: number) => Promise<SelectItem[]> = (lanewayId) => get(getUrl("setImportLanewayId"), { lanewayId });