
export enum ExportBillExecuteFlag {
    Cancel = -1,// 取消
    WaitingPick = 0, // 等待挑选
    Picking = 1, // 挑选中
    WaitingDownSend = 2,// 等待下发
    WaitingExecute = 3,// 等待执行
    Executing = 4,// 执行中
    Finished = 5,// 完成
}

export type TypeExportBillExecuteFlag = keyof typeof ExportBillExecuteFlag;

export const ExportBillExecuteFlagNames: { [K in TypeExportBillExecuteFlag]: string } = {
    "Cancel": "已取消",
    "WaitingPick": "待挑选",
    "Picking": "挑选中",
    "WaitingDownSend": "待下发",
    "WaitingExecute": "待执行",
    "Executing": "执行中",
    "Finished": "已完成",
};

export function getExportBillExecuteFlagName(e: TypeExportBillExecuteFlag) {
    return ExportBillExecuteFlagNames[e];
}

