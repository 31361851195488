import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { EquipmentTaskHub } from "@/Hubs/EquipmentTaskHub";
import { HubErrorContext } from "@/Hubs/HubError";
import { TaskMode, TypeTaskMode } from "@/Models/Enums/TaskMode";
import { ViewEquipmentTask } from "@/Models/Views/ViewEquipmentTask";
import { createContext, useContext, useEffect, useMemo } from "react";

export class EquipmentTaskProvider {
    public readonly hub: EquipmentTaskHub;
    constructor(onError: tpOnError, mode: TaskMode) {
        const modeCheck = (s: TypeTaskMode) => (TaskMode[s] & mode) === mode;
        this.hub = new EquipmentTaskHub(onError);
        this.hub.on("DataChange", data => {
            if (modeCheck(data.taskMode)) {
                const index = this.data.Value.findIndex(s => s.id === data.id);
                if (index !== -1) {
                    this.data.Value[index] = data;
                    this.data.Value = [...this.data.Value];
                }
            }
        });
        this.hub.on("Remove", id => {
            const index = this.data.Value.findIndex(s => s.id === id);
            if (index !== -1) {
                this.data.Value.splice(index, 1);
                this.data.Value = [...this.data.Value];
            }
        });
        this.hub.on("Add", s => {
            if (modeCheck(s.taskMode)) {
                this.data.Value = [...this.data.Value, s];
            }
        });
        this.hub.start(async () => {
            try {
                this.data.Value = await this.hub.getViews(mode);
            } catch (error) {
                console.log(error);
            }
        });
    }
    public data = new Obs<ViewEquipmentTask[]>([]);
}

export const EquipmentTaskProviderContext = createContext<EquipmentTaskProvider>(null as any);

export function useEquipmentTaskProvider(mode: TaskMode) {
    const onError = useContext(HubErrorContext);
    const provider = useMemo(() => new EquipmentTaskProvider(onError, mode), [onError, mode]);
    useEffect(() => {
        return () => {
            provider.hub.stop();
        };
    }, [provider]);
    return provider;
}