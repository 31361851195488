import { Layout, Menu } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, NavigateFunction, useRoutes } from "react-router-dom";
import { getRouteData, IMenuGroup, resolveIMenuGroupToItem } from "./routeData";
import './index.scss';
import Home from "./Components/Home";
import { ContentPageSizeContextBody } from "@/Common/ContextBody";
import { GlobalProviderContext } from "@/App/GlobalProvider";

export default function Wms() {
    const defaultMenuKeys = useDefaultMenuKeys();
    const provider = useContext(GlobalProviderContext);
    const [user, setUser] = useState(provider.user.Value);
    useEffect(() => provider.user.listen(setUser), [provider.user]);
    const data = useMemo(() => getRouteData(user!), [user]);
    const element = useRoutes([...resolveIMenuGroupToItem(data).map(s => ({ path: s.path, element: s.element })), { path: '*', element: (<Home />) }])
    const nav = useNavigate();
    const menuItems = useMemo<ItemType[]>(() => resolveMenuItems(data, nav), [data, nav]);
    return (<Layout className="wms-root">
        <Layout.Sider theme="light" className="nav" >
            <Menu mode="inline" items={menuItems} className="menu" {...defaultMenuKeys} onOpenChange={console.log} />
        </Layout.Sider>
        <Layout.Content className="content">
            <ContentPageSizeContextBody>
                {element}
            </ContentPageSizeContextBody>
        </Layout.Content>
    </Layout>)
}

function useDefaultMenuKeys() {
    return useMemo(() => {
        const d1 = document.location.href.toLowerCase();
        const d2 = d1.split("#/wms/");
        if (d2.length > 1) {
            const d3 = d2[1];
            const d4 = d3.split('-');
            return { defaultOpenKeys: [d4[0]], defaultSelectedKeys: [d3] }
        }
        return { defaultOpenKeys: undefined, defaultSelectedKeys: undefined }
    }, [])
}

function resolveMenuItems(data: IMenuGroup[], nav: NavigateFunction,): ItemType[] {
    // printMenuDescription(data);
    return data.map(s => {
        return {
            key: s.path,
            label: s.name,
            icon: (<s.icon />),
            children: s.items.map(a => {
                return {
                    label: a.name,
                    key: a.path,
                    onClick: () => {
                        document.title = a.name;
                        nav(a.path);
                    },
                    icon: (<a.icon />)
                };
            })
        };
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function printMenuDescription(data: IMenuGroup[]) {
    const d1 = data.map((s, index) => {
        const list = s.items.map((a) => a.name);
        const listStr = list.join(',');
        return `(${index + 1})${s.name}:${listStr}。`;
    });
    console.log(d1.join('\r\n'));
    printSysRightList(data);
}


function printSysRightList(data: IMenuGroup[]) {
    const list = data.map(s => {
        const t1 = `new SysRight{Name = "${s.path}",SysUserGroupId =1,Right=0xfffffff}`;
        return t1;
    });
    console.log(list.join(',\r\n'))
}
