import { Context2DOperator } from "@/Common/CropImg/useContent2D";

/**阻止事件冒泡 */
export function stopPropagation(e: { preventDefault: () => void, stopPropagation: () => void }) {
    e.preventDefault();
    e.stopPropagation();
}

export class MouseEventRegister {
    constructor(c2d: Context2DOperator) {
        this.canvas = c2d.canvas;
        this.c2d = c2d;
        this.load();
    }

    canvas: HTMLCanvasElement;
    isMouseDown: boolean = false;
    mouseStartEvent: MouseEvent | null = null;
    c2d: Context2DOperator;

    clear = () => {
        console.log('注销c2d');
        this.canvas.removeEventListener("mousedown", this.mousedown);
        this.canvas.removeEventListener("mouseup", this.mouseup);
        this.canvas.removeEventListener("mouseleave", this.mouseleave);
        this.canvas.removeEventListener("mousemove", this.mousemove);
        this.canvas.removeEventListener("wheel", this.wheel);
    };

    private load = () => {
        console.log('加载c2d');
        this.isMouseDown = false;
        this.mouseStartEvent = null;
        this.canvas.addEventListener("mousedown", this.mousedown);
        this.canvas.addEventListener("mouseup", this.mouseup);
        this.canvas.addEventListener("mouseleave", this.mouseleave);
        this.canvas.addEventListener("mousemove", this.mousemove);
        this.canvas.addEventListener("wheel", this.wheel);
    };

    wheel = (e: WheelEvent) => {
        this.c2d.zoom(e.deltaY < 0, e);
    };

    mousedown = (e: MouseEvent) => {
        stopPropagation(e);
        this.isMouseDown = true;
        this.mouseStartEvent = e;
    };

    mouseup = (e: MouseEvent) => {
        this.isMouseDown = false;
    };

    mouseleave = (e: MouseEvent) => {
        this.isMouseDown = false;
    };

    mousemove = (e: MouseEvent) => {
        if (this.isMouseDown && this.mouseStartEvent) {
            this.c2d.move(e.x - this.mouseStartEvent.x, e.y - this.mouseStartEvent.y);
            this.mouseStartEvent = e;
        }
    };
}
