import { exportToExcel } from "@/Common/Print/exportToExcel";
import { Table, TableProps } from "antd";
import { Fragment, useContext, useMemo } from "react";
import { ContentSizeContext } from "../context";
import { TableInnerButton } from "./getTableAction";

export function sumWidth(arr: { width?: number }[], def: number = 120): number {
    let num = 0;
    arr.forEach(s => num += (s.width || def));
    return num;
}

function OperationTable<TBase extends {}, T extends TBase>({ ...props }
    : TableProps<T> & { heightOffset?: number }) {

    const pagination = useMemo(() => {
        const pageSizeOptions = ["10", "20", "50", "100"];
        if (props.pagination) {
            if (!props.pagination.pageSizeOptions) {
                props.pagination.pageSizeOptions = pageSizeOptions;
            }
            props.pagination.responsive = true;
            return props.pagination;
        }
        else {
            return { showSizeChanger: true, pageSizeOptions, responsive: true, defaultPageSize: 13 };
        }
    }, [props.pagination]);

    const printElement = useMemo(() => {
        if (props.dataSource && props.dataSource.length > 0) {
            const dataSource = props.dataSource;
            return (<div style={{ position: "relative" }}>
                <div style={{ position: "absolute", bottom: 20, }}>
                    <TableInnerButton type="link" onClick={() => exportToExcel(dataSource, props.columns as any, "export")}>导出Excel</TableInnerButton>
                </div>
            </div>);
        }
        return null;
    }, [props.dataSource, props.columns]);

    const width = useMemo(() => {
        if (props.columns) {
            return sumWidth(props.columns as any);
        }
        return undefined;
    }, [props.columns]);

    const size = useContext(ContentSizeContext);
    console.log(size[1] - (props.heightOffset ?? 0) - 145);
    return (<Fragment>
        <Table bordered size="middle"  {...props} scroll={{ x: width, y: size[0] - (props.heightOffset ?? 0) - 145 }} pagination={pagination}
        />
        {printElement}
    </Fragment>)
}

export default OperationTable;