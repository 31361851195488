import { getExitPortGoodsTypeViewsByGoodsTypeId, setDisabled } from "@/Api/ExitPortGoodsTypeController";
import { getSelectItems } from "@/Api/GoodsTypeController";
import { boolProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ViewExitPortGoodsType } from "@/Models/Views/ViewExitPortGoodsType";
import { Select, Space, Switch, Table } from "antd";
import React, { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";

export default Index;

function getColumns(): ColumnType<ViewExitPortGoodsType>[] {
    return [
        strProps("goodsTypeName", "物料类别", 160, false, false),
        strProps("exitPortName", "出库口", 160, false, false),
        strProps("warehouseName", "库房名称", 160, false, false),
        boolProps("disabled", "状态", 80, false, (s, r) => (<EditStatus record={r} />)),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewExitPortGoodsType[]>([]);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<SelectItem[]>([]);
    const [goodsTypeId, setGoodsTypeId] = useState<number>();

    useEffect(() => {
        if (goodsTypeId) {
            (async () => {
                setLoading(true);
                try {
                    setData(await getExitPortGoodsTypeViewsByGoodsTypeId(goodsTypeId));
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [goodsTypeId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setOptions(await getSelectItems());
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    return (<Table loading={loading} size="middle" rowKey={s => `${s.exitPortId}-${s.goodsTypeId}`} bordered pagination={false} dataSource={data} columns={columns} title={() => (<div>
        <Space>
            <Select style={{ minWidth: 160 }} value={goodsTypeId} options={options} showSearch allowClear={false} onChange={(s) => setGoodsTypeId(s as any)} />
        </Space>
    </div>)} />);
}

export function EditStatus({ record }: { record: ViewExitPortGoodsType }) {
    const [status, setStatus] = useState(!record.disabled);
    const [loading, setLoading] = useState(false);
    const onConfirm = useCallback(async () => {
        setLoading(true);
        try {
            await setDisabled(record.exitPortId, record.goodsTypeId, !record.disabled);
            record.disabled = !record.disabled;
            setStatus(!record.disabled);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }, [record]);
    return (<Switch loading={loading} disabled={loading} checkedChildren="可达" unCheckedChildren="关闭" checked={status} onClick={onConfirm} />);
}