import { utils, writeFile } from 'xlsx';


export function exportToExcel<T>(data: readonly T[], title: { dataIndex: keyof T, title: string, render?: (s: any, r: any) => any }[], name: string) {
    const arr = [];
    arr.push(title.map(s => s.title));
    data.forEach(s => {
        arr.push(title.map(a => {
            if (!a.dataIndex) return (a.render as any)(undefined, s);
            const val = s[a.dataIndex];
            if (a.render) {
                const d2 = a.render(val, s);
                if (typeof d2 == "string") {
                    return d2;
                }
                else {
                    return d2?.props?.children;
                }
            }
            return val;
        }));
    });
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(arr);
    utils.book_append_sheet(wb, ws, name);
    writeFile(wb, name + ".xlsx");
}


