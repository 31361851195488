import { get, post } from '@/Api';
import { EquipmentType } from '@/Models/Enums/EquipmentType';
import { UpkeepRecord } from '@/Models/Tables/UpkeepRecord';
import { ViewUpkeepAlarm } from '@/Models/Views/ViewUpkeepAlarm';
import { ViewUpkeepRecord } from '@/Models/Views/ViewUpkeepRecord';

const name = 'Equipment';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export type getUpkeepRecordsProps = { configId: number | null, equipmentId: string };
export function getUpkeepRecords({ configId, equipmentId }: getUpkeepRecordsProps): Promise<ViewUpkeepRecord[]> {
    return get(getUrl("getUpkeepRecords"), { configId, equipmentId });
}
export function addUpkeepRecord(data: UpkeepRecord): Promise<ViewUpkeepAlarm> {
    return post(getUrl("AddUpkeepRecord"), data);
}
export function getUpkeepAlarmList(type: EquipmentType): Promise<ViewUpkeepAlarm[]> {
    return get(getUrl("getUpkeepAlarmList"), { type });
}