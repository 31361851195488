import { Obs } from "@/Common/Obs";
import { Button, List, Modal, Space } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LeafItemEdit } from "./LeafItemEdit";
import { LeafItem } from "./LeafItem";
import { LeafDataProvider } from "./LeafDataProvider";
import { LeafComponentProps } from "./LeafComponentProps";
import { SyncLedDisplay } from "./SyncLedDisplay";



export function LeafItemContainer({ data }: { data: LeafDataProvider; }) {
    const syncDisplay = useContext(SyncLedDisplay);
    const [items, setItems] = useState(data.data.Value);
    useEffect(() => data.data.listen(setItems), [data.data]);
    const [edit, setEdit] = useState<Obs<LeafComponentProps> | null>(null);
    const onCancel = useCallback(() => setEdit(null), []);
    const onDelete = useCallback((d: Obs<LeafComponentProps>) => {
        data.remove(d);
        syncDisplay();
    }, [data, syncDisplay]);
    const showInfo = useCallback(async () => {
        const t1 = data.data.Value.map(s => s.Value);
        Modal.info({ content: toCSharpCode(t1), title: "CSharp代码" })
    }, [data.data.Value])

    const header = useMemo(() => {
        return <Space>
            <Button type="link" size="small" onClick={showInfo}>显示信息</Button>
            <Button type="link" size="small" onClick={() => {
                setEdit(data.add());
                syncDisplay();
            }}>添加</Button>
        </Space>
    }, [data, showInfo, syncDisplay])

    return <div>
        <List itemLayout="vertical"
            header={header}>
            {items.map((s, index) => (<LeafItem data={s} onEdit={setEdit} onDelete={onDelete} key={index} />))}
            {edit && <LeafItemEdit data={edit} onCancel={onCancel} />}
        </List>
    </div>;
}


function convertLeaf(d: LeafComponentProps) {
    return `BaseLedRect.Create(${d.top}, ${d.left}, ${d.right}, ${d.bottom}, ${d.fontSize}, "${d.text}", ${d.text.length})`;
}

function toCSharpCode(d: LeafComponentProps[]) {
    const strList = [];
    strList.push("var leafs = new List<BaseLedRect>(){");
    d.forEach(s => strList.push(`\t\t\t${convertLeaf(s)},`));
    strList.push("\t\t};");
    return strList.join('\r');
}