import { Obs } from "@/Common/Obs";
import { Text, useApp } from "@pixi/react";
import * as PIXI from 'pixi.js';
import { useEffect, useMemo, useRef, useState } from "react";
import { DrawProvider } from "./DrawProvider";
import { LeafComponentProps } from "./LeafComponentProps";

export function LeafText({ data, syncDisplay }: { data: Obs<LeafComponentProps>; syncDisplay: () => void }) {
    const textRef = useRef<PIXI.Text>(null);
    const [value, setValue] = useState(data.Value);
    useEffect(() => data.listen(setValue), [data]);
    const app = useApp();
    useEffect(() => {
        if (textRef.current) {
            const draw = new DrawProvider(app, textRef.current, (dom) => {
                data.Value.left = Math.round(dom.x);
                data.Value.top = Math.round(dom.y);
                data.Value.right = 200;
                data.Value.bottom = 80;
                syncDisplay();
            }, s => {
                if (s > 0) {
                    data.Value.fontSize--;
                    data.resetValue();
                    syncDisplay();
                }
                else if (s < 0) {
                    data.Value.fontSize++;
                    data.resetValue();
                    syncDisplay();
                }
            });
            return draw.stop;
        }
    }, [app, data, syncDisplay]);

    useEffect(() => {
        if (textRef.current) {
            value.left = Math.round(textRef.current.x);
            value.top = Math.round(textRef.current.y);
            data.Value.right = 200;
            data.Value.bottom = 80;
            syncDisplay();
        }
    }, [data.Value, syncDisplay, value]);

    const style = useMemo(() => new PIXI.TextStyle({
        align: 'center',
        fontFamily: '宋体',
        fontSize: value.fontSize,
        fill: ['red'],
        padding: 2,
    }), [value.fontSize]);

    return <Text
        interactive={true}
        text={value.text}
        x={value.left}
        y={value.top}
        style={style}
        ref={textRef} />;
}
