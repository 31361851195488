import axios, { AxiosResponse } from "axios";
import { Md5 } from "ts-md5";

const splitSize = 1024 * 1024;
const url = '/api';

const getUrl = (method: MethodType) => `${url}/${method}`;
type MethodType = "Upload" | "Merge";

export default class UploadController {

    constructor(f: File, onFinished: (val: string) => void) {
        this.file = f;
        this.onFinished = onFinished;
    }

    setLoading?: (bl: boolean) => void;
    onFinished: (val: string) => void;
    onError?: (err: string) => void;
    onProgress?: (d: number) => void;
    private file: File;

    private loading = (b: boolean) => {
        this.setLoading && this.setLoading(b);
    }

    private error = (err: string) => {
        this.onError && this.onError(err);
        this.loading(false);
    }

    private progress = (d: number) => {
        this.onProgress && this.onProgress(d);
    }

    private finished = (d: AxiosResponse<any>) => {
        this.onFinished(d.data);
        this.loading(false);
    }

    upload = () => {
        this.loading(true);
        if (this.file.size > splitSize) {
            this.shardUpload(0, Math.ceil(this.file.size / splitSize), [], new Md5());
        }
        else {
            getMd5FormData(this.file, s => axios.post(getUrl("Upload"), s)
                .then(this.finished)
                .catch(e => this.error(`文件上传失败！${JSON.stringify(e)}`)));
        }
    }

    shardUpload = (index: number, totalCount: number, shardNames: string[], totalMd5: Md5) => {

        const start = index * splitSize;
        const blob = this.file.slice(start, start + splitSize);
        const fr = new FileReader();

        fr.onload = ((s) => {

            const md5 = new Md5().appendByteArray(new Uint8Array(s.target?.result as ArrayBuffer)).end()!.toString();

            totalMd5.appendByteArray(new Uint8Array(s.target?.result as ArrayBuffer));

            const fd = new FormData();
            fd.append(md5, blob);
            axios.post(getUrl("Upload"), fd).then(s => {
                if (s.status === 200) {
                    shardNames.push(md5);
                    this.progress((Math.floor((index / totalCount) * 99)));
                    if (index >= totalCount) {
                        const extIndex = this.file.name.lastIndexOf('.');
                        const ext = extIndex === -1 ? '' : this.file.name.substring(extIndex);
                        axios.post(getUrl("Merge"), { shards: shardNames, fileName: totalMd5.end()!.toString() + ext })
                            .then(this.finished)
                            .catch(e => this.error(`合并碎片失败! ${JSON.stringify(e)}`));
                    }
                    else {
                        this.shardUpload(index + 1, totalCount, shardNames, totalMd5);
                    }
                }
                else this.error(`碎片文件上传失败!开始位置:第${index}片.${start}-${blob.size}`);
            })
                .catch(e => this.error(`碎片文件上传接口调用失败!开始位置:第${index}片.${start}-${blob.size} ${JSON.stringify(e)}`));
        });
        fr.onerror = e => this.error(`文件读取失败!${JSON.stringify(e)}`);
        fr.readAsArrayBuffer(blob);
    }
}

const getMd5FormData: (data: File, cb: (d: FormData) => void) => void = (data, cb) => {
    const fr = new FileReader();
    fr.onload = ((s) => {
        const md5 = new Md5().appendByteArray(new Uint8Array(s.target?.result as ArrayBuffer)).end()!.toString();
        const i = data.name.lastIndexOf('.');
        let name: string;
        if (i !== -1) name = md5 + data.name.substr(i);
        else name = md5;
        const fd = new FormData();
        fd.append(name, data, name);
        cb(fd);
    });
    fr.readAsArrayBuffer(data);
};