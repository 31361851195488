import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import * as echarts from 'echarts';
import { delay } from "lodash";
import { HomeProviderContext } from "./HomeProvider";
import { DayAnalysis } from "@/Models/Other/Home";
import dayjs from "dayjs";

function getOptions(v: DayAnalysis) {
    const colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
    const title = `${dayjs().format('YYYY-MM-DD')} 入库出库分析`;
    return {
        title: {
            text: title,
            left: 'center'
        },
        xAxis: {
            type: 'category',
            data: ['入库数', '出库数', '入库任务', '出库任务']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: [v.importQuantity, v.exportQuantity, v.importTaskCount, v.exportTaskCount],
            type: 'bar',
            itemStyle: {

                normal: { label: { show: true, formatter: '{b} {c}', position: 'top' }, color: (f: any) => colors[f.dataIndex], }
            }
        }]
    };
}


function Index() {
    const divRef = useRef<HTMLDivElement>(null);
    const charRef = useRef<{ chart?: echarts.ECharts }>({});
    const provider = useContext(HomeProviderContext);
    const [dayAnalysis, setDayAnalysis] = useState(provider.dayAnalysis.Value);
    useEffect(() => provider.dayAnalysis.listen(setDayAnalysis), [provider]);

    useEffect(() => {
        if (charRef.current.chart) {
            charRef.current.chart.setOption(getOptions(dayAnalysis));
        }
    }, [dayAnalysis]);

    useLayoutEffect(() => {
        if (divRef.current) {
            const node = divRef.current;
            delay(() => {
                const chart = echarts.init(node);
                chart.setOption(getOptions(provider.dayAnalysis.Value));
                const obj = { width: node.clientWidth, height: node.clientHeight };
                chart.resize(obj);
                charRef.current.chart = chart;
            }, provider.renderDelay);
        }
    }, [provider]);
    return <div ref={divRef}></div>;
}

export default Index;