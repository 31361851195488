import { deleteTask, updFlag } from "@/Api/EquipmentTaskController";
import { ContentPageSizeContextBody } from "@/Common/ContextBody";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { getTaskExecuteFlagName, TaskExecuteFlag, taskExecuteFlagNames } from "@/Models/Enums/TaskExecuteFlag";
import { TaskMode } from "@/Models/Enums/TaskMode";
import { ViewEquipmentTask } from "@/Models/Views/ViewEquipmentTask";
import { getColumns } from "@/Pages/Wms/Components/Equipment/Task";
import { Dropdown, Popconfirm, Modal, message } from "antd";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { Fragment, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { importInSlot } from "@/Api/WcsDataProviderController";
import { EquipmentTaskProviderContext, useEquipmentTaskProvider } from "@/Pages/Wms/Components/Equipment/Task/EquipmentTaskProvider";

export default Index;

function Index() {
    const [modal, contextHolder] = Modal.useModal();

    const columns = useMemo(() => {
        const tmp = getColumns();
        {
            const index = tmp.findIndex(s => s.dataIndex === "executeFlag");
            tmp[index].render = (_, r) => <ChangeFlagDom record={r} modal={modal} />;
        }
        {
            const index = tmp.findIndex(s => s.dataIndex === "taskNo");
            tmp[index].render = (_, r) => <ApplyInSlot record={r} />;
        }
        return tmp;
    }, [modal]);

    const taskProvider = useEquipmentTaskProvider(TaskMode.All);
    const [data, setData] = useState(taskProvider.data.Value);
    useEffect(() => {
        return taskProvider.data.listen(setData);
    }, [taskProvider]);

    return <EquipmentTaskProviderContext.Provider value={taskProvider}>
        <ContentPageSizeContextBody>
            <CommonTable rowKey={s => s.id} columns={columns} dataSource={data} pagination={false} />
            {contextHolder}
            {/* <Button hidden onClick={() => {
                data.forEach(async s => {
                    try {
                        const flag: TypeTaskExecuteFlag = "Cancel";
                        const res1 = await updFlag(s.id, TaskExecuteFlag[s.executeFlag], flag as any);
                        console.log(res1 > 0 && (flag === "Cancel" || flag === "Finished"));
                        if (res1 > 0 && (flag === "Cancel" || flag === "Finished")) {
                            console.log(await deleteTask(s.id));
                        }
                    } catch (error) {
                        console.error(error);
                    }
                });
            }}>取消所有任务</Button> */}
        </ContentPageSizeContextBody>
    </EquipmentTaskProviderContext.Provider>;
}

function ChangeFlagDom({ record, modal }: { record: ViewEquipmentTask, modal: Omit<ModalStaticFunctions, "warn"> }) {
    const onChangeFlag = useCallback((flag: any) => {
        modal.confirm({
            title: `您确定要将任务号为:${record.taskNo}的任务状态修改为${getTaskExecuteFlagName(flag)}吗？`, content: ` 手动操作任务时需要确认这个任务的实际执行状态!`, onOk: async () => {
                try {
                    const res1 = await updFlag(record.id, TaskExecuteFlag[record.executeFlag], flag);
                    console.log(res1 > 0 && (flag === "Cancel" || flag === "Finished"));
                    if (res1 > 0 && (flag === "Cancel" || flag === "Finished")) {
                        console.log(await deleteTask(record.id));
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            okText: "确认",
            cancelText: "取消"
        });
    }, [modal, record]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: `您确定要手动删除任务号为:${record.taskNo}的任务吗？`, content: ` 手动操作任务时需要确认这个任务的实际执行状态!`, onOk: async () => {
                try {
                    const res2 = await deleteTask(record.id);
                    console.log(res2);
                } catch (error) {
                    console.error(error);
                }
            },
            okText: "确认",
            cancelText: "取消"
        });
    }, [modal, record]);



    const menuOptions = useMemo(() => {
        const items = Object.keys(taskExecuteFlagNames)
            .filter(s => s !== record.executeFlag)
            .map(s => ({ label: getTaskExecuteFlagName(s as any), key: s, onClick: () => onChangeFlag(s) }));
        items.push({ label: "删除", key: "delete", onClick: onDelete });
        return { items };
    }, [onChangeFlag, onDelete, record.executeFlag]);

    return <Fragment>
        <Dropdown menu={menuOptions} trigger={["click"]}>
            <TableInnerButton type="link">
                {getTaskExecuteFlagName(record.executeFlag)}<DownOutlined />
            </TableInnerButton>
        </Dropdown>
    </Fragment>;
}

function ApplyInSlot({ record }: { record: ViewEquipmentTask }) {
    const [loading, setLoading] = useState(false);
    if (record.slotCode?.length > 0) {
        return <Fragment>{record.taskNo}</Fragment>;
    }
    return (<Popconfirm disabled={loading} title="您确定要申请入库吗" onConfirm={async () => {
        try {
            setLoading(true);
            const res = await importInSlot(parseInt(record.taskNo), 0, 1);
            message.info(JSON.stringify(res));
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }}>
        <TableInnerButton disabled={loading} loading={loading} type="link">{record.taskNo}</TableInnerButton>
    </Popconfirm>);
}   