import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { BaseHub, tpOnError } from "./BaseHub";
import { IExportBillHubAction } from "./IActions/IExportBillHubAction";

export class ExportBillHub extends BaseHub<IExportBillHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/ExportBill");
    }
    public getUnFinishedList = () => this.hub.invoke<ViewExportBill[]>("GetUnFinishedList");
}

