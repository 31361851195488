import { getBluePrintCascaderItems } from "@/Api/BluePrintController";
import { getContractSelectItems } from "@/Api/ContractController";
import GoodsApi from "@/Api/GoodsApi";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { getPurchaseRequisitionStatusOptions } from "@/Models/Enums/PurchaseRequisitionStatus";
import { CascaderItem } from "@/Models/Other/CascaderItem";
import { SelectItem } from "@/Models/Other/SelectItem";
import { PurchaseRequisition } from "@/Models/Tables/PurchaseRequisition";
import { Cascader, Form, Input, InputNumber, Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

function Item(props: IFormItemProps<PurchaseRequisition>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<PurchaseRequisition>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ contract: SelectItem[], goods: SelectItem[], bluePrint: CascaderItem[] }>({ contract: [], goods: [], bluePrint: [] });
    const contractId = Form.useWatch("contractId", form);
    const showBluePrintSelect = useMemo(() => contractId > 0, [contractId]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const contract = await getContractSelectItems();
                const goods = await GoodsApi.getSelectItems();
                setOptions(s => ({ ...s, contract, goods }));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!contractId) return;
            const bluePrint = await getBluePrintCascaderItems(contractId);
            setOptions(s => ({ ...s, bluePrint }));
            console.log(bluePrint, contractId);
            if (data) {
                const t1 = bluePrint.find(s => s.children.some(a => a.value === data.bluePrintId));
                if (t1) {
                    form.setFieldValue("bluePrintIdValue", [t1.value, data.bluePrintId]);
                }
            }
        })();
    }, [contractId, data, form]);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            if (t1["bluePrintIdValue"]) t1.bluePrintId = t1["bluePrintIdValue"][1];
            onSubmit({ ...data, ...t1 });
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onSubmit]);

    return <MyModal confirmLoading={loading} name={`销售商品明细`} data={data} width={400} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" labelCol={{ span: 8 }} initialValues={data}>
            <Item name="contractId" label="合同名称" labelAlign="right" >
                <Select options={options.contract} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            {showBluePrintSelect && <Form.Item name={"bluePrintIdValue" as any} label="图纸名称" labelAlign="right" >
                <Cascader options={options.bluePrint} loading={loading} showSearch allowClear ></Cascader>
            </Form.Item>}
            <Item name="goodsId" label="物料名称" labelAlign="right" required>
                <Select options={options.goods} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="price" label="参考单价" labelAlign="right" required >
                <InputNumber precision={4} min={1} style={{ width: '100%' }} />
            </Item>
            <Item name="quantity" label="数量" labelAlign="right" required >
                <InputNumber min={1} style={{ width: '100%' }} />
            </Item>
            <Item name="brand" label="品牌" >
                <Input />
            </Item>
            <Item name="usePosition" label="使用位置" >
                <Input />
            </Item>
            <Item name="status" label="状态" >
                <Select options={getPurchaseRequisitionStatusOptions()} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;