import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Location } from './Location';
import { ViewSlot } from '@/Models/Views/ViewSlot';
import { reverse, uniq } from 'lodash';
import { Button } from 'antd';
import { Conveyers } from './Conveyers';
import { LocationPositionProviderContext } from './LocationPositionProvider';
import { SlotProviderContext } from '@/Pages/Wms/Components/Equipment/SlotManager/SlotProvider';

export function Locations({ warehouseId, plcNo }: { warehouseId: number, plcNo: number }) {
    const provider = useContext(SlotProviderContext);
    const [data, setData] = useState(provider.data.Value.filter(s => s.warehouseId === warehouseId));
    const [currentLayer, setCurrentLayer] = useState(1);
    const positionProvider = useContext(LocationPositionProviderContext);
    useEffect(() => provider.data.listen(a => setData(a.filter(s => s.warehouseId === warehouseId))), [provider, warehouseId]);
    const currentData = useMemo(() => data.filter(s => s.layer === currentLayer), [data, currentLayer]);
    const layers = useMemo(() => {
        const d1 = uniq(data.map(s => s.layer));
        if (warehouseId === 1) return reverse(d1);
        else return d1;
    }, [data, warehouseId]);

    const onShow = useCallback((s: ViewSlot) => {
        provider.showStockInfo.Value = s;
    }, [provider]);

    return (
        <Fragment>
            {currentData.map(s => <Location data={s} key={s.id} onShow={onShow} position={positionProvider} />)}
            <Button.Group className={`layers-action`}>
                {layers.map(s => (<Button key={s}
                    type={s === currentLayer ? "primary" : "default"}
                    onClick={() => setCurrentLayer(s)} block >{`第${s}层`}</Button>))}
            </Button.Group>
            <Conveyers plcNo={plcNo} warehouseId={warehouseId} />
        </Fragment>
    );
}

