import { getCategorySelectItems } from "@/Api/EquipmentFaultController";
import { utcEndSecond, utcStartSecond } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { ExcludeBasePagination, EquipmentFaultSplitPageProps } from "@/Models/Other/BaseSplitPageProps";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, DatePicker, Form, Input, Select, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";


export function SearchComponent({ onSearch }: { onSearch: (data: ExcludeBasePagination<EquipmentFaultSplitPageProps>) => void; }) {
    const [categoryOptions, setCategoryOptions] = useState<SelectItem[]>([]);
    useEffect(() => {
        getCategorySelectItems().then(setCategoryOptions).catch(console.error);
    }, []);

    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let start: number | undefined = undefined;
            let end: number | undefined = undefined;
            if (s["dateRange"]) {
                start = utcStartSecond(s["dateRange"][0]);
                end = utcEndSecond(s["dateRange"][1]);
            }

            let stationNo: number | null = null;

            try {
                stationNo = s["stationNo"] ? parseInt(s["stationNo"]) : null;
            } catch (error) {
                console.error(error);
                return;
            }

            onSearch(
                {
                    start, end,
                    stationNo: stationNo,
                    categoryId: s["categoryId"],
                    isFaulting: s["isFaulting"],
                }
            );
        }}>
            <Form.Item name="dateRange" label="日期范围">
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss"></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item label="故障状态" name="isFaulting">
                <Switch checkedChildren="故障中" unCheckedChildren="所有故障" />
            </Form.Item>
            <Form.Item label="站台号" name="stationNo">
                <Input />
            </Form.Item>
            <Form.Item label="异常类别" name="categoryId">
                <Select options={categoryOptions} style={{ minWidth: 300 }} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions >;
}
