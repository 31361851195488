import DayAnalysis from "./DayAnalysis";
import Detail from "./Detail";
import MonthAnalysis from "./MonthAnalysis";
import RatioUse from "./RatioUse";
import { useEffect } from "react";
import { HomeProvider, HomeProviderContext } from "./HomeProvider";
import { useCreateHubProvider } from "@/Hubs/HubError";
import './index.scss';

function Home() {
    const provider = useCreateHubProvider(HomeProvider);
    useEffect(() => provider.hub.stop, [provider]);
    return (<HomeProviderContext.Provider value={provider}>
        <div className="page-home">
            <RatioUse></RatioUse>
            <DayAnalysis></DayAnalysis>
            <MonthAnalysis></MonthAnalysis>
            <Detail></Detail>
        </div>
    </HomeProviderContext.Provider>);
}

export default Home;