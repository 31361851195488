import { getSelectItems } from "@/Api/SysUserGroupController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { TypeEditData } from "@/Common/UserInfo/SysUserAction";
import UserAvatar from "@/Common/UserInfo/UserAvatar";
import { getShieldFlagNames } from "@/Models/Enums/ShieldFlag";
import { SelectItem } from "@/Models/Other/SelectItem";
import { SysUser } from "@/Models/Tables/SysUser";
import { Form, Input, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { Md5 } from "ts-md5";

function Item(props: IFormItemProps<SysUser>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data, type }: AddProps<SysUser> & { type: TypeEditData["type"] }) {
    const [options, setoptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const isPwd = useMemo(() => type === "editPwd", [type]);
    const onOk = useCallback(() => {
        setLoading(true);
        form.validateFields().then(s => {
            if (data) {
                if (isPwd) {
                    onSubmit(new Md5().appendStr(s.pwd).end()!.toString());
                }
                else {
                    onSubmit({ ...data, ...s });
                }
            }
            else {
                onSubmit({ ...s, pwd: new Md5().appendStr(s.pwd).end()!.toString() });
            }
        })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [data, form, onSubmit, isPwd]);
    useEffect(() => {
        setLoading(false);
        getSelectItems().then(setoptions).catch(console.error).finally(() => setLoading(false));
    }, []);

    return <MyModal name="用户" data={data} width={360} onCancel={onCancel} confirmLoading={loading} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data ?? { shieldFlag: "Active" }} labelCol={{ span: 8 }}>
            <Item name="avatar" label="用户头像" required  >
                <UserAvatar />
            </Item>
            <Item name="name" label="用户姓名" required >
                <Input disabled={isPwd} ></Input>
            </Item>
            <Item name="code" label="用户编码" required >
                <Input disabled={isPwd} ></Input>
            </Item>
            {(isPwd || (!data)) && <Item name="pwd" label="用户密码" required >
                <Input.Password ></Input.Password>
            </Item>}
            <Item name="sysUserGroupId" label="用户组" required >
                <Select disabled={isPwd} options={options} />
            </Item>
            <Item name="shieldFlag" label="在职/离职" required >
                <Select disabled={isPwd} options={[
                    { label: getShieldFlagNames("Active"), value: "Active" },
                    { label: getShieldFlagNames("Resigned"), value: "Resigned" },
                ]}></Select>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;