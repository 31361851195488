import { StackerInfo } from "@/Models/Other/StackerInfo";
import { createContext } from "react";

export class StackerPositionProvider {
    private _positionData: { [K: string]: { left: number; top: number; }; } = {};
    constructor() {
        this._positionData = {};
    }

    public readonly getPosition = (data: StackerInfo) => {
        const key = `${data.warehouseId}-${data.stationNo}-${data.currentColumn}`;
        if (!this._positionData[key]) {
            this._positionData[key] = this.buildPosition(data);
        }
        return this._positionData[key];
    };

    private buildPosition(data: StackerInfo) {
        return this.w1Build(data);
    }
    private w1Build(data: StackerInfo) {
        const base_top = 173;
        const base_left = 715;
        const colStep = 43.125;
        const left = base_left - (data.currentColumn * colStep);
        return { left, top: base_top };
    }

}

export const StackerPositionProviderContext = createContext<StackerPositionProvider>(null as any);