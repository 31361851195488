import { add, del, getViews, upd } from "@/Api/GoodsTypeController";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { GoodsType } from "@/Models/Tables/GoodsType";
import  { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<GoodsType>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "类别编码", 120, true, true),
        strProps("name", "类别名称", 200, true, true),
        numProps("robotNo", "视觉识别码", 120, true),
        strProps("remark", "备注", undefined as any, true, true),
    ];
}

export default Index;
// function Index() {
//     const columns = useMemo(getColumns, []);
//     const [loading, setLoading] = useState(false);
//     const [data, setData] = useState<GoodsType[]>([]);
//     useEffect(() => {
//         (async () => {
//             try {
//                 setLoading(true);
//                 setData(await getViews());
//             } catch (error) {
//                 console.error(error);
//             }
//             finally {
//                 setLoading(false);
//             }
//         })();
//     }, []);
//     return <CommonTable loading={loading} dataSource={data} columns={columns}
//     />;
// }

function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< GoodsType> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
        editCheck={s => !(s.id > 1)}
    />;
}

function updMerge(source: GoodsType, target: GoodsType) {
    target.id = source.id;
    return target;
}
