import ExportBillApi from "@/Api/ExportBillApi";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { useCallback, useMemo, useState } from "react";

export function DownSendComponent({ record }: { record: ViewExportBill; }) {
    const [loading, setLoading] = useState(false);
    const onDownSendTask = useCallback(async () => {
        setLoading(true);
        try {
            const res = await ExportBillApi.downSendTask(record.id);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [record.id]);
    const text = useMemo(() => record.executeFlag === "WaitingDownSend"
        || record.executeFlag === "WaitingPick" ? "下发任务" : "重发", [record.executeFlag]);
    return (<TableInnerButton loading={loading} type="link" onClick={onDownSendTask}>{text}</TableInnerButton>);
}
