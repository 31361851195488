import { Listen } from "@/Common/Listen";
import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { PlatformHub } from "@/Hubs/PlatformHub";
import { ViewExportNotify } from "@/Models/Views/ViewExportNotify";
import { createContext } from "react";

export class PlatformHubProvider {
    public readonly hub: PlatformHub;
    public readonly notifyChange = new Listen<ViewExportNotify>()
    constructor(onError: tpOnError) {
        this.hub = new PlatformHub(onError);
        this.hub.on("StatusChange", ({ key, value }) => {
            const d2 = this.data.Value;
            if (d2[key]) {
                d2[key] = value;
            }
            else {
                this.data.Value[key] = value;
            }
            this.data.resetValue();
        });
        this.hub.start(async () => {
            this.loading.Value = true;
            try {
                this.data.Value = await this.hub.getPlatformStatus();
            } catch (error) {
                console.log(error);
            }
            finally {
                this.loading.Value = false;
            }
        });
    }
    public loading = new Obs(false);
    public data = new Obs<{ [K: number]: boolean }>([]);
}

export const PlatformHubProviderContext = createContext<PlatformHubProvider>(null as any);





