import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { GoodsType } from "@/Models/Tables/GoodsType";
import { Form, Input } from "antd";
import React from "react";
import { useCallback } from "react";

function Item(props: IFormItemProps<GoodsType>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}
function Add({ onCancel, onSubmit, data }: AddProps<GoodsType>) {
    const [form] = Form.useForm();
    const onOk = useCallback(() => {
        form.validateFields().then(onSubmit);
    }, [form, onSubmit]);
    return <MyModal name="物料类型" data={data} width={360} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" initialValues={data} labelCol={{ span: 8 }}>
            <Item name="code" label="类别编码" required >
                <Input ></Input>
            </Item>
            <Item name="name" label="类别名称" required >
                <Input ></Input>
            </Item>
            <Item name="robotNo" label="视觉识别码" required rules={[{
                validator: (_, v) => {
                    const dt1 = [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17];
                    if (dt1.includes(parseInt(v))) {
                        return Promise.resolve();
                    }
                    else {
                        return Promise.reject(`视觉识别码[${v}]未定义!`);
                    }
                }
            }]}>
                <Input ></Input>
            </Item>
            <Item name="remark" label="备注" >
                <Input ></Input>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;