import { SysUserToken } from "@/Models/Other/SysUserToken";
import { Button, Modal } from "antd";
import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserOutlined } from '@ant-design/icons';
import Add from './Add';
import { TypeEditData } from "./SysUserAction";
import { upd, updPwd } from "@/Api/SysUserController";
import './index.scss';
import { useNavigate } from "react-router-dom";
import { GlobalProviderContext } from "@/App/GlobalProvider";
import { cleanLoginInfo } from "../LocalStorageProvider";

interface UserInfoProps {
    data: SysUserToken;
    isEdit?: boolean;
}

const UserInfo: FunctionComponent<UserInfoProps> = ({ data, isEdit }) => {
    const nav = useNavigate();
    const provider = useContext(GlobalProviderContext);
    const [edit, setEdit] = useState<TypeEditData>();
    const [showOperations, setShowOperations] = useState(false);
    const ele = useRef<HTMLDivElement>(null);
    const loginOut = useCallback(() => {
        cleanLoginInfo();
        provider.user.Value = null;
        nav("/login", { state: document.location.pathname });
    }, [nav, provider.user])
    useEffect(() => {
        if (isEdit && ele.current) {
            const t1 = ele.current;
            const mouseenter = () => {
                setShowOperations(true);
            };
            const mouseleave = () => {
                setShowOperations(false);
            };
            t1.addEventListener("mouseenter", mouseenter);
            t1.addEventListener("mouseleave", mouseleave);
            return () => {
                t1.removeEventListener("mouseenter", mouseenter);
                t1.removeEventListener("mouseleave", mouseleave);
            };
        }
    }, [isEdit]);

    const onLoginOut = useCallback(() => {
        Modal.confirm({ title: "您确定要注销登录吗?", onOk: loginOut });
    }, [loginOut]);

    const editInfo = useCallback(() => setEdit({ data, type: "editInfo" }), [data]);
    const editPwd = useCallback(() => setEdit({ data, type: "editPwd" }), [data]);
    const onCancel = useCallback(() => setEdit(undefined), []);

    const onSubmit = useCallback(async (data: any) => {
        try {
            if (edit?.data) {
                if (edit.type === "editPwd") {
                    console.log(await updPwd(edit.data.id, data));
                }
                else {
                    console.log(await upd(data));
                }
            }
            setEdit(undefined);
        } catch (error) {
            console.error(error);
        }
    }, [edit]);

    if (data) {
        return (
            <div ref={ele} className="userInfo-root" style={{ position: "relative", overflow: "visible", zIndex: 999 }}>
                <div className="avatar">
                    {data.avatar && data.avatar.length > 0 ? <img src={data.avatar} alt="" /> : <UserOutlined style={{ fontSize: 44 }} />}
                </div>
                <div className="info">
                    <div className="code">
                        <span>{data.code}</span>
                    </div>
                    <div className="name">
                        <span>{data.name}</span>
                    </div>
                    <div className="group">
                        <span>{data.groupName}</span>
                    </div>
                </div>
                {showOperations && (
                    <Button.Group style={{ position: "absolute", bottom: -32, right: 0 }}>
                        <Button onClick={editInfo} >修改信息</Button>
                        <Button onClick={editPwd} >修改密码</Button>
                        <Button onClick={onLoginOut} >注销</Button>
                    </Button.Group>
                )}
                {edit && <Add onCancel={onCancel} onSubmit={onSubmit} data={edit.data as any} type={edit.type} />}
            </div>
        );
    }
    else {
        return (<></>);
    }
};

export default UserInfo;