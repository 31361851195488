import GoodsApi from "@/Api/GoodsApi";
import EditTableCheck from "@/Common/Table/EditTableCheck";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { Goods } from "@/Models/Tables/Goods";
import { ViewGoods } from "@/Models/Views/ViewGoods";
import { useMemo } from "react";
import Add from "./Add";
import { getUploadFlagNames } from "@Models/Enums/UploadFlag";

function getColumns(): ColumnType<ViewGoods>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("typeName", "类别", 120, true, true),
        strProps("name", "物品名称", 160, true, true),
        strProps("code", "物品编码", 120, true, true),
        strProps("standard", "规格", 150, true, true),
        // numProps("robotNo", "视觉码", 80, false),
        strProps("unit", "单位", 60, false, false),
        numProps("price", "单价", 80, true, s => s.toFixed(2)),
        strProps("areaName", "所属区域", 120, true, true),
        numProps("stockQuantity", "库存数量", 100, true),
        numProps("stockHigh", "最高库存", 100, true),
        numProps("stockLow", "最低库存", 100, true),
        numProps("validityDay", "保质期(天)", 110, true),
        numProps("alarmDay", "提前报警(天)", 120, true),
        strProps("uploadFlag", "上传状态", 80, true, true, getUploadFlagNames),
        strProps("remark", "备注", 80, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTableCheck< Goods, ViewGoods> getData={GoodsApi.getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={GoodsApi.upd}
        delDataHandle={(s: { id: number; }) => GoodsApi.del(s.id)}
        updMerge={updMerge}
        addDataHandle={GoodsApi.add}
        editCheck={(s: { id: number; }) => s.id === 1}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewGoods, target: Goods) {
    target.id = source.id;
    return target;
}