import { getStackerDeviceStatusKeys, setDownSendStackerTask, setStackerDeviceStatus, setStackerTaskStatus } from '@/Api/TestPlcController';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';


export function StackerComponent() {
    const [stationOptions, setStationOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await getStackerDeviceStatusKeys();
                setStationOptions(d1.map(s => ({ label: s.toString(), value: s })));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    if (stationOptions.length === 0) return <Spin spinning={loading} />;
    return (<div >
        <div>堆垛机</div>
        <div className='plcTest-stacker'>
            <StackerDeviceStatusComponent options={stationOptions} />
            <StackerTaskStatusComponent options={stationOptions} />
            <DownSendStackerTaskComponent options={stationOptions} />
        </div>
    </div>);
}
export function StackerDeviceStatusComponent({ options }: { options: SelectItem[] }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setStackerDeviceStatus(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>设备状态反馈</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0].value}>
                <Select options={options} />
            </Form.Item>
            <Form.Item name="controlMode" label="控制模式" initialValue={5}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="currentLanewayNo" label="当前巷道号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}


export function StackerTaskStatusComponent({ options }: { options: SelectItem[] }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setStackerTaskStatus(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>堆垛机预约反馈</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0].value}>
                <Select options={options} />
            </Form.Item>
            <Form.Item name="status" label="设备状态" initialValue={1}>
                <Input></Input>
            </Form.Item>
            <Form.Item name="taskNo" label="任务号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}
export function DownSendStackerTaskComponent({ options }: { options: SelectItem[] }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            const res = await setDownSendStackerTask(d1 as any);
            console.log(d1, res);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);
    return <div>
        <div>下发堆垛机任务</div>
        <Form layout='inline' form={form}>
            <Form.Item name="stationNo" label="站台号" required rules={[{ required: true }]} initialValue={options[0].value}>
                <Select options={options} />
            </Form.Item>
            <Form.Item name="taskNo" label="任务号" initialValue={0}>
                <Input></Input>
            </Form.Item>
            <Form.Item>
                <Button loading={loading} disabled={loading} onClick={onSubmit}>写入</Button>
            </Form.Item>
        </Form>
    </div>;
}

