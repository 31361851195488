import { get, post } from '@/Api';
import { StockSlotBatchExportSplitPageProps, StockSlotSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { BatchInfo } from '@/Models/Other/BatchInfo';
import { PaginationData } from "@/Models/Other/PaginationData";
import { StockSlotFilter } from '@/Models/Other/StockSlotFilter';
import { ViewBatchExportGroupInfo } from '@/Models/Views/ViewBatchExportGroupInfo';
import { ViewBatchExportStockSlot } from '@/Models/Views/ViewBatchExportStockSlot';
import { ViewHighLowAlarm } from '@/Models/Views/ViewHighLowAlarm';
import { ViewSimpleStockSlot } from '@/Models/Views/ViewSimpleStockSlot';
import { ViewStockSlot } from '@/Models/Views/ViewStockSlot';
import { ViewSumStockSlot } from '@/Models/Views/ViewSumStockSlot';
import { ViewValidityAlarm } from '@/Models/Views/ViewValidityAlarm';

const name = 'StockSlot';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function updStatus(data: { id: number, goodsStatusId?: number | null }[]): Promise<number> {
    return post(getUrl("updStatus"), data);
}
export function getHighAlarms(): Promise<ViewHighLowAlarm[]> {
    return get(getUrl("getHighAlarms"));
}
export function getLowAlarms(): Promise<ViewHighLowAlarm[]> {
    return get(getUrl("getLowAlarms"));
}
export function getValidityDays(): Promise<ViewValidityAlarm[]> {
    return get(getUrl("getValidityDays"));
}
export function getAlarmDays(): Promise<ViewValidityAlarm[]> {
    return get(getUrl("getAlarmDays"));
}
export function getViews(data: StockSlotSplitPageProps): Promise<PaginationData<ViewStockSlot>> {
    return get(getUrl("getViews"), data);
}
export function getAllViews(): Promise<ViewStockSlot[]> {
    return get(getUrl("GetAllViews"));
}
export function getViewsExcludeEmptyStock(data: StockSlotFilter): Promise<ViewStockSlot[]> {
    return get(getUrl("GetViewsExcludeEmptyStock"), data);
}
export function getSumViews(): Promise<ViewSumStockSlot[]> {
    return get(getUrl("getSumViews"));
}
export function getViewsBySlotId(slotId: number): Promise<ViewStockSlot[]> {
    return get(getUrl("getViewsBySlotId"), { slotId });
}
export function getViewBatchExportStockSlots(data: StockSlotBatchExportSplitPageProps): Promise<PaginationData<ViewBatchExportStockSlot>> {
    return get(getUrl("getViewBatchExportStockSlots"), data);
}
export function getViewBatchExportGroupInfo(data: StockSlotBatchExportSplitPageProps): Promise<ViewBatchExportGroupInfo[]> {
    return get(getUrl("getViewBatchExportGroupInfo"), data);
}
export function getSimpleStockSlots(stockCode: string): Promise<ViewSimpleStockSlot[]> {
    return get(getUrl("GetSimpleStockSlots"), { stockCode });
}
export function getCanExportViews(notifyId: number): Promise<ViewStockSlot[]> {
    return get(getUrl("GetCanExportViews"), { notifyId });
}
export function getBatchInfo(goodsId: number): Promise<BatchInfo[]> {
    return get(getUrl("GetBatchInfo"), { goodsId });
}