import Bill from './Bill';
import Order from './Order';
import Task from './Task';
import ExportBill from './QueryBill';
import ExitPort from './ExitPort';
import ExportOrder from './QueryOrder';
import Platform from './Platform';
import {
    ExportOutlined,
    ProfileOutlined,
    ReadOutlined,
    RocketOutlined,
    BarsOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    TranslationOutlined,
    SettingOutlined,
    GatewayOutlined,
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
import LanewayExport from './LanewayExport';
import ExitPortGoodsType from './ExitPortGoodsType';

function exportGroup(): IMenuGroup {
    return {
        name: "出库管理",
        icon: ExportOutlined,
        path: "export",
        items: [
            {
                name: "物料出库设置",
                icon: GatewayOutlined,
                element: <ExitPortGoodsType />,
                path: "ExitPortGoodsType",
                right: 1 << 1,
            },
            {
                name: "巷道配置",
                icon: SettingOutlined,
                element: <LanewayExport />,
                path: "LanewayExport",
                right: 1 << 2,
            },
            {
                name: "出库单",
                icon: ProfileOutlined,
                element: <Bill />,
                path: "Bill",
                right: 1 << 3,
            },
            {
                name: "出库流水",
                icon: ReadOutlined,
                element: <Order />,
                path: "Order",
                right: 1 << 4,
            },
            {
                name: "出库口管理",
                icon: BarsOutlined,
                element: <ExitPort />,
                path: "ExitPort",
                right: 1 << 5,
            },
            {
                name: "月台单据管理",
                icon: TranslationOutlined,
                element: <Platform />,
                path: "Platform",
                right: 1 << 6,
            },
            {
                name: "出库任务",
                icon: RocketOutlined,
                element: <Task />,
                path: "Task",
                right: 1 << 7,
            },
            {
                name: "出库单查询",
                icon: ShoppingCartOutlined,
                element: <ExportBill />,
                path: "ExportBill",
                right: 1 << 8,
            },
            {
                name: "出流水查询",
                icon: ShoppingOutlined,
                element: <ExportOrder />,
                path: "ExportOrder",
                right: 1 << 9,
            },
        ]
    };
}

export default exportGroup;