import ExportNotifyApi from "@/Api/ExportNotifyApi";
import { getSelectItems, setStatus } from "@/Api/PlatformController";
import { DragSortingTable } from "@/Common/Table/DragSortingTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { toYMD, toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ViewExportNotifyExtPlatform } from "@/Models/Views/ViewExportNotifyExtPlatform";
import { Button, Radio, Space, Switch, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlatformHubProvider } from "./PlatformHubProvider";

export default Index;

function getColumns(): ColumnType<ViewExportNotifyExtPlatform>[] {
    return [
        strProps("billCode", "WMS单据号", 200, false, false),
        strProps("mesBillNo", "Mes单据号", 160, false, false),
        numProps("planDate", "计划日期", 100, false, toYMD),
        numProps("index", "序号", 60, false),
        strProps("goodsCode", "物料编码", 160, false, false),
        strProps("goodsName", "物品名称", 160, false, false),
        strProps("customerName", "机体名称", 120, false, false),
        strProps("customerName", "图号", 160, false, false),
        strProps("batchNo", "批次号", 160, false, false),
        numProps("quantity", "计划数", 80, false),
        numProps("completeQuantity", "完成数", 80, false),
        numProps("stockQuantity", "库存数量", 80, false),
        numProps("time", "单据添加时间", 160, false, toYMDHMS),
    ];
}

function Index() {
    const [data, setData] = useState<ViewExportNotifyExtPlatform[]>([]);
    const [loading, setLoading] = useState(false);
    const [platformOptions, setPlatformOptions] = useState<SelectItem[]>([]);
    const [platform, setPlatform] = useState<number>();
    const [editIndex, setEditIndex] = useState(false);
    const columns = useMemo(getColumns, []);
    const provider = useCreateHubProvider(PlatformHubProvider);

    const [platformStatus, setPlatformStatus] = useState(provider.data.Value);
    useEffect(() => provider.data.listen(setPlatformStatus), [provider.data]);
    const status = useMemo(() => {
        if (platform) {
            return platformStatus[platform];
        } else {
            return false;
        }
    }, [platform, platformStatus]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = (await getSelectItems()).sort((a, b) => a.value - b.value);
                setPlatformOptions(data);
                setPlatform(data[0].value);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (platform) {
            (async () => {
                const data = await ExportNotifyApi.getViewExportNotifyExtPlatforms(platform);
                data.sort((a, b) => a.index - b.index);
                setData(data);
            })();
        }
    }, [platform]);

    const onSave = useCallback(async (obj: ViewExportNotifyExtPlatform[]) => {
        const d1 = obj.map((s, index) => ({ ...s, index }));
        const data = await ExportNotifyApi.updNotifyIndex(d1 as any);
        if (data > 0) {
            setData(d1);
            setEditIndex(false);
        }
    }, []);

    const onEditIndex = useCallback(() => setEditIndex(true), []);
    const onCancel = useCallback(() => setEditIndex(false), []);

    return ((editIndex) ?
        (<DragSortingTable loading={loading} onSave={onSave} onCancel={onCancel} size="middle" bordered pagination={false} dataSource={data} columns={columns} title={() => `调整顺序`} />)
        :
        (<Table size="middle" rowKey={s => s.id} bordered pagination={false} dataSource={data} columns={columns} title={() => (<div>
            <Space>
                <Radio.Group optionType="button" options={platformOptions} value={platform} onChange={s => setPlatform(s.target.value)} />
            </Space>
            <Space style={{ float: 'right' }}>
                <Switch checkedChildren="出库中" unCheckedChildren="暂停出库" checked={status} onChange={async s => {
                    setLoading(true);
                    try {
                        const d1 = await setStatus(platform as any, s);
                        console.log(d1);
                    } catch (error) {
                        console.error(error);
                    }
                    finally {
                        setLoading(false);
                    }
                }} />
                <Button type="link" size="middle" onClick={onEditIndex}>{`调整顺序`}</Button>
            </Space>
        </div>)} />));
}