import GoodsApi from "@/Api/GoodsApi";
import { getSelectItems as getSlotSelectItems } from "@/Api/SlotController";
import { utcEndSecond, utcStartSecond } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { ExcludeBasePagination as Ext, OrderSplitPageProps } from "@/Models/Other/BaseSplitPageProps";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, Cascader, DatePicker, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { CascaderProps } from "antd/lib/cascader";
import { forEach, groupBy } from "lodash";
import { useState } from "react";

export function SearchComponent({ onSearch }: { onSearch: (data: Ext<OrderSplitPageProps>) => void; }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SelectItem[]>([]);
    const [slotOptions, setSlotOptions] = useState<CascaderProps<any>["options"]>([]);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let start: number | undefined = undefined;
            let end: number | undefined = undefined;
            if (s["dateRange"]) {
                start = utcStartSecond(s["dateRange"][0]);
                end = utcEndSecond(s["dateRange"][1]);
            }
            let slotId = undefined;
            if (s["slotId"]) {
                slotId = s["slotId"][1];
            }
            onSearch(
                {
                    start, end, billCode: s["billCode"], stockCode: s["stockCode"], goodsId: s["goodsId"], slotId
                }
            );
        }}>
            <Form.Item name="dateRange" label="日期范围">
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss"></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item label="出库单号" name="billCode">
                <Input />
            </Form.Item>
            <Form.Item label="托盘条码" name="stockCode">
                <Input />
            </Form.Item>
            <Form.Item label="物品信息" name="goodsId">
                <Select onDropdownVisibleChange={data.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        GoodsApi.getSelectItems().then(a => setData((list) => {
                            return a.map(s => ({
                                label: (<div>
                                    <div>{s.label}</div>
                                    <div>{s.standard}</div>
                                </div>), value: s.value, data: s
                            })) as any
                        })).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} filterOption={(input, opt: any) => {
                    return opt.data.label.toLowerCase().includes(input.toLowerCase())
                        || opt.data.standard?.toLowerCase().includes(input.toLowerCase());
                }} style={{ minWidth: 200 }} loading={loading} options={data} showSearch allowClear />
            </Form.Item>
            <Form.Item label="储位编码" name="slotId">
                <Cascader style={{ minWidth: 100 }} onDropdownVisibleChange={slotOptions ? async s => {
                    if (s) {
                        setLoading(true);
                        try {
                            const d1 = await getSlotSelectItems();
                            const slot: CascaderProps<any>["options"] = [];
                            forEach(groupBy(d1, s => s.warehouseName), (s, key) => {
                                slot.push({ label: key, value: key, children: s });
                            });
                            setSlotOptions(slot);
                        } catch (error) {
                            console.error(error);
                        }
                        finally {
                            setLoading(false);
                        }
                    }
                } : undefined} showSearch allowClear options={slotOptions} />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}


