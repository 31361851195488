import { renderBool } from "@/Common/numExtensions";
import { ColumnType } from "@/Common/Table/type";
import { getStatusString, StackerInfo } from "@/Models/Other/StackerInfo";
import { Table } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { HomeProviderContext } from "./HomeProvider";

export function getColumns(): ColumnType<StackerInfo>[] {
    return [
        { title: "No", width: 60, dataIndex: "stationNo", render: (_, r) => `${r.warehouseId}-${r.stationNo}` },
        {
            title: "状态", children: [
                { title: "在线", width: 45, dataIndex: "online", render: renderBool },
                { title: "联机", width: 45, dataIndex: "controlMode", render: renderBool },
            ]
        } as any,
        {
            title: "当前", children: [
                { title: "巷道", width: 45, dataIndex: "currentLanewayNo" },
                { title: "排", width: 45, dataIndex: "currentRow" },
                { title: "列", width: 45, dataIndex: "currentColumn" },
                { title: "层", width: 45, dataIndex: "currentLayer" },
            ]
        } as any,
        {
            title: "目标", children: [
                { title: "巷道", width: 45, dataIndex: "targetLanewayNo" },
                { title: "排", width: 45, dataIndex: "targetRow" },
                { title: "列", width: 45, dataIndex: "targetColumn" },
                { title: "层", width: 45, dataIndex: "targetLayer" },
            ]
        } as any,
        { title: "设备状态", width: 80, dataIndex: "status", render: getStatusString, ellipsis: true },
        // { title: "传感器状态", width: 0, dataIndex: "hasSomething", render: getStatusString, ellipsis: true },
    ];
}

export function StackerList() {
    const provider = useContext(HomeProviderContext);
    const [stackerInfoList, setStackerInfo] = useState(provider.stackerInfo.Value);
    const columns = useMemo(getColumns, []);
    useEffect(() => {
        return provider.stackerInfo.listen(setStackerInfo);
    }, [provider.stackerInfo]);
    return <Table rowKey={s => `${s.plcNo}-${s.stationNo}`} className="stacker-list" scroll={{ y: 220 }} bordered pagination={false} size="small" columns={columns} dataSource={stackerInfoList} />;
}

