import { CommonTable } from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMD, toYMDHMS } from "@/Common/date";
import { useCreateHubProvider } from "@/Hubs/HubError";
import { getExportBillExecuteFlagName } from "@/Models/Enums/ExportBillExecuteFlag";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AddForm } from "./AddForm";
import { BillExecuteFlagOperation } from "./BillExecuteFlagOperation";
import EditPlatformForm from "./EditPlatformForm";
import { ExportBillHubProvider, ExportBillHubProviderContext } from "./ExportBillHubProvider";
import { expandable } from "./NotifyList/expandable";

function getColumns(onShowAdd: () => void, onShowEditPlatform: (r: ViewExportBill) => void): ColumnType<ViewExportBill>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("typeName", "单据类型", 120, true, true),
        strProps("code", "出库单编码", 160, true, true),
        numProps("planDate", "计划日期", 120, true, toYMD),
        strProps("platformName", "出库月台", 100, true, false, (s, r) => (r.executeFlag === "WaitingPick" || r.executeFlag === "Picking" || r.executeFlag === "WaitingDownSend") ? <TableInnerButton type="link" onClick={() => onShowEditPlatform(r)}>{s}</TableInnerButton> : s),
        numProps("quantity", "计划数", 100),
        numProps("factQuantity", "任务数", 100),
        numProps("completeQuantity", "完成数", 100),
        strProps("executeFlag", "执行标志", 120, true, true, getExportBillExecuteFlagName),
        numProps("time", "建单时间", 160, true, toYMDHMS),
        { title: <TableInnerButton type="primary" onClick={onShowAdd}>添加</TableInnerButton>, width: 140, key: "action", render: (_, r) => (<BillExecuteFlagOperation record={r} />) },
        // { title: "a", key: "a" }
    ];
}

export default Index;
function Index() {
    const provider = useCreateHubProvider(ExportBillHubProvider);
    const [showAdd, setShowAdd] = useState(false);
    const [editPlatformData, setEditPlatformData] = useState<ViewExportBill>();
    const onShowAdd = useCallback(() => setShowAdd(true), []);
    const onCancel = useCallback(() => setShowAdd(false), []);
    const columns = useMemo(() => getColumns(onShowAdd, setEditPlatformData), [onShowAdd]);
    const [data, setData] = useState<ViewExportBill[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => provider.loading.listen(setLoading), [provider]);
    useEffect(() => provider.data.listen(setData), [provider]);
    return (<ExportBillHubProviderContext.Provider value={provider}>
        <CommonTable loading={loading}
            rowKey={s => s.id}
            dataSource={data}
            columns={columns}
            expandable={expandable}
            widthOffset={48}
        />
        {showAdd && <AddForm onCancel={onCancel} />}
        {editPlatformData && <EditPlatformForm data={editPlatformData} onCancel={() => setEditPlatformData(undefined)} />}
    </ExportBillHubProviderContext.Provider>);
}

