import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { Customer } from '@/Models/Tables/Customer';
namespace CustomerApi {
    const name = 'Customer';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }
    export function getViews(): Promise<Customer[]> {
        return get(getUrl("getViews"));
    }
    export function add(data: Customer): Promise<Customer> {
        return post(getUrl("add"), data);
    }
    export function upd(data: Customer): Promise<Customer> {
        return post(getUrl("upd"), data);
    }
    export function del(id: number): Promise<number> {
        return get(getUrl("del"), { id });
    }
    export function getSelectItems(): Promise<SelectItem[]> {
        return get(getUrl("getSelectItems"));
    }
}
export default CustomerApi;