import { add, del, getViews, upd } from "@/Api/ContractPayRecordController";
import { toYMD, toYMDHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import EditTable from "@/Common/Table/EditTable";
import { ColumnType } from "@/Common/Table/type";
import { urlRender } from "@/Common/UrlHelp";
import { getPayModeNames } from "@/Models/Enums/PayMode";
import { ContractPayRecord } from "@/Models/Tables/ContractPayRecord";
import { ViewContractPayRecord } from "@/Models/Views/ViewContractPayRecord";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<ViewContractPayRecord>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("contractCode", "合同编号", 160, true, true),
        strProps("contractName", "合同名称", 200, true, true),
        strProps("departmentName", "签约部门", 120, true, true),
        numProps("amount", "付款金额", 100, true),
        strProps("mode", "付款方式", 120, true, true, getPayModeNames),
        numProps("date", "付款日期", 100, true, toYMD),
        strProps("voucher", "佐证资料", 200, true, true, urlRender),
        strProps("userName", "录入人", 100, true, true),
        numProps("time", "录入时间", 160, true, toYMDHMS),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< ContractPayRecord, ViewContractPayRecord> getData={getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={upd}
        delDataHandle={s => del(s.id)}
        updMerge={updMerge}
        addDataHandle={add}
        rowKey={"id"}
    />;
}

function updMerge(source: ViewContractPayRecord, target: ContractPayRecord) {
    target.id = source.id;
    return target;
}