import { toYMDHMS } from "@/Common/date";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { getTaskModeNames, TaskMode } from "@/Models/Enums/TaskMode";
import { ViewEquipmentTask } from "@/Models/Views/ViewEquipmentTask";
import { useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { EquipmentTaskProviderContext, useEquipmentTaskProvider } from "./EquipmentTaskProvider";
import { TaskExecuteFlagActionDom } from "./TaskExecuteFlagActionDom";
import { TaskLogComponent } from "./TaskLogComponent";

export default Index;

export function getColumns(): ColumnType<ViewEquipmentTask>[] {
    return [
        numProps("id", "任务Id", 80, true),
        strProps("warehouseName", "库房", 100, true, true),
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("taskNo", "任务号", 100, true, true),
        strProps("taskMode", "任务模式", 100, true, true, getTaskModeNames),
        strProps("stockCode", "托盘条码", 120, true, true),
        strProps("begin", "起始位置", 100, true, true),
        strProps("end", "终止位置", 100, true, true),
        strProps("goodsInfo", "物品信息", 200, true, true),
        numProps("height", "尺寸", 80, false),
        numProps("time", "创建时间", 160, true, toYMDHMS),
        { title: "执行标志", dataIndex: "executeFlag", width: 120, sorter: strSorter("executeFlag"), render: (_, r) => <TaskExecuteFlagActionDom record={r} /> },
        strProps("remark", "备注", 100, true, true),
    ];
}

function Index() {
    return <CommonTaskList mode={TaskMode.All} />;
}

export function CommonTaskList({ mode }: { mode: TaskMode }) {
    const [record, setRecord] = useState<ViewEquipmentTask | null>(null);
    const columns = useMemo(() => getTaskLogColumns(setRecord), []);
    const taskProvider = useEquipmentTaskProvider(mode);
    const [data, setData] = useState(taskProvider.data.Value);
    const onCancel = useCallback(() => setRecord(null), []);
    useEffect(() => taskProvider.data.listen(setData), [taskProvider]);
    return <EquipmentTaskProviderContext.Provider value={taskProvider}>
        <CommonTable rowKey={s => s.id} columns={columns} dataSource={data} />
        {record && <TaskLogComponent record={record} isFinished={false} onCancel={onCancel} />}
    </EquipmentTaskProviderContext.Provider>;
}

function getTaskLogColumns(setData: (record: ViewEquipmentTask) => void) {
    const data = getColumns();
    const index = data.findIndex(s => s.dataIndex === "taskNo");
    if (index > -1) {
        data[index].render = (v, r) => <TableInnerButton onClick={() => setData(r)} type="link" >{v}</TableInnerButton>;
    }
    return data;
}