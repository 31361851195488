import { Form, InputNumber } from "antd";
import { useContext, useEffect, useState } from "react";
import { EditableContext } from "./EditableProvider";
import { ViewStockSlotExtItems } from "./ViewStockSlotExtItems";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    dataIndex: string;
    record: ViewStockSlotExtItems;
    children: React.ReactNode;
    index: number;
}
export function EditableCell({ dataIndex, record, index, children, ...restProps }: EditableCellProps) {
    if (dataIndex === "pickQuantity") {
        return <PickQuantityCell {...restProps as any} record={record} />;
    }
    else {
        return <td {...restProps}>{children}</td>;
    }
}

function PickQuantityCell({ record, ...restProps }: EditableCellProps) {
    const provider = useContext(EditableContext);
    const [editRecord, setEditRecord] = useState(provider.record.Value);
    useEffect(() => provider.record.listen(setEditRecord), [provider.record]);
    if (record === editRecord) {
        return <td {...restProps}>
            <Form.Item name={"pickQuantity"} style={{ margin: 0 }} rules={[{
                validator(rule, value, callback) {
                    const quantity = provider.data.reduce((p, c) => p + c.pickQuantity, 0);
                    if (quantity + value > provider.needQuantity) {
                        callback(`挑选数量不能大于${provider.needQuantity - quantity}`);
                    }
                    callback();
                },
            }]}>
                <InputNumber min={0} precision={2} max={record.quantity} />
            </Form.Item>
        </td>;
    }
    else {
        return <td {...restProps} onClick={() => provider.onEdit(record)}>{record.pickQuantity}</td>;
    }
}
