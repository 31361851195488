import { addUpkeepRecord } from "@/Api/EquipmentController";
import { EquipmentType } from "@/Models/Enums/EquipmentType";
import { ViewUpkeepAlarm } from "@/Models/Views/ViewUpkeepAlarm";
import { Form, Input, Modal, Descriptions } from "antd";
import { Fragment, useCallback, useContext, useState } from "react";
import { EquipmentTypeContext } from "../UpkeepConfig/common";

function Add({ onCancel, onSubmit, data }: { onCancel: () => void, onSubmit: (d: ViewUpkeepAlarm) => void, data: ViewUpkeepAlarm }) {
    const type = useContext(EquipmentTypeContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields() as any;
            const d2 = await addUpkeepRecord({ remark: d1.remark, configId: data.configId, equipmentId: data.equipmentId } as any);
            onSubmit(d2);
            onCancel();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [data.configId, data.equipmentId, form, onCancel, onSubmit]);

    return <Modal confirmLoading={loading} title="设备保养" open width={760} onCancel={onCancel} onOk={onOk}>
        <Descriptions title={data.configName} column={3} size="small" bordered style={{ marginBottom: 16 }} >
            {type === EquipmentType.Conveyer ?
                (<Fragment>
                    <Descriptions.Item label="运行时间">{data.runtime1}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastRuntime1}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.runtimeInterval1}</Descriptions.Item>
                </Fragment>) : (<Fragment>
                    <Descriptions.Item label="行走时间">{data.runtime1}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastRuntime1}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.runtimeInterval1}</Descriptions.Item>
                    <Descriptions.Item label="行走里程">{data.mileage1}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastMileage1}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.mileageInterval1}</Descriptions.Item>

                    <Descriptions.Item label="升降时间">{data.runtime2}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastRuntime2}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.runtimeInterval2}</Descriptions.Item>
                    <Descriptions.Item label="升降里程">{data.mileage2}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastMileage2}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.mileageInterval2}</Descriptions.Item>

                    <Descriptions.Item label="单叉时间">{data.runtime3}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastRuntime3}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.runtimeInterval3}</Descriptions.Item>
                    <Descriptions.Item label="单叉里程">{data.mileage3}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastMileage3}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.mileageInterval3}</Descriptions.Item>

                    <Descriptions.Item label="双叉时间">{data.runtime4}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastRuntime4}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.runtimeInterval4}</Descriptions.Item>
                    <Descriptions.Item label="双叉里程">{data.mileage4}</Descriptions.Item>
                    <Descriptions.Item label="上次保养">{data.lastMileage4}</Descriptions.Item>
                    <Descriptions.Item label="保养周期">{data.mileageInterval4}</Descriptions.Item>

                </Fragment>)
            }
        </Descriptions>
        <Form form={form} labelAlign="right" layout="vertical" initialValues={data} labelCol={{ span: 8 }}>
            <Form.Item name="remark" label="保养描述信息" required rules={[{ required: true }]} >
                <Input.TextArea rows={5}></Input.TextArea>
            </Form.Item>
        </Form>
    </Modal>;
}

export default Add;