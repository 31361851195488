import { get, post } from '@/Api';
import { TaskSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { SelectItem } from '@/Models/Other/SelectItem';
import { ViewEquipmentFault } from '@/Models/Views/ViewEquipmentFault';

const name = 'EquipmentFault';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getViews(data: TaskSplitPageProps): Promise<PaginationData<ViewEquipmentFault>> {
    return post(getUrl("getViews"), data);
}

export function getCategorySelectItems(): Promise<SelectItem[]> {
    return get(getUrl("getCategorySelectItems"));
}