import { get, post } from '@/Api';
import { OrderSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { ExportOrder } from '@/Models/Tables/Order';
import { ViewExportOrder } from '@/Models/Views/ViewExportOrder';

const name = 'ExportOrder';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getUnFinishedViews(): Promise<ViewExportOrder[]> {
    return get(getUrl("getUnFinishedViews"));
}

export function getViews(data: OrderSplitPageProps): Promise<PaginationData<ViewExportOrder>> {
    return get(getUrl("getViews"), data);
}

export function add(data: ExportOrder[]): Promise<number> {
    return post(getUrl("Add"), data);
}

export function cancel(id: number): Promise<number> {
    return get(getUrl("Cancel"), { id });
}

export function reSetUploadFlag(id: number): Promise<number> {
    return get(getUrl("ReSetUploadFlag"), { id });
}