import { get, post } from '@/Api';
import { SelectItemExt1 } from '@/Models/Other/SelectItem';
import { PurchaseRequisition } from '@/Models/Tables/PurchaseRequisition';
import { ViewPurchaseRequisition } from '@/Models/Views/ViewPurchaseRequisition';

const name = 'PurchaseRequisition';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewPurchaseRequisition[]> {
    return get(getUrl("getViews"));
}
export function add(data: PurchaseRequisition): Promise<ViewPurchaseRequisition> {
    return post(getUrl("add"), data);
}
export function upd(data: PurchaseRequisition): Promise<ViewPurchaseRequisition> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
export function getWaitingPurchase(id: number): Promise<number> {
    return get(getUrl("GetWaitingPurchase"), { id });
}
export function getGoodsSelectItems(): Promise<SelectItemExt1[]> {
    return get(getUrl("GetSelectItems"));
}
export function getViewsByGoodsIdAndNotifyId(goodsId: number, notifyId?: number): Promise<ViewPurchaseRequisition[]> {
    return get(getUrl("GetViewsByGoodsIdAndNotifyId"), { goodsId, notifyId });
}