import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { ConveyerProviderContext } from './ConveyerProvider';
import './conveyer.scss';
import { Conveyer } from './Conveyer';
import { ConveyerLocationBuilderContext } from './Conveyer/ConveyerLocationBuilder';

export function Conveyers({ plcNo, warehouseId }: { plcNo: number, warehouseId: number }) {
    const provider = useContext(ConveyerProviderContext);
    const [conveyers, setConveyers] = useState(provider.conveyer.Value);
    const build = useContext(ConveyerLocationBuilderContext);
    const settings = useMemo(() => build.getData(warehouseId), [build, warehouseId]);
    useEffect(() => provider.conveyer.listen(setConveyers), [provider.conveyer]);
    return (<Fragment>
        {settings.map((s, k) => {
            const key = `${plcNo}-${s.stationNo}`;
            if (conveyers[key]) {
                return <Conveyer key={key} data={conveyers[key]} config={s} />;
            }
            else {
                return null;
            }
        })}
    </Fragment>);
}


