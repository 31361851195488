import { get } from '@/Api';
import { TaskExecuteFlag } from '@/Models/Enums/TaskExecuteFlag';
const name = 'WcsDataProvider';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function UpdStockSlot(val: number, code: string): Promise<number> {
    return get(getUrl("UpdStockSlot"), { status: val, slotCode: code });
}
export function deleteFinishedTask(): Promise<number> {
    return get(getUrl("DeleteFinishedTask"));
}
export function getExportTask(warehouseId: number, stackerNo: number): Promise<{ tk?: any, msg?: string }> {
    return get(getUrl("GetExportTask"), { warehouseId, stackerNo });
}
export function importInSlot(taskNo: number, stationNo: number, warehouseId: number): Promise<{ tk?: any, msg?: string }> {
    return get(getUrl("ImportInSlot"), {
        taskNo,
        stationNo,
        warehouseId
    });
}
export function setStationCount(stationNo: number, count: number): Promise<any> {
    return get(getUrl("SetStationCount"), { stationNo, count });
}
export function importScanTask(stockCode: string, stationNo: number, warehouseId: number): Promise<{ tk?: any, msg?: string }> {
    return get(getUrl("ImportScanTask"),
        {
            stockCode,
            stationNo,
            warehouseId
        });
}
export function switchImportSlot(warehouseId: number, taskNo: string): Promise<{ tk?: any, msg?: string }> {
    return get(getUrl("SwitchImportSlot"), {
        warehouseId,
        taskNo
    });
}
export function importEmptyStock(stationNo: number, warehouseId: number): Promise<{ tk?: any, msg?: string }> {
    return get(getUrl("ImportEmptyStock"), {
        warehouseId,
        stationNo
    });
}
export function switchLanewayNo(taskNo: number, warehouseId: number, stationNo: number): Promise<number> {
    return get(getUrl("SwitchLanewayNo"), {
        taskNo, warehouseId, stationNo
    });
}
export function taskArriveToCache(warehouseId: number, taskNo: string): Promise<number> {
    return get(getUrl("TaskArriveToCache"), { warehouseId, taskNo });
}
export function taskArriveToConveyer(taskNo: string): Promise<number> {
    return get(getUrl("TaskArriveToConveyer"), { taskNo });
}
export function updateTaskExecuteFlag(id: number, flag: TaskExecuteFlag): Promise<number> {
    return get(getUrl("UpdateTaskExecuteFlag"), { id, flag });
}
export function apply520(): Promise<number> {
    return get(getUrl("Apply520"));
}
export function moveInLaneway(originSlotId: number, targetSlotId: number): Promise<string> {
    return get(getUrl("MoveInLaneway"), { originSlotId, targetSlotId });
}
export function offlineMove(originSlotId: number, targetSlotId: number): Promise<number> {
    return get(getUrl("OfflineMove"), { originSlotId, targetSlotId });
}