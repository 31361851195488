import ImportBillApi from "@/Api/ImportBillApi";
import ImportNotifyApi from "@/Api/ImportNotifyApi";
import { offlineImport } from "@/Api/OfflineController";
import { getSelectItems as getSlotSelectItems } from "@/Api/SlotController";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, Cascader, Form, Input, InputNumber, message, Select } from "antd";
import { CascaderProps } from "antd/lib/cascader";
import { forEach, groupBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import './index.scss';

export default function Import() {
    const [options, setOptions] = useState<{ bill: SelectItem[], slot: CascaderProps<{}>["options"] }>();
    const [notifyOptions, setNotifyOptions] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [billId, setBillId] = useState<number>();
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await Promise.all([ImportBillApi.getSelectItems(), getSlotSelectItems()]);
                const slot: CascaderProps<{}>["options"] = [];
                forEach(groupBy(res[1], s => s.warehouseName), (s, key) => {
                    slot.push({ label: key, value: key, children: s });
                });
                setOptions({ bill: res[0], slot });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (billId) {
            (async () => {
                setLoading(true);
                try {
                    const d1 = await ImportNotifyApi.getViews(billId);
                    setNotifyOptions(d1.map(s => ({
                        label: (<div>
                            <div>{s.goodsName}</div>
                            <div>{s.standard}</div>
                        </div>), value: s.id, data: s
                    })) as any);
                } catch (error) {
                    console.error(error);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [billId]);

    const [form] = Form.useForm();

    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const d1 = await form.validateFields();
            d1.slotId = d1.slotId[1];
            console.log(d1);
            const d2 = await offlineImport(d1 as any);
            if (d2.res > 0) {
                message.info("脱机入库成功!");
                form.resetFields();
            }
            else {
                message.error(d2.msg);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form]);

    return (<div className="offline-import-root">
        <div style={{ width: 300 }}>
            <Form layout="horizontal" form={form} labelCol={{ span: 8 }} >
                <Form.Item label="托盘条码" name="stockCode" rules={[{ required: true, pattern: /^[0-9]{6}$/, message: "托盘条码格式错误" }]} required>
                    <Input />
                </Form.Item>
                <Form.Item label="储位编码" name="slotId" rules={[{ required: true }]} required>
                    <Cascader showSearch allowClear options={options?.slot} />
                </Form.Item>
                <Form.Item label="入库单" >
                    <Select options={options?.bill} loading={loading} optionFilterProp="label" showSearch onChange={setBillId as any} ></Select>
                </Form.Item>
                <Form.Item label="物品名称" name="notifyId" rules={[{ required: true }]} required>
                    <Select options={notifyOptions} loading={loading} filterOption={(input, opt: any) => {
                        console.log(opt, input);
                        return opt.data.goodsName.toLowerCase().includes(input?.toLowerCase())
                            || opt.data.standard?.toLowerCase().includes(input?.toLowerCase());
                    }} showSearch allowClear ></Select>
                </Form.Item>
                <Form.Item label="入库数量" name="quantity" rules={[{
                    validator: (s, v) => {
                        if (v < 0) return Promise.reject(new Error("入库数量不能小于0"));
                        return Promise.resolve();
                    }
                }]} required>
                    <InputNumber />
                </Form.Item>
            </Form>
            <Button block loading={loading} disabled={loading} onClick={onSubmit}>脱机入库</Button>
        </div >
    </div>);
}

