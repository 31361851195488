import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { ViewLanewayPort } from "@/Models/Views/ViewLanewayPort";
import { PortType } from '@/Models/Enums/PortType';

const name = 'LanewayPort';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function setPriority(data: ViewLanewayPort[]): Promise<ViewLanewayPort[]> {
    return post(getUrl("SetPriority"), data);
}
export function getLanewayPortViewsByPortId(portId: number): Promise<ViewLanewayPort[]> {
    return get(getUrl("GetLanewayPortViewsByPortId"), { PortId: portId });
}
export function getLanewayPortViewsByLanewayId(lanewayId: number, type: PortType): Promise<ViewLanewayPort[]> {
    return get(getUrl("GetLanewayPortViewsByLanewayId"), { lanewayId, type });
}
export function getPortSelectItems(type: PortType): Promise<SelectItem[]> {
    return get(getUrl("GetPortSelectItems"), { type });
}
export function setDisabled(lanewayId: number, portId: number, disabled: boolean): Promise<number> {
    return get(getUrl("SetDisabled"), { lanewayId, portId, disabled });
}
