import React from "react";

export function CutLine() {
    return <div className="cut-line">
        <div className="title">
            <span>货位颜色表示</span>
        </div>
        <div className="item Empty"><span>空储位</span></div>
        <div className="item HasSomething"><span>有物品</span></div>
        <div className="item Importing"><span>正在入库</span></div>
        <div className="item Exporting"><span>正在出库</span></div>
        <div className="item Lock"><span>储位锁定</span></div>
        <div className="item WaitingConfirm"><span>待确认</span></div>
    </div>;
}