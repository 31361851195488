import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { Col, Form, Input, Row, Select, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { PurchaseNotify } from "@/Models/Tables/PurchaseNotify";
import { getGroupBySelectItemExt1, SelectItem, SelectItemGroup } from "@/Models/Other/SelectItem";
import { getGoodsSelectItems, getViewsByGoodsIdAndNotifyId } from "@/Api/PurchaseRequisitionController";
import { getPurchaseBillSelectItems } from "@/Api/PurchaseBillController";
import { ViewPurchaseRequisition } from "@/Models/Views/ViewPurchaseRequisition";
import { PurchaseRequisition } from "@/Models/Tables/PurchaseRequisition";
import { ColumnType } from "@/Common/Table/type";
import { numProps, strProps } from "@/Common/Table/column";

function Item(props: IFormItemProps<PurchaseNotify>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<PurchaseNotify>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{
        bill: SelectItem[],
        goods: SelectItemGroup[],
        requisitions: ViewPurchaseRequisition[]
    }>({ bill: [], goods: [], requisitions: [] });
    const goodsId = Form.useWatch("goodsId", form);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const goodsFn = getGoodsSelectItems();
                const billFn = getPurchaseBillSelectItems();
                const [goods, bill] = await Promise.all([goodsFn, billFn]);
                setOptions(s => ({ ...s, bill, goods: getGroupBySelectItemExt1(goods) }));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            console.log(t1);
            onSubmit({ ...data, ...t1 });
        } catch (error) {
            console.error('has a error ', error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onSubmit]);

    return <MyModal confirmLoading={loading} name={`采购明细`} data={data} width={1280} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" layout="vertical" labelCol={{ span: 8 }} initialValues={data} onFinish={console.log}>
            <Row gutter={24}>
                <Col span={6}>
                    <Item name="billId" label="采购单" labelAlign="right" >
                        <Select options={options.bill} loading={loading} disabled={loading} optionFilterProp="label" showSearch allowClear />
                    </Item>
                </Col>
                <Col span={6}>
                    <Item name="goodsId" label="物料名称" labelAlign="right" >
                        <Select options={options.goods} loading={loading} disabled={loading || data !== undefined} optionFilterProp="label" showSearch allowClear />
                    </Item>
                </Col>
                <Col span={12}>
                    <Item name="remark" label="备注" >
                        <Input ></Input>
                    </Item>
                </Col>
            </Row>
            <Form.Item name="requisitions" label="需求明细" labelAlign="left" >
                <RequisitionSelect data={data} goodsId={goodsId} />
            </Form.Item>
        </Form>
    </MyModal>;
}

export default Add;


function getColumns(): ColumnType<ViewPurchaseRequisition>[] {
    return [
        numProps("id", "Id", 60, true),
        strProps("contractName", "合同名称", 120, true, true),
        strProps("bluePrintName", "图纸名称", 120, true, true),
        strProps("goodsCode", "物料编码", 120, true, true),
        strProps("goodsName", "物料名称", 200, true, true),
        strProps("brand", "品牌", 160, true, true),
        strProps("goodsStandard", "物料规格", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

function RequisitionSelect({ data, goodsId, value, onChange }: { data?: PurchaseNotify, goodsId?: number, value?: number[], onChange?: (value: PurchaseRequisition[]) => void }) {
    const columns = useMemo(getColumns, []);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<ViewPurchaseRequisition[]>([]);
    const [selectItems, setSelectItems] = useState<ViewPurchaseRequisition[]>([]);
    const selectedRowKeys = useMemo(() => selectItems.map(s => s.id), [selectItems]);
    useEffect(() => {
        if (onChange) {
            onChange(selectItems);
        }
    }, [onChange, selectItems])

    useEffect(() => {
        if (goodsId) {
            (async () => {
                setLoading(true);
                try {
                    const items = await getViewsByGoodsIdAndNotifyId(goodsId, data?.id);
                    setItems(items);
                } catch (error) {
                    console.error(error);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [data, goodsId]);
    return (<Table rowKey={s => s.id} loading={loading} size="small" columns={columns} rowSelection={{ selectedRowKeys, onChange: (_, rows) => setSelectItems(rows) }} dataSource={items} />)
}
