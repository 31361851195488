import { get, post } from '@/Api';
import { OrderSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { ImportOrder } from '@/Models/Tables/Order';
import { ViewImportOrder } from '@/Models/Views/ViewImportOrder';

const name = 'ImportOrder';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getUnFinishedViews(): Promise<ViewImportOrder[]> {
    return get(getUrl("getUnFinishedViews"));
}

export function add(notifyId: number, stockCode: string): Promise<number> {
    return get(getUrl("add"), { notifyId, stockCode });
}

export function addOrders(data: ImportOrder[]): Promise<number> {
    return post(getUrl("addOrders"), data);
}

export function getViews(data: OrderSplitPageProps): Promise<PaginationData<ViewImportOrder>> {
    return get(getUrl("getViews"), data);
}

export function cancel(id: number): Promise<ViewImportOrder> {
    return get(getUrl("cancel"), { id });
}

export function faultImport(stockCode: string): Promise<number> {
    return get(getUrl("FaultImport"), { stockCode });
}

export function reSetUploadFlag(id: number): Promise<number> {
    return get(getUrl("ReSetUploadFlag"), { id });
}