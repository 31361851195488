import { get } from '@/Api';
import { TaskExecuteFlag } from '@/Models/Enums/TaskExecuteFlag';
import { EquipmentTaskLog } from '@/Models/Tables/EquipmentTaskLog';

const name = 'EquipmentTask';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function deleteTask(taskId: number): Promise<number> {
    return get(getUrl("Delete"), { taskId });
}
export function updFlag(taskId: number, originFlag: TaskExecuteFlag, targetFlag: TaskExecuteFlag): Promise<number> {
    return get(getUrl("updFlag"), { taskId, originFlag, targetFlag });
}
export function emptyStockImport(stockCode: string, quantity = 1): Promise<number> {
    return get(getUrl("emptyStockImport"), { stockCode, quantity });
}
export function emptyExport(slotId?: number): Promise<number> {
    return get(getUrl("EmptyExport"), { slotId });
}
export function slotExport(slotId: number): Promise<number> {
    return get(getUrl("SlotExport"), { slotId });
}
export function getLogs(taskId: number): Promise<EquipmentTaskLog[]> {
    return get(getUrl("GetLogs"), { taskId });
}