import { get, post } from '@/Api';
import { SelectItem } from '@/Models/Other/SelectItem';
import { GoodsStatus } from '@/Models/Tables/GoodsStatus';

namespace GoodsStatusApi {
    const name = 'GoodsStatus';

    function getUrl(url: string) {
        return `${name}/${url}`;
    }
    export function getViews(): Promise<GoodsStatus[]> {
        return get(getUrl("getViews"));
    }
    export function add(data: GoodsStatus): Promise<GoodsStatus> {
        return post(getUrl("add"), data);
    }
    export function upd(data: GoodsStatus): Promise<GoodsStatus> {
        return post(getUrl("upd"), data);
    }
    export function del(id: number): Promise<number> {
        return get(getUrl("del"), { id });
    }
    export function getSelectItems(): Promise<SelectItem[]> {
        return get(getUrl("getSelectItems"));
    }
}
export default GoodsStatusApi;