import {
  HashRouter,
  Navigate,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import Led from "../Pages/Led";
import Wcs from "../Pages/Wcs";
import Wms from "../Pages/Wms";
import { Button, Layout, Space } from "antd";
import { logo } from "./config";
import "./index.scss";
import Login from "../Pages/Login";
import { HubError, useCreateHubProvider } from "@/Hubs/HubError";
import { GlobalProvider, GlobalProviderContext } from "./GlobalProvider";
import { useEffect, useRef, useState } from "react";
import { cleanLoginInfo, getSysUserToken, setSysUserToken } from "@/Common/LocalStorageProvider";
import { refresh } from "@/Api/SysUserController";
import { setToken } from "@/Api";
import UserInfo from "@/Common/UserInfo";
import { NetWorkSpeedComponent } from "@/Common/NetWorkSpeedComponent";
import PLCTest from "@/Pages/plcTest";
import Test from "@/Pages/Test";

function App() {
  return (
    <HashRouter>
      <HubError >
        <RootLayout />
      </HubError>
    </HashRouter>
  );
}

export default App;

function RootLayout() {
  const { nav, user, provider, isReady, element } = useUserInfoHandle();

  return (<GlobalProviderContext.Provider value={provider}>
    <Layout className="app-layout">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="..." onClick={() => user && nav("/wms")} />
        </div>
        <div style={{ display: "flex" }}>
          <NetWorkSpeedComponent plcNo={1} name="弯轨库" />
          <NetWorkSpeedComponent plcNo={2} name="料箱库" />
          <NetWorkSpeedComponent plcNo={4} name="分拣线" />
          <NetWorkSpeedComponent plcNo={3} name="2T库" />
          <NetWorkSpeedComponent plcNo={5} name="穿梭库" />
        </div>
        <div className="title">科德仓库管理系统</div>
        <div className="user-info">
          {user && <UserInfo data={user} isEdit />}
        </div>
      </div>
      <div className="content">
        {isReady && element}
      </div>
      <div className="footer">
        <span>
          {`Copyright © 2021 - Kedle. All Rights Reserved.科德智能 版权所有`}
        </span>
        <div className="other-nav">
          <Space>
            <Button size="small" type="link" onClick={() => { document.title = "WMS数据管理"; nav("/wms"); }}>WMS数据管理</Button>
            <Button size="small" type="link" onClick={() => { document.title = "WCS运行监视"; nav("/wcs"); }}>WCS运行监视</Button>
            <Button size="small" type="link" onClick={() => { document.title = "Led配置"; nav("/led"); }}>Led配置</Button>
            <Button size="small" type="link" onClick={() => { document.title = "PLC测试"; nav("/plcTest"); }}>PLC测试</Button>
            <Button size="small" type="link" onClick={() => { document.title = "测试"; nav("/test"); }}>测试</Button>
          </Space>
        </div>
      </div>
    </Layout>
  </GlobalProviderContext.Provider>)
}

function useUserInfoHandle() {
  const [isReady, setLoading] = useState(false);
  const provider = useCreateHubProvider(GlobalProvider);
  const nav = useRef(useNavigate());
  const [user, setUser] = useState(provider.user.Value);
  useEffect(() => provider.user.listen(setUser), [provider]);
  useEffect(() => {
    (async () => {
      try {
        const d1 = getSysUserToken();
        if (!d1) {
          throw new Error('no login');
        }
        setToken(d1.token);
        const d2 = await refresh();
        if (!d2) {
          throw new Error('refresh token fail');
        }
        setSysUserToken(d2);
        provider.user.Value = d2;
      } catch (error) {
        console.error(error);
        cleanLoginInfo();
        nav.current("/login", { state: document.location.pathname });
      }
      finally {
        setLoading(true);
      }
    })();
    return provider.refresh.listen(async () => {
      const d2 = await refresh();
      if (d2) {
        setSysUserToken(d2);
        provider.user.Value = d2;
      }
      else {
        cleanLoginInfo();
        provider.user.Value = null;
        nav.current("/login", { state: document.location.pathname });
      }
    });
  }, [provider]);
  const element = useRoutes([
    { path: "/wms/*", element: (<Wms />) },
    { path: "/wcs", element: (<Wcs />) },
    { path: "/led", element: (<Led />) },
    { path: "/plcTest", element: (<PLCTest />) },
    { path: "/test", element: (<Test />) },
    { path: "/login", element: (<Login />) },
    { path: "*", element: (<Navigate to="/wms" />) },

  ])
  return { nav: nav.current, user, provider, isReady, element };
}
