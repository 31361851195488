import { get, post } from '@/Api';
import { Slot } from '@/Models/Tables/Slot';
import { ViewSlot } from '@/Models/Views/ViewSlot';

const name = 'FlatSlot';

function getUrl(url: string) {
    return `${name}/${url}`;
}
export function getViews(): Promise<ViewSlot[]> {
    return get(getUrl("getViews"));
}
export function add(data: Slot): Promise<ViewSlot> {
    return post(getUrl("add"), data);
}
export function upd(data: Slot): Promise<ViewSlot> {
    return post(getUrl("upd"), data);
}
export function del(id: number): Promise<number> {
    return get(getUrl("del"), { id });
}
