import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { DeviceMessageHub } from "@/Hubs/DeviceMessageHub";
import { MessageItem } from "@/Hubs/IActions/IDeviceMessageHubAction";
import { forEach } from "lodash";
import { createContext } from "react";


export class DeviceMessageProvider {
    public readonly hub: DeviceMessageHub;
    constructor(onError: tpOnError) {
        this.hub = new DeviceMessageHub(onError);
        this.hub.on("MessageAppend", data => {
            if (this.data.Value[data.key]) {
                if (this.data.Value[data.key].Value.length >= 20) {
                    this.data.Value[data.key].Value.splice(0, 1);
                }
                this.data.Value[data.key].Value.push(data.value);
                this.data.Value[data.key].resetValue();
            }
            else {
                this.data.Value[data.key] = new Obs([data.value]);
                this.data.resetValue();
            }
        });
        this.hub.start(async () => {
            try {
                const data: { [K: string]: Obs<MessageItem[]> } = {};
                const obj = await this.hub.getData();
                forEach(obj, (s, k) => {
                    data[k] = new Obs(s);
                });
                this.data.Value = data;
            } catch (error) {
                console.log(error);
            }
        });
    }
    public data = new Obs<{ [K: string]: Obs<MessageItem[]> }>({});
}

export const DeviceMessageProviderContext = createContext<DeviceMessageProvider>(null as any);