import { get } from '@/Api';
import { TaskSplitPageProps } from '@/Models/Other/BaseSplitPageProps';
import { PaginationData } from "@/Models/Other/PaginationData";
import { EquipmentTaskLog } from '@/Models/Tables/EquipmentTaskLog';
import { ViewEquipmentTaskFinished } from '@/Models/Views/ViewEquipmentTask';

const name = 'EquipmentTaskFinished';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getViews(data: TaskSplitPageProps): Promise<PaginationData<ViewEquipmentTaskFinished>> {
    return get(getUrl("getViews"), data);
}
export function getViewsByStartAndEnd(data: { start: number, end: number }): Promise<ViewEquipmentTaskFinished[]> {
    return get(getUrl("GetViewsByStartAndEnd"), data);
}
export function getLogs(taskId: number): Promise<EquipmentTaskLog[]> {
    return get(getUrl("GetLogs"), { taskId });
}