
export enum BillTypeCategory {
    Import = 1,
    Export = 2,
    Check = 3
}

export type TypeBillTypeCategory = keyof typeof BillTypeCategory;

const BillTypeCategoryNames: { [K in TypeBillTypeCategory]: string } = {
    Import: "入库单",
    Export: "出库单",
    Check: "盘点单",
};

export function getBillTypeCategoryName(e: TypeBillTypeCategory) {
    return BillTypeCategoryNames[e];
}

export function getOptions() {
    return Object.keys(BillTypeCategoryNames).map(s => ({ label: (BillTypeCategoryNames as any)[s], value: s }));
}