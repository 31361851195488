import { isArray } from "lodash";



export class Obs<T> {
    private _val: T;

    constructor(defaultValue: T) {
        this._val = defaultValue;
    }

    get Value() {
        return this._val;
    }

    set Value(val: T) {
        this._val = val;
        this._fns.forEach(f => f(this._val));
    }

    readonly resetValue = () => {
        if (isArray(this.Value)) {
            this.Value = [...this.Value] as any;
        }
        else {
            this.Value = Object.assign({}, this.Value);
        }
    }

    private _fns: ((val: T) => void)[] = [];

    readonly listen = (fn: (val: T) => void) => {
        this._fns.push(fn);
        return () => {
            const index = this._fns.indexOf(fn);
            if (index === -1) {
                console.error("钩子卸载失败!");
            }
            else {
                this._fns.splice(index, 1);
            }
        };
    }

}
