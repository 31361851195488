import { MouseEventRegister } from "@/Common/CropImg/MouseEventRegister";


export interface ICanvasProps {
    c2d: CanvasRenderingContext2D;
    img: HTMLImageElement;
    width: number;
    height: number;
}

export class Context2DOperator {

    constructor(c2d: CanvasRenderingContext2D,
        img: HTMLImageElement,
        div: HTMLDivElement
    ) {
        this.c2d = c2d;
        this.img = img;
        this.canvas = c2d.canvas;
        this.canvas.height = div.clientHeight;
        this.canvas.width = div.clientWidth;
        this.width = img.width;
        this.height = img.height;
        c2d.drawImage(img, this.x, this.y, this.width, this.height);
        this.canvas.hidden = false;
        img.hidden = true;
        this.cop = new MouseEventRegister(this);
    }
    c2d: CanvasRenderingContext2D;
    img: HTMLImageElement;
    canvas: HTMLCanvasElement;
    cop: MouseEventRegister;
    x: number = 0;
    y: number = 0;
    width: number;
    height: number;


    clear = () => {
        this.cop.clear();
        console.log('注销！');
    }

    move = (x: number, y: number) => {
        this.x += x;
        this.y += y;
        this.draw();
    }

    /**
     * 缩放  耗时两天，特此写注释 需要静下心来画图模拟理清思路
     * @param inc 放大 or 缩小
     * @param offset  鼠标所在的偏移量
     */
    zoom = (inc: boolean, offset: { offsetX: number, offsetY: number }) => {

        /**
         *   *0.1表示放大或缩小的量
         */
        const tmpW = this.width * 0.1;
        const tmpH = this.height * 0.1;

        /**
         * 这里必须要进行-x y操作  这样才能获取到鼠标所在位置相对与原点的距离
         */
        const ox = offset.offsetX - this.x;
        const oy = offset.offsetY - this.y;

        /**
         * 这里计算原点将要偏移的量
         */
        const offx = + ox * 0.1;
        const offy = + oy * 0.1;

        if (inc) {
            this.width = this.width + tmpW;
            this.height = this.height + tmpH;
            /**
             * 放大操作
             * 原点偏移
             * 原点 = 原点 -原点要偏移的量
             */
            this.x = this.x - (offx);
            this.y = this.y - (offy);
        }
        else {
            this.width = this.width - tmpW;
            this.height = this.height - tmpH;

            /**
             * 缩放操作
             * 原点 = 原点偏移的量+原点
             */
            this.x = (offx) + this.x;
            this.y = (offy) + this.y;
        }
        this.draw();
    }

    draw = () => {
        this.c2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.c2d.drawImage(this.img, this.x, this.y, this.width, this.height);
    }
}




