import { get } from '@/Api';
import { ViewTemporaryStock } from '@/Models/Views/ViewTemporaryStock';

const name = 'TemporaryStock';

function getUrl(url: string) {
    return `${name}/${url}`;
}

export function getViews(): Promise<ViewTemporaryStock[]> {
    return get(getUrl("getViews"));
}

export function updStockCode(oldStockCode: string, newStockCode: string): Promise<number> {
    return get(getUrl("UpdStockCode"), { oldStockCode, newStockCode });
}
