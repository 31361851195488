import CustomerApi from "@/Api/CustomerApi";
import { getDepartmentSelectItems } from "@/Api/DepartmentController";
import { AddProps, convertProps, IFormItemProps, MyModal } from "@/Common/Form/MyModal";
import { getContractStatusOptions as getContractStatusSelectOptions } from "@/Models/Enums/ContractStatus";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Contract } from "@/Models/Tables/Contract";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

function Item(props: IFormItemProps<Contract>) {
    return <Form.Item {...convertProps(props)}  ></Form.Item>;
}

function Add({ onCancel, onSubmit, data }: AddProps<Contract>) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ customer: SelectItem[], department: SelectItem[] }>({ customer: [], department: [] });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const customer = await CustomerApi.getSelectItems();
                const department = await getDepartmentSelectItems();
                setOptions({ customer, department });
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        form.setFieldValue("dateValue", dayjs(data?.date));
    }, [data?.date, form])

    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const t1 = await form.validateFields();
            const date = t1.dateValue.valueOf();
            onSubmit({ ...data, ...t1, date });
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [data, form, onSubmit]);

    return <MyModal confirmLoading={loading} name={`${data?.code || ''}合同信息`} data={data} width={400} onCancel={onCancel} onOk={onOk}>
        <Form form={form} labelAlign="right" labelCol={{ span: 8 }} initialValues={data}>
            <Item name="customerId" label="客户" labelAlign="right" required>
                <Select options={options.customer} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="departmentId" label="部门" labelAlign="right" required>
                <Select options={options.department} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="name" label="合同名称" labelAlign="right" required >
                <Input />
            </Item>
            <Item name="price" label="合同金额" labelAlign="right" required >
                <InputNumber precision={4} min={1} style={{ width: '100%' }} />
            </Item>
            <Form.Item name="dateValue" label="签订日期" labelAlign="right" required rules={[{ required: true }]} >
                <DatePicker picker="date" />
            </Form.Item>
            <Item name="status" label="合同状态" labelAlign="right"  >
                <Select options={getContractStatusSelectOptions()} loading={loading} optionFilterProp="label" showSearch allowClear ></Select>
            </Item>
            <Item name="remark" label="备注" >
                <Input.TextArea rows={3} ></Input.TextArea>
            </Item>
        </Form>
    </MyModal>;
}

export default Add;