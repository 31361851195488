import { ViewSlot } from "@/Models/Views/ViewSlot";
import { createContext } from "react";

export const LocationPositionProviderContext = createContext<LocationPositionProvider>(null as any);
export class LocationPositionProvider {
    private _positionData: { [K: string]: { left: number, top: number } } = {};
    constructor() {
        this._positionData = {};
    }
    public readonly getPosition = (data: ViewSlot) => {
        if (!this._positionData[data.id]) {
            this._positionData[data.id] = this.buildPosition(data);
        }
        return this._positionData[data.id];
    }
    private buildPosition(data: ViewSlot) {
        return this.buildLaneway1Position(data);
    }

    private _rowPosition: { [K: number]: number } = {
        1: 320,
        2: 93,
    }
    private _colPosition: { [K: number]: number } = {
        1: 1012,
        2: 1012 - 86.75 * 1,
        3: 1012 - 86.75 * 2,
        4: 1012 - 86.75 * 3,
        5: 665,
    }

    private buildLaneway1Position(data: ViewSlot) {
        const top = this._rowPosition[data.row];
        const left = this._colPosition[data.column];
        return { top, left }
    }
}




