import { Obs } from "@/Common/Obs";
import { LeafComponentProps } from "./LeafComponentProps";


export class LeafDataProvider {
    readonly data: Obs<Obs<LeafComponentProps>[]> = new Obs<Obs<LeafComponentProps>[]>([]);
    readonly add = () => {
        const d1 = new Obs(new LeafComponentProps("新图形", 10, 0, 0 + 2));
        this.data.Value.push(d1);
        this.data.resetValue();
        return d1;
    };

    readonly remove = (d: Obs<LeafComponentProps>) => {
        const index = this.data.Value.findIndex(s => s === d);
        if (index > -1) {
            this.data.Value.splice(index, 1);
            this.data.resetValue();
        }
    };
}
