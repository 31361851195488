import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { SlotHub } from "@/Hubs/SlotHub";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { createContext } from "react";


export class SlotProvider {

    public readonly hub: SlotHub;

    constructor(onError: tpOnError) {
        this.hub = new SlotHub(onError);
        this.hub.on("DataChange", data => {
            const index = this.data.Value.findIndex(s => s.id === data.id);
            if (index !== -1) {
                this.data.Value[index] = data;
                this.data.resetValue();
                if (data.id === this.showStockInfo.Value?.id) {
                    this.showStockInfo.Value = data;
                }
            }
            else {
                console.error('cant find the data', data.id);
            }
        });
        this.hub.on("DataListChange", data => {
            this.data.Value = this.data.Value.map(s => data.find(a => s.id === a.id) || s);
            this.data.resetValue();
        });
        this.hub.start(async () => {
            try {
                this.data.Value = await this.hub.getViews();
            } catch (error) {
                console.log(error);
            }
            finally {
                this.loading.Value = false;
            }
        });
    }
    public loading = new Obs<boolean>(true);
    public data = new Obs<ViewSlot[]>([]);
    public readonly showStockInfo = new Obs<ViewSlot | null>(null);

}

export const SlotProviderContext = createContext<SlotProvider>(null as any);