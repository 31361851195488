import axios, { AxiosResponse } from 'axios';
import { message, notification } from 'antd';
export { axios };
const getUrl = (url: string) => `/api/${url}`;

export async function get<T>(url: string, data?: any): Promise<T> {
    try {
        const res = await axios.get(getUrl(url), data && { params: data });
        return check(res) as any;
    } catch (error) {
        throw errorHandle(error);
    }
}

export async function getLogin<T>(url: string, data?: any): Promise<T> {
    try {
        const res = await axios.get(getUrl(url), data && { params: data });
        return check(res);
    } catch (error: any) {
        let msg: string;
        if (error?.response?.status > 596) {
            msg = error.response.data;
        }
        else {
            msg = JSON.stringify(error);
        }
        message.info(msg);
        throw msg;
    }
}

export async function post<T>(url: string, data?: any): Promise<T> {
    try {
        const res = await axios.post(getUrl(url), data);
        return check(res);
    } catch (error) {
        throw errorHandle(error);
    }
}

const errorHandle = (error: any) => {
    let msg: string;
    if (error.message === 'Request failed with status code 401') {
        msg = "您的登陆已过期!请重新登陆";
        message.info(msg);
        commonFunction.isLast?.call(null);
    }
    else {
        if (error?.response) {
            if (error.response.status > 596) {
                msg = error.response.data;
            }
            else {
                msg = error.response.status.toString();
            }
            message.error(msg);
            return;
        }
        else {
            msg = JSON.stringify(error);
        }
        notification.open({
            message: msg,
            type: 'error',
            duration: 2,
            placement: 'bottomRight',
        });
    }
    return msg;
};

const check = (data: AxiosResponse<any>) => {
    if (data.status === 298) {
        // message.warn(data.data);
        notification.open({
            message: JSON.stringify(data.data),
            type: 'warning',
            duration: 2,
            placement: 'bottomRight',
        });
        return null;
    }
    if (data.status === 297) {
        // message.warn(data.data);
        notification.open({
            message: JSON.stringify(data.data),
            type: 'error',
            duration: 2,
            placement: 'bottomRight',
        });
        return null;
    }
    else {
        return data.data;
    }
};

export const setToken = (token: string) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const commonFunction: { isLast?: () => void } = {};
//