import SupplierApi from "@/Api/SupplierApi";
import EditTable from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { Supplier } from "@/Models/Tables/Supplier";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<Supplier>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "编码", 160, true, true),
        strProps("name", "供应商名称", 200, true, true),
        strProps("linkMan", "联系人", 120, true, true),
        strProps("phoneNumber", "联系电话", 160, true, true),
        strProps("address", "地址", 200, true, true),
        strProps("remark", "备注", 200, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< Supplier> getData={SupplierApi.getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={SupplierApi.upd}
        delDataHandle={s => SupplierApi.del(s.id)}
        updMerge={updMerge}
        addDataHandle={SupplierApi.add}
        rowKey={"id"}
    />;
}

function updMerge(source: Supplier, target: Supplier) {
    target.id = source.id;
    return target;
}