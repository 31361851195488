import Bill from './Bill';
import {
    ImportOutlined,
    ProfileOutlined,
    ManOutlined
} from '@ant-design/icons';
import { IMenuGroup } from '../../routeData';
import Requisition from './Requisition';
import Notify from './Notify';

function purchaseGroup(): IMenuGroup {
    return {
        name: "采购管理",
        path: "purchase",
        icon: ImportOutlined,
        items: [
            {
                name: "需求清单",
                icon: ManOutlined,
                element: <Requisition />,
                path: "Requisition",
                right: 1 << 0,
            },
            {
                name: "采购单",
                icon: ProfileOutlined,
                element: <Bill />,
                path: "Bill",
                right: 1 << 1,
            },
            {
                name: "采购明细",
                icon: ProfileOutlined,
                element: <Notify />,
                path: "Notify",
                right: 1 << 2,
            },
        ]

    }
}

export default purchaseGroup; 