import React, { Fragment } from "react";
import { StockInfoList } from "./StockInfoList";
import { StockInfoListProps } from "./StockInfoListProps";

export function SlotOperation(props: StockInfoListProps) {
    if (props.data.status === "HasSomething")
        return <StockInfoList {...props} />;
    else
        return <Fragment />;
}
