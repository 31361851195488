import { TaskMode } from "@/Models/Enums/TaskMode";
import { ViewEquipmentTask } from "@/Models/Views/ViewEquipmentTask";
import { BaseHub, tpOnError } from "./BaseHub";
import { IEquipmentTaskHubAction } from "./IActions/IEquipmentTaskHubAction";



export class EquipmentTaskHub extends BaseHub<IEquipmentTaskHubAction> {
    constructor(onError: tpOnError) {
        super(onError, "Hubs/EquipmentTask");
    }
    public getViews = (mode: TaskMode) => this.hub.invoke<ViewEquipmentTask[]>("GetViews", mode);
}
