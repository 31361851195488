import { getLanewayPortViewsByPortId, getPortSelectItems, setDisabled, setPriority } from "@/Api/LanewayPortController";
import { boolProps, numProps, strProps } from "@/Common/Table/column";
import { DragSortingTable } from "@/Common/Table/DragSortingTable";
import { ColumnType } from "@/Common/Table/type";
import { getImportPortOptions, PortType } from "@/Models/Enums/PortType";
import { SelectItem } from "@/Models/Other/SelectItem";
import { ViewLanewayPort } from "@/Models/Views/ViewLanewayPort";
import { Button, Radio, Select, Space, Switch, Table } from "antd";
import React, { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";

export default Index;

function getColumns(): ColumnType<ViewLanewayPort>[] {
    return [
        strProps("portName", "设备名称", 160, false, false),
        numProps("stationNo", "设备站台号", 80, false),
        strProps("warehouseName", "库房名称", 160, false, false),
        strProps("lanewayName", "巷道名称", 160, false, false),
        numProps("no", "巷道号", 80, false),
        boolProps("disabled", "状态", 80, false, (s, r) => (<EditStatus record={r} />)),
        numProps("priority", "优先级", 80, false),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewLanewayPort[]>([]);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [options, setOptions] = useState<SelectItem[]>([]);
    const [stationNo, setStationNo] = useState<number>();
    const [portType, setPortType] = useState(PortType.Import);

    useEffect(() => {
        if (stationNo) {
            (async () => {
                setLoading(true);
                try {
                    setData(sortData(await getLanewayPortViewsByPortId(stationNo)));
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [stationNo]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setOptions(await getPortSelectItems(portType));
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [portType]);

    const onEdit = useCallback(() => setIsEdit(true), []);
    const onCancel = useCallback(() => setIsEdit(false), []);
    const onSave = useCallback(async (d: ViewLanewayPort[]) => {
        setLoading(true);
        try {
            const d1 = d.map((a, b) => {
                a.priority = b + 1;
                return a;
            });
            const d2 = await setPriority(d1);
            setData(sortData(d2));
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
        setIsEdit(false);
    }, []);

    const portOptions = useMemo(getImportPortOptions, []);

    return (<div className="port-item">
        {
            (isEdit) ?
                (<DragSortingTable loading={loading} onSave={onSave} onCancel={onCancel} size="middle" bordered pagination={false} dataSource={data} columns={columns} title={() => `调整${stationNo}优先级`} />)
                :
                (
                    <Table size="middle" rowKey={s => `${s.lanewayId}-${s.portId}`} bordered pagination={false} dataSource={data} columns={columns} title={() => (<div>
                        <Space>
                            <Select style={{ minWidth: 160 }} value={stationNo} options={options} showSearch allowClear={false} onChange={(s) => setStationNo(s as any)} />
                            <Radio.Group optionType="button" options={portOptions} value={portType} onChange={s => setPortType(s.target.value)}></Radio.Group>
                        </Space>
                        {stationNo && <Button type="link" style={{ float: 'right' }} size="middle" onClick={onEdit}>调整优先级</Button>}
                    </div>)} />
                )
        }
    </div>);
}

function sortData(s: ViewLanewayPort[]) {
    s.sort((a, b) => a.priority - b.priority);
    return s;
}

export function EditStatus({ record }: { record: ViewLanewayPort }) {
    const [status, setStatus] = useState(!record.disabled);
    const [loading, setLoading] = useState(false);
    const onConfirm = useCallback(async () => {
        setLoading(true);
        try {
            await setDisabled(record.lanewayId, record.portId, !record.disabled);
            record.disabled = !record.disabled;
            setStatus(!record.disabled);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }, [record]);
    return (<Switch loading={loading} disabled={loading} checkedChildren="可达" unCheckedChildren="关闭" checked={status} onClick={onConfirm} />);
}