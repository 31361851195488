import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Table } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { TableProps } from 'antd/lib/table';
import './index.scss';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {} as any;
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item: any) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

export function DragSortingTable<T extends {}>({ dataSource, onSave, title, onCancel, ...props }: TableProps<T> & { onSave: (datas: T[]) => void, dataSource: T[], onCancel: () => void }) {

    const [data, setData] = useState(dataSource!);

    useEffect(() => {
        setData(dataSource!);
    }, [dataSource]);

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex: any, hoverIndex: any) => {
            const dragRow = data[dragIndex];
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [data],
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <Table               {...props}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                    index,
                    moveRow,
                } as any)}
                title={(d) => (<div >
                    {title && title(d)}
                    <Button.Group style={{ float: "right" }}>
                        <Button type='link' size='middle' disabled={data === dataSource} onClick={() => onSave(data)}>保存</Button>
                        <Button type='link' size='middle' onClick={onCancel}>取消</Button>
                    </Button.Group>
                </div>)}
            />
        </DndProvider>
    );
}
