import ImportNotifyApi from "@/Api/ImportNotifyApi";
import { add } from "@/Api/ImportOrderController";
import { toYMD } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { ViewImportNotify } from "@/Models/Views/ViewImportNotify";
import { Form, Input, Modal, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ExpandableConfig } from "antd/lib/table/interface";
import { sum } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

export const expandable: ExpandableConfig<ViewImportBill> = {
    expandedRowRender,
};

function expandedRowRender(record: ViewImportBill, index: number, indent: number, expanded: boolean) {
    if (expanded) {
        return <Notifies record={record} />;
    }
}

interface NotifiesProps {
    record: ViewImportBill
}

function getColumns(setScanRecord: (record?: ViewImportNotify) => void): ColumnType<ViewImportNotify>[] {
    return [
        numProps("id", "Id", 80, false),
        strProps("goodsTypeName", "物料类别", 120, false, false),
        strProps("goodsCode", "物料编码", 160, false, false),
        strProps("goodsName", "物料名称", 300, false, false),
        strProps("standard", "规格", 200, false, false),
        numProps("packageCount", "包装数量", 120, false),
        strProps("goodsStatusName", "状态", 80, false, false),
        numProps("quantity", "计划数量", 120, false),
        numProps("factQuantity", "已下发数量", 120, false),
        numProps("completeQuantity", "已完成数量", 120, false),
        numProps("productionDate", "生产日期", 160, false, toYMD),
        // { title: "组盘入库", key: "action", width: 100, render: (_, r) => (r.quantity - r.factQuantity > 0) && (<TableInnerButton type="link" onClick={() => setScanRecord(r)}>添加</TableInnerButton>) }
    ];
}

function ScanCode({ record, onCancel }: { record: ViewImportNotify, onCancel: () => void }) {
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const onOk = useCallback(async () => {
        setLoading(true);
        try {
            const data = await form.validateFields();
            const res = await add(record.id, data["stockCode"]);
            console.log(res);
            onCancel();
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, [form, onCancel, record.id]);
    return (<Modal title="扫描缸体" onCancel={onCancel} confirmLoading={loading} open onOk={onOk}>
        <Form form={form} >
            <Form.Item label="托盘条码" name="stockCode">
                <Input></Input>
            </Form.Item>
        </Form>
    </Modal>);
}

function Notifies({ record }: NotifiesProps) {
    const [data, setData] = useState<ViewImportNotify[]>([]);
    const [scanRecord, setScanRecord] = useState<ViewImportNotify>();
    const columns = useMemo(() => getColumns(setScanRecord), []);
    // 加40像素补偿，避免右侧显示异常
    const width = useMemo(() => sum(columns.map(a => a.width || 120)) + 40, [columns]);
    useEffect(() => {
        ImportNotifyApi.getViews(record.id).then(setData)
            .catch(console.error);
    }, [record]);

    const onCancel = useCallback(() => setScanRecord(undefined), []);

    return (<div>
        <Table pagination={false}
            size="small"
            style={{ width }}
            // loading={loading}
            rowKey={s => s.id}
            bordered
            dataSource={data}
            columns={columns}
        />
        {scanRecord && <ScanCode record={scanRecord} onCancel={onCancel} />}
    </div>);
}
