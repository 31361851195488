import GoodsStatusApi from "@/Api/GoodsStatusApi";
import { getViewsExcludeEmptyStock, updStatus } from "@/Api/StockSlotController";
import { CommonTable } from "@/Common/Table/EditTable";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { toYMD, toYMDHMS } from "@/Common/date";
import { getStockFlagName } from "@/Models/Enums/StockFlag";
import { SelectItem } from "@/Models/Other/SelectItem";
import { StockSlotFilter } from "@/Models/Other/StockSlotFilter";
import { ViewStockSlot } from "@/Models/Views/ViewStockSlot";
import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Modal, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Fragment, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { SearchComponent } from "./SearchComponent";
import { StatusComponent } from "./StatusComponent";

export default Index;

function getColumns(): ColumnType<ViewStockSlot>[] {
    return [
        strProps("lanewayName", "巷道", 100, true, true),
        strProps("supplierCode", "供应商", 100, true, true),
        strProps("goodsName", "物品名称", 120, true, true),
        strProps("stockCode", "托盘条码", 120, true, true),
        numProps("quantity", "数量", 80, true),
        strProps("slotCode", "储位编码", 120, true, true),
        strProps("goodsStatusName", "状态", 100, true, true, (_, r) => <StatusComponent record={r} />),
        strProps("batchNo", "批次", 100, true, true),
        numProps("productionDate", "生产日期", 160, true, toYMD),
        numProps("time", "入库时间", 160, true, toYMDHMS),
        { title: "状态", dataIndex: "flag", width: 80, sorter: strSorter("flag"), render: getStockFlagName },
        strProps("remark", "备注", 100, true, true),
    ];
}

function Index() {
    const columns = useMemo(getColumns, []);
    const [data, setData] = useState<ViewStockSlot[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [modal, modalHolder] = Modal.useModal();
    const [statusOptions, setStatusOptions] = useState<SelectItem[]>([]);

    const onSearch = useCallback(async (d: StockSlotFilter) => {
        setLoading(true);
        try {
            const d1 = await getViewsExcludeEmptyStock(d);
            setData(d1);
            setSelectedRowKeys([]);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const d1 = await GoodsStatusApi.getSelectItems();
                d1.push({ label: "无", value: null as any })
                setStatusOptions(d1);
            } catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);

    const onOk = useCallback(async (d: number[], v: number, n: string) => {
        setLoading(true);
        try {
            const r1 = await updStatus(d.map(a => ({ id: a, goodsStatusId: v })));
            if (r1 > 0) {
                setData(c => {
                    c.forEach(e => {
                        if (d.some(f => f === e.id)) {
                            console.log(n);
                            e.goodsStatusName = n;
                        }
                    });
                    return [...c];
                });
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    }, []);

    const onMenuItemClick = useCallback((s: { label: string, value: any }) => {
        modal.confirm({
            title: "警告!", content: `您确定要将这${selectedRowKeys.length}条数据的品质状态转换为${s.label}吗?`,
            onOk: () => onOk(selectedRowKeys, s.value, s.label)
        });
    }, [modal, onOk, selectedRowKeys])

    const menuOptions = useMemo(() => {
        const items = statusOptions.map(s => ({ label: s.label, key: s.value, onClick: () => onMenuItemClick(s) }))
        return { items };
    }, [onMenuItemClick, statusOptions])

    return (<Fragment>
        <SearchComponent onSearch={onSearch} />
        <StatusOptionsContext.Provider value={statusOptions}>
            <CommonTable rowKey={s => s.id} rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys as any }} heightOffset={43} loading={loading} columns={columns} dataSource={data} />
        </StatusOptionsContext.Provider>
        {modalHolder}
        <div style={{ position: 'relative' }}>
            <Space style={{ position: 'absolute', bottom: 8 }}>
                {data.length > 0 && <CheckAllComponent selected={selectedRowKeys} data={data} setKeys={setSelectedRowKeys} />}
                {selectedRowKeys.length > 0 && <Dropdown disabled={loading} menu={menuOptions} trigger={["click"]}>
                    <Button type="primary" >
                        质量转换<DownOutlined />
                    </Button>
                </Dropdown>}
            </Space>
        </div>
    </Fragment>);
}

export const StatusOptionsContext = createContext<SelectItem[]>(null as any);

function CheckAllComponent({ selected, data, setKeys }: { selected: number[], data: ViewStockSlot[], setKeys: (d: number[]) => void }) {
    const value = useMemo(() => selected.length === data.length, [selected, data]);
    const indeterminate = useMemo(() => selected.length > 0 && selected.length < data.length, [data, selected]);
    const text = useMemo(() => {
        if (value) return "反选";
        return "全选";
    }, [value]);
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setKeys(data.map(s => s.id));
        }
        else {
            setKeys([]);
        }
    };
    return <Checkbox checked={value} indeterminate={indeterminate} onChange={onCheckAllChange}>{text}</Checkbox>;
}

