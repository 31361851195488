
import BillTypeApi from "@/Api/BillTypeApi";
import EditTable from "@/Common/Table/EditTable";
import { numProps, strProps } from "@/Common/Table/column";
import { ColumnType } from "@/Common/Table/type";
import { getBillTypeCategoryName } from "@/Models/Enums/BillTypeCategory";
import { BillType } from "@/Models/Tables/BillType";
import { useMemo } from "react";
import Add from "./Add";

function getColumns(): ColumnType<BillType>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("code", "编码", 120, true, true),
        strProps("category", "单据类别", 120, true, true, getBillTypeCategoryName),
        strProps("name", "单据名称", 200, true, true),
        strProps("remark", "备注", 160, true, true),
    ];
}

export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    return <EditTable< BillType> getData={BillTypeApi.getViews}
        baseColumns={columns}
        isRank
        AddDom={Add}
        updDataHandle={BillTypeApi.upd}
        delDataHandle={s => BillTypeApi.del(s.id)}
        updMerge={updMerge}
        addDataHandle={BillTypeApi.add}
        editCheck={s => s.id < 11}
        rowKey={"id"}
    />;
}

function updMerge(source: BillType, target: BillType) {
    target.id = source.id;
    return target;
}