
export enum EquipmentType {
    Stacker = 1,// 堆垛机
    Conveyer = 2,// 输送机
}

export type TypeEquipmentType = keyof typeof EquipmentType;

const EquipmentTypeNames: { [K in TypeEquipmentType]: string } = {
    Conveyer: "输送机",
    Stacker: "堆垛机"
};

export function getEquipmentTypeNames(e: TypeEquipmentType) {
    return EquipmentTypeNames[e];
}

export function getEquipmentTypeOptions() {
    return [
        { label: EquipmentTypeNames.Stacker, value: EquipmentType.Stacker },
        { label: EquipmentTypeNames.Conveyer, value: EquipmentType.Conveyer },
    ];
}

