import GoodsApi from "@/Api/GoodsApi";
import GoodsStatusApi from "@/Api/GoodsStatusApi";
import { utcEndSecond, utcStartSecond } from "@/Common/date";
import { TableActions } from "@/Common/Table/TableActions";
import { ExcludeBasePagination, StockSlotSplitPageProps } from "@/Models/Other/BaseSplitPageProps";
import { SelectItem } from "@/Models/Other/SelectItem";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";

export function SearchComponent({ onSearch }: { onSearch: (data: ExcludeBasePagination<StockSlotSplitPageProps>) => void; }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SelectItem[]>([]);
    const [goodsStatusOptions, setGoodsStatusOptions] = useState<SelectItem[]>([]);
    const [form] = useForm();
    return <TableActions>
        <Form form={form} layout="inline" onFinish={s => {
            let start: number | undefined = undefined;
            let end: number | undefined = undefined;
            if (s["dateRange"]) {
                start = utcStartSecond(s["dateRange"][0]);
                end = utcEndSecond(s["dateRange"][1]);
            }
            onSearch(
                {
                    start, end,
                    billCode: s["billCode"],
                    slotCode: s["slotCode"],
                    goodsId: s["goodsId"],
                    goodsStatusId: s["goodsStatusId"],
                }
            );
        }}>
            <Form.Item name="dateRange" label="日期范围">
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss"></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item label="入库单号" name="billCode">
                <Input />
            </Form.Item>
            <Form.Item label="储位编码" name="slotCode">
                <Input />
            </Form.Item>
            <Form.Item label="物品信息" name="goodsId">
                <Select onDropdownVisibleChange={data.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        GoodsApi.getSelectItems().then(s => setData(s.filter(s => s.value !== 1))).catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 200 }} loading={loading} options={data} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item label="品质状态" name="goodsStatusId">
                <Select onDropdownVisibleChange={data.length === 0 ? s => {
                    if (s) {
                        setLoading(true);
                        GoodsStatusApi.getSelectItems()
                            .then(s => { s.push({ label: " ", value: null as any }); return s; })
                            .then(setGoodsStatusOptions)
                            .catch(console.error).finally(() => setLoading(false));
                    }
                } : undefined} style={{ minWidth: 100 }} loading={loading} options={goodsStatusOptions} optionFilterProp="label" showSearch allowClear />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">查询</Button>
            </Form.Item>
        </Form>
    </TableActions>;
}
