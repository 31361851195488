
export enum PortType {
    StackerPort = 0,// 堆垛机取货,卸货平台
    Import = 1,// 入库口
    Export = 2,// 出库口
    SortOut = 3,// 拣选口
    TurnPort = 4,// 转向输送机
    EmptyStockExport = 5,// 拆盘机
    EmptyStockImport = 6,// 叠盘机
    PdaImport = 7,// 手持入库口
    Upender = 8,// 翻转机
    Palletizer = 9,// 码垛机
    DePalletizer = 10,// 拆垛机
    ChooseImport = 11,// 拆垛机
}



export type TypePortType = keyof typeof PortType;

const portTypeNames: { [K in TypePortType]: string } = {
    "StackerPort": "卸货台",
    "Import": "入库口",
    "Export": "出库口",
    "SortOut": "拣选口",
    "TurnPort": "转向输送机",
    "EmptyStockExport": "拆盘机",
    "EmptyStockImport": "叠盘机",
    "PdaImport": "手持入库口",
    "Upender": "翻转机",
    "DePalletizer": "拆垛机",
    "Palletizer": "码垛机",
    "ChooseImport": "拣选入库口"
};

export function getPortTypeNames(e: TypePortType) {
    return portTypeNames[e];
}

export function getImportPortOptions() {
    return [
        { label: portTypeNames.Import, value: PortType.Import },
        // { label: portTypeNames.Palletizer, value: PortType.Palletizer },
        // { label: portTypeNames.EmptyStockImport, value: PortType.EmptyStockImport },
        { label: portTypeNames.TurnPort, value: PortType.TurnPort },
        { label: portTypeNames.ChooseImport, value: PortType.ChooseImport },
    ];
}

