
export enum ShieldFlag {
    Active = 0,
    Resigned = 1
}


export type TypeShieldFlag = keyof typeof ShieldFlag;

const ShieldFlagNames: { [K in TypeShieldFlag]: string } = {
    Active: "在职",
    Resigned: "离职",
};

export function getShieldFlagNames(e: TypeShieldFlag) {
    return ShieldFlagNames[e];
}