import { Descriptions } from "antd";
import { useContext, useEffect, useState } from "react";
import { HomeProviderContext } from "./HomeProvider";
import { StackerList } from "./StackerList";

interface SlotInfoData {
    ratio: string;
    emptyCount: number;
    noEmptyCount: number;
    totalSlot: number;
}

function Index() {
    const provider = useContext(HomeProviderContext);
    const [slotInfoData, setSlotInfoData] = useState<SlotInfoData>({} as any);
    useEffect(() => {
        return provider.slotRatioUse.listen(s => {
            const noEmptyCount = s.hasSomething + s.importing + s.exporting;
            const totalSlot = s.empty + noEmptyCount;
            const ratio = (noEmptyCount / totalSlot * 100).toFixed(4);
            setSlotInfoData({ ratio, noEmptyCount, emptyCount: s.empty, totalSlot });
        });
    }, [provider]);

    return <div className="detail">
        <div>
            <Descriptions title="储位" column={4}>
                <Descriptions.Item label="储位利用率">{slotInfoData.ratio}%</Descriptions.Item>
                <Descriptions.Item label="空储位">{slotInfoData.emptyCount}</Descriptions.Item>
                <Descriptions.Item label="非空储位">{slotInfoData.noEmptyCount}</Descriptions.Item>
                <Descriptions.Item label="储位总计">{slotInfoData.totalSlot}</Descriptions.Item>
            </Descriptions>
            <StackerList />
        </div>
    </div >;
}

export default Index;