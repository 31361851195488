import dayjs, { Dayjs } from "dayjs";

export function utcStartDay(d: Dayjs) {
    return d.startOf("day").valueOf();
}
export function utcStartSecond(d: Dayjs) {
    return d.startOf("second").valueOf();
}
export function utcStartHour(d: Dayjs) {
    return d.startOf("hour").valueOf();
}
export function utcEndHour(d: Dayjs) {
    return d.endOf("hour").valueOf();
}
export function utcEndDay(d: Dayjs) {
    return d.endOf("day").valueOf();
}
export function utcEndSecond(d: Dayjs) {
    return d.endOf("second").valueOf();
}
export function toYMDHMS(v?: number) {
    if (!v) return '';
    return dayjs(v).format("YYYY-MM-DD HH:mm:ss");
}

export function toYMD(v?: number) {
    if (!v) return '';
    return dayjs(v).format("YYYY-MM-DD");
}

export const DAY = 24 * 60 * 60 * 1000;

export function getOnlyHMS(v1: number, v2: number) {
    let seconds = Math.floor((v2 - v1) / 1000);
    const flag = seconds < 0;
    if (flag) seconds = Math.abs(seconds);
    let res = '';
    if (seconds > 3600) {
        res += `${Math.floor(seconds / 3600)}时`;
        seconds = seconds % 3600;
    }
    if (seconds >= 60) {
        res += `${Math.floor(seconds / 60)}分`;
        seconds = seconds % 60;
    }
    if (seconds > 0) {
        res += `${seconds}秒`;
    }
    if (flag) return `-${res}`;
    return res;
}

export function resolveEquipmentTime(seconds: number) {
    const flag = seconds < 0;
    if (flag) seconds = Math.abs(seconds);
    let res = '';
    if (seconds > 3600) {
        res += `${Math.floor(seconds / 3600)}时`;
        seconds = seconds % 3600;
    }
    if (seconds >= 60) {
        res += `${Math.floor(seconds / 60)}分`;
        seconds = seconds % 60;
    }
    if (seconds > 0) {
        res += `${seconds}秒`;
    }
    if (flag) return `-${res}`;
    return res;
}


export function local(data: Dayjs): Dayjs {
    // return (data as any).local();
    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    console.log(data);
    return data;
}