import ExportBillApi from "@/Api/ExportBillApi";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { numProps, strProps, strSorter } from "@/Common/Table/column";
import { TableInnerButton } from "@/Common/Table/getTableAction";
import { ColumnType } from "@/Common/Table/type";
import { toYMDHMS } from "@/Common/date";
import { ExportBillExecuteFlag, ExportBillExecuteFlagNames, getExportBillExecuteFlagName } from "@/Models/Enums/ExportBillExecuteFlag";
import { UploadOrderType } from "@/Models/Enums/UploadOrderType";
import { ExportBillSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewExportBill } from "@/Models/Views/ViewExportBill";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SearchComponent } from "../../Import/QueryBill/SearchComponent";
import { UploadOrderLogComponent } from "../../Import/QueryOrder/OrderUploadLogComponent";
import { expandable } from "../Bill/NotifyList/expandable";
import { ResetUploadComponent } from "../QueryOrder/ResetUploadComponent";

function getColumns(setUploadLogData: (data: ViewExportBill) => void): ColumnType<ViewExportBill>[] {
    return [
        numProps("id", "Id", 80, true),
        strProps("typeName", "单据类型", 120, true, true),
        { title: "出库单编码", dataIndex: "code", width: 160, ellipsis: true, render: (v, r) => <TableInnerButton onClick={() => setUploadLogData(r)} type="link" >{v}</TableInnerButton> },
        strProps("platformName", "月台", 120, true, true),
        strProps("supplierName", "供应商", 120, true, true),   strProps("customerName", "客户", 120, true, true),     
        numProps("quantity", "计划数量", 100),
        numProps("completeQuantity", "已完成数量", 120),
        strProps("executeFlag", "执行标志", 100, true, true, getExportBillExecuteFlagName),
        { title: "上传", dataIndex: "uploadFlag", width: 80, sorter: strSorter("uploadFlag"), render: (_, r) => r.executeFlag === "Finished" ? <ResetUploadComponent record={r} reSetUploadFlag={ExportBillApi.reSetUploadFlag} /> : undefined },
        strProps("userName", "创建人", 100, true, true),
        numProps("time", "建单时间", 160, true, toYMDHMS),
    ];
}

export default QueryBill;
function QueryBill() {
    const [uploadLogData, setUploadLogData] = useState<ViewExportBill>();
    const onClearUploadLogData = useCallback(() => setUploadLogData(undefined), []);
    const columns = useMemo(() => getColumns(setUploadLogData), []);
    const searchProps = useRef<ExportBillSplitPageProps>({ current: 1, pageSize: 13 });
    const [data, setData] = useState<PaginationData<ViewExportBill>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        ExportBillApi.getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);
    useEffect(() => {
        onSearch({});
    }, [onSearch]);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} flagOptions={Object.keys(ExportBillExecuteFlagNames).map((s: any) => ({ label: (ExportBillExecuteFlagNames as any)[s], value: ExportBillExecuteFlag[s] }))} />
        <CommonTable rowKey={s => s.id} heightOffset={83} expandable={expandable} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange, showSizeChanger: true }} />
        <PrintFooter data={data.items} title="出库单" columns={columns} />
        {uploadLogData && <UploadOrderLogComponent id={uploadLogData.id!} orderNo={uploadLogData.code} type={UploadOrderType.ExportBill} onCancel={onClearUploadLogData} />}
    </Fragment>;
}

