import { getViews } from "@/Api/AccessLogController";
import { toYMDHMS } from "@/Common/date";
import { numProps, strProps } from "@/Common/Table/column";
import { CommonTable } from "@/Common/Table/EditTable";
import { PrintFooter } from "@/Common/Table/PrintFooter";
import { renderCopyable } from "@/Common/Table/renderCopyable";
import { ColumnType } from "@/Common/Table/type";
import { AccessLogSplitPageProps, useQueryPageChange } from "@/Models/Other/BaseSplitPageProps";
import { PaginationData } from "@/Models/Other/PaginationData";
import { ViewAccessLog } from "@/Models/Views/ViewAccessLog";
import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import { SearchComponent } from "./SearchComponent";

function getColumns(): ColumnType<ViewAccessLog>[] {
    return [
        strProps("userName", "用户", 80, true, true),
        strProps("ip", "Host", 140, true, true),
        numProps("port", "端口", 80, true),
        strProps("method", "请求方法", 80, true, true),
        strProps("url", "请求URL", 200, true, true),
        strProps("query", "参数", 100, true, true),
        strProps("requestBody", "请求内容", 200, false, false, renderCopyable),
        numProps("statusCode", "响应状态", 80, false),
        strProps("responseBody", "返回值", 200, false, false, renderCopyable),
        numProps("reqTime", "请求时间", 160, true, toYMDHMS),
        numProps("reqTime", "响应时间", 160, true, toYMDHMS),
        { title: "耗时", width: 120, key: "cost", render: (_, r) => `${(r.resTime - r.reqTime)}ms` }
    ];
}

export default AccessLog;
function AccessLog() {
    const columns = useMemo(getColumns, []);

    const searchProps = useRef<AccessLogSplitPageProps>({ current: 1, pageSize: 14 });
    const [data, setData] = useState<PaginationData<ViewAccessLog>>({ items: [], current: searchProps.current.current, pageSize: searchProps.current.pageSize, total: 1 });
    const [loading, setLoading] = useState(false);
    const query = useCallback((current: number, pageSize: number) => {
        setLoading(true);
        searchProps.current.current = current;
        searchProps.current.pageSize = pageSize;
        getViews(searchProps.current).then(setData).catch(console.error).finally(() => setLoading(false));
    }, []);
    const onSearch = useCallback((data: {}) => {
        searchProps.current = { ...searchProps.current, ...data, current: 1 };
        query(searchProps.current.current, searchProps.current.pageSize);
    }, [query]);
    useEffect(() => {
        onSearch({});
    }, [onSearch]);
    const pageChange = useQueryPageChange(searchProps, query);
    return <Fragment>
        <SearchComponent onSearch={onSearch} />
        <CommonTable rowKey={s => s.id} heightOffset={83} loading={loading} columns={columns} dataSource={data.items} pagination={{ total: data.total, pageSize: data.pageSize, current: data.current, onChange: pageChange, showSizeChanger: true }} />
        <PrintFooter data={data.items as any} title="接口调用日志" columns={columns as any} />
    </Fragment>;
}
