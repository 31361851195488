import { Obs } from "@/Common/Obs";
import { tpOnError } from "@/Hubs/BaseHub";
import { ImportBillHub } from "@/Hubs/ImportBillHub";
import { ViewImportBill } from "@/Models/Views/ViewImportBill";
import { createContext } from "react";

export class ImportBillHubProvider {
    hub: ImportBillHub;
    constructor(onError: tpOnError) {
        this.hub = new ImportBillHub(onError);
        this.hub.on("ExecuteFlagChange", this._dataChange);
        this.hub.on("Add", s => this.data.Value = [...this.data.Value, s]);
        this.hub.start(async () => {
            this.loading.Value = true;
            try {
                this.data.Value = await this.hub.getUnFinishedList();
            } catch (error) {
                console.log(error);
            }
            finally {
                this.loading.Value = false;
            }
        });
    }

    private _dataChange = (data: ViewImportBill) => {
        const index = this.data.Value.findIndex(s => s.id === data.id);
        if (index > -1) {
            const d1 = [...this.data.Value];
            if (data.executeFlag === "Finished" || data.executeFlag === "Cancel") {
                d1.splice(index, 1);
            }
            else {
                d1[index] = data;
            }
            this.data.Value = d1;
        }
    }
    public loading = new Obs(false);
    public data = new Obs<ViewImportBill[]>([]);
}

export const ImportBillHubProviderContext = createContext<ImportBillHubProvider>(null as any);

