import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateHubProvider } from '@/Hubs/HubError';
import { Locations } from './Locations';
import { CutLine } from './CutLine';
import { Stackers } from './Stackers';
import { StackerProvider, StackerProviderContext } from './StackerProvider';
import { ConveyerProvider, ConveyerProviderContext } from './ConveyerProvider';
import { LocationPositionProvider, LocationPositionProviderContext } from './LocationPositionProvider';
import { StackerPositionProvider, StackerPositionProviderContext } from './StackerPositionProvider';
import { ConveyerLocationBuilder, ConveyerLocationBuilderContext } from './Conveyer/ConveyerLocationBuilder';
import { SlotOperation } from '@/Pages/Wms/Components/Stock/StaticSlot/SlotOperation';
import { SlotProvider, SlotProviderContext } from '@/Pages/Wms/Components/Equipment/SlotManager/SlotProvider';
import './index.scss';


export default App;

function App() {
    const slotProvider = useCreateHubProvider(SlotProvider);
    const stackerProvider = useCreateHubProvider(StackerProvider);
    const conveyerProvider = useCreateHubProvider(ConveyerProvider);
    const slotPositionProvider = useMemo(() => new LocationPositionProvider(), []);
    const stackerPositionProvider = useMemo(() => new StackerPositionProvider(), []);
    const conveyerPositionBuilder = useMemo(() => new ConveyerLocationBuilder(), []);
    const [showData, setShowData] = useState(slotProvider.showStockInfo.Value);
    const onCancel = useCallback(() => slotProvider.showStockInfo.Value = null, [slotProvider]);
    useEffect(() => slotProvider.showStockInfo.listen(setShowData), [slotProvider.showStockInfo]);

    return (
        <SlotProviderContext.Provider value={slotProvider}>
            <StackerProviderContext.Provider value={stackerProvider}>
                <ConveyerProviderContext.Provider value={conveyerProvider}>
                    <LocationPositionProviderContext.Provider value={slotPositionProvider}>
                        <StackerPositionProviderContext.Provider value={stackerPositionProvider}>
                            <ConveyerLocationBuilderContext.Provider value={conveyerPositionBuilder}>
                                <div className="wcs-root">
                                    <div className='other'>
                                        <CutLine />
                                    </div>
                                    <div className='wcs-background'></div>
                                    <WarehouseMap plcNo={1} warehouseId={1} />
                                    {showData && <div>
                                        <SlotOperation data={showData} onCancel={onCancel} />
                                    </div>}
                                </div>
                            </ConveyerLocationBuilderContext.Provider>
                        </StackerPositionProviderContext.Provider>
                    </LocationPositionProviderContext.Provider>
                </ConveyerProviderContext.Provider>
            </StackerProviderContext.Provider>
        </SlotProviderContext.Provider>
    );
}

function WarehouseMap({ plcNo, warehouseId }: { plcNo: number, warehouseId: number }) {
    return <div className={`warehouse warehouse-${warehouseId}`} >
        <Locations plcNo={plcNo} warehouseId={warehouseId} />
        <Stackers plcNo={plcNo} warehouseId={warehouseId} />
    </div >;
}

