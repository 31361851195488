export enum TaskMode {
    All = 0,
    Import = 1,
    Export = 1 << 1,
    Move = 1 << 2,// 移库
    Check = 1 << 3,// 盘点
    Auto = 1 << 4,// 自动
    EmptyStock = 1 << 5,
    Slot = 1 << 6,
    Order = 1 << 7,
    Batch = 1 << 8,
    Choose = 1 << 9,
    Bill = 1 << 10,
    PDA = 1 << 13,
    Palletizer = 1 << 17,
    DePalletizer = 1 << 18,
    MoveShuttle = (1 << 19),// 巷道内移车
    ImportShuttle = (1 << 20) | Import,// 巷道内入车
    ExportShuttle = (1 << 21) | Export,// 巷道内出车
    ChargeShuttle = (1 << 22),// 穿梭车充电
    MoveStackerShuttle = (1 << 23),// 巷道内堆垛机移车
    MoveBeforeSlot = (1 << 11) | Move,
    MoveEmptyStockToConveyer = (1 << 12) | Move,
    PDAImport = PDA | Import,
    AutoImport = Auto | Import,
    PalletizerImport = Palletizer | Import,// 码盘机入库
    DePalletizerImport = DePalletizer | Import,// 拆盘机入库
    MoveBeforeExportTask = (1 << 14) | Move,//移动前面的出库任务
    MoveInLaneway = (1 << 15) | Move,// 巷道内移库
    CheckImport = Check | Import,
    CheckExport = Check | Export,
    EmptyStockImport = Import | EmptyStock,
    EmptyStockExport = Export | EmptyStock,
    FaultExport = Export | (1 << 16),
    SlotExport = Export | Slot,
    BatchExport = Export | Batch,
    OrderImport = Import | Order,
    ChooseExport = Choose | Export,
    OrderExport = Order | Export,
    BillExport = Bill | Export,
}

export type TypeTaskMode = keyof typeof TaskMode;

export const TaskModeNames: { [K in TypeTaskMode]: string } = {
    Import: "入库模式",
    Export: "出库模式",
    EmptyStock: "空托盘",
    Slot: "储位",
    Order: "流水",
    Batch: "批量",
    Choose: "拣选",
    Bill: "单据",
    OrderImport: "流水入库",
    EmptyStockImport: "空托盘入库",
    SlotExport: "储位出库",
    BatchExport: "批量出库",
    ChooseExport: "拣选出库",
    BillExport: "单据出库",
    Move: "移库",
    EmptyStockExport: "空托盘出库",
    OrderExport: "流水出库",
    MoveEmptyStockToConveyer: "托盘移动",
    MoveBeforeSlot: "出库移库",
    MoveBeforeExportTask: "任务移库",
    PDAImport: "手持入库",
    All: "所有任务",
    Check: "盘点",
    CheckExport: "盘点出库",
    CheckImport: "盘点入库",
    MoveInLaneway: "巷道移库",
    Auto: "自动",
    AutoImport: "自动入库",
    PDA: "手持",
    FaultExport: "异常出库",
    DePalletizer: "拆垛",
    DePalletizerImport: "拆垛入库",
    PalletizerImport: "码垛入库",
    Palletizer: "码垛",

    MoveShuttle :"巷道内穿梭车移车",
    ImportShuttle :"巷道内入车",
    ExportShuttle:" 巷道内出车",
    ChargeShuttle:"穿梭车充电",
    MoveStackerShuttle:"巷道内堆垛机移车"

};

export function getTaskModeNames(e: TypeTaskMode) {
    return TaskModeNames[e];
}

export function getTaskModeSelectOptions() {
    return [
        {
            label: TaskModeNames.AutoImport, value: TaskMode.AutoImport
        },
        {
            label: TaskModeNames.BillExport, value: TaskMode.BillExport
        },
        {
            label: TaskModeNames.MoveBeforeExportTask, value: TaskMode.MoveBeforeExportTask
        },
        {
            label: TaskModeNames.MoveBeforeSlot, value: TaskMode.MoveBeforeSlot
        },
        {
            label: TaskModeNames.MoveInLaneway, value: TaskMode.MoveInLaneway
        },
        {
            label: TaskModeNames.EmptyStockImport, value: TaskMode.EmptyStockImport
        },
        {
            label: TaskModeNames.EmptyStockExport, value: TaskMode.EmptyStockExport
        },
        {
            label: TaskModeNames.MoveShuttle, value: TaskMode.MoveShuttle
        },
        {
            label: TaskModeNames.ImportShuttle, value: TaskMode.ImportShuttle
        },  {
            label: TaskModeNames.ExportShuttle, value: TaskMode.ExportShuttle
        }, {
            label: TaskModeNames.ChargeShuttle, value: TaskMode.ChargeShuttle
        },

        {
            label: TaskModeNames.MoveStackerShuttle, value: TaskMode.MoveStackerShuttle
        },
        
    ];
}