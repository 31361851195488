export enum StockFlag {
    Free = 0,
    Occupy = 1,//占用
}

export type TypeStockFlag = keyof typeof StockFlag;

const stockFlagNames: { [K in TypeStockFlag]: string } = {
    Free: "正常",
    Occupy: "占用",
};

export function getStockFlagName(e: TypeStockFlag) {
    return stockFlagNames[e];
}


