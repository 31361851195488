import { CommonTable } from "@/Common/Table/EditTable";
import { TaskMode } from "@/Models/Enums/TaskMode";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { EquipmentTaskProviderContext, useEquipmentTaskProvider } from "../../Equipment/Task/EquipmentTaskProvider";
import { getColumns } from "../../Equipment/Task";


export default Index;
function Index() {
    const columns = useMemo(getColumns, []);
    const taskProvider = useEquipmentTaskProvider(TaskMode.Export);
    const [data, setData] = useState(taskProvider.data.Value);
    useEffect(() => taskProvider.data.listen(setData), [taskProvider]);
    return <EquipmentTaskProviderContext.Provider value={taskProvider}>
        <CommonTable rowKey={s => s.id} columns={columns} dataSource={data} />
    </EquipmentTaskProviderContext.Provider>;
}