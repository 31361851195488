import { SlotLockFlag } from "@/Models/Enums/SlotLockFlag";
import { ViewSlot } from "@/Models/Views/ViewSlot";
import { BaseHub, tpOnError } from "./BaseHub";
import { ISlotHubAction } from "./IActions/ISlotHubAction";

export class SlotHub extends BaseHub<ISlotHubAction>{
    constructor(onError: tpOnError) {
        super(onError, "Hubs/Slot");
    }
    public getViews = () => this.hub.invoke<ViewSlot[]>("GetViews");
    public updLockFlag = (slotId: number, flag: SlotLockFlag) => this.hub.invoke<number>("updLockFlag", slotId, flag);
}




